/**
 * Refer:
 * UA to GA4 Migration: https://support.google.com/analytics/answer/10119380?hl=en
 */

import {
  CONTACT_US_FORM_SUBMISSION,
  SET_COMMUNITY_ACCESS_TYPE_EVENT,
  TRACK_ROUTE_CHANGE
} from './analyticsConsts';
import {
  IS_DEV_ENV,
  TRACK_AD_GROUP_ID_KEY,
  TRACK_AD_GROUP_NAME_KEY,
  TRACK_AD_ID_KEY,
  TRACK_AD_NAME_KEY,
  TRACK_CAMPAIGN_ID_KEY,
  TRACK_CAMPAIGN_KEY,
  TRACK_CLICK_ID_KEY,
  TRACK_CREATIVE_ID_KEY,
  TRACK_MEDIUM_KEY,
  TRACK_SOURCE_KEY
} from '../utility/constants';
/* eslint-disable no-undef */
import {
  getCommunityDataByCode,
  getCommunityPricingService
} from '@/services/communitiesService';
import {
  getCourseCardsData,
  getUpcomingCourseOfferingsByCourseCode
} from '../services/courseService';

import TagManager from 'react-gtm-module';
import config from './config';
import sha1 from 'crypto-js/sha1';
import {
  getCommunityIdFromSessionStorage,
  getLoggedInUserEmailFromStorage
} from './storageHelpers';

const isGA4 =
  process.env.NEXT_PUBLIC_DOMAIN_REGION == 'philippines' ? true : false;

export const initiateGTM = () => {
  const tagManagerArgs = {
    gtmId: config?.gtmId
  };

  TagManager.initialize(tagManagerArgs);
  //To track if tag manager is already loaded to prevent multiple loads
  window.gtmLoaded = true;
};

//Optimize
export const initialOptimizeExperimentVariant = '0';

export const callOptimizeEvent = async function (experimentId, callback) {
  let intervalId = setInterval(() => {
    if (window.google_optimize !== undefined) {
      const variant = window.google_optimize.get(experimentId);
      if (variant) {
        callback(variant);
        clearInterval(intervalId);
      }
    }
  }, 100);
};

export const trackBundlePurchase = (data = {}) => {
  window.dataLayer?.push({
    event: 'BundlePurchase',
    data
  });
};

/**
 * GA4 Events for Community Manager Portal
 */
/**
 * 
 * community: {
      name: data.community.name,
      code: data.community.code,
      id: data.community.id
    }
 */

export const trackButtonClicks = (data = {}) => {
  window.dataLayer?.push({
    event: 'button_clicks',
    button: {
      text: data?.button?.text,
      location: data?.button?.location, //To idenitfy where button is located
      description: data?.button?.description, //To idenitfy which button specifically
      event: data?.button?.event, //To idenitfy when button is triggered
      event_description: data?.button?.eventDescription //To idenitfy what the button does
    },
    community: {
      ...data?.community
    }
  });
};

export const trackGAEventWithCommunityAndUser = (eventId, payload) => {
  // Get User Data from Local Storage
  const user = JSON.parse(localStorage.getItem('adminUserData'));

  // Get Community Data from Session Storage
  const community = JSON.parse(sessionStorage.getItem('activeCommunity'));
  const fullPayload = {
    ...(payload || {}),
    // This is override to the correct storage based answer
    communityCode: community?.code,
    communityId: community?._id,
    email: user?.email,
    userId: user?._id
  };
  trackGAEvent(eventId, fullPayload);
};

export const trackGAEvent = async (eventId, payload) => {
  if (typeof window === 'undefined') {
    return;
  }

  let data = {
    action: eventId,
    timestamp: new Date().getTime(),

    ...payload
  };

  //this change is only applicable to client-side
  if (!window.dataLayer) {
    window.dataLayer = [];
  }
  const user = JSON.parse(localStorage.getItem('adminUserData'));

  // Add email and userId by defauilt if user is logged in
  if (user) {
    data = {
      ...data,
      email: user?.email,
      userId: user?._id
    };
  }

  window.dataLayer.push({
    event: 'nasio_general_events',
    data
  });
};

export const trackRouteChange = (route) => {
  const communityId = getCommunityIdFromSessionStorage();
  const email = getLoggedInUserEmailFromStorage();

  const payload = {
    email,
    communityId,
    link: route
  };
  trackGAEvent(TRACK_ROUTE_CHANGE, payload);
};

export const trackFormSubmission = (data = {}) => {
  window.dataLayer?.push({
    event: 'form_submission',
    form: {
      type: data?.form?.type, // which form it is
      event: data?.form?.event // To idenitfy where button is located
    },
    community: {
      ...data?.community
    }
  });
};
/**
 * HELPER FUNCTIONS
 */

// Formats GA4 item from {title, code, mode, by, university, currency, price, quantity, promoCode}
export const formatItemInGA4Format = (data) => {
  let item = {
    item_name: data.title,
    item_id: data.code,
    item_variant: data.mode,
    item_brand: data.by,
    affiliation: data.university,
    currency: data.currency ? data.currency : 'USD',
    value: data.price ? data.price : 0,
    quantity: data?.quantity ? data.quantity : 1,
    coupon: data?.promoCode ? data.promoCode : ''
  };

  if (data.categories) {
    const categories = Object.keys(data.categories);
    categories.forEach((category, index) => {
      if (index === 0) {
        item[`item_category`] = category;
      } else {
        item[`item_category${index + 1}`] = category;
      }
    });
  }
  return item;
};

// Formats GA4 item from {title, code, mode, by, university, currency, price, quantity, promoCode}
export const formatCommunityItemInGA4Format = (data) => {
  let item = {
    item_name: data.title,
    item_id: data.code,
    item_variant: data.mode,
    item_brand: data.By,
    affiliation: data.university,
    currency: data.currency ? data.currency : 'USD',
    value: data.unit_amount ? data.unit_amount : 0,
    quantity: data?.quantity ? data.quantity : 1,
    coupon: data?.promoCode ? data.promoCode : ''
  };

  if (data.categories) {
    const categories = Object.keys(data.categories);
    categories.forEach((category, index) => {
      if (index === 0) {
        item[`item_category`] = category;
      } else {
        item[`item_category${index + 1}`] = category;
      }
    });
  }
  return item;
};

/**
 * Get product data from a list of courseCodes
 * @param {*} courseCodesList
 * @returns an array of items in GA4 item format
 */
const getEcommerceProductList = async (courseCodesList) => {
  const { data } = await getCourseCardsData(courseCodesList);
  let items = [];
  const courses = Object.keys(data);
  courses.forEach((course) => {
    const dataItem = data[course];
    if (dataItem) {
      const item = formatItemInGA4Format(dataItem);
      items.push(item);
    }
  });
  return items;
};

/**
 * Get product data from a community code
 * @param {*} communityCode
 * @returns an array of items in GA4 item format
 */
const getEcommerceCommunity = async (communityCode) => {
  const { data } = await getCommunityDataByCode(communityCode);
  let items = [];
  const dataItem = data.data;
  if (dataItem) {
    const stripeId = dataItem.stripeProductId;
    const { data } = await getCommunityPricingService(stripeId);
    const priceForOneMonth = data.find(
      (x) =>
        x.recurring.interval_count === 1 &&
        x.recurring.interval === 'month'
    );
    const item = formatCommunityItemInGA4Format({
      ...dataItem,
      ...priceForOneMonth
    });
    items.push(item);
  }

  return items;
};

export const resetEcommerce = async (
  event,
  data,
  userData = {},
  courses = [],
  communityCode = ''
) => {
  if (!window.dataLayer) return;
  window.dataLayer?.push({
    ecommerce: null
  });
  let items = [];
  let quantity = 1;
  if (courses.length === 0 && communityCode.length === 0) {
    const item = formatItemInGA4Format(data);
    items = [item];
  } else if (communityCode.length != 0) {
    items = await getEcommerceCommunity(communityCode);
    quantity = 1;
  } else {
    items = await getEcommerceProductList(courses);
    quantity = courses.length;
  }
  window.dataLayer?.push({
    event,
    ecommerce: {
      items,
      value: data.price,
      currency: data.currency,
      title: data?.title,
      affiliation:
        data?.contentType == 'product_group'
          ? data.bundleSlug
          : data.university,
      transaction_id: data?.transactionId,
      content_type: data?.contentType ? data?.contentType : 'product',
      quantity
    },
    data: userData
  });
};

// enhanced ecommerce
export const trackCoursePageView = (data = {}) => {
  if (!window.dataLayer) return;
  resetEcommerce('CoursePageView', data);
};

export const trackCommunityPageView = (data = {}) => {
  if (!window.dataLayer) return;
  resetEcommerce('CommunityPageView', data);
};

export const trackCheckoutPageView = (data = {}) => {
  if (!window.dataLayer) return;
  resetEcommerce('CheckoutPageView', data);
};

export const trackAddPaymentInfo = (data = {}) => {
  window.dataLayer?.push({
    event: 'AddPaymentInfo',
    data
  });
};

// when cta is clicked
export const trackInitiateCheckout = (data = {}) => {
  trackGAEvent('initiate_checkout', data);
};

// when email is filled
export const trackBeginCheckout = (data = {}) => {
  trackGAEvent('checkout_fill_email', data);
};

export const trackAddDiscountClick = () => {
  window.dataLayer?.push({
    event: 'DiscountClick'
  });
};

// Tracking homepage clicks
// Track button click

/**
 *
 * {
 *  page: 'HomePage',
 *  section: 'Banner',
 *  time: <time>,
 *  time: <timezone>
 * }
 */
export const startCommunityForFreeButtonClick = (data = {}) => {
  window.dataLayer?.push({
    event: 'startCommunityForFreeButtonClick',
    data
  });
};

/**
 *
 * {
 *  page: 'StartCommunityForm',
 *  email: <email>,
 *  time: <time>,
 *  timeZone: <timezone>
 * }
 */
export const startCommunityAplicationFormSubmit = (data = {}) => {
  window.dataLayer?.push({
    event: 'startCommunityAplicationFormSubmit',
    data
  });
};

/**
 *
 * {
 *  page: 'StartCommunityForm',
 *  email: <email>,
 *  time: <time>,
 *  timeZone: <timezone>
 * }
 */
export const startCommunityAplicationSuccessfulSubmit = (data = {}) => {
  window.dataLayer?.push({
    event: 'startCommunityAplicationSuccessfulSubmit',
    data
  });
};

export const trackSelectStripeClick = () => {
  window.dataLayer?.push({
    event: 'SelectPaymentOptionStripe',
    data: {
      category: 'stripe',
      action: 'click',
      label: 'stripe'
    }
  });
};

export const trackSelectRapydClick = () => {
  window.dataLayer?.push({
    event: 'SelectPaymentOptionRapyd',
    data: {
      category: 'rapyd',
      action: 'click',
      label: 'rapyd'
    }
  });
};

export const trackSelectRapydOptionClick = (option) => {
  window.dataLayer?.push({
    event: `SelectPaymentOptionRapyd-${option}`,
    data: {
      category: 'rapyd',
      action: 'click',
      label: option
    }
  });
};

export const trackApplication = (application) => {
  window.dataLayer?.push({
    event: 'Application',
    data: {
      courseOfferingId: application.courseOfferingId || '',
      courseOfferingTitle: application.courseOfferingTitle || '',
      eventID: application.event_id ?? '',
      contentType: application.contentType || 'product'
    }
  });
};

export const trackPurchase = (paymentTrackingOptions) => {
  window.dataLayer?.push({
    event: 'Purchase',
    data: {
      courseOfferingId: paymentTrackingOptions.courseOfferingId || '',
      courseOfferingTitle:
        paymentTrackingOptions.courseOfferingTitle || '',
      paymentAmount: paymentTrackingOptions.value,
      currency: paymentTrackingOptions.currency ?? 'USD',
      pricePerItem: paymentTrackingOptions.pricePerItem,
      quantity: paymentTrackingOptions.quantity || 1,
      eventID: paymentTrackingOptions.event_id ?? '',
      contentType: paymentTrackingOptions.contentType || 'product'
    }
  });
};

// Uses ecommerce from prev ecommerce push aka CoursePageView
// data should have email, and lead_type
export const trackLead = (data = {}) => {
  window.dataLayer?.push({
    event: 'Lead',
    data: {
      email: data.email,
      phone: data.phoneNumber,
      lead_type: data.leadType
    }
  });
};

const trackConversionImpactWrapper = (paymentTrackingOptions) => {
  ire(
    'trackConversion',
    27490,
    {
      orderId: paymentTrackingOptions.orderId,
      clickid: paymentTrackingOptions.clickId,
      customerId: paymentTrackingOptions.customerId,
      customerEmail: sha1(paymentTrackingOptions.customerEmail),
      customerStatus: paymentTrackingOptions.customerStatus,
      currencyCode: paymentTrackingOptions.currency,
      orderPromoCode: paymentTrackingOptions.coupon,
      items: [
        {
          subTotal: paymentTrackingOptions.price,
          category: paymentTrackingOptions.courseCode,
          sku: paymentTrackingOptions.courseOfferingId ?? '',
          quantity: paymentTrackingOptions.quantity,
          name: paymentTrackingOptions.courseName
        }
      ]
    },
    { verifySiteDefinitionMatch: true }
  );
};

export const getTrackingInformationForBE = (params = {}) => {
  let trackingData = {
    source: 'organic',
    ...params
  };
  if (typeof window !== 'undefined') {
    if (localStorage.getItem(TRACK_CLICK_ID_KEY)) {
      trackingData[TRACK_CLICK_ID_KEY] =
        localStorage.getItem(TRACK_CLICK_ID_KEY);
    }
    if (localStorage.getItem(TRACK_SOURCE_KEY)) {
      trackingData[TRACK_SOURCE_KEY] =
        localStorage.getItem(TRACK_SOURCE_KEY);
    }
    if (localStorage.getItem(TRACK_MEDIUM_KEY)) {
      trackingData[TRACK_MEDIUM_KEY] =
        localStorage.getItem(TRACK_MEDIUM_KEY);
    }
    if (localStorage.getItem(TRACK_AD_GROUP_ID_KEY)) {
      trackingData[TRACK_AD_GROUP_ID_KEY] = localStorage.getItem(
        TRACK_AD_GROUP_ID_KEY
      );
    }
    if (localStorage.getItem(TRACK_AD_GROUP_NAME_KEY)) {
      trackingData[TRACK_AD_GROUP_NAME_KEY] = localStorage.getItem(
        TRACK_AD_GROUP_NAME_KEY
      );
    }
    if (localStorage.getItem(TRACK_AD_ID_KEY)) {
      trackingData[TRACK_AD_ID_KEY] =
        localStorage.getItem(TRACK_AD_ID_KEY);
    }
    if (localStorage.getItem(TRACK_AD_NAME_KEY)) {
      trackingData[TRACK_AD_NAME_KEY] =
        localStorage.getItem(TRACK_AD_NAME_KEY);
    }
    if (localStorage.getItem(TRACK_CAMPAIGN_ID_KEY)) {
      trackingData[TRACK_CAMPAIGN_ID_KEY] = localStorage.getItem(
        TRACK_CAMPAIGN_ID_KEY
      );
    }
    if (localStorage.getItem(TRACK_CAMPAIGN_KEY)) {
      trackingData[TRACK_CAMPAIGN_KEY] =
        localStorage.getItem(TRACK_CAMPAIGN_KEY);
    }
    if (localStorage.getItem(TRACK_CREATIVE_ID_KEY)) {
      trackingData[TRACK_CREATIVE_ID_KEY] = localStorage.getItem(
        TRACK_CREATIVE_ID_KEY
      );
    }
  }
  return trackingData;
};

export const storeTrackingInformation = (router_query) => {
  if (
    router_query?.irclickid ||
    router_query?.fbclid ||
    router_query?.gclid ||
    router_query?.ttclid ||
    router_query?._kx
  ) {
    localStorage.removeItem(TRACK_AD_GROUP_ID_KEY);
    localStorage.removeItem(TRACK_AD_GROUP_NAME_KEY);
    localStorage.removeItem(TRACK_AD_ID_KEY);
    localStorage.removeItem(TRACK_AD_NAME_KEY);
    localStorage.removeItem(TRACK_CAMPAIGN_ID_KEY);
    localStorage.removeItem(TRACK_CAMPAIGN_KEY);
    localStorage.removeItem(TRACK_CREATIVE_ID_KEY);
  }
  if (router_query?.irclickid) {
    localStorage.setItem('irclickid', router_query?.irclickid);
    localStorage.setItem(TRACK_CLICK_ID_KEY, router_query?.irclickid);
    localStorage.setItem(TRACK_SOURCE_KEY, 'impact');
    localStorage.removeItem(TRACK_MEDIUM_KEY);
  } else if (router_query?.fbclid) {
    localStorage.setItem(TRACK_CLICK_ID_KEY, router_query?.fbclid);
    localStorage.setItem(TRACK_SOURCE_KEY, 'fb');
    localStorage.setItem(TRACK_MEDIUM_KEY, 'cpc');
  } else if (router_query?.gclid) {
    localStorage.setItem(TRACK_CLICK_ID_KEY, router_query?.gclid);
    localStorage.setItem(TRACK_SOURCE_KEY, 'google');
    localStorage.setItem(TRACK_MEDIUM_KEY, 'cpc');
  } else if (router_query?.ttclid) {
    localStorage.setItem(TRACK_CLICK_ID_KEY, router_query?.ttclid);
    localStorage.setItem(TRACK_SOURCE_KEY, 'tiktok');
    localStorage.setItem(TRACK_MEDIUM_KEY, 'cpc');
  } else if (router_query?._kx) {
    localStorage.setItem(TRACK_CLICK_ID_KEY, router_query?._kx);
    localStorage.setItem(TRACK_SOURCE_KEY, 'klaviyo');
    localStorage.setItem(TRACK_MEDIUM_KEY, 'email');
  }
  // utm parameters take precedence for source, medium and campaign
  if (router_query?.utm_source) {
    localStorage.setItem(TRACK_SOURCE_KEY, router_query?.utm_source);
  }
  if (router_query?.utm_medium) {
    localStorage.setItem(TRACK_MEDIUM_KEY, router_query?.utm_medium);
  }
  if (router_query?.adgroupid) {
    localStorage.setItem(TRACK_AD_GROUP_ID_KEY, router_query?.adgroupid);
  }
  if (router_query?.adgroupname) {
    localStorage.setItem(
      TRACK_AD_GROUP_NAME_KEY,
      router_query?.adgroupname
    );
  }
  if (router_query?.adid) {
    localStorage.setItem(TRACK_AD_ID_KEY, router_query?.adid);
  }
  if (router_query?.adname) {
    localStorage.setItem(TRACK_AD_NAME_KEY, router_query?.adname);
  }
  if (router_query?.campaignid) {
    localStorage.setItem(TRACK_CAMPAIGN_ID_KEY, router_query?.campaignid);
  }
  if (router_query?.utm_campaign) {
    localStorage.setItem(TRACK_CAMPAIGN_KEY, router_query?.utm_campaign);
  }
  if (router_query?.creativeid) {
    localStorage.setItem(TRACK_CREATIVE_ID_KEY, router_query?.creativeid);
  }
};

export const trackConversionImpact = (paymentTrackingOptions) => {
  const intervalIdIreCheck = setInterval(() => {
    if (window.ire) {
      trackConversionImpactWrapper(paymentTrackingOptions);
      clearInterval(intervalIdIreCheck);
    }
  }, 2000);

  return () => clearInterval(intervalIdIreCheck);
};

export const trackIdentityImpact = (user) => {
  ire('identify', {
    customerId: user ? user.user_id : '',
    customerEmail: user ? sha1(user.email) : ''
  });
};

export const impactTagEvents = {
  SEE_MORE_BTN: 'Impact - See More Btn Clicked',
  COURSE_CARD_CLICK: 'Impact - Course Card Clicked',
  MODAL_POPUP: 'Impact - Modal Popped Up',
  MODAL_SUBMIT: 'Impact - Modal Submitted'
};

export const trackWaitlistModalEvent = (event) => {
  window.dataLayer?.push({ event });
};

export const initializeTrackUserId = (id) => {
  window.dataLayer?.push({ userId: id });
};

export const clearTrackUserId = () => {
  window.dataLayer?.push({ userId: null });
};

export const trackSearchClick = ({
  eventName,
  algoliaDisplayedObjectIDs,
  userToken,
  algoliaIndexName,
  courseCode
}) => {
  window.dataLayer?.push({
    event: eventName,
    data: {
      algoliaDisplayedObjectIDs,
      algoliaIndexName,
      userToken,
      courseCode
    }
  });
};

export const trackTikTokIdentity = (data = {}) => {
  window.dataLayer?.push({ event: 'TikTokIdentity', data });
};

/**
 * Helper function to get product data
 * @param {*} courseCode
 * @returns
 */
async function getEcommerceProduct(courseCode) {
  const { data } = await getUpcomingCourseOfferingsByCourseCode(
    courseCode
  );

  const product = data?.[0];

  return {
    name: product?.courseDescription,
    id: courseCode,
    category: product?.category,
    brand: '',
    variant: product?.type,
    currency: product?.currency
  };
}

/**
 * Enhanced Ecommerce Tracking Events
 *
 */
export const trackProductClick = async (courseCode, list) => {
  const product = await getEcommerceProduct(courseCode);

  const layer = {
    ecommerce: {
      actionField: { list },
      click: {
        products: [product]
      }
    }
  };

  trackEnhancedEcommerceEvent('click', layer);
};

export const trackCourseDetailView = async (courseCode) => {
  const product = await getEcommerceProduct(courseCode);

  // Note: Don't trigger for products with no courseType (variant)
  if (!product?.variant) {
    return;
  }

  const layer = {
    ecommerce: {
      currencyCode: product.currency,
      detail: {
        products: [product]
      }
    }
  };
  trackEnhancedEcommerceEvent('detail', layer);
};

export const trackAddCourseToCart = async ({
  courseCode,
  courseOfferingId,
  currencyCode = 'USD',
  price,
  quantity = 1
}) => {
  window.dataLayer.push({ ecommerce: null });
  if (isGA4) {
    window.dataLayer?.push({
      event: 'add_to_cart',
      ecommerce: {
        currencyCode,
        add: {
          items: [
            {
              item_name: courseOfferingId,
              item_id: courseCode,
              price,
              category: courseCode,
              quantity
            }
          ]
        }
      }
    });
  } else {
    const product = await getEcommerceProduct(courseCode);
    const layer = {
      event: 'addToCart',
      ecommerce: {
        currencyCode,
        add: {
          products: [product]
        }
      }
    };
    trackEnhancedEcommerceEvent('add', layer);
  }
};

export const trackCheckout = async ({
  courseCode,
  // courseOfferingId,
  price,
  coupon = '',
  step,
  option = ''
  // currency
}) => {
  const product = await getEcommerceProduct(courseCode);

  const layer = {
    event: 'checkout',
    ecommerce: {
      checkout: {
        actionField: { step, option },
        products: [{ ...product, coupon, price }]
      }
    }
  };
  trackEnhancedEcommerceEvent('checkout', layer);
};

export const trackEnhancedPurchase = async ({
  transactionId,
  courseName,
  courseCode,
  courseOfferingId,
  price,
  coupon = '',
  quantity = 1,
  currency
}) => {
  if (isGA4) {
    window.dataLayer?.push({
      ecommerce: {
        purchase: {
          actionField: {
            id: transactionId
          },
          items: [
            {
              item_name: `${courseName} | ${courseOfferingId}`,
              item_id: courseCode,
              price,
              category: courseCode,
              coupon,
              quantity,
              currency
            }
          ]
        }
      }
    });
  } else {
    const product = await getEcommerceProduct(courseCode);
    const layer = {
      ecommerce: {
        purchase: {
          actionField: {
            id: transactionId
          },
          products: [
            { ...product, coupon, quantity, price }
            // {
            //   name: `${courseName} | ${courseOfferingId}`,
            //   id: courseCode,
            //   price,
            //   category: courseCode,
            //   coupon,
            //   quantity,
            //   currency
            // }
          ]
        }
      }
    };
    trackEnhancedEcommerceEvent('purchase', layer);
  }
};

const trackEnhancedEcommerceEvent = (metric, layer) => {
  if (!window.dataLayer) return;
  window.dataLayer?.push({ ecommerce: null });
  // All ecommerce events are tied-to `productClick` event set in GTM
  // The actual enhanced ecommerce property is captured in `layer` as `click`, `detail` etc
  window.dataLayer?.push({ event: 'productClick', ...layer });
};

export const getCommunityDataForAnalytics = (community) => {
  return {
    communityId: community?._id
  };
};

// Used when form is submitted on https://nas.io/application/contact-us page
export const trackContactUsFormSubmission = (data = {}) => {
  window.dataLayer?.push({
    event: CONTACT_US_FORM_SUBMISSION,
    data
  });
};

export const trackCommunityAccessType = (payload = {}) => {
  const data = {
    email: payload.email,
    communityCode: payload.communityCode,
    communityPaymentType: payload.communityPaymentType
  };

  window.dataLayer?.push({
    event: SET_COMMUNITY_ACCESS_TYPE_EVENT,
    data
  });
};
