import React from 'react';

const MessageChatCircle = ({ fill = '#3442B2' }) => {
  return (
    <svg width="19" height="18" fill="none" viewBox="0 0 19 18">
      <path
        fill={fill}
        fillRule="evenodd"
        d="M4.25 7.5c0-3.733 3.044-6.75 6.79-6.75 3.745 0 6.789 3.017 6.789 6.75 0 .841-.155 1.648-.44 2.393a7.812 7.812 0 00-.054.145l-.003.01v.004l.014.123.305 2.473c.014.114.03.244.034.356a1.14 1.14 0 01-.094.522 1.125 1.125 0 01-.579.565 1.141 1.141 0 01-.523.082 4.271 4.271 0 01-.356-.042l-.02-.003-2.39-.35a4.906 4.906 0 00-.126-.018h-.004l-.01.003-.15.055a6.81 6.81 0 01-2.795.42C9.923 16 8.225 17.25 6.224 17.25a4.683 4.683 0 01-1.562-.267 10.373 10.373 0 00-.185-.064h-.004l-.125.017-2.246.307a.75.75 0 01-.834-.905l.458-2.071c.016-.072.025-.111.03-.14l.001-.004a4.962 4.962 0 01-.31-1.748c0-1.97 1.15-3.681 2.816-4.448A6.806 6.806 0 014.25 7.5zm1.5.024c.156-.016.314-.024.474-.024C8.879 7.5 11 9.7 11 12.375c0 .126-.005.251-.014.375h.053a5.311 5.311 0 001.9-.348c.106-.04.23-.087.345-.111.104-.022.194-.032.3-.032a3.321 3.321 0 01.357.035l2.194.321-.278-2.257-.003-.026a2.18 2.18 0 01-.023-.32c.003-.103.013-.189.035-.29.025-.11.071-.23.11-.332l.012-.031a5.2 5.2 0 00.34-1.859c0-2.895-2.363-5.25-5.288-5.25-2.926 0-5.29 2.355-5.29 5.25v.024zm3.646 5.701c.068-.271.104-.556.104-.85C9.5 10.494 8.016 9 6.224 9c-1.793 0-3.277 1.494-3.277 3.375 0 .378.06.74.17 1.077l.014.041c.042.128.09.274.11.396.017.115.022.203.015.32-.007.123-.036.25-.059.352l-.006.03-.226 1.02 1.18-.16.026-.005c.093-.012.208-.028.318-.027.104 0 .18.008.283.028.108.022.237.068.353.109l.036.013c.333.117.69.181 1.063.181 1.5 0 2.783-1.046 3.162-2.49a.731.731 0 01.01-.035z"
        clipRule="evenodd"></path>
    </svg>
  );
};

export default MessageChatCircle;
