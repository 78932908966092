import React from 'react';

const Cached = ({ customClassNames }) => {
  return (
    <svg
      className={`c-Cached ${customClassNames}`}
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24">
      <mask
        id="mask0_4257_128707"
        style={{ maskType: 'alpha' }}
        width="24"
        height="25"
        x="0"
        y="-1"
        maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0 -0.001H24V23.999H0z"></path>
      </mask>
      <g mask="url(#mask0_4257_128707)">
        <path
          fill="#1C1B1F"
          d="M12.05 19.999c-2.233 0-4.133-.775-5.7-2.325C4.785 16.124 4 14.232 4 11.999v-.175l-.9.9a.948.948 0 01-.7.275.948.948 0 01-.7-.275.948.948 0 01-.274-.7c0-.283.091-.517.275-.7l2.6-2.6c.1-.1.208-.171.325-.213.116-.041.241-.062.375-.062.133 0 .258.02.375.062a.883.883 0 01.325.213l2.6 2.6a.948.948 0 01.275.7.948.948 0 01-.275.7.948.948 0 01-.7.275.948.948 0 01-.7-.275l-.9-.9v.175c0 1.667.587 3.083 1.763 4.25 1.174 1.167 2.603 1.75 4.287 1.75a5.402 5.402 0 001.925-.35.974.974 0 01.538-.05.888.888 0 01.487.25c.3.3.42.62.363.962-.059.342-.28.58-.663.713-.434.15-.87.267-1.312.35a7.2 7.2 0 01-1.338.125zm6.95-4.45c-.133 0-.258-.021-.374-.063a.871.871 0 01-.325-.212l-2.6-2.6a.948.948 0 01-.275-.7c0-.283.091-.517.275-.7a.948.948 0 01.7-.275c.283 0 .516.092.7.275l.9.9v-.175c0-1.667-.588-3.083-1.762-4.25-1.176-1.167-2.605-1.75-4.288-1.75-.334 0-.659.03-.975.088a5.732 5.732 0 00-.95.262.97.97 0 01-.537.05.887.887 0 01-.488-.25c-.3-.3-.421-.621-.363-.963.058-.341.28-.579.663-.712.433-.15.87-.267 1.313-.35a7.19 7.19 0 011.337-.125c2.233 0 4.133.775 5.7 2.325C19.217 7.874 20 9.766 20 11.999v.175l.9-.9a.948.948 0 01.7-.275c.283 0 .516.092.7.275a.949.949 0 01.275.7.948.948 0 01-.275.7l-2.6 2.6c-.1.1-.209.17-.325.212a1.1 1.1 0 01-.375.063z"></path>
      </g>
    </svg>
  );
};

Cached.defaultprops = {
  customClassNames: ''
};

export default Cached;
