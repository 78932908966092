import PropTypes from 'prop-types';
import React from 'react';

const HourGlassIcon = ({ width, height, fillColor }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 12 14" fill="none">
      <path
        d="M3.33268 12.3335H8.66602V10.3335C8.66602 9.60016 8.4049 8.97239 7.88268 8.45016C7.36046 7.92794 6.73268 7.66683 5.99935 7.66683C5.26602 7.66683 4.63824 7.92794 4.11602 8.45016C3.59379 8.97239 3.33268 9.60016 3.33268 10.3335V12.3335ZM0.666016 13.6668V12.3335H1.99935V10.3335C1.99935 9.65572 2.15779 9.0195 2.47468 8.42483C2.79113 7.83061 3.23268 7.35572 3.79935 7.00016C3.23268 6.64461 2.79113 6.1695 2.47468 5.57483C2.15779 4.98061 1.99935 4.34461 1.99935 3.66683V1.66683H0.666016V0.333496H11.3327V1.66683H9.99935V3.66683C9.99935 4.34461 9.84113 4.98061 9.52468 5.57483C9.20779 6.1695 8.76602 6.64461 8.19935 7.00016C8.76602 7.35572 9.20779 7.83061 9.52468 8.42483C9.84113 9.0195 9.99935 9.65572 9.99935 10.3335V12.3335H11.3327V13.6668H0.666016Z"
        fill={fillColor}
      />
    </svg>
  );
};

HourGlassIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fillColor: PropTypes.string
};

HourGlassIcon.defaultProps = {
  width: '12',
  height: '14',
  fillColor: '#75777A'
};

export default HourGlassIcon;
