import React from 'react';

const WebsiteStroke = ({ fill, customClassNames }) => {
  return (
    <svg
      className={`c-WebsiteStroke ${customClassNames}`}
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32">
      <path
        fill={fill}
        d="M5.334 24c-.733 0-1.36-.26-1.883-.783a2.569 2.569 0 01-.784-1.884V6.667c0-.734.262-1.362.784-1.884A2.567 2.567 0 015.334 4h21.333c.734 0 1.362.26 1.884.783.522.522.783 1.15.783 1.884v14.666a2.57 2.57 0 01-.783 1.884 2.57 2.57 0 01-1.884.783H5.334zm0-2.667h21.333V6.667H5.334v14.666zM2.667 28c-.377 0-.694-.128-.949-.384a1.287 1.287 0 01-.384-.95c0-.377.128-.694.384-.949.255-.256.572-.384.95-.384h26.666c.378 0 .694.128.95.384.255.255.383.572.383.95 0 .377-.128.694-.384.949a1.287 1.287 0 01-.949.384H2.667z"></path>
      <path
        fill={fill}
        d="M13.75 16.685l.9 2c.11.266.3.4.566.4.267 0 .456-.134.567-.4l.9-2 2-.9c.267-.111.4-.3.4-.567 0-.267-.133-.456-.4-.567l-2-.9-.9-2c-.133-.289-.322-.433-.567-.433-.244 0-.433.144-.566.433l-.9 2-2 .9c-.29.134-.434.322-.434.567 0 .244.145.433.434.567l2 .9zM17.716 11.385l.434.933c.11.244.244.367.4.367.155 0 .289-.123.4-.367l.433-.933.933-.434c.245-.11.367-.244.367-.4 0-.155-.122-.289-.367-.4l-.933-.433-.433-.933c-.111-.245-.245-.367-.4-.367-.156 0-.29.122-.4.367l-.434.933-.933.433c-.244.111-.367.245-.367.4 0 .156.123.29.367.4l.933.434z"></path>
    </svg>
  );
};

WebsiteStroke.defaultProps = {
  fill: '#191C1E',
  customClassNames: ''
};

export default WebsiteStroke;
