import { trackRouteChange } from '@/utility/analytics';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

const RouteTracker = () => {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (route) => {
      console.log('Tracking route change:', route);
      trackRouteChange(route);
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default RouteTracker;
