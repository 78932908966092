import React from 'react';

const FormsAddOn = ({ customClassNames, fill }) => {
  return (
    <svg
      className={`c-FormsAddOn ${customClassNames}`}
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20">
      <mask
        id="mask0_4404_151145"
        style={{ maskType: 'alpha' }}
        width="20"
        height="21"
        x="0"
        y="-1"
        maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0 -0.001H20V19.999H0z"></path>
      </mask>
      <g mask="url(#mask0_4404_151145)">
        <path
          fill={fill}
          d="M13.25 16.999v-2.25H11v-1.5h2.25v-2.25h1.5v2.25H17v1.5h-2.25v2.25h-1.5zm-11.25-1v-1.5h1.5v1.5H2zm3 0v-1.5h4.042c.027.264.069.521.125.771.055.25.139.493.25.729H5zm-3-3.5v-1.5h1.5v1.5H2zm3 0v-1.5h5.021a7.192 7.192 0 00-.448.719c-.132.243-.24.503-.323.781H5zm-3-3.5v-1.5h1.5v1.5H2zm3 0v-1.5h12v1.5H5zm-3-3.5v-1.5h1.5v1.5H2zm3 0v-1.5h12v1.5H5z"></path>
      </g>
    </svg>
  );
};

FormsAddOn.defaultProps = {
  customClassNames: '',
  fill: '#1C1B1F'
};

export default FormsAddOn;
