import React from 'react';

const TiktokColoured = () => {
  return (
    <svg width="20" height="20" fill="none" viewBox="0 0 20 20">
      <path
        fill="#26F4EE"
        fillRule="evenodd"
        d="M13.949 0c.365 2.75 2.234 4.69 5.258 4.862V7.47l-.02.001V5.256c-3.023-.171-4.762-1.811-5.127-4.561h-3.203v11.57c.463 5.207-4.14 5.361-5.9 3.387 2.065 1.139 5.417.399 5.02-4.082V0h3.972zM5.344 19.18c-1.81-.329-3.456-1.282-4.422-2.676C-1.426 13.118.69 7.599 7.5 7.031v3.26l-.011.002V7.847c-6.313.886-6.947 6.865-5.199 9.352.674.959 1.781 1.633 3.054 1.98z"
        clipRule="evenodd"></path>
      <path
        fill="#FB2C53"
        fillRule="evenodd"
        d="M14.873.695c.239 1.795 1.062 3.323 2.447 4.216-1.873-.636-2.971-2.096-3.253-4.216h.806zm4.32 4.948c.259.048.527.08.807.097v3.092c-1.754.152-3.289-.357-5.077-1.305l.274 5.295c0 1.708.008 2.488-1.032 4.059-2.331 3.524-6.528 3.802-9.366 2.124 3.71 1.35 9.329-.289 9.318-6.183v-5.78c1.788.949 3.323 1.458 5.077 1.305V5.643zM7.496 7.878c.367-.067.752-.12 1.157-.153v3.26c-1.474.215-2.41.614-2.849 1.353-1.38 2.325.397 4.17 2.383 4.446-2.309.339-5.017-1.725-3.402-4.446.438-.739 1.237-1.138 2.711-1.352V7.878z"
        clipRule="evenodd"></path>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M14.058.694c.366 2.75 2.103 4.39 5.125 4.562v3.091c-1.753.153-3.287-.356-5.075-1.305v5.78c.014 7.543-9.185 8.117-11.814 4.376C.547 14.711 1.18 8.733 7.49 7.846v3.14c-1.474.214-2.273.613-2.71 1.351-2.705 4.563 6.73 7.277 6.077-.073V.694h3.201z"
        clipRule="evenodd"></path>
    </svg>
  );
};

export default TiktokColoured;
