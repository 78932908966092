import adminProtectedAxiosRequest from './helpers/adminProtectedAxiosRequest';
import config from '../utility/config';
import { getAxiosError } from './helpers';
import protectedAxiosRequest from './helpers/protectedAxiosRequest';
const { learnPortalBackend, loginApiRoute } = config;

export const userLoginService = async (email, password) => {
  try {
    const path = loginApiRoute + '/api/v1/log-in';
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify({
        email,
        password
      })
    };
    const res = await fetch(path, options);
    const data = await res.json();

    if (data.error) {
      throw new Error(data.info || data.error);
    }

    if (data?.status === 'error') {
      throw new Error(data.message || 'Something went wrong');
    }

    return data;
  } catch (e) {
    return { error: e.message };
  }
};

export const forgetPassword = async (email, requestor) => {
  try {
    const body = {
      email
    };
    if (requestor) {
      body.requestor = requestor;
    }

    const path = loginApiRoute + '/api/v1/forget-password';
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify(body)
    };
    const res = await fetch(path, options);
    const data = await res.json();

    if (data.error) {
      throw new Error(data.info || data.error);
    }

    if (data?.status === 'error') {
      throw new Error(data.message || 'Something went wrong');
    }

    return data;
  } catch (e) {
    return { error: e.message };
  }
};

/**
 * Payload should send either authToken or refreshToken
 * @param {*} payload | Object
 * @param {*} payload.authToken | String | For Social Logins
 * @param {*} payload.refreshToken | String
 * @returns Promise
 */
export const getTokensService = async (payload) => {
  try {
    const path = `${loginApiRoute}/api/v1/get-token`;
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify(payload)
    };
    const res = await fetch(path, options);
    const data = await res.json();

    if (data.error) {
      throw new Error(data.info || data.error);
    }

    if (data?.status === 'error') {
      throw new Error(data.message || 'Something went wrong');
    }

    return { data, error: '' };
  } catch (e) {
    return { error: e.message };
  }
};

export const getUserProfile = async () => {
  try {
    return await protectedAxiosRequest.get(
      `${learnPortalBackend}/api/v1/user-profile`
    );
  } catch (e) {
    return getAxiosError(e);
  }
};

export const getUserEnrollmentData = async () => {
  try {
    return await protectedAxiosRequest.get(
      `${learnPortalBackend}/api/v1/signed-up-info`
    );
  } catch (e) {
    return getAxiosError(e);
  }
};

export const updateUserBasicProfile = async (payload) => {
  try {
    const apiPath = `${learnPortalBackend}/api/v1/update-basic-profile`;
    return await adminProtectedAxiosRequest.post(apiPath, payload);
  } catch (error) {
    return getAxiosError(error);
  }
};
