import React from 'react';

const NPLDollar = ({ customClassNames }) => {
  return (
    <svg
      width="12"
      height="20"
      viewBox="0 0 12 20"
      fill="none"
      className={`c-NPLDollar fill-current ${customClassNames}`}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.0013 0.833252C6.46154 0.833252 6.83464 1.20635 6.83464 1.66659V2.49992H7.66797C9.96916 2.49992 11.8346 4.3654 11.8346 6.66658C11.8346 7.12682 11.4615 7.49992 11.0013 7.49992C10.5411 7.49992 10.168 7.12682 10.168 6.66658C10.168 5.28587 9.04868 4.16658 7.66797 4.16658H6.83464V9.16658H7.66797C9.96916 9.16658 11.8346 11.0321 11.8346 13.3333C11.8346 15.6344 9.96916 17.4999 7.66797 17.4999H6.83464V18.3333C6.83464 18.7935 6.46154 19.1666 6.0013 19.1666C5.54106 19.1666 5.16797 18.7935 5.16797 18.3333V17.4999H4.33464C2.03345 17.4999 0.167969 15.6344 0.167969 13.3333C0.167969 12.873 0.541065 12.4999 1.0013 12.4999C1.46154 12.4999 1.83464 12.873 1.83464 13.3333C1.83464 14.714 2.95392 15.8333 4.33464 15.8333H5.16797V10.8333H4.33464C2.03345 10.8333 0.167969 8.96777 0.167969 6.66658C0.167969 4.3654 2.03345 2.49992 4.33464 2.49992H5.16797V1.66659C5.16797 1.20635 5.54106 0.833252 6.0013 0.833252ZM5.16797 4.16658H4.33464C2.95392 4.16658 1.83464 5.28587 1.83464 6.66658C1.83464 8.0473 2.95392 9.16658 4.33464 9.16658H5.16797V4.16658ZM6.83464 10.8333V15.8333H7.66797C9.04868 15.8333 10.168 14.714 10.168 13.3333C10.168 11.9525 9.04868 10.8333 7.66797 10.8333H6.83464Z"
        className="fill-current"
      />
    </svg>
  );
};

export default NPLDollar;
