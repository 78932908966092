import React from 'react';

const LiveEventsFeatureGoldIcon = ({ customClassNames }) => {
  return (
    <svg
      className={`c-LiveEventsFeatureGoldIcon ${customClassNames}`}
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32">
      <g>
        <path
          fill="#937300"
          fillRule="evenodd"
          d="M21.334 1.416c.69 0 1.25.56 1.25 1.25v1.418h2.75a2.583 2.583 0 012.583 2.583V25.334a2.583 2.583 0 01-2.583 2.583H6.667a2.583 2.583 0 01-2.583-2.583V6.667a2.583 2.583 0 012.583-2.583h2.749V2.666a1.25 1.25 0 112.5 0v1.418h8.168V2.666c0-.69.56-1.25 1.25-1.25zm4.083 8V6.667a.083.083 0 00-.083-.083H6.667a.083.083 0 00-.083.083v2.749h18.833zm-18.833 2.5h18.833v13.418a.083.083 0 01-.083.083H6.667a.083.083 0 01-.083-.083V11.916zm14.75 8.168c-.69 0-1.25.56-1.25 1.25v.003c0 .69.56 1.25 1.25 1.25h.003c.69 0 1.25-.56 1.25-1.25v-.003c0-.69-.56-1.25-1.25-1.25h-.003zm-6.584 1.25c0-.69.56-1.25 1.25-1.25h.003c.69 0 1.25.56 1.25 1.25v.003c0 .69-.56 1.25-1.25 1.25H16c-.69 0-1.25-.56-1.25-1.25v-.003zm-4.084-1.25c-.69 0-1.25.56-1.25 1.25v.003c0 .69.56 1.25 1.25 1.25h.003c.69 0 1.25-.56 1.25-1.25v-.003c0-.69-.56-1.25-1.25-1.25h-.003zM20.084 16c0-.69.56-1.25 1.25-1.25h.003c.69 0 1.25.56 1.25 1.25v.003c0 .69-.56 1.25-1.25 1.25h-.003c-.69 0-1.25-.56-1.25-1.25V16zM16 14.75c-.69 0-1.25.56-1.25 1.25v.003c0 .69.56 1.25 1.25 1.25h.003c.69 0 1.25-.56 1.25-1.25V16c0-.69-.56-1.25-1.25-1.25H16zM9.416 16c0-.69.56-1.25 1.25-1.25h.003c.69 0 1.25.56 1.25 1.25v.003c0 .69-.56 1.25-1.25 1.25h-.003c-.69 0-1.25-.56-1.25-1.25V16z"
          clipRule="evenodd"></path>
      </g>
    </svg>
  );
};

LiveEventsFeatureGoldIcon.defaultProps = {
  customClassNames: ''
};

export default LiveEventsFeatureGoldIcon;
