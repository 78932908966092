import React from 'react';

const NPLBookOpen = ({ customClassNames }) => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      className={`c-NPLBookOpen stroke-current ${customClassNames}`}>
      <path
        d="M10.0013 16.5L9.91792 16.3749C9.33906 15.5066 9.04962 15.0725 8.66722 14.7582C8.32868 14.4799 7.93861 14.2712 7.51931 14.1438C7.04568 14 6.52389 14 5.48032 14H4.33464C3.40121 14 2.9345 14 2.57798 13.8183C2.26438 13.6586 2.00941 13.4036 1.84962 13.09C1.66797 12.7335 1.66797 12.2668 1.66797 11.3333V4.16667C1.66797 3.23325 1.66797 2.76654 1.84962 2.41002C2.00941 2.09641 2.26438 1.84144 2.57798 1.68166C2.9345 1.5 3.40121 1.5 4.33464 1.5H4.66797C6.53481 1.5 7.46823 1.5 8.18127 1.86331C8.80848 2.18289 9.31841 2.69282 9.63799 3.32003C10.0013 4.03307 10.0013 4.96649 10.0013 6.83333M10.0013 16.5V6.83333M10.0013 16.5L10.0847 16.3749C10.6635 15.5066 10.953 15.0725 11.3354 14.7582C11.6739 14.4799 12.064 14.2712 12.4833 14.1438C12.9569 14 13.4787 14 14.5223 14H15.668C16.6014 14 17.0681 14 17.4246 13.8183C17.7382 13.6586 17.9932 13.4036 18.153 13.09C18.3346 12.7335 18.3346 12.2668 18.3346 11.3333V4.16667C18.3346 3.23325 18.3346 2.76654 18.153 2.41002C17.9932 2.09641 17.7382 1.84144 17.4246 1.68166C17.0681 1.5 16.6014 1.5 15.668 1.5H15.3346C13.4678 1.5 12.5344 1.5 11.8213 1.86331C11.1941 2.18289 10.6842 2.69282 10.3646 3.32003C10.0013 4.03307 10.0013 4.96649 10.0013 6.83333"
        className="stroke-current"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NPLBookOpen;
