import React from 'react';

const ShareIcon = ({ fill, customClassNames, width, height }) => {
  return (
    <svg
      className={`${customClassNames}`}
      width={width}
      height={height}
      viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2929 2.29289C11.6834 1.90237 12.3166 1.90237 12.7071 2.29289L15.7071 5.29289C16.0976 5.68342 16.0976 6.31658 15.7071 6.70711C15.3166 7.09763 14.6834 7.09763 14.2929 6.70711L12 4.41421L9.70711 6.70711C9.31658 7.09763 8.68342 7.09763 8.29289 6.70711C7.90237 6.31658 7.90237 5.68342 8.29289 5.29289L11.2929 2.29289Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C12.5523 2 13 2.44772 13 3V13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13V3C11 2.44772 11.4477 2 12 2Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 11C3 9.89543 3.89543 9 5 9H7C7.55228 9 8 9.44772 8 10C8 10.5523 7.55228 11 7 11H5V20H19V11H17C16.4477 11 16 10.5523 16 10C16 9.44772 16.4477 9 17 9H19C20.1046 9 21 9.89543 21 11V20C21 21.1046 20.1046 22 19 22H5C3.89543 22 3 21.1046 3 20V11Z"
        fill={fill}
      />
    </svg>
  );
};

ShareIcon.defaultProps = {
  fill: '#191C1E',
  customClassNames: '',
  width: 24,
  height: 24
};

export default ShareIcon;
