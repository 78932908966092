import PropTypes from 'prop-types';

const ChevronCircleDownIcon = ({ width, height, fillColor }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#clip0_152_19908)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.99996 1.99984C4.68625 1.99984 1.99996 4.68613 1.99996 7.99984C1.99996 11.3135 4.68625 13.9998 7.99996 13.9998C11.3137 13.9998 14 11.3135 14 7.99984C14 4.68613 11.3137 1.99984 7.99996 1.99984ZM0.666626 7.99984C0.666626 3.94975 3.94987 0.666504 7.99996 0.666504C12.05 0.666504 15.3333 3.94975 15.3333 7.99984C15.3333 12.0499 12.05 15.3332 7.99996 15.3332C3.94987 15.3332 0.666626 12.0499 0.666626 7.99984ZM4.86189 6.86177C5.12224 6.60142 5.54435 6.60142 5.8047 6.86177L7.99996 9.05703L10.1952 6.86177C10.4556 6.60142 10.8777 6.60142 11.138 6.86177C11.3984 7.12212 11.3984 7.54423 11.138 7.80457L8.47136 10.4712C8.21101 10.7316 7.7889 10.7316 7.52855 10.4712L4.86189 7.80457C4.60154 7.54423 4.60154 7.12212 4.86189 6.86177Z"
          fill={fillColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_152_19908">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

ChevronCircleDownIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fillColor: PropTypes.string
};

ChevronCircleDownIcon.defaultProps = {
  width: '16',
  height: '16',
  fillColor: '#1B1B18'
};

export default ChevronCircleDownIcon;
