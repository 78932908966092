import useFacebookChatIcon from '@/hooks/useFacebookChatIcon';
import Waitlist from '@/modules/Waitlist';
import { getLocalPriceInfo } from '@/services/paymentService';
import { routeListShowingChat } from '@/utility/constants';
import { checkIsWebView } from '@/utility/webview';
import { useRouter } from 'next/router';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
export const UserSessionContext = createContext();

export const UserSessionContextProvider = ({ children }) => {
  const [impactEmailPopupShown, setImpactEmailPopupShown] =
    useState(false);
  const [coursePopupShown, setCoursePopupShown] = useState({});
  const [referralDiscountPopupShown, setReferralDiscountPopupShown] =
    useState(false);
  const [withValidReferralCode, setWithValidReferralCode] =
    useState(false);
  const [currentReferralCode, setCurrentReferralCode] = useState({
    owner: null,
    code: null
  });
  const [sessionHomePageLogoLink, setSessionHomePageLogoLink] =
    useState('/');
  const [sessionSearchClient, setSessionSearchClient] = useState(null);
  const [usdConversionMultiplier, setUsdConversionMultiplier] =
    useState(null);
  const [waitlistSessionData, setWaitlistSessionData] = useState(
    Waitlist.getWaitlistEnrollmentData()
  );
  const [localCurrency, setLocalCurrency] = useState('USD');

  const [isWebView, setIsWebView] = useState(false);

  const router = useRouter();
  const { showChatWidget, hideChatWidget } = useFacebookChatIcon();

  const turnOffImpactEmailPopup = useCallback(() => {
    setImpactEmailPopupShown(true);
  }, []);

  const turnOffReferralDiscountPopup = useCallback(() => {
    setReferralDiscountPopupShown(true);
  }, []);

  const getCountryMultiplier = async (customCurrency) => {
    const exchangeRateRes = await getLocalPriceInfo(true, customCurrency);
    if (exchangeRateRes?.data) {
      setLocalCurrency(exchangeRateRes?.data?.localCurrency ?? 'USD');
      const defaultRate = exchangeRateRes?.data?.conversionMultiplier ?? 1;
      setUsdConversionMultiplier(defaultRate);
    }
  };

  const clearWaitinglistSessionData = () => {
    Waitlist.clearWaitlistEnrollmentData();
    setWaitlistSessionData({});
  };

  useEffect(() => {
    Waitlist.setWaitlistEnrollmentData(waitlistSessionData);
  }, [waitlistSessionData]);

  /* Calling USD conversion multiplier to send revenue data to analyitcs
  in USD and not local currency cause they dont support local currency */
  useEffect(() => {
    getCountryMultiplier();
  }, []);

  //Show chat if route is in the route list
  useEffect(() => {
    if (routeListShowingChat.includes(router?.pathname)) {
      showChatWidget();
    } else {
      hideChatWidget();
    }
    setSessionHomePageLogoLink('/');
  }, [hideChatWidget, router, showChatWidget]);

  // check on browser if user agent is webview.
  useEffect(() => {
    const userAgent = window?.navigator?.userAgent;
    if (userAgent) {
      setIsWebView(checkIsWebView(userAgent));
    }
  }, []);

  const value = {
    coursePopupShown,
    setCoursePopupShown,
    impactEmailPopupShown,
    turnOffImpactEmailPopup,
    referralDiscountPopupShown,
    turnOffReferralDiscountPopup,
    currentReferralCode,
    setCurrentReferralCode,
    withValidReferralCode,
    setWithValidReferralCode,
    setSessionHomePageLogoLink,
    sessionHomePageLogoLink,
    sessionSearchClient,
    setSessionSearchClient,
    usdConversionMultiplier,
    waitlistSessionData,
    setWaitlistSessionData,
    clearWaitinglistSessionData,
    isWebView,
    localCurrency,
    getCountryMultiplier
  };

  return (
    <UserSessionContext.Provider value={value}>
      {children}
    </UserSessionContext.Provider>
  );
};

export const useUserSessionContext = () => useContext(UserSessionContext);
