import React from 'react';

const IconLRight = ({ customClassNames }) => {
  return (
    <svg
      className={`c-IconLRight ${customClassNames}`}
      width="20"
      height="21"
      fill="none"
      viewBox="0 0 20 21">
      <path
        fill="#191C1E"
        d="M4.166 11.39h9.309l-4.067 4.067a.84.84 0 000 1.183.83.83 0 001.175 0l5.492-5.492a.83.83 0 000-1.175l-5.484-5.5a.83.83 0 10-1.175 1.175l4.059 4.075H4.166a.836.836 0 00-.833.834c0 .458.375.833.833.833z"></path>
    </svg>
  );
};

IconLRight.defaultProps = {
  customClassNames: ''
};

export default IconLRight;
