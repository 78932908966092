import React from 'react';

const LinkedInIcon = ({ fill, customClassNames, width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      className={`${customClassNames}`}
      viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.37864 3C3.64871 3 3.05664 3.57767 3.05664 4.28942V19.711C3.05664 20.4228 3.64871 21 4.37864 21H19.6213C20.3515 21 20.9433 20.4228 20.9433 19.7108V4.28942C20.9433 3.57767 20.3515 3 19.6213 3H4.37864ZM8.47867 9.94065V18.0686H5.77707V9.94065H8.47867ZM8.65667 7.42696C8.65667 8.20694 8.07027 8.83112 7.12834 8.83112H7.12811H7.11064C6.20388 8.83112 5.61772 8.20694 5.61772 7.42696C5.61772 6.62927 6.22159 6.02257 7.14628 6.02257C8.07027 6.02257 8.63897 6.62927 8.65667 7.42696ZM12.6753 18.0686H9.97395C9.97418 18.0207 10.0092 10.7009 9.97419 9.94089H12.6756V11.0915C13.0346 10.5378 13.6773 9.75014 15.1104 9.75014C16.8878 9.75014 18.2204 10.9119 18.2204 13.4083V18.0686H15.519V13.7209C15.519 12.6281 15.1279 11.8828 14.1505 11.8828C13.4041 11.8828 12.9595 12.3854 12.7643 12.871C12.693 13.0443 12.6753 13.2874 12.6753 13.5301V18.0686ZM9.97395 18.0688V18.0686C9.97395 18.0688 9.97395 18.0688 9.97395 18.0688Z"
        fill={fill}
      />
    </svg>
  );
};

LinkedInIcon.defaultProps = {
  fill: '#191C1E',
  customClassNames: '',
  width: 24,
  height: 24
};

export default LinkedInIcon;
