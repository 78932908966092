import React from 'react';

const CoursesIcon = ({ width, height, fill }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 15 16" fill="none">
      <path
        d="M4.5 2C4.2875 2 4.10925 1.928 3.96525 1.784C3.82175 1.6405 3.75 1.4625 3.75 1.25C3.75 1.0375 3.82175 0.85925 3.96525 0.71525C4.10925 0.57175 4.2875 0.5 4.5 0.5H10.5C10.7125 0.5 10.8905 0.57175 11.034 0.71525C11.178 0.85925 11.25 1.0375 11.25 1.25C11.25 1.4625 11.178 1.6405 11.034 1.784C10.8905 1.928 10.7125 2 10.5 2H4.5ZM2.25 4.25C2.0375 4.25 1.85925 4.17825 1.71525 4.03475C1.57175 3.89075 1.5 3.7125 1.5 3.5C1.5 3.2875 1.57175 3.10925 1.71525 2.96525C1.85925 2.82175 2.0375 2.75 2.25 2.75H12.75C12.9625 2.75 13.1405 2.82175 13.284 2.96525C13.428 3.10925 13.5 3.2875 13.5 3.5C13.5 3.7125 13.428 3.89075 13.284 4.03475C13.1405 4.17825 12.9625 4.25 12.75 4.25H2.25ZM1.5 15.5C1.0875 15.5 0.7345 15.3533 0.441 15.0597C0.147 14.7657 0 14.4125 0 14V6.5C0 6.0875 0.147 5.73425 0.441 5.44025C0.7345 5.14675 1.0875 5 1.5 5H13.5C13.9125 5 14.2657 5.14675 14.5597 5.44025C14.8533 5.73425 15 6.0875 15 6.5V14C15 14.4125 14.8533 14.7657 14.5597 15.0597C14.2657 15.3533 13.9125 15.5 13.5 15.5H1.5ZM1.5 14H13.5V6.5H1.5V14ZM7.1625 12.4813L9.5625 10.8687C9.7875 10.7188 9.9 10.5125 9.9 10.25C9.9 9.9875 9.7875 9.78125 9.5625 9.63125L7.1625 8.01875C6.9125 7.84375 6.65625 7.83125 6.39375 7.98125C6.13125 8.13125 6 8.35625 6 8.65625V11.8438C6 12.1438 6.13125 12.3687 6.39375 12.5187C6.65625 12.6687 6.9125 12.6562 7.1625 12.4813Z"
        fill={fill}
      />
    </svg>
  );
};

CoursesIcon.defaultProps = {
  width: 15,
  height: 16,
  fill: '#1B1B18'
};
export default CoursesIcon;
