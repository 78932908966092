import React from 'react';

const DiscordIcon = ({ width, height, fill }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 36 28" fill="none">
      <path
        d="M30.129 2.63722C27.8982 1.61364 25.506 0.859513 23.0049 0.427593C22.9593 0.419257 22.9138 0.440089 22.8903 0.481754C22.5827 1.02894 22.2419 1.74279 22.0033 2.30388C19.3131 1.90113 16.6367 1.90113 14.0017 2.30388C13.763 1.73032 13.4099 1.02894 13.1008 0.481754C13.0774 0.441479 13.0319 0.420647 12.9863 0.427593C10.4865 0.858133 8.09436 1.61226 5.8622 2.63722C5.84287 2.64555 5.82631 2.65945 5.81532 2.67749C1.27786 9.45635 0.0348642 16.0686 0.644638 22.5989C0.647397 22.6308 0.665331 22.6614 0.690164 22.6808C3.68385 24.8793 6.58374 26.214 9.42978 27.0986C9.47533 27.1125 9.52359 27.0959 9.55258 27.0583C10.2258 26.139 10.8259 25.1696 11.3405 24.1501C11.3709 24.0904 11.3419 24.0196 11.2798 23.996C10.3279 23.6349 9.4215 23.1946 8.54961 22.6947C8.48064 22.6544 8.47512 22.5558 8.53856 22.5085C8.72204 22.3711 8.90557 22.228 9.08077 22.0836C9.11246 22.0572 9.15663 22.0516 9.1939 22.0683C14.9219 24.6835 21.1231 24.6835 26.7835 22.0683C26.8207 22.0502 26.8649 22.0558 26.898 22.0822C27.0732 22.2266 27.2567 22.3711 27.4416 22.5085C27.505 22.5558 27.5009 22.6544 27.4319 22.6947C26.56 23.2044 25.6536 23.6349 24.7003 23.9946C24.6383 24.0182 24.6107 24.0904 24.641 24.1501C25.1666 25.1681 25.7667 26.1376 26.4276 27.057C26.4552 27.0959 26.5048 27.1125 26.5504 27.0986C29.4102 26.214 32.3101 24.8793 35.3038 22.6808C35.33 22.6614 35.3465 22.6322 35.3493 22.6002C36.0791 15.0505 34.127 8.49252 30.1745 2.67887C30.1648 2.65945 30.1483 2.64555 30.129 2.63722ZM12.1959 18.6226C10.4714 18.6226 9.0504 17.0394 9.0504 15.095C9.0504 13.1506 10.4438 11.5674 12.1959 11.5674C13.9617 11.5674 15.3689 13.1645 15.3413 15.095C15.3413 17.0394 13.9479 18.6226 12.1959 18.6226ZM23.8257 18.6226C22.1012 18.6226 20.6803 17.0394 20.6803 15.095C20.6803 13.1506 22.0736 11.5674 23.8257 11.5674C25.5915 11.5674 26.9987 13.1645 26.9711 15.095C26.9711 17.0394 25.5915 18.6226 23.8257 18.6226Z"
        fill={fill}
      />
    </svg>
  );
};

DiscordIcon.defaultProps = {
  width: 36,
  height: 28,
  fill: '#5865F2'
};

export default DiscordIcon;
