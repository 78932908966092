import PropTypes from 'prop-types';
import React from 'react';

const className = 'c-SlackOutlineIcon';
const SlackOutlineIcon = ({ width, height, fillColor }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 17 16" fill="none">
      <path
        d="M4.82724 9.67046C4.82724 10.4028 4.22898 11.0011 3.49663 11.0011C2.76428 11.0011 2.16602 10.4028 2.16602 9.67046C2.16602 8.9381 2.76428 8.33984 3.49663 8.33984H4.82724V9.67046Z"
        fill={fillColor}
      />
      <path
        d="M5.49805 9.67046C5.49805 8.9381 6.09631 8.33984 6.82866 8.33984C7.56101 8.33984 8.15927 8.9381 8.15927 9.67046V13.0021C8.15927 13.7345 7.56101 14.3328 6.82866 14.3328C6.09631 14.3328 5.49805 13.7345 5.49805 13.0021V9.67046Z"
        fill={fillColor}
      />
      <path
        d="M6.82866 4.32724C6.09631 4.32724 5.49805 3.72898 5.49805 2.99663C5.49805 2.26428 6.09631 1.66602 6.82866 1.66602C7.56101 1.66602 8.15927 2.26428 8.15927 2.99663V4.32724H6.82866Z"
        fill={fillColor}
      />
      <path
        d="M6.82832 4.99805C7.56067 4.99805 8.15893 5.59631 8.15893 6.32866C8.15893 7.06101 7.56067 7.65927 6.82832 7.65927H3.49663C2.76428 7.65927 2.16602 7.06101 2.16602 6.32866C2.16602 5.59631 2.76428 4.99805 3.49663 4.99805H6.82832Z"
        fill={fillColor}
      />
      <path
        d="M12.1719 6.32866C12.1719 5.59631 12.7701 4.99805 13.5025 4.99805C14.2348 4.99805 14.8331 5.59631 14.8331 6.32866C14.8331 7.06101 14.2348 7.65927 13.5025 7.65927H12.1719V6.32866Z"
        fill={fillColor}
      />
      <path
        d="M11.5011 6.32832C11.5011 7.06067 10.9028 7.65893 10.1705 7.65893C9.4381 7.65893 8.83984 7.06067 8.83984 6.32832V2.99663C8.83984 2.26428 9.4381 1.66602 10.1705 1.66602C10.9028 1.66602 11.5011 2.26428 11.5011 2.99663V6.32832Z"
        fill={fillColor}
      />
      <path
        d="M10.1705 11.6719C10.9028 11.6719 11.5011 12.2701 11.5011 13.0025C11.5011 13.7348 10.9028 14.3331 10.1705 14.3331C9.4381 14.3331 8.83984 13.7348 8.83984 13.0025V11.6719H10.1705Z"
        fill={fillColor}
      />
      <path
        d="M10.1705 11.0011C9.4381 11.0011 8.83984 10.4028 8.83984 9.67046C8.83984 8.9381 9.4381 8.33984 10.1705 8.33984H13.5021C14.2345 8.33984 14.8328 8.9381 14.8328 9.67046C14.8328 10.4028 14.2345 11.0011 13.5021 11.0011H10.1705Z"
        fill={fillColor}
      />
    </svg>
  );
};

SlackOutlineIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fillColor: PropTypes.string
};

SlackOutlineIcon.defaultProps = {
  width: '15',
  height: '14',
  fillColor: '#FBFCFF'
};

export default SlackOutlineIcon;
