import {
  MODE_COHORT_LIVE,
  MODE_COHORT_ONDEMAND,
  MODE_FREE_WORKSHOP_COURSE,
  MODE_GIFT_CARD,
  MODE_LIVE,
  MODE_ONDEMAND,
  MODE_VIDEO_ONDEMAND,
  PRE_RECORDED_COURSE_OFFER_TYPE
} from '@/utility/checkoutConstants';
import courseList from '../data/CoursePage/coursesList';
import config from '../utility/config';
import unAuthorizedRequest from './helpers/unAuthorizedRequest';
import authorizedRequest from './helpers/authorizedRequest';

const { apiPaymentBasePath, apiStaticDataPath } = config;

export const getCourseLocalConfigByCode = (courseCode) => {
  try {
    // TODO : Replace with API when API in place.
    const courseData = courseList.find(
      (course) => course.courseCode === courseCode
    );

    if (!courseData) throw new Error(`Course [${courseCode}] not found.`);

    return { error: false, data: courseData };
  } catch (e) {
    return { error: e.message };
  }
};

export const getUpcomingCourseOfferingsByCourseCode = async (
  courseCode
) => {
  if (!courseCode) return;

  try {
    const timezoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const apiPath = `${apiPaymentBasePath}/api/v1/get-course-list-by-prefix/?course_prefix_code=${courseCode}&timezone_id=${timezoneId}`;
    const res = await fetch(apiPath, { method: 'GET' });
    const data = await res.json();

    if (data.error) {
      const errMsg =
        data?.info ||
        data?.message ||
        data?.error ||
        'Something went wrong';
      throw new Error(errMsg);
    }

    return { data, error: false };
  } catch (e) {
    return { error: e.message };
  }
};

export const getCourseOfferingById = async (courseOfferingId) => {
  try {
    const timezoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const apiPath = `${apiPaymentBasePath}/api/v1/get-course-offer-by-offer-code/?course_offer_code=${courseOfferingId}&timezone_id=${timezoneId}`;
    const res = await fetch(apiPath, { method: 'GET' });
    const data = await res.json();

    if (data.error) {
      const errMsg =
        data?.info ||
        data?.message ||
        data?.error ||
        'Something went wrong';
      throw new Error(errMsg);
    }

    return { data, error: false, statusCode: res.status };
  } catch (e) {
    return { error: e.message };
  }
};

export const getCoursePaymentOptions = async (courseCode) => {
  try {
    const path = apiPaymentBasePath + '/api/v1/sign-up-payment-options/';
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ courseCode })
    };
    const res = await fetch(path, options);
    const data = await res.json();

    return data;
  } catch (e) {
    return { error: e.message };
  }
};

export const enrollToCourseForFreeService = async (params) => {
  try {
    const path = `${apiPaymentBasePath}/api/v1/course-enrolment-zero/`;
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(params)
    };

    const res = await authorizedRequest(path, options);

    return res;
  } catch (e) {
    return { error: e.message };
  }
};

export const emailCourseCurriculum = async ({
  data,
  course,
  courseName
}) => {
  const body = JSON.stringify({ ...data, course, courseName });

  try {
    const path = `${apiStaticDataPath}/api/v1/coming-soon-notification/`;
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body
    };

    const res = await authorizedRequest(path, options);

    return res;
  } catch (e) {
    return { error: e.message };
  }
};

export const sendWaitlistSignup = async ({ data, courseCode }) => {
  const body = JSON.stringify({ ...data, courseCode });

  try {
    const path = `${apiStaticDataPath}/api/v1/waitlist/`;
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body
    };

    const res = await unAuthorizedRequest(path, options);

    return res;
  } catch (e) {
    return { error: e.message };
  }
};

export const emailExistingCourseCurriculum = async ({
  email,
  course,
  courseName
}) => {
  const body = JSON.stringify({ email, course, courseName });

  try {
    const path = `${apiStaticDataPath}/api/v1/send-curriculum`;
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body
    };

    const res = await authorizedRequest(path, options);

    return res;
  } catch (e) {
    return { error: e.message };
  }
};

export const generalEnrollmentService = async (params) => {
  const body = JSON.stringify(params);

  try {
    const path = `${apiStaticDataPath}/api/v1/general-enrolment`;
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body
    };

    const res = await authorizedRequest(path, options);

    return res;
  } catch (e) {
    return { error: e.message };
  }
};

export const getCourseNextDate = async (courseCodes = []) => {
  const csCourseCode = Array.isArray(courseCodes)
    ? courseCodes.join(',')
    : '';
  try {
    const timezoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const apiPath = `${apiStaticDataPath}/api/v1/next-course-date/?coursePrefixCodes=${csCourseCode}&timezoneId=${timezoneId}`;
    const res = await fetch(apiPath, { method: 'GET' });
    const data = await res.json();

    if (data.error) {
      const errMsg =
        data?.info ||
        data?.message ||
        data?.error ||
        'Something went wrong';
      throw new Error(errMsg);
    }

    const datesData = data?.data || {};

    return { data: datesData, error: false };
  } catch (e) {
    return { error: e.message };
  }
};

export const getCourseCardsData = async (
  arrCoursesList,
  domainAccess = ''
) => {
  try {
    const timezoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const csCourseCode = Array.isArray(arrCoursesList)
      ? arrCoursesList.join(',')
      : '';

    const apiPath = `${apiStaticDataPath}/api/v1/course-card-data/?courseCodes=${csCourseCode}&timezoneId=${timezoneId}&domainAccess=${domainAccess}`;
    const res = await fetch(apiPath, { method: 'GET' });
    const data = await res.json();

    if (data.error) {
      const errMsg =
        data?.info ||
        data?.message ||
        data?.error ||
        'Something went wrong';
      throw new Error(errMsg);
    }

    const courseCardsData = data?.data || {};

    return { data: courseCardsData, error: false };
  } catch (e) {
    return { error: e.message };
  }
};

/**
 * API to add email to course reminder list
 * @param {string} params.courseCode
 * @param {string} params.email
 * @return {Promise}
 */
export const courseRemindMeService = async (params) => {
  try {
    const apiPath = `${apiStaticDataPath}/api/v1/course-reminder-list`;
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(params)
    };
    const res = await fetch(apiPath, options);

    const data = await res.json();

    if (data?.status) {
      const error = new Error(data.message || 'Something went wrong');
      error.errorCode = data.errorCode;

      throw error;
    }

    return { data, error: false };
  } catch (error) {
    return { error };
  }
};

// Course can be bought if it is not under waitlist AND has next course date (for live course)
export const canCourseBeBought = (
  nextCourseDate,
  courseType,
  isWaitlist
) => {
  return (
    (!!nextCourseDate || courseType === PRE_RECORDED_COURSE_OFFER_TYPE) &&
    !isWaitlist
  );
};

const isDateInPast = (d) => {
  const paramDate = new Date(d);
  const now = new Date();

  return paramDate < now;
};

export const checkIsCourseWaitlist = (courseData) => {
  if (!courseData) return true;

  const { mode, nextCourseDate } = courseData;

  switch (mode) {
    case MODE_LIVE:
      return isDateInPast(nextCourseDate);
    case MODE_ONDEMAND:
    case MODE_VIDEO_ONDEMAND:
      return false;
    case MODE_COHORT_LIVE:
    case MODE_COHORT_ONDEMAND:
      if (!nextCourseDate) return true;

      if (nextCourseDate) {
        return isDateInPast(nextCourseDate);
      }

      return false;
    case MODE_FREE_WORKSHOP_COURSE:
      return isDateInPast(nextCourseDate);
    case MODE_GIFT_CARD:
      return false;
    default:
      return true;
  }
};
