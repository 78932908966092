import React from 'react';

const MagicReach = ({ fill, customClassNames }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      className={`c-MagicReach ${customClassNames}`}>
      <path
        d="M4.93307 21.734C4.68863 21.4895 4.56641 21.1784 4.56641 20.8006C4.56641 20.4229 4.68863 20.1118 4.93307 19.8673L11.4664 13.334H7.99974C7.62196 13.334 7.30507 13.206 7.04907 12.95C6.79396 12.6949 6.66641 12.3784 6.66641 12.0007C6.66641 11.6229 6.79396 11.306 7.04907 11.05C7.30507 10.7949 7.62196 10.6673 7.99974 10.6673H14.6664C15.0442 10.6673 15.3611 10.7949 15.6171 11.05C15.8722 11.306 15.9997 11.6229 15.9997 12.0007V18.6673C15.9997 19.0451 15.8722 19.3615 15.6171 19.6166C15.3611 19.8727 15.0442 20.0007 14.6664 20.0007C14.2886 20.0007 13.9722 19.8727 13.7171 19.6166C13.4611 19.3615 13.3331 19.0451 13.3331 18.6673V15.2006L6.79974 21.734C6.5553 21.9784 6.24418 22.1007 5.86641 22.1007C5.48863 22.1007 5.17752 21.9784 4.93307 21.734ZM23.9997 26.6673H10.6664V28.0006H23.9997V26.6673ZM23.9997 5.33398V4.00065H10.6664V5.33398H23.9997ZM10.6664 30.6673C9.93307 30.6673 9.30552 30.4064 8.78374 29.8846C8.26107 29.362 7.99974 28.734 7.99974 28.0006V22.6673H10.6664V24.0007H23.9997V8.00065H10.6664V9.33398H7.99974V4.00065C7.99974 3.26732 8.26107 2.63932 8.78374 2.11665C9.30552 1.59487 9.93307 1.33398 10.6664 1.33398H23.9997C24.7331 1.33398 25.3611 1.59487 25.8837 2.11665C26.4055 2.63932 26.6664 3.26732 26.6664 4.00065V28.0006C26.6664 28.734 26.4055 29.362 25.8837 29.8846C25.3611 30.4064 24.7331 30.6673 23.9997 30.6673H10.6664Z"
        fill="#1C1B1F"
      />
      <path
        d="M2.55273 14.7812L2.95899 15.6562C3.06315 15.8854 3.18815 16 3.33398 16C3.47982 16 3.60482 15.8854 3.70898 15.6562L4.11523 14.7812L4.99023 14.375C5.2194 14.2708 5.33398 14.1458 5.33398 14C5.33398 13.8542 5.2194 13.7292 4.99023 13.625L4.11523 13.2188L3.70898 12.3438C3.60482 12.1146 3.47982 12 3.33398 12C3.18815 12 3.06315 12.1146 2.95899 12.3438L2.55273 13.2188L1.67773 13.625C1.44857 13.7292 1.33398 13.8542 1.33398 14C1.33398 14.1458 1.44857 14.2708 1.67773 14.375L2.55273 14.7812Z"
        fill={fill}
      />
      <path
        d="M3.88477 26.7812L4.29102 27.6562C4.39518 27.8854 4.52018 28 4.66602 28C4.81185 28 4.93685 27.8854 5.04101 27.6562L5.44727 26.7812L6.32227 26.375C6.55143 26.2708 6.66602 26.1458 6.66602 26C6.66602 25.8542 6.55143 25.7292 6.32227 25.625L5.44727 25.2188L5.04101 24.3438C4.93685 24.1146 4.81185 24 4.66602 24C4.52018 24 4.39518 24.1146 4.29102 24.3438L3.88477 25.2188L3.00977 25.625C2.7806 25.7292 2.66602 25.8542 2.66602 26C2.66602 26.1458 2.7806 26.2708 3.00977 26.375L3.88477 26.7812Z"
        fill={fill}
      />
    </svg>
  );
};

MagicReach.defaultProps = {
  fill: '#191C1E',
  customClassNames: ''
};

export default MagicReach;
