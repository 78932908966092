import React from 'react';

const className = 'c-IconV2-VideoLibrary';

const VideoLibrary = ({
  customClassNames,
  fill,
  width,
  height,
  customInnerClassNames
}) => {
  return (
    <svg
      className={`${className} ${customClassNames}`}
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 24 24">
      <path
        fill={fill}
        className={`${customInnerClassNames}`}
        d="M4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm16-4H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-8 12.5v-9l6 4.5-6 4.5z"></path>
    </svg>
  );
};

VideoLibrary.defaultProps = {
  customClassNames: '',
  fill: '#222',
  width: '24',
  height: '24'
};

export default VideoLibrary;
