import React from 'react';

const className = 'c-IconV2-NasIoLogo';

const NasIoLogo = ({
  width = '24',
  height = '24',
  customClassNames,
  fill
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      className={`${className} ${customClassNames}`}>
      <path
        d="M2.76504 13.7446C1.74499 12.7245 1.74499 11.0707 2.76504 10.0506C3.7851 9.03065 5.43894 9.03065 6.45901 10.0506L13.847 17.4386C14.8671 18.4586 14.8671 20.1126 13.847 21.1326C12.8269 22.1527 11.1731 22.1527 10.1529 21.1326L2.76504 13.7446Z"
        fill={fill}
      />
      <path
        d="M13.8477 21.1327C12.8276 22.1526 11.1738 22.1526 10.1537 21.1327C9.13366 20.1126 9.13367 18.4587 10.1537 17.4386L17.5417 10.0506C18.5617 9.03064 20.2156 9.03064 21.2356 10.0506C22.2557 11.0708 22.2557 12.7245 21.2356 13.7447L13.8477 21.1327Z"
        fill={fill}
      />
      <path
        d="M11.9926 7.71164C13.5698 7.71164 14.8484 6.43305 14.8484 4.85583C14.8484 3.2786 13.5698 2 11.9926 2C10.4153 2 9.13672 3.2786 9.13672 4.85583C9.13672 6.43305 10.4153 7.71164 11.9926 7.71164Z"
        fill="#FBC91B"
      />
    </svg>
  );
};

NasIoLogo.defaultProps = {
  fill: 'white'
};

export default NasIoLogo;
