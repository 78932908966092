import React from 'react';

const FacebookFooter = ({ customClassNames }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={`${customClassNames}`}>
      <g id="footer-icon-facebook">
        <g id="Frame" clipPath="url(#clip0_1971_110592)">
          <g id="Group">
            <g id="Group_2">
              <path
                id="Vector"
                d="M22 12C22 6.48016 17.5267 2 12 2C6.48016 2 2 6.48016 2 12C2 16.9932 5.65937 21.1313 10.4405 21.8769V14.8933H7.89603V12H10.4337V9.79754C10.4337 7.29412 11.9248 5.90561 14.2093 5.90561C15.3037 5.90561 16.446 6.10397 16.446 6.10397V8.56635H15.1874C13.9425 8.56635 13.5595 9.33926 13.5595 10.1259V12H16.3365L15.8919 14.8933H13.5595V21.8837C18.3475 21.1313 22 16.9932 22 12Z"
                fill="#FBFCFF"
              />
            </g>
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1971_110592">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(2 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

FacebookFooter.defaultProps = {
  customClassNames: ''
};

export default FacebookFooter;
