import { SHOW_SESSIONIZED_PAYOUT_NUDGE } from '@/pages/portal/home/utils.js';
import Cookies from 'js-cookie';
import { getPrimaryDomain } from '@/utility/stringHelper';

export const ACCESS_TOKEN_KEY = 'accessTokenNA';
export const ACCESS_TOKEN_KEY_MEMBER = 'accessTokenNA';
export const REFRESH_TOKEN_KEY = 'refreshTokenNA';
export const REFRESH_TOKEN_KEY_MEMBER = 'refreshTokenNA';
export const ADMIN_USER_DATA = 'adminUserData';
export const USER_LOGGED_IN_KEY_SESSION = 'adminLoggedIn';
export const TELEGRAM_TOKEN = 'telegramToken';
class AdminAuth {
  /**
   * Save the user data in session Storage
   *
   * @param {string} userData
   */
  static saveUser(userData) {
    localStorage.setItem(ADMIN_USER_DATA, JSON.stringify(userData));
  }

  /**
   * Save the accessToken in local Storage
   *
   * @param {string} accessToken
   * @param {string} refreshToken
   */
  static authenticateUser(accessToken, refreshToken) {
    Cookies.set(ACCESS_TOKEN_KEY, accessToken, {
      path: '/',
      domain: getPrimaryDomain()
    });
    Cookies.set(REFRESH_TOKEN_KEY, refreshToken, {
      path: '/',
      domain: getPrimaryDomain()
    });
  }

  static authenticateMemberUser(accessToken, refreshToken) {
    Cookies.set(ACCESS_TOKEN_KEY_MEMBER, accessToken, {
      path: '/',
      domain: getPrimaryDomain()
    });
    Cookies.set(REFRESH_TOKEN_KEY_MEMBER, refreshToken, {
      path: '/',
      domain: getPrimaryDomain()
    });
  }
  /**
   * De-authenticate a user.
   * Remove a token from Local Storage.
   *
   */
  static deauthenticateUser() {
    localStorage.removeItem(TELEGRAM_TOKEN);
    localStorage.removeItem(ADMIN_USER_DATA);
    sessionStorage?.removeItem(USER_LOGGED_IN_KEY_SESSION);
    sessionStorage?.removeItem(SHOW_SESSIONIZED_PAYOUT_NUDGE);
    Cookies.remove(ACCESS_TOKEN_KEY, {
      path: '/',
      domain: getPrimaryDomain()
    });
    Cookies.remove(REFRESH_TOKEN_KEY, {
      path: '/',
      domain: getPrimaryDomain()
    });
    Cookies.remove(ACCESS_TOKEN_KEY_MEMBER, {
      path: '/',
      domain: getPrimaryDomain()
    });
    Cookies.remove(REFRESH_TOKEN_KEY_MEMBER, {
      path: '/',
      domain: getPrimaryDomain()
    });
    localStorage.setItem('userConfigData', JSON.stringify({}));
  }

  /**
   * return user token
   *
   * @returns {string}
   */
  static getUserToken() {
    return Cookies.get(ACCESS_TOKEN_KEY);
  }

  /**
   * return user data
   *
   * @returns {string}
   */
  static getAdminUserData() {
    if (!localStorage) return null;
    return JSON.parse(localStorage?.getItem(ADMIN_USER_DATA));
  }

  /**
   * return user token
   *
   * @returns {string}
   */
  static getRefreshToken() {
    return Cookies.get(REFRESH_TOKEN_KEY);
  }

  /**
   * return user token
   *
   * @returns {string}
   */
  static getUserTokenFromCookies() {
    return Cookies.get(ACCESS_TOKEN_KEY);
  }

  static getUserTokenFromCookiesForMemberAndAdmin() {
    return Cookies.get(ACCESS_TOKEN_KEY_MEMBER);
  }

  /**
   * return user token
   *
   * @returns {string}
   */
  static getRefreshTokenFromCookies() {
    return Cookies.get(REFRESH_TOKEN_KEY);
  }

  static isAuthenticated() {
    return (
      !!this.getUserTokenFromCookiesForMemberAndAdmin() ||
      !!this.getUserToken()
    );
  }

  // static isAuthenticatedLocal() {
  //   return !!this.getUserToken();
  // }
  static isAuthenticatedLocal() {
    return (
      !!this.getUserTokenFromCookiesForMemberAndAdmin() ||
      !!this.getUserToken()
    );
  }

  //use this for tracking/analytics purposes only
  static setUserLoggedInForSession() {
    sessionStorage?.setItem(USER_LOGGED_IN_KEY_SESSION, 'logged_in');
  }

  //use this for tracking/analytics purposes only
  static getUserLoggedInForSession() {
    const isUserLoggedIn = sessionStorage?.getItem(
      USER_LOGGED_IN_KEY_SESSION
    );

    return !!isUserLoggedIn;
  }
}

export default AdminAuth;
