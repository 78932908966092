import PropTypes from 'prop-types';

const TextIcon = ({ width, height, fillColor }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.64535 2H11.3547C11.6471 1.99999 11.8994 1.99998 12.1083 2.01423C12.3281 2.02924 12.5481 2.06224 12.7654 2.15224C13.2554 2.35523 13.6448 2.74458 13.8478 3.23463C13.9378 3.45192 13.9708 3.67186 13.9858 3.89175C14 4.10058 14 4.35291 14 4.64534V4.66667C14 5.03486 13.7015 5.33333 13.3333 5.33333C12.9651 5.33333 12.6667 5.03486 12.6667 4.66667C12.6667 4.34692 12.6663 4.14049 12.6555 3.98251C12.6452 3.83051 12.6274 3.77262 12.6159 3.74488C12.5483 3.58153 12.4185 3.45174 12.2551 3.38408C12.2274 3.37259 12.1695 3.35485 12.0175 3.34447C11.8595 3.3337 11.6531 3.33333 11.3333 3.33333H8.66667V12.6667H10C10.3682 12.6667 10.6667 12.9651 10.6667 13.3333C10.6667 13.7015 10.3682 14 10 14H6C5.63181 14 5.33333 13.7015 5.33333 13.3333C5.33333 12.9651 5.63181 12.6667 6 12.6667H7.33333V3.33333H4.66667C4.34692 3.33333 4.14049 3.3337 3.98251 3.34447C3.83051 3.35485 3.77262 3.37259 3.74488 3.38408C3.58153 3.45174 3.45174 3.58153 3.38408 3.74488C3.37259 3.77262 3.35485 3.83051 3.34447 3.98251C3.3337 4.14049 3.33333 4.34692 3.33333 4.66667C3.33333 5.03486 3.03486 5.33333 2.66667 5.33333C2.29848 5.33333 2 5.03486 2 4.66667L2 4.64534C1.99999 4.35291 1.99998 4.10058 2.01423 3.89175C2.02924 3.67186 2.06224 3.45192 2.15224 3.23463C2.35523 2.74458 2.74458 2.35523 3.23463 2.15224C3.45192 2.06224 3.67186 2.02924 3.89175 2.01423C4.10058 1.99998 4.35291 1.99999 4.64535 2Z"
        fill={fillColor}
      />
    </svg>
  );
};

TextIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fillColor: PropTypes.string
};

TextIcon.defaultProps = {
  width: '16',
  height: '16',
  fillColor: '#1B1B18'
};

export default TextIcon;
