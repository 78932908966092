import AdminAuth from '@/modules/AdminAuth';
import axios from 'axios';
import { getTokensService } from '@/services/community/auth/admin/adminCommunityAuthService';
const adminProtectedAxiosRequest = axios.create();

const protectedReqConfigMiddleware = (config) => {
  // Allow Auth headers to be passed in (for example on login where we fetch token from response manually)
  if (config?.headers?.Authorization) return config;

  const accessToken = `Bearer ${AdminAuth.getUserToken()}`;
  if (AdminAuth.getUserToken()) {
    const argHeaders = config?.headers || {};
    config.headers = {
      Authorization: accessToken,
      ...argHeaders
    };
    return config;
  }
  // Don't run the request if the user token is not available
  return Promise.reject('No Token');
};

const protectedReqErrorMiddleware = (error) => {
  return Promise.reject(error);
};

const protectedResMiddleware = (response) => response;
const protectedResErrorMiddleware = async (resError) => {
  if (axios.isCancel(resError)) {
    return Promise.resolve({ error: 'Request Cancelled' });
  }
  try {
    const status = resError?.response?.status;
    const originalRequest = resError.config;
    let retry = originalRequest?.retry;

    const refreshToken = AdminAuth.getRefreshToken();

    if (status === 403) {
      // Forbidden means that user is just a learner and not an admin
      // Log the user out
      AdminAuth.deauthenticateUser();
      return Promise.reject(`User is not an admin`);
    }
    if (!retry) {
      retry = 3;
    }
    if (retry === 0) {
      // Redirect to login
      AdminAuth.deauthenticateUser();
      return Promise.reject(`Max Retry Reached`);
    }

    if (refreshToken && status === 401) {
      const payload = { refreshToken, isCommunityAdmin: true };
      const { data, error } = await getTokensService(payload);
      if (error) {
        console.error('Trying to refresh token', error);
        // Refresh token also doesn't work. Log the user out
        AdminAuth.deauthenticateUser();
        window.location.href = '/login';
        return Promise.reject(error);
      }

      const { token, refresh_token } = data;

      AdminAuth.authenticateUser(token, refresh_token);

      if (!token) throw new Error('No Access Token Found.');

      originalRequest.headers.Authorization = `Bearer ${token}`;
      retry -= 1;
      return adminProtectedAxiosRequest({ ...originalRequest, retry });
    }

    return Promise.reject(resError);
  } catch (error) {
    return Promise.reject(error);
  }
};

// Request interceptors
adminProtectedAxiosRequest.interceptors.request.use(
  protectedReqConfigMiddleware,
  protectedReqErrorMiddleware
);

// Response interceptors
adminProtectedAxiosRequest.interceptors.response.use(
  protectedResMiddleware,
  protectedResErrorMiddleware
);

export default adminProtectedAxiosRequest;
