import PropTypes from 'prop-types';
import React from 'react';

const className = 'c-CopyLink';

const CopyLink = ({ width, height, customClassNames, fill }) => {
  return (
    <svg
      className={`${className} ${customClassNames}`}
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.2513 10C3.2513 8.575 4.40964 7.41666 5.83464 7.41666H9.16797V5.83333H5.83464C3.53464 5.83333 1.66797 7.7 1.66797 10C1.66797 12.3 3.53464 14.1667 5.83464 14.1667H9.16797V12.5833H5.83464C4.40964 12.5833 3.2513 11.425 3.2513 10ZM6.66797 10.8333H13.3346V9.16666H6.66797V10.8333ZM14.168 5.83333H10.8346V7.41666H14.168C15.593 7.41666 16.7513 8.575 16.7513 10C16.7513 11.425 15.593 12.5833 14.168 12.5833H10.8346V14.1667H14.168C16.468 14.1667 18.3346 12.3 18.3346 10C18.3346 7.7 16.468 5.83333 14.168 5.83333Z"
        fill={fill}
      />
    </svg>
  );
};

CopyLink.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  customClassNames: PropTypes.string
};
CopyLink.defaultProps = {
  width: '20',
  height: '20',
  fill: '#222'
};

export default CopyLink;
