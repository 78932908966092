import PropTypes from 'prop-types';

const HashIcon = ({ width, height, fillColor }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.44292 1.34218C6.8061 1.40271 7.05145 1.7462 6.99092 2.10938L6.56474 4.66645H10.5463L11.009 1.89018C11.0696 1.527 11.4131 1.28165 11.7762 1.34218C12.1394 1.40271 12.3848 1.7462 12.3242 2.10938L11.8981 4.66645H13.6667C14.0349 4.66645 14.3333 4.96492 14.3333 5.33311C14.3333 5.7013 14.0349 5.99978 13.6667 5.99978H11.6758L11.0092 9.99978H13C13.3682 9.99978 13.6667 10.2983 13.6667 10.6664C13.6667 11.0346 13.3682 11.3331 13 11.3331H10.7869L10.3242 14.1094C10.2637 14.4726 9.92022 14.7179 9.55704 14.6574C9.19386 14.5968 8.94852 14.2534 9.00905 13.8902L9.43522 11.3331H5.45363L4.99092 14.1094C4.93039 14.4726 4.5869 14.7179 4.22372 14.6574C3.86054 14.5968 3.6152 14.2534 3.67573 13.8902L4.10191 11.3331H1.66667C1.29848 11.3331 1 11.0346 1 10.6664C1 10.2983 1.29848 9.99978 1.66667 9.99978H4.32413L4.99079 5.99978H2.33333C1.96514 5.99978 1.66667 5.7013 1.66667 5.33311C1.66667 4.96492 1.96514 4.66645 2.33333 4.66645H5.21302L5.67573 1.89018C5.73626 1.527 6.07974 1.28165 6.44292 1.34218ZM6.34252 5.99978L5.67585 9.99978H9.65745L10.3241 5.99978H6.34252Z"
        fill={fillColor}
      />
    </svg>
  );
};

HashIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fillColor: PropTypes.string
};

HashIcon.defaultProps = {
  width: '16',
  height: '16',
  fillColor: '#1B1B18'
};

export default HashIcon;
