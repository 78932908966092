import React from 'react';

const NPLInfo = ({ customClassNames }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={`c-NPLInfo fill-current ${customClassNames}`}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2ZM0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10ZM8.9502 6C8.9502 5.44772 9.39791 5 9.9502 5H10.0502C10.6025 5 11.0502 5.44772 11.0502 6V6.1C11.0502 6.65228 10.6025 7.1 10.0502 7.1H9.9502C9.39791 7.1 8.9502 6.65228 8.9502 6.1V6ZM10 8C10.5523 8 11 8.44772 11 9V14C11 14.5523 10.5523 15 10 15C9.44771 15 9 14.5523 9 14V9C9 8.44772 9.44771 8 10 8Z"
        className="fill-current"
      />
    </svg>
  );
};

export default NPLInfo;
