import React from 'react';

function YoutubeGreyIcon({ customClassNames, height, width, viewBox }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox || '0 0 24 24'}
      fill="none"
      className={customClassNames}>
      <path
        d="M17.9898 5.88486C17.7977 5.15189 17.2348 4.5741 16.5209 4.37673C15.2166 4.01041 9.99965 4.01041 9.99965 4.01041C9.99965 4.01041 4.78286 4.01041 3.47864 4.36285C2.77847 4.56 2.20178 5.152 2.00973 5.88486C1.6665 7.22361 1.6665 9.99999 1.6665 9.99999C1.6665 9.99999 1.6665 12.7904 2.00973 14.1151C2.20199 14.848 2.76473 15.4258 3.47874 15.6231C4.7966 15.9896 9.99985 15.9896 9.99985 15.9896C9.99985 15.9896 15.2166 15.9896 16.5209 15.6371C17.2349 15.4399 17.7977 14.8621 17.99 14.1292C18.3331 12.7904 18.3331 10.0141 18.3331 10.0141C18.3331 10.0141 18.3468 7.22361 17.9898 5.88486V5.88486ZM8.33868 12.5649V7.43507L12.6769 9.99999L8.33868 12.5649Z"
        fill="#2C2C2C"
        fillOpacity="0.5"
      />
    </svg>
  );
}

YoutubeGreyIcon.defaultProps = {
  customClassNames: '',
  height: '24',
  width: '24',
  viewBox: '0 0 24 24'
};

export default YoutubeGreyIcon;
