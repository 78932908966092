import {
  ACTION_CREATE_BUNDLE,
  CHECKOUT_TYPE_BUNDLE,
  CHECKOUT_TYPE_COMMUNITY,
  CHECKOUT_TYPE_COURSE
} from './checkoutConstants';

import config from '@/utility/config';
import { convertObjToQueryString } from '@/services/helpers/queryString';
import { openInNewTab } from '@/components/common/Editor/utils/openInNewTab';

export const getCheckoutRoute = ({
  type,
  courseOfferingId,
  step = 'enroll',
  communitySlug,
  bundleSlug,
  bundleAction = ACTION_CREATE_BUNDLE,
  bundleCourses,
  autoCreateBundle,
  interval = 'month',
  intervalCount = 1,
  actionType,
  sourceInfo,
  requestor,
  discountCode,
  entityDiscountCode
}) => {
  switch (type) {
    case CHECKOUT_TYPE_COURSE:
      if (!courseOfferingId) return '/';

      return `/checkout?type=${type}&courseOfferingId=${courseOfferingId}&step=${step}`;
    case CHECKOUT_TYPE_COMMUNITY: {
      if (!courseOfferingId) return '/';
      let query = {
        type,
        communityCode: courseOfferingId, // Course offering Id is community Code
        step,
        interval,
        intervalCount,
        slug: communitySlug,
        requestor
      };

      if (actionType) query.actionType = actionType;
      if (sourceInfo?.type) query.sourceInfoType = sourceInfo.type;
      if (sourceInfo?.origin) query.sourceInfoOrigin = sourceInfo.origin;
      if (discountCode) query.discountCode = discountCode;
      if (entityDiscountCode)
        query.entityDiscountCode = entityDiscountCode;

      const queryStr = convertObjToQueryString(query);
      return `/checkout?${queryStr}`;
    }
    case CHECKOUT_TYPE_BUNDLE:
      if (!bundleSlug) return '/';

      if (autoCreateBundle) {
        const coursesList = bundleCourses?.join(',');

        return `/checkout?type=${type}&bundleSlug=${bundleSlug}&bundleAction=checkout&step=${step}&autoCreateBundle=1&bundleCourses=${coursesList}`;
      }

      if (bundleCourses) {
        const coursesList = bundleCourses?.join(',');
        return `/checkout?type=${type}&bundleSlug=${bundleSlug}&bundleAction=${bundleAction}&step=${step}&bundleCourses=${coursesList}`;
      } else {
        return `/checkout?type=${type}&bundleSlug=${bundleSlug}&bundleAction=${bundleAction}&step=${step}`;
      }

    default:
      return '/';
  }
};

export const getCourseSignupRoute = (
  courseOfferingId,
  step = 'enroll'
) => {
  return getCheckoutRoute({
    type: CHECKOUT_TYPE_COURSE,
    courseOfferingId,
    step
  });
};

export const getCommunityCheckoutRoute = ({
  communityCode,
  interval,
  intervalCount,
  step = 'enroll',
  communitySlug,
  actionType = '',
  sourceInfo,
  requestor,
  discountCode,
  entityDiscountCode
}) => {
  return getCheckoutRoute({
    type: CHECKOUT_TYPE_COMMUNITY,
    courseOfferingId: communityCode,
    step,
    interval,
    communitySlug,
    intervalCount,
    actionType,
    sourceInfo,
    requestor,
    discountCode,
    entityDiscountCode
  });
};

export const getBundleCheckoutRoute = ({
  bundleSlug,
  bundleAction = ACTION_CREATE_BUNDLE,
  step = 'enroll',
  bundleCourses,
  autoCreateBundle
}) => {
  return getCheckoutRoute({
    type: CHECKOUT_TYPE_BUNDLE,
    bundleSlug,
    bundleAction,
    step,
    bundleCourses,
    autoCreateBundle
  });
};

export const discoveryPageRoute = '/courses';
export const discoveryPageDegreeCategoryRoute =
  '/courses/category/degrees';
export const businessPageRoute = '/business';
export const creatorPageRoute = '/creators';
export const checkoutPageRoute = 'checkout';
export const communitiesPageRoute = `${config.siteBaseUrl}/#join-a-community`;
export const academyRoute = 'https://nasacademy.com';

export const getCourseCategoryPageRouteFromSlug = (slug) =>
  `/courses/category/${slug}`;

//converting query object to query string
export const getQueryStringFromObject = ({ queryParams = {} }) => {
  try {
    const paramKeys = Object.keys(queryParams);
    if (paramKeys.length === 0) return '';
    return paramKeys.map((key) => `${key}=${queryParams[key]}`).join('&');
  } catch (e) {
    console.error(e);
    return '';
  }
};

export const CM_PORTAL_HOMEPAGE_ROUTE = '/portal';

export const START_COMMUNITY_ONBOARDING_ROUTE = '/start-community';
export const LOGIN_ROUTE = '/login';
export const PRODUCT_DEMO_PAGE_ROUTE = '/product-demo';
export const startACommunityRoute = '/application/start-a-community';
export const homepageJoinACommunityHashRoute = 'join-a-community';
export const PAYOUT_FORM_ROUTE = '/portal/settings/payout-form';
export const settingsAddDiscountCodePageRoute =
  '/portal/settings/discounts/add';
export const CREATE_MAGIC_REACH_ROUTE = '/portal/magic-reach/new';
export const CM_PORTAL_GETTING_STARTED_ROUTE = '/portal/get-started';
export const CM_PORTAL_HOME_ROUTE = '/portal/home';
export const CM_PORTAL_EVENTS_ROUTE = '/portal/events';
export const CM_PORTAL_ADD_NEW_EVENT_ROUTE = '/portal/events/add';
export const CM_PORTAL_MONEY_PAGE_ROUTE = '/portal/money';
export const CM_PORTAL_DISCOUNT_PAGE_ROUTE = '/portal/money/discount';
export const CM_PORTAL_MAGIC_REACH_PAGE_ROUTE = '/portal/magic-reach';
export const CM_PORTAL_LIBRARY_PAGE_ROUTE = '/portal/library';
export const CM_PORTAL_SETTINGS_PAGE_ROUTE = '/portal/settings';
export const CM_PORTAL_PUBLIC_PAGE_ROUTE = '/portal/settings'; //the public page is the first tab of the setting page
export const CM_PORTAL_MEMBERS_PAGE_ROUTE = '/portal/members';
export const CM_PORTAL_ANALYTICS_PAGE_ROUTE = '/portal/analytics';
export const CM_PORTAL_FEED_PAGE_ROUTE = '/portal/feed';

export const EVENTS_FEATURE_PAGE_ROUTE = '/features/live-events';
export const MONETIZATION_FEATURE_PAGE_ROUTE = '/features/monetization';
export const MAGIC_REACH_FEATURE_PAGE_ROUTE = '/features/magic-reach';
export const CONTENT_LIB_FEATURE_PAGE_ROUTE = '/features/content-library';
export const CHAT_INTEGRATION_FEATURE_PAGE_ROUTE =
  '/features/chat-integrations';
export const PUBLIC_PAGE_FEATURE_PAGE_ROUTE = '/features/public-page';

export function getMemberPortalRoute(activeCommunityId) {
  let memberPortalRoute =
    config.memberPortalPath || 'https://member.nas.io/communities';
  if (activeCommunityId) {
    memberPortalRoute += `/communities/${activeCommunityId}/home`;
  }
  return memberPortalRoute;
}

export function goToMemberView(activeCommunityId) {
  const memberPortalRoute = getMemberPortalRoute(activeCommunityId);
  openInNewTab(memberPortalRoute);
}

export function goToMemberViewSettings() {
  const baseMemberPortalPath =
    config.memberPortalPath || 'https://member.nas.io';
  let settingProfileRoute = `${baseMemberPortalPath}/setting/profile`;

  openInNewTab(settingProfileRoute);
}

export function getDuplicateEventPageRoute(duplicateFromEventId) {
  return `${CM_PORTAL_ADD_NEW_EVENT_ROUTE}?duplicateFromEventId=${duplicateFromEventId}`;
}

export const getDiscountCodeFromUrlParams = (router) => {
  return router?.query?.discountCode ?? '';
};

export const getCreatePaidEventRoute = () => {
  return `${CM_PORTAL_ADD_NEW_EVENT_ROUTE}?access=paid`;
};

export const getSettingsPageChatTabRoute = () => {
  return `${CM_PORTAL_SETTINGS_PAGE_ROUTE}?tab=chat`;
};
