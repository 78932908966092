import React from 'react';

const FeaturePlay = ({ width, height }) => {
  return (
    <svg
      className="c-FeaturePlay"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 88 88">
      <path
        fill="#fff"
        d="M33.148 28.746c0-1.392 1.54-2.233 2.711-1.48l23.732 15.256a1.76 1.76 0 010 2.961L35.86 60.739c-1.17.753-2.711-.088-2.711-1.48V28.746z"></path>
    </svg>
  );
};

FeaturePlay.defaultProps = {
  width: '88',
  height: '88'
};

export default FeaturePlay;
