import React from 'react';
const className = 'c-CouponIcon';
const CouponIcon = ({ width, height, customClassName, fill }) => {
  return (
    <svg
      className={`${className} ${customClassName}`}
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none">
      <path
        d="M10.8743 16.8333C10.5549 17.1527 10.1591 17.3125 9.68685 17.3125C9.21463 17.3125 8.81879 17.1527 8.49935 16.8333L1.16602 9.49996C1.01324 9.34718 0.891849 9.16663 0.801849 8.95829C0.711293 8.74996 0.666016 8.52774 0.666016 8.29163V2.33329C0.666016 1.87496 0.829349 1.48246 1.15602 1.15579C1.48213 0.829681 1.87435 0.666626 2.33268 0.666626H8.29102C8.52713 0.666626 8.74935 0.711626 8.95768 0.801626C9.16602 0.892181 9.34657 1.01385 9.49935 1.16663L16.8327 8.52079C17.1521 8.84024 17.3118 9.23246 17.3118 9.69746C17.3118 10.163 17.1521 10.5555 16.8327 10.875L10.8743 16.8333ZM9.68685 15.6666L15.6452 9.70829L8.29102 2.33329H2.33268V8.29163L9.68685 15.6666ZM4.41602 5.66663C4.76324 5.66663 5.05824 5.54496 5.30102 5.30163C5.54435 5.05885 5.66602 4.76385 5.66602 4.41663C5.66602 4.0694 5.54435 3.7744 5.30102 3.53163C5.05824 3.28829 4.76324 3.16663 4.41602 3.16663C4.06879 3.16663 3.77379 3.28829 3.53102 3.53163C3.28768 3.7744 3.16602 4.0694 3.16602 4.41663C3.16602 4.76385 3.28768 5.05885 3.53102 5.30163C3.77379 5.54496 4.06879 5.66663 4.41602 5.66663Z"
        fill={fill}
      />
    </svg>
  );
};

CouponIcon.defaultProps = {
  width: 18,
  height: 18,
  fill: '#1B1B18'
};

export default CouponIcon;
