const DragIndicatorReorder = ({
  width,
  height,
  customClassNames,
  fillColor
}) => {
  return (
    <svg
      className={`${customClassNames}`}
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 24 24">
      <path
        fill={fillColor}
        d="M11 18c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zm-2-8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"></path>
    </svg>
  );
};

DragIndicatorReorder.defaultProps = {
  width: 24,
  height: 24,
  fillColor: '#666666',
  customClassNames: ''
};

export default DragIndicatorReorder;
