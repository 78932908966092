const className = 'c-IconV2-ArrowForward';

const ArrowForward = ({ customClassNames, fill, width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 33 33"
      fill="none"
      className={`${className} ${customClassNames}`}>
      <path
        d="M7.16634 18.2272H22.0597L15.553 24.7338C15.033 25.2538 15.033 26.1072 15.553 26.6272C16.073 27.1472 16.913 27.1472 17.433 26.6272L26.2197 17.8405C26.7397 17.3205 26.7397 16.4805 26.2197 15.9605L17.4463 7.16051C16.9263 6.64051 16.0863 6.64051 15.5663 7.16051C15.0463 7.68051 15.0463 8.52051 15.5663 9.04051L22.0597 15.5605H7.16634C6.43301 15.5605 5.83301 16.1605 5.83301 16.8938C5.83301 17.6272 6.43301 18.2272 7.16634 18.2272Z"
        fill={fill}
      />
    </svg>
  );
};

ArrowForward.defaultProps = {
  customClassNames: '',
  fill: '#191C1E',
  width: '33',
  height: '33'
};

export default ArrowForward;
