import React from 'react';

const className = 'c-IconV2-Calendar-Event';

const CalendarEvent = ({ customClassNames, color }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={`${className} ${customClassNames}`}>
      <path
        d="M2.66602 5.33325H13.3327"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.666 2.66675H3.33268C2.96449 2.66675 2.66602 2.96522 2.66602 3.33341V12.6667C2.66602 13.0349 2.96449 13.3334 3.33268 13.3334H12.666C13.0342 13.3334 13.3327 13.0349 13.3327 12.6667V3.33341C13.3327 2.96522 13.0342 2.66675 12.666 2.66675Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.83398 8H5.50065C5.4086 8 5.33398 8.07462 5.33398 8.16667V10.5C5.33398 10.592 5.4086 10.6667 5.50065 10.6667H7.83398C7.92603 10.6667 8.00065 10.592 8.00065 10.5V8.16667C8.00065 8.07462 7.92603 8 7.83398 8Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.666 1.33325V2.66659"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.33398 1.33325V2.66659"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

CalendarEvent.defaultProps = {
  customClassNames: '',
  color: '#191C1E'
};

export default CalendarEvent;
