import React from 'react';

const Dash = ({ fill }) => {
  return (
    <svg
      className="c-Dash"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24">
      <path fill={fill} d="M3 11h18v2H3v-2z"></path>
    </svg>
  );
};

Dash.defaultProps = {
  fill: '#AAABAE'
};

export default Dash;
