import Auth from '../../modules/Auth';

const authorizedRequest = async (path, options) => {
  try {
    const { headers } = options || {};
    //the token is already passed to options.headers
    if (!headers || !headers['Authorization']) {
      const authToken =
        sessionStorage.getItem('accessToken') || Auth.getUserToken();

      options.headers = {
        ...headers,
        Authorization: `Bearer ${authToken}`
      };
    }

    const res = await fetch(path, options);

    const data = await res.json();

    // Check Res Status for Error.
    if (res.status >= 500) {
      const msg = data?.message || 'Internal Server Error';
      throw new Error(msg);
    }

    if (res.status >= 400) {
      const msg = data?.info || data?.message || 'Not Found.';
      throw new Error(msg);
    }

    return { error: false, data };
  } catch (e) {
    throw new Error(e.message);
  }
};

export default authorizedRequest;
