import React from 'react';

const MoreVerticle = ({
  width = 24,
  height = 24,
  fill = '#AAABAE',
  customClassNames
}) => {
  return (
    <svg
      className={`c-MoreVerticle ${customClassNames}`}
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 24 24">
      <path
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M11 18a1 1 0 112 0 1 1 0 01-2 0zM11 12a1 1 0 112 0 1 1 0 01-2 0zM11 6a1 1 0 112 0 1 1 0 01-2 0z"></path>
    </svg>
  );
};

MoreVerticle.defaultProps = {
  fill: '#AAABAE'
};

export default MoreVerticle;
