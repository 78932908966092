import React from 'react';

function UploadCloudO1Icon({ customClassNames, width, height, fill }) {
  return (
    <svg
      className={`c-UploadCloudO1Icon ${customClassNames}`}
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 16 16">
      <path
        fill={fill}
        fillOpacity="0.5"
        fillRule="evenodd"
        d="M3.5 4.762a4.668 4.668 0 019 0 3.668 3.668 0 011.204 6.62.667.667 0 11-.741-1.108 2.334 2.334 0 00-1.077-4.264.667.667 0 01-.597-.556 3.334 3.334 0 00-6.578 0 .667.667 0 01-.596.556 2.334 2.334 0 00-1.077 4.264.667.667 0 11-.742 1.108A3.668 3.668 0 013.5 4.762zM7.334 9.61l-1.529 1.528a.667.667 0 11-.943-.943L7.53 7.53c.26-.26.682-.26.943 0l2.666 2.666a.667.667 0 01-.942.943L8.667 9.61V14a.667.667 0 11-1.333 0V9.61z"
        clipRule="evenodd"></path>
    </svg>
  );
}

UploadCloudO1Icon.defaultProps = {
  customClassNames: '',
  fill: '#1B1B18',
  width: '16',
  height: '16'
};

export default UploadCloudO1Icon;
