import React from 'react';

const WhatsappIcon = ({ fill, customClassNames, width, height }) => {
  return (
    <svg
      className={`${customClassNames}`}
      width={width}
      height={height}
      viewBox="0 0 24 24">
      <path
        d="M1.99275 22.0529L3.40651 16.8902C2.53444 15.3792 2.07574 13.6652 2.07638 11.9092C2.07862 6.41582 6.54901 1.94656 12.0422 1.94656C14.7079 1.94769 17.21 2.98525 19.0916 4.86845C20.973 6.75164 22.0089 9.25488 22.0079 11.9172C22.0055 17.4104 17.5345 21.8803 12.0422 21.8803C12.0424 21.8803 12.0419 21.8803 12.0422 21.8803H12.0379C10.37 21.8797 8.73133 21.4613 7.27575 20.6675L1.99275 22.0529ZM7.5204 18.8639L7.82306 19.0432C9.09454 19.7978 10.5524 20.1969 12.039 20.1977H12.0422C16.6074 20.1977 20.3232 16.4826 20.3251 11.9166C20.3259 9.7038 19.4652 7.62306 17.9014 6.0579C16.3373 4.49274 14.2579 3.63029 12.0453 3.62933C7.47635 3.62933 3.76075 7.34396 3.75898 11.9098C3.75834 13.4745 4.19606 14.9982 5.02534 16.3168L5.22241 16.6302L4.38543 19.6858L7.5204 18.8639Z"
        fill={fill}
      />
      <path
        d="M1.99023 22.0532L3.40399 16.8905C2.53193 15.3795 2.07323 13.6655 2.07387 11.9095C2.07611 6.41615 6.54649 1.94689 12.0397 1.94689C14.7054 1.94801 17.2075 2.98558 19.0891 4.86878C20.9705 6.75197 22.0063 9.2552 22.0054 11.9175C22.003 17.4107 17.532 21.8806 12.0397 21.8806C12.0399 21.8806 12.0394 21.8806 12.0397 21.8806H12.0354C10.3675 21.88 8.72881 21.4617 7.27324 20.6678L1.99023 22.0532ZM7.51789 18.8642L7.82054 19.0435C9.09203 19.7981 10.5498 20.1972 12.0365 20.198L12.039 20.1977C16.6042 20.1977 20.3207 16.4829 20.3226 11.9169C20.3234 9.70413 19.4627 7.62339 17.8988 6.05823C16.3348 4.49307 14.2553 3.63062 12.0427 3.62965C7.47383 3.62965 3.75823 7.34429 3.75647 11.9102C3.75583 13.4748 4.19354 14.9985 5.02283 16.3171L5.21989 16.6305L4.38292 19.6861L7.51789 18.8642Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.54688 7.74425C9.36039 7.32961 9.16396 7.32127 8.98644 7.31406C8.84129 7.30782 8.67514 7.30814 8.50932 7.30814C8.34333 7.30814 8.07352 7.37062 7.84538 7.61976C7.61707 7.8689 6.97363 8.47131 6.97363 9.6965C6.97363 10.9218 7.86604 12.1057 7.99053 12.272C8.11502 12.4381 9.71319 15.0327 12.2441 16.0308C14.3476 16.8605 14.7756 16.6954 15.2322 16.6539C15.6888 16.6124 16.7054 16.0515 16.9129 15.4701C17.1205 14.8887 17.1205 14.3904 17.0582 14.2861C16.9959 14.1824 16.8299 14.1201 16.5809 13.9956C16.3319 13.8711 15.1077 13.2685 14.8794 13.1855C14.6511 13.1024 14.4851 13.0611 14.3191 13.3104C14.153 13.5593 13.6762 14.1201 13.5308 14.2861C13.3855 14.4525 13.2402 14.4734 12.9912 14.3487C12.7423 14.2239 11.9402 13.9612 10.9888 13.1128C10.2486 12.4529 9.74876 11.6377 9.60344 11.3884C9.45828 11.1394 9.60104 11.0164 9.71287 10.8803C9.91426 10.6354 10.2522 10.1949 10.3352 10.0289C10.4181 9.86264 10.3766 9.71732 10.3145 9.59267C10.2522 9.46819 9.76814 8.23676 9.54688 7.74425Z"
        fill={fill}
      />
    </svg>
  );
};

WhatsappIcon.defaultProps = {
  fill: '#191C1E',
  customClassNames: '',
  width: 24,
  height: 24
};

export default WhatsappIcon;
