import React from 'react';

const MagicReachFeatureIcon = ({ customClassNames }) => {
  return (
    <svg
      className={`c-MagicReachFeatureIcon ${customClassNames}`}
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32">
      <g>
        <path
          fill="#937300"
          d="M25.333 2H6.667A2.675 2.675 0 004 4.667v18.666C4 24.8 5.2 26 6.667 26H12l4 4 4-4h5.333C26.8 26 28 24.8 28 23.333V4.667C28 3.2 26.8 2 25.333 2zm0 21.333h-6.44L16 26.227l-2.893-2.894h-6.44V4.667h18.666v18.666zM16 22l2.507-5.493L24 14l-5.493-2.507L16 6l-2.507 5.493L8 14l5.493 2.507L16 22z"></path>
      </g>
    </svg>
  );
};

MagicReachFeatureIcon.defaultProps = {
  customClassNames: ''
};

export default MagicReachFeatureIcon;
