import React from 'react';

const NPLLightbulb = ({ customClassNames, stroke }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      stroke={stroke}
      className={`c-NPLLightbulb fill-stroke ${customClassNames}`}>
      <path
        d="M6.66536 11.7726V13.3335C6.66536 14.0699 7.26232 14.6668 7.9987 14.6668C8.73508 14.6668 9.33203 14.0699 9.33203 13.3335V11.7726M7.9987 1.3335V2.00016M1.9987 8.00016H1.33203M3.66536 3.66683L3.2653 3.26676M12.332 3.66683L12.7322 3.26676M14.6654 8.00016H13.9987M11.9987 8.00016C11.9987 10.2093 10.2078 12.0002 7.9987 12.0002C5.78956 12.0002 3.9987 10.2093 3.9987 8.00016C3.9987 5.79102 5.78956 4.00016 7.9987 4.00016C10.2078 4.00016 11.9987 5.79102 11.9987 8.00016Z"
        className="stroke-current"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

NPLLightbulb.defaultProps = {
  stroke: '',
  customClassNames: ''
};
export default NPLLightbulb;
