import React from 'react';

const NasIODark = ({ customClassNames }) => {
  return (
    <svg
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      className={customClassNames}>
      <path
        fill="#2C2C2C"
        d="M2.304 11.454a2.177 2.177 0 013.078-3.078l6.157 6.157a2.177 2.177 0 11-3.078 3.078l-6.157-6.157z"></path>
      <path
        fill="#2C2C2C"
        d="M11.54 17.61a2.177 2.177 0 01-3.08-3.078l6.157-6.156a2.177 2.177 0 113.079 3.078l-6.157 6.157z"></path>
      <path
        fill="#FBC91B"
        d="M9.995 6.426a2.38 2.38 0 100-4.76 2.38 2.38 0 000 4.76z"></path>
    </svg>
  );
};

export default NasIODark;
