import React from 'react';
const className = 'c-Rectangle1284Icon';
const Rectangle1284Icon = ({ customClassName, width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill="none"
      className={`${className} ${customClassName}`}>
      <path
        d="M0.343262 1H25.0001C27.2092 1 29.0001 2.79086 29.0001 5V29.2353"
        stroke="#E1E2E5"
        strokeWidth="2"
      />
    </svg>
  );
};

Rectangle1284Icon.defaultProps = {
  width: 30,
  height: 30
};

export default Rectangle1284Icon;
