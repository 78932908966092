import React from 'react';

const className = 'c-IconV2-Exercise';

const Exercise = ({ customClassNames, width, height }) => {
  return (
    <svg
      className={`${className} ${customClassNames}`}
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 24 25">
      <path
        fill="#222"
        d="M14.5 3.498H5.333c-.916 0-1.666.75-1.666 1.667v10.833c0 .459.375.834.833.834a.836.836 0 00.833-.834v-10c0-.458.375-.833.834-.833H14.5a.836.836 0 00.833-.833.836.836 0 00-.833-.834zm.492 3.825l4.025 4.025c.308.309.483.734.483 1.175v7.642c0 .917-.75 1.667-1.667 1.667H8.658c-.916 0-1.658-.75-1.658-1.667l.008-11.667c0-.916.742-1.666 1.659-1.666h5.141c.442 0 .867.175 1.184.491zm-.492 5.342h3.75l-4.583-4.583v3.75c0 .458.375.833.833.833z"></path>
    </svg>
  );
};

Exercise.defaultProps = {
  customClassNames: '',
  width: '24',
  height: '25'
};

export default Exercise;
