import PropTypes from 'prop-types';
import React from 'react';

const className = 'c-FacebookOutlineIcon';
const FacebookOutlineIcon = ({ width, height, fillColor }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 15 14" fill="none">
      <path
        d="M14.1673 7.00065C14.1673 3.32076 11.1851 0.333984 7.50065 0.333984C3.82076 0.333984 0.833984 3.32076 0.833984 7.00065C0.833984 10.3294 3.27357 13.0882 6.46098 13.5852V8.92952H4.76467V7.00065H6.45642V5.53234C6.45642 3.8634 7.45049 2.93772 8.97352 2.93772C9.70311 2.93772 10.4646 3.06996 10.4646 3.06996V4.71155H9.6256C8.79568 4.71155 8.54032 5.22683 8.54032 5.75122V7.00065H10.3917L10.0953 8.92952H8.54032V13.5898C11.7323 13.0882 14.1673 10.3294 14.1673 7.00065Z"
        fill={fillColor}
      />
    </svg>
  );
};

FacebookOutlineIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fillColor: PropTypes.string
};

FacebookOutlineIcon.defaultProps = {
  width: '15',
  height: '14',
  fillColor: '#FBFCFF'
};

export default FacebookOutlineIcon;
