import React from 'react';

const LibraryAdd = ({ customClassNames }) => {
  return (
    <svg
      className={`c-LibraryAdd ${customClassNames}`}
      width="33"
      height="32"
      fill="none"
      viewBox="0 0 33 32">
      <mask
        id="mask0_5097_13609"
        style={{ maskType: 'alpha' }}
        width="33"
        height="33"
        x="0"
        y="-1"
        maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0.5 -0.001H32.5V31.999H0.5z"></path>
      </mask>
      <g mask="url(#mask0_5097_13609)">
        <path
          fill="#1C1B1F"
          d="M19.167 18.665c.377 0 .694-.128.95-.384.255-.255.383-.572.383-.95v-2.666h2.667c.377 0 .694-.128.95-.384.255-.255.383-.572.383-.95 0-.377-.128-.694-.384-.95a1.29 1.29 0 00-.95-.383H20.5V9.332c0-.378-.128-.695-.383-.951a1.294 1.294 0 00-.95-.383 1.29 1.29 0 00-.95.383 1.291 1.291 0 00-.384.95v2.667h-2.666a1.29 1.29 0 00-.95.383 1.291 1.291 0 00-.384.95c0 .379.128.695.384.95.255.256.572.384.95.384h2.666v2.667c0 .377.128.694.384.949.256.256.572.384.95.384zm-8 5.333c-.734 0-1.361-.26-1.883-.782a2.569 2.569 0 01-.784-1.884v-16c0-.734.261-1.362.784-1.884a2.567 2.567 0 011.883-.783h16c.733 0 1.361.26 1.884.783.522.522.782 1.15.782 1.884v16c0 .733-.26 1.361-.782 1.884a2.57 2.57 0 01-1.884.782h-16zm0-2.666h16v-16h-16v16zm-5.334 8c-.733 0-1.36-.261-1.882-.783a2.569 2.569 0 01-.784-1.884V9.332c0-.378.128-.695.384-.951a1.29 1.29 0 01.95-.383c.377 0 .694.128.95.383.255.256.382.573.382.95v17.334h17.334c.377 0 .694.128.95.384.255.255.383.572.383.95 0 .377-.128.694-.384.949a1.287 1.287 0 01-.95.384H5.834z"></path>
      </g>
    </svg>
  );
};

LibraryAdd.defaultProps = {
  customClassNames: ''
};

export default LibraryAdd;
