import React from 'react';

const UsersMoney = ({ width, height, fill }) => {
  return (
    <svg width={width} height={height} fill="none" viewBox="0 0 18 18">
      <path
        fill={fill}
        fillRule="evenodd"
        d="M4.875 5.25a2.25 2.25 0 114.5 0 2.25 2.25 0 01-4.5 0zm2.25-3.75a3.75 3.75 0 100 7.5 3.75 3.75 0 000-7.5zm4.781.273a.75.75 0 10-.562 1.39 2.25 2.25 0 010 4.174.75.75 0 00.562 1.39 3.75 3.75 0 000-6.954zM9 10.5H5.973c-.676 0-1.222 0-1.666.03-.456.031-.86.097-1.242.255a3.75 3.75 0 00-2.03 2.03c-.158.382-.224.786-.255 1.242-.03.444-.03.99-.03 1.666v.027a.75.75 0 001.5 0c0-.71 0-1.204.027-1.59.026-.38.074-.602.144-.771A2.25 2.25 0 013.64 12.17c.169-.07.39-.118.77-.144C4.798 12 5.292 12 6 12h3a.75.75 0 000-1.5zm6-.75a.75.75 0 00-1.5 0v.037a1.876 1.876 0 00.375 3.713h.75a.375.375 0 010 .75H12.75a.75.75 0 000 1.5h.75a.75.75 0 001.5 0v-.037A1.876 1.876 0 0014.625 12h-.75a.375.375 0 010-.75h1.875a.75.75 0 000-1.5H15z"
        clipRule="evenodd"></path>
    </svg>
  );
};
UsersMoney.defaultProps = {
  width: 18,
  height: 18,
  fill: '#EBB800'
};

export default UsersMoney;
