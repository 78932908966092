const serverHelpers = {};

serverHelpers.isLocalHost = () => {
  const NEXT_PUBLIC_SITE_BASE_URL = process.env.NEXT_PUBLIC_SITE_BASE_URL;
  return (
    NEXT_PUBLIC_SITE_BASE_URL.includes('localhost') ||
    NEXT_PUBLIC_SITE_BASE_URL.includes('127.0.0.1') ||
    NEXT_PUBLIC_SITE_BASE_URL.includes('local.')
  );
};

module.exports = serverHelpers;
