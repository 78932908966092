import FacebookGreyIcon from '@/components/common/Icons/FacebookGreyIcon';
import InstagramGreyIcon from '@/components/common/Icons/InstagramGreyIcon';
import LinkedinGreyIcon from '@/components/common/Icons/LinkedinGreyIcon';
import TiktokGreyIcon from '@/components/common/Icons/TiktokGreyIcon';
import TwitterGreyIcon from '@/components/common/Icons/TwitterGreyIcon';
import YoutubeGreyIcon from '@/components/common/Icons/YoutubeGreyIcon';
import FacebookFooter from '@/components/common/IconsV2/FacebookFooterIcon';
import InstagramFooterIcon from '@/components/common/IconsV2/InstagramFooterIcon';
import LinkedInFooterIcon from '@/components/common/IconsV2/LinkedInFooterIcon';
import TiktokFooterIcon from '@/components/common/IconsV2/TiktokFooterIcon';
import TwitterFooterIcon from '@/components/common/IconsV2/TwitterFooterIcon';
import YoutubeFooterIcon from '@/components/common/IconsV2/YoutubeFooterIcon';
import config from './config';

export const COUNTRY_DATA_SESSION = 'country_data_session';
export const CONTACT_EMAIL = 'info@thenasacademy.com';
export const STATIC_ASSET_BASE_URL = config.imagesBasePath;
export const NA_PRIMARY_COLOR = '#fbc91b';
export const DARK_BUTTON_TEXT_COLOR = '#000000';
export const DOCUMENT_TITLE_TEMPLATE = 'Nas Academy - We create Creators';
export const NA_CONTACT_EMAIL = 'hello@nas.io';
export const NA_CREATOR_CONTACT_EMAIL = 'creators@nasacademy.com';
export const IMPACT_URL = '/impact';
export const REFERRAL_PAGE_URL = '/refer-a-friend';
export const REFERRAL_CODE_VALIDATION_API = `${config.learnPortalBackend}/api/v1/promo-code?code`;
export const LEARNING_PORTAL_DASHBOARD = `${config.learningPortalDashboard}`;
export const TRACK_CLICK_ID_KEY = 'clickid';
export const TRACK_SOURCE_KEY = 'source';
export const TRACK_MEDIUM_KEY = 'medium';
export const TRACK_CAMPAIGN_KEY = 'campaign';
export const TRACK_CAMPAIGN_ID_KEY = 'campaignid';
export const TRACK_CREATIVE_ID_KEY = 'creativeid';
export const TRACK_AD_GROUP_ID_KEY = 'adgroupid';
export const TRACK_AD_GROUP_NAME_KEY = 'adgroupname';
export const TRACK_AD_ID_KEY = 'adid';
export const TRACK_AD_NAME_KEY = 'adname';
export const ERROR_FOR_NO_EMAIL = 'Email is required';
export const ERROR_FOR_WRONG_EMAIL = 'Please enter a valid email address';
export const ERROR_FOR_WRONG_PHONE_NUMBER =
  'Please check the format of the phone number';
export const IS_DEV_ENV = process?.env?.NODE_ENV === 'development';

export const calendarVendorOptions = [
  { google: 'Google' },
  { apple: 'iCal' },
  { outlook: 'Outlook' },
  { outlookcom: 'Outlook.com' },
  { yahoo: 'Yahoo!' }
];

export const fundingTopStickyProps = {
  isHidden: true,
  desktopText: [
    {
      text: "Have you heard? We've raised"
    },
    {
      text: ' $11M in Series A funding ',
      isBolder: true
    },
    {
      text: 'to grow the company. '
    },
    {
      link: 'https://nasacademy.com/blog/article/why-nas-academy-raised-11m-for-creators',
      text: 'Read more'
    }
  ]
};

export const PORTAL_REDIRECT_ERROR_CODE = 10;

export const routeListShowingChat = [
  '/about-us',
  '/subscribe',
  '/faq',
  '/privacy',
  '/enrollment-terms',
  '/creators',
  '/impact',
  '/trainers',
  '/business',
  '/how-it-works'
];

export const darkLogoImageData = {
  alt: 'Logo',
  mobileImgProps: {
    src: 'https://d2oi1rqwb0pj00.cloudfront.net/icons/nas_academy_icon_dark.svg',
    layout: 'intrinsic',
    width: 48,
    height: 48
  }
};

export const footerIcons = {
  facebook: FacebookGreyIcon,
  instagram: InstagramGreyIcon,
  twitter: TwitterGreyIcon,
  youtube: YoutubeGreyIcon,
  linkedin: LinkedinGreyIcon,
  tiktok: TiktokGreyIcon
};

export const newFooterIcons = {
  facebook: FacebookFooter,
  instagram: InstagramFooterIcon,
  twitter: TwitterFooterIcon,
  youtube: YoutubeFooterIcon,
  linkedin: LinkedInFooterIcon,
  tiktok: TiktokFooterIcon
};

export const roles = {
  admin: 'admin',
  member: 'member',
  viewer: 'viewer',
  manager: 'manager'
};

export const MOBILE_APP_URL = 'https://nas.io/install';
