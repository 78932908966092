import React from 'react';

const CalendarIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      fill="none"
      viewBox="0 0 21 20">
      <path
        fill="#191C1E"
        fillRule="evenodd"
        d="M2.833 6.667a1 1 0 011-1h13.333a1 1 0 110 2H3.833a1 1 0 01-1-1z"
        clipRule="evenodd"></path>
      <path
        fill="#191C1E"
        fillRule="evenodd"
        d="M2.833 4.167c0-1.013.82-1.834 1.833-1.834h11.667c1.013 0 1.833.821 1.833 1.833v11.667c0 1.013-.82 1.833-1.833 1.833H4.666a1.833 1.833 0 01-1.833-1.833V4.167zm2 .166v11.334h11.333V4.332H4.833z"
        clipRule="evenodd"></path>
      <path
        fill="#191C1E"
        fillRule="evenodd"
        d="M7.416 11.666a1 1 0 011-1h4.167a1 1 0 110 2H8.416a1 1 0 01-1-1z"
        clipRule="evenodd"></path>
      <path
        fill="#191C1E"
        fillRule="evenodd"
        d="M10.5 8.583a1 1 0 011 1v4.167a1 1 0 11-2 0V9.583a1 1 0 011-1zM13.833.667a1 1 0 011 1v1.666a1 1 0 11-2 0V1.666a1 1 0 011-1zM7.166.667a1 1 0 011 1v1.666a1 1 0 11-2 0V1.666a1 1 0 011-1z"
        clipRule="evenodd"></path>
    </svg>
  );
};

export default CalendarIcon;
