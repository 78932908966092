import React from 'react';

const TwitterGreyIcon = ({
  customClassNames,
  width,
  height,
  viewBox,
  fill = '#888888'
}) => {
  const className = 'c-TwitterGreyIcon';

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox || '0 0 16 13'}
      fill="none"
      className={`${className} ${customClassNames}`}>
      <path
        d="M0.250992 13.5218C1.83534 14.5266 3.71473 15.1081 5.72936 15.1081C11.3892 15.1081 15.9772 10.5196 15.9772 4.86028C15.9772 4.71897 15.9743 4.57872 15.9688 4.43888C16.1735 4.27778 17.4397 3.26297 17.75 2.61058C17.75 2.61058 16.7218 3.03788 15.7155 3.13854C15.7135 3.13854 15.7111 3.13901 15.7091 3.13901C15.7091 3.13901 15.7111 3.13802 15.7145 3.13556C15.8073 3.07361 17.1036 2.19117 17.2791 1.13974C17.2791 1.13974 16.5518 1.52792 15.5336 1.86751C15.365 1.92351 15.1886 1.97856 15.0066 2.0296C14.3503 1.3291 13.4173 0.891876 12.3817 0.891876C10.3953 0.891876 8.78565 2.50104 8.78565 4.48594C8.78565 4.76604 8.81788 5.03867 8.87884 5.30043C8.60125 5.28904 4.37407 5.05204 1.49831 1.5695C1.49831 1.5695 -0.220395 3.91583 2.52252 6.32856C2.52252 6.32856 1.68871 6.29533 0.969357 5.86553C0.969357 5.86553 0.705141 8.70806 3.81142 9.43483C3.81142 9.43483 3.20019 9.66635 2.22508 9.50128C2.22508 9.50128 2.77039 11.798 5.53018 12.0127C5.53018 12.0127 3.34787 13.9841 0.25 13.5215L0.250992 13.5218Z"
        fill={fill}
      />
    </svg>
  );
};

TwitterGreyIcon.defaultProps = {
  height: '13',
  width: '16',
  viewBox: '0 0 16 13'
};

export default TwitterGreyIcon;
