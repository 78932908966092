import PropTypes from 'prop-types';
import React from 'react';

const className = 'c-UnlockIcon';
const UnlockIcon = ({ width, height, fill, customClassNames }) => {
  return (
    <svg
      className={`${className} ${customClassNames}`}
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none">
      <path
        d="M12.5 13.5C11.4 13.5 10.5 14.4 10.5 15.5C10.5 16.6 11.4 17.5 12.5 17.5C13.6 17.5 14.5 16.6 14.5 15.5C14.5 14.4 13.6 13.5 12.5 13.5ZM18.5 8.5H17.5V6.5C17.5 3.74 15.26 1.5 12.5 1.5C10.22 1.5 8.23 3.04 7.66 5.25C7.52 5.79 7.84 6.33 8.38 6.47C8.91 6.61 9.46 6.29 9.6 5.75C9.94 4.43 11.13 3.5 12.5 3.5C14.15 3.5 15.5 4.85 15.5 6.5V8.5H6.5C5.4 8.5 4.5 9.4 4.5 10.5V20.5C4.5 21.6 5.4 22.5 6.5 22.5H18.5C19.6 22.5 20.5 21.6 20.5 20.5V10.5C20.5 9.4 19.6 8.5 18.5 8.5ZM18.5 19.5C18.5 20.05 18.05 20.5 17.5 20.5H7.5C6.95 20.5 6.5 20.05 6.5 19.5V11.5C6.5 10.95 6.95 10.5 7.5 10.5H17.5C18.05 10.5 18.5 10.95 18.5 11.5V19.5Z"
        fill={fill}
      />
    </svg>
  );
};

UnlockIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  customClassNames: PropTypes.string
};

UnlockIcon.defaultProps = {
  width: '25',
  height: '24',
  fill: '#191C1E',
  customClassNames: ''
};

export default UnlockIcon;
