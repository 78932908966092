import React from 'react';

const YoutubeFooterIcon = ({ customClassNames }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={`${customClassNames}`}>
      <g id="footer-icon-youtube ">
        <path
          id="Vector"
          d="M21.5879 7.06184C21.3575 6.18228 20.6819 5.48893 19.8252 5.25209C18.2602 4.8125 11.9998 4.8125 11.9998 4.8125C11.9998 4.8125 5.73963 4.8125 4.17456 5.23543C3.33435 5.47202 2.64234 6.18241 2.41187 7.06184C2 8.66834 2 12 2 12C2 12 2 15.3485 2.41187 16.9382C2.64258 17.8176 3.31787 18.5109 4.17469 18.7478C5.75611 19.1875 12 19.1875 12 19.1875C12 19.1875 18.2602 19.1875 19.8252 18.7646C20.682 18.5279 21.3575 17.8345 21.5882 16.9551C21.9999 15.3485 21.9999 12.0169 21.9999 12.0169C21.9999 12.0169 22.0164 8.66834 21.5879 7.06184V7.06184ZM10.0066 15.0779V8.9221L15.2124 12L10.0066 15.0779Z"
          fill="#FBFCFF"
        />
      </g>
    </svg>
  );
};

YoutubeFooterIcon.defaultProps = {
  customClassNames: ''
};

export default YoutubeFooterIcon;
