import React from 'react';

const LinkedinColoured = () => {
  return (
    <svg width="20" height="20" fill="none" viewBox="0 0 20 20">
      <g clipPath="url(#clip0_434_53305)">
        <path
          fill="#0A66C2"
          fillRule="evenodd"
          d="M1.478 0C.662 0 0 .642 0 1.433v17.135C0 19.358.662 20 1.478 20h17.044c.816 0 1.478-.641 1.478-1.432V1.433C20 .642 19.338 0 18.522 0H1.478zm4.585 7.712v9.03H3.042v-9.03h3.02zm.199-2.793c0 .866-.656 1.56-1.71 1.56h-.019c-1.014 0-1.67-.694-1.67-1.56 0-.886.676-1.56 1.71-1.56 1.033 0 1.669.674 1.689 1.56zm4.493 11.824h-3.02c0-.053.04-8.186 0-9.03h3.02V8.99c.402-.616 1.12-1.49 2.723-1.49 1.987 0 3.477 1.29 3.477 4.064v5.178h-3.02v-4.83c0-1.215-.438-2.043-1.53-2.043-.835 0-1.332.558-1.55 1.098-.08.192-.1.463-.1.732v5.043z"
          clipRule="evenodd"></path>
        <path
          fill="#fff"
          d="M6.063 16.743V7.712H3.042v9.03h3.02zM4.553 6.479c1.053 0 1.709-.694 1.709-1.56-.02-.886-.656-1.56-1.69-1.56-1.033 0-1.708.674-1.708 1.56 0 .866.655 1.56 1.669 1.56h.02zM7.735 16.743h3.02V11.7c0-.27.02-.54.1-.732.218-.54.715-1.098 1.55-1.098 1.092 0 1.53.828 1.53 2.042v4.83h3.02v-5.177c0-2.774-1.49-4.065-3.477-4.065-1.602 0-2.321.875-2.723 1.49V7.713h-3.02c.04.845 0 8.978 0 9.03z"></path>
      </g>
      <defs>
        <clipPath id="clip0_434_53305">
          <path fill="#fff" d="M0 0H20V20H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default LinkedinColoured;
