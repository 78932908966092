import React from 'react';

const NPLUsersPlus = ({ customClassNames }) => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      className={`c-NPLUsersPlusIcon stroke-current ${customClassNames}`}>
      <path
        d="M15.8346 16.5V11.5M13.3346 14H18.3346M10.0013 11.5H6.66797C5.11483 11.5 4.33826 11.5 3.72569 11.7537C2.90893 12.092 2.26002 12.741 1.9217 13.5577C1.66797 14.1703 1.66797 14.9469 1.66797 16.5M12.918 1.7423C14.1396 2.23679 15.0013 3.43443 15.0013 4.83333C15.0013 6.23224 14.1396 7.42988 12.918 7.92437M11.2513 4.83333C11.2513 6.67428 9.75892 8.16667 7.91797 8.16667C6.07702 8.16667 4.58464 6.67428 4.58464 4.83333C4.58464 2.99238 6.07702 1.5 7.91797 1.5C9.75892 1.5 11.2513 2.99238 11.2513 4.83333Z"
        stroke="#18774C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NPLUsersPlus;
