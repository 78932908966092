import React from 'react';

const Resource = () => {
  return (
    <svg
      className="c-Resource"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20">
      <path
        fill="#191C1E"
        d="M14.166 16.667h-10a.836.836 0 01-.833-.833v-10A.836.836 0 002.5 5a.836.836 0 00-.833.834v10.833c0 .917.75 1.667 1.666 1.667h10.834A.836.836 0 0015 17.5a.836.836 0 00-.834-.833zm2.5-15h-10C5.75 1.667 5 2.417 5 3.334v10C5 14.25 5.75 15 6.667 15h10c.916 0 1.666-.75 1.666-1.666v-10c0-.917-.75-1.667-1.666-1.667zm0 8.333l-2.083-1.25L12.5 10V3.334h4.166V10z"></path>
    </svg>
  );
};

export default Resource;
