import React from 'react';

const SentimentDissatisfied = () => {
  return (
    <svg
      className="c-SentimentDissatisfied"
      width="56"
      height="56"
      fill="none"
      viewBox="0 0 56 56">
      <path
        fill="#FD4F57"
        d="M36.167 25.667a3.5 3.5 0 100-7 3.5 3.5 0 000 7zM19.833 25.667a3.5 3.5 0 100-7 3.5 3.5 0 000 7z"></path>
      <path
        fill="#FD4F57"
        d="M27.977 4.667C15.097 4.667 4.667 15.12 4.667 28c0 12.88 10.43 23.334 23.31 23.334C40.88 51.334 51.333 40.88 51.333 28c0-12.88-10.453-23.333-23.356-23.333zm.023 42C17.687 46.667 9.333 38.314 9.333 28 9.333 17.687 17.687 9.334 28 9.334c10.313 0 18.667 8.353 18.667 18.666 0 10.314-8.354 18.667-18.667 18.667zm0-14c-4.433 0-8.47 2.263-10.85 6.02a1.744 1.744 0 00.56 2.403c.817.514 1.89.257 2.403-.56 1.727-2.753 4.667-4.386 7.887-4.386 3.22 0 6.16 1.633 7.887 4.386.326.537.91.817 1.493.817.327 0 .63-.093.933-.257a1.744 1.744 0 00.56-2.403c-2.403-3.78-6.44-6.02-10.873-6.02z"></path>
    </svg>
  );
};

export default SentimentDissatisfied;
