import PropTypes from 'prop-types';
import React from 'react';

const className = 'c-CopyContentIcon';

const CopyContentIcon = ({
  width,
  height,
  customClassNames,
  fill,
  ...rest
}) => {
  return (
    <svg
      className={`${className} ${customClassNames}`}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}>
      <g id="content_copy">
        <path
          id="content_copy_2"
          d="M5 22C4.45 22 3.979 21.8043 3.587 21.413C3.19567 21.021 3 20.55 3 20V6H5V20H16V22H5ZM9 18C8.45 18 7.97933 17.8043 7.588 17.413C7.196 17.021 7 16.55 7 16V4C7 3.45 7.196 2.979 7.588 2.587C7.97933 2.19567 8.45 2 9 2H18C18.55 2 19.021 2.19567 19.413 2.587C19.8043 2.979 20 3.45 20 4V16C20 16.55 19.8043 17.021 19.413 17.413C19.021 17.8043 18.55 18 18 18H9ZM9 16H18V4H9V16Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};

CopyContentIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  customClassNames: PropTypes.string
};
CopyContentIcon.defaultProps = {
  width: '24',
  height: '24',
  fill: '#D9D9D9'
};

export default CopyContentIcon;
