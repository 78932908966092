const SENTRY_DSN =
  process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

const NEXT_PUBLIC_ENV = process.env.NEXT_PUBLIC_ENV || 'development';

import { isLocalHost } from './src/utility/serverHelpers';

const sentryBasedConfigs = {
  enabled: !isLocalHost(), //only enable Sentry on live websites
  dsn: SENTRY_DSN,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  environment: NEXT_PUBLIC_ENV,
  //ignore errors to reduce alert noises
  ignoreErrors: [
    /Non-Error promise rejection captured with/i,
    /AbortError/i,
    /AbortError: The play\(\)/i,
    /Invariant: attempted to hard navigate to the same URL/i,
    /Failed to load static props/i,
    /Unexpected token '<'/i,
    /No space between hexadecimal literal and identifier/i
  ],
  ignoreUrls: [
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i
  ]
};

export default sentryBasedConfigs;
