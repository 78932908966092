import React from 'react';

const className = 'c-File04';

function File04({ width, height, fill, customClassNames }) {
  return (
    <svg
      className={`${className} ${customClassNames}`}
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 24 24">
      <path
        fill={fill}
        fillRule="evenodd"
        d="M13 3.02c-.186-.017-.46-.02-.988-.02H8.8c-.857 0-1.439 0-1.889.038-.438.035-.663.1-.819.18a2 2 0 00-.874.874c-.08.156-.145.38-.18.819C5 5.361 5 5.943 5 6.8v10.4c0 .857 0 1.439.038 1.889.035.438.1.663.18.819a2 2 0 00.874.874c.156.08.38.145.819.18C7.361 21 7.943 21 8.8 21h6.4c.857 0 1.439 0 1.889-.038.438-.035.663-.1.819-.18a2 2 0 00.874-.874c.08-.156.145-.38.18-.819.037-.45.038-1.032.038-1.889V9.988c0-.527-.003-.802-.02-.988h-3.412c-.252 0-.498 0-.706-.017a2.022 2.022 0 01-.77-.201 2 2 0 01-.874-.874 2.022 2.022 0 01-.201-.77C13 6.93 13 6.684 13 6.432V3.02zm1.39-1.672a4 4 0 00-.699-.237C13.228.999 12.75 1 12.114 1H8.759c-.805 0-1.47 0-2.01.044-.563.046-1.08.145-1.565.392a4 4 0 00-1.748 1.748c-.247.485-.346 1.002-.392 1.564C3 5.29 3 5.954 3 6.758v10.483c0 .805 0 1.47.044 2.01.046.563.145 1.08.392 1.565a4 4 0 001.748 1.748c.485.247 1.002.346 1.564.392C7.29 23 7.954 23 8.758 23h6.483c.805 0 1.47 0 2.01-.044.563-.046 1.08-.145 1.565-.392a4 4 0 001.748-1.748c.247-.485.346-1.002.392-1.564.044-.541.044-1.206.044-2.01V9.987v-.102c0-.635 0-1.114-.11-1.577a3.999 3.999 0 00-.48-1.156c-.248-.406-.587-.745-1.037-1.194l-.072-.072-3.188-3.188-.072-.072c-.449-.45-.788-.789-1.194-1.038a4.015 4.015 0 00-.457-.24zM15 4.414V6.4c0 .297 0 .459.01.575l.001.014h.014c.116.01.278.011.575.011h1.986L15 4.414z"
        clipRule="evenodd"></path>
    </svg>
  );
}

File04.defaultProps = {
  width: '24',
  height: '24',
  fill: '#FCCB1D'
};

export default File04;
