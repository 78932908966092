import { STATIC_ASSET_BASE_URL } from '@/utility/constants';

export const STORYTELLING_LIVE_COURSE_CODE = 'NS';
export const STORYTELLING_COURSE_CODE = 'NSPR';
export const STORYTELLING_ONDEMAND_COURSE_CODE = 'NSPR';
export const BETTER_WRITER_COURSE_CODE = 'NUSEIR_BETTER_WRITER';
export const CREATOR_MASTERCOURSE_CODE = 'NUSEIR_CREATOR_MASTER_COURSE';
export const MOBILE_CREATOR_MASTERCOURSE_CODE =
  'MOBILE_CREATOR_MASTERCOURSE';
export const PHOTOGRAPHY_COURSE_CODE = 'NP'; // Legacy
export const FACEBOOK_ONDEMAND_COURSE_CODE = 'NLPR';
export const CREATOR_MASTER_COURSE_CODE = 'NG';
export const DEAR_ALYNE_COURSE_CODE = 'DAPR';
export const DEAR_ALYNE_LIVE_COURSE_CODE = 'DA';
export const DEAR_ALYNE_ONDEMAND_COURSE_CODE = 'DAPR';
export const THE_UNSTOPPABLE_CONFIDENCE_COURSE_CODE = 'TUCPR';
export const THE_UNSTOPPABLE_CONFIDENCE_LIVE_COURSE_CODE = 'TUC';
export const THE_UNSTOPPABLE_CONFIDENCE_ONDEMAND_COURSE_CODE = 'TUCPR';
export const TABOO_TOPICS_COURSE_CODE = 'DAT';
export const TABOO_TOPICS_LIVE_COURSE_CODE = 'DAT';
export const UNLOCKING_TIKTOK_WITH_MING_COURSE_CODE = 'MTIKPR';
export const UNLOCKING_TIKTOK_WITH_MING_LIVE_COURSE_CODE = 'MTIK';
export const UNLOCKING_TIKTOK_WITH_MING_ONDEMAND_COURSE_CODE = 'MTIKPR';
export const COMEDY_SKITS_WITH_MARKIAN_COURSE_CODE = 'MCOM';
export const ULTIMATE_VIRAL_VIDEO_COURSE_CODE = 'MCOM';
export const ULTIMATE_VIRAL_VIDEO_COURSE_CODE_SCRIPTING = 'MCOMS';
export const ULTIMATE_VIRAL_VIDEO_COURSE_CODE_ACTING = 'MCOMA';
export const ULTIMATE_VIRAL_VIDEO_COURSE_CODE_EDITING = 'MCOME';
export const DEFAULT_TEMPLATE_ID = 1; // Obsolete
export const COMEDY_SKITS_LOW_COST_COURSE_CODE = 'MCOM2'; // Legacy
export const VFX_COURSE_CODE = 'VFX';
export const VFX_LIVE_COURSE_CODE = 'VFX';
export const COURSE_LIVE_TYPE = 'live';
export const COURSE_PRERECORDED_TYPE = 'preRecorded';
export const CRYPTO_CREATOR_MASTER_COURSE = 'CCMC'; // TODO temp
export const CRYPTO_CREATOR_MASTER_COURSE_ONDEMAND_COURSE_CODE = 'MKPR';
export const FUTURE_OF_CRYPTO_ONDEMAND_COURSE_CODE = 'MKPR';
export const DA_MONEY_COURSE_CODE = 'DAM';
export const DA_MONEY_LIVE_COURSE_CODE = 'DAM';
export const HOW_TO_DRAW_COURSE_CODE = 'DFYIPR';
export const HOW_TO_DRAW_COURSE_CODE_COHORT_ONDEMAND =
  'ANTHONY_FRANCISCO_DRAW';
export const WISE_WITH_MONEY_COURSE_CODE = 'WWMPR';
export const WHANG_OD_COURSE_CODE = 'CDKAPR';
export const HOW_TO_PRESENT_YOURSELF_COURSE_CODE = 'HPYPR';
export const HOW_TO_BE_A_QUEEN_COHORT_COURSE_CODE =
  'CATRIONA_HOW_TO_BE_A_QUEEN';
export const DAN_MACE_FILMMAKER_COHORT_COURSE_CODE =
  'DAN_MACE_BECOME_FILMMAKER';
export const MAV_BASKETBALL_COURSE_CODE = 'PBAPR';
export const CREATOR_BUSINESS_COURSE_CODE = 'BCBPR';
export const VISUAL_STORYTELLER_COURSE_CODE = 'TVSPR';
export const RITESH_BILLION_DOLLAR_BUSINESS_COURSE_CODE = 'IBDBPR';
export const RITESH_BILLION_DOLLAR_BUSINESS_COHORT_COURSE_CODE =
  'RITESH_BILLION_DOLLAR';
export const LEARN_FROM_JAMES_DEAKIN_COURSE_CODE = 'LJDPR';
export const LEARN_FROM_MICHAEL_CINCO_COURSE_CODE = 'LMCPR';
export const LEARN_FROM_JESSICA_SOHO_COURSE_CODE = 'LJSPR';
export const LEARN_FROM_ERWAN_HEUSSAFF_COURSE_CODE = 'LEHPR';
export const LEARN_FROM_MOIRA_COURSE_CODE = 'LMDTPR';
export const DAN_MACE_FINDING_YOUR_VOICE = 'DAN_MACE_FINDING_YOUR_VOICE';
export const SUSTENTOFILA_GOING_GREEN = 'SUSTENTOFILA_GOING_GREEN';
export const SUSTENTOFILA_GOING_GREEN_SPANISH =
  'SUSTENTOFILA_GOING_GREEN_SPANISH';
export const BELIK_ZOOM_HERO_COURSE_CODE = 'BELIK_ZOOM_HERO';
export const UPTIN_COMMUNICATE_YOUR_BRAND = 'UPTIN_COMMUNICATE_YOUR_BRAND';
export const EDITING_FINAL_CUT_PRO_COURSE_CODE =
  'NAS_DAILY_FINAL_CUT_BEGINNER';
export const UPTIN_COMMUNICATE_YOUR_BRAND_COURSE_CODE =
  'UPTIN_COMMUNICATE_YOUR_BRAND';
export const ROSS_MEMORY_PRODUCTIVITY_COURSE_CODE =
  'ROSS_MEMORY_PRODUCTIVITY';
export const KAI_SONG_FREELANCER = 'KAI_SONG_FREELANCER';
export const NICOLE_BANISTER_ADOPT_CULTURES =
  'NICOLE_BANISTER_ADOPT_CULTURES';
export const THIS_GUY_EDITS_FILMMAKING = 'THIS_GUY_EDITS_FILMMAKING';
export const JORDAN_MATTER_PHOTOGRAPHY = 'JORDAN_MATTER_PHOTOGRAPHY';
export const XAVIER_MORTIMER_MAGIC = 'XAVIER_MORTIMER_MAGIC';
export const REAL_TALK_DARBS_PODCAST = 'REAL_TALK_DARBS_PODCAST';
export const SMILE_SQUAD_VIRAL_VIDEO = 'SMILE_SQUAD_VIRAL_VIDEO';
export const MENTOUR_PILOT_LEADERSHIP = 'MENTOUR_PILOT_LEADERSHIP';
export const SMILE_SQUAD_IMPLEMENTATION_LAB =
  'SMILE_SQUAD_IMPLEMENTATION_LAB';
export const NIKKI_HENDERSON_PROJECT_MANAGEMENT =
  'NIKKI_HENDERSON_PROJECT_MANAGEMENT';
export const PATRICK_DANG_SALES = 'PATRICK_DANG_SALES';
export const JAYNE_NORRIE_CONFIDENT_SINGING =
  'JAYNE_NORRIE_CONFIDENT_SINGING';
export const LO_HARRIS_SELF_DISCOVERY = 'LO_HARRIS_SELF_DISCOVERY';
export const NIKI_PROSHIN_CREATOR = 'NIKI_PROSHIN_CREATOR_WORKSHOP';
export const MARIA_MALANDRINO_FAIRYTALE = 'MARIA_MALANDRINO_FAIRYTALE';
export const ROSS_FLIPPIN_WORKSHOP = 'ROSS_FLIPPIN_WORKSHOP';
export const UPTIN_SAIIDI_COMMUNICATE_BRAND_WORKSHOP =
  'UPTIN_SAIIDI_COMMUNICATE_BRAND_WORKSHOP';
export const MARIA_MALANDRINO_FAIRYTALE_WORKSHOP =
  'MARIA_MALANDRINO_FAIRYTALE_WORKSHOP';
export const BELIK_ZOOM_WORKSHOP = 'BELIK_ZOOM_WORKSHOP';
export const KAI_FREEDOM_WORKSHOP = 'KAI_FREEDOM_WORKSHOP';
export const NICOLE_CULTURE_WORKSHOP = 'NICOLE_CULTURE_WORKSHOP';
export const COCO_CORDERO_VOICE_WORKSHOP = 'COCO_CORDERO_VOICE_WORKSHOP';
export const NIKO_BOLANTE_PHOTOS_WORKSHOP = 'NIKO_BOLANTE_PHOTOS_WORKSHOP';
export const BEA_CHU_AUDIO_TIPS_WORKSHOP = 'BEA_CHU_AUDIO_TIPS_WORKSHOP';
export const ISAIAH_YOUTUBE_SHORTS_101 = 'ISAIAH_YOUTUBE_SHORTS_101';
export const TRISTAN_HOW_TO_10X_YOUR_FOLLOWERS_WORKSHOP =
  'TRISTAN_HOW_TO_10X_YOUR_FOLLOWERS';
export const NORMANDO_HOW_TO_MAKE_YOUR_DREAMS_COME_TRUE_WORKSHOP =
  'NORMANDO_HOW_TO_MAKE_YOUR_DREAMS_COME_TRUE';
export const TEJAS_CREATOR_LESSONS_WORKSHOP =
  'TEJAS_CREATOR_LESSONS_WORKSHOP';
export const KRISTIN_TRAVEL_WORKSHOP = 'KRISTIN_TRAVEL_WORKSHOP';
export const WILL_CARMACK_AFTEREFFECTS_WORKSHOP =
  'WILL_CARMACK_AFTEREFFECTS_WORKSHOP';
export const CARLOOPLE_CREATOR_BUSINESS_SCHOOL =
  'CARLOOPLE_CREATOR_BUSINESS_SCHOOL';
export const GRAB_FOR_GOOD_PHASE_2_FORCE = 'GRAB_FOR_GOOD_PHASE_2_FORCE';
export const BUILD_A_BUSINESS = 'BUILD_A_BUSINESS';
export const VINFAST_GO_BOUNDLESS = 'VINFAST_GO_BOUNDLESS';
export const MXTAKATAK_PHASE2 = 'MXTAKATAK_PHASE2';
export const MINGWEI_CRUSH_ON_TIKTOK_COHORT_COURSE_CODE =
  'MINGWEI_CRUSH_ON_TIKTOK';
export const NUSEIR_CRUSH_ON_FACEBOOK_COHORT_COURSE_CODE =
  'NUSEIR_CRUSH_ON_FACEBOOK';
export const PROJECT_NIGHTFALL_CONFIDENCE_COHORT_COURSE_CODE =
  'PROJECT_NIGHTFALL_CONFIDENCE';
export const DEAR_ALYNE_CONTENT_CREATION_COHORT_COURSE_CODE =
  'DEAR_ALYNE_CONTENT_CREATION';
export const ONE_CHAMPIONSHIP_CREATOR = 'ONE_CHAMPIONSHIP_CREATOR';
export const AXIE_FELLOWSHIP_PROGRAM = 'AXIE_INFINITY_CREATOR_FELLOWSHIP';
export const MANILA_LUZON_SCHOOL_OF_DRAG = 'MANILA_LUZON_SCHOOL_OF_DRAG';
export const DAN_MACE_BECOME_FILMMAKER = 'DAN_MACE_BECOME_FILMMAKER';
// TODO add other necessary data for CCMC

export const COURSE_TYPE_LABEL = {
  [COURSE_LIVE_TYPE]: 'live',
  [COURSE_PRERECORDED_TYPE]: 'On-demand'
};

export const RECOMMEND_ENGINE_PAGE_TITLE = "Know what's best for you";

//Course Links
export const courseLinks = {
  [BUILD_A_BUSINESS]: '/build-a-business',
  [HOW_TO_DRAW_COURSE_CODE_COHORT_ONDEMAND]: '/anthony',
  [MANILA_LUZON_SCHOOL_OF_DRAG]: '/manilaluzon',
  [ISAIAH_YOUTUBE_SHORTS_101]: '/isaiahphoto',
  [HOW_TO_BE_A_QUEEN_COHORT_COURSE_CODE]: '/catriona',
  [STORYTELLING_COURSE_CODE]: '/nuseir',
  [STORYTELLING_ONDEMAND_COURSE_CODE]: '/nuseir',
  [BETTER_WRITER_COURSE_CODE]: '/nuseir',
  [MOBILE_CREATOR_MASTERCOURSE_CODE]: '/mobile-content-creator',
  [FACEBOOK_ONDEMAND_COURSE_CODE]: '/nuseir/facebook',
  [PHOTOGRAPHY_COURSE_CODE]: '/course/photography',
  [CREATOR_MASTER_COURSE_CODE]: '/nuseir/creator-mastercourse',
  [CRYPTO_CREATOR_MASTER_COURSE]: '/metapurse/cryptocreator',
  [CRYPTO_CREATOR_MASTER_COURSE_ONDEMAND_COURSE_CODE]:
    '/metapurse/future-of-crypto',
  [DA_MONEY_COURSE_CODE]: '/dearalyne/money',
  [DEAR_ALYNE_CONTENT_CREATION_COHORT_COURSE_CODE]: '/dearalyne',
  [THE_UNSTOPPABLE_CONFIDENCE_COURSE_CODE]: '/projectnightfall',
  [THE_UNSTOPPABLE_CONFIDENCE_ONDEMAND_COURSE_CODE]: '/projectnightfall',
  [TABOO_TOPICS_COURSE_CODE]: '/dearalyne/sensitive-topics',
  [UNLOCKING_TIKTOK_WITH_MING_COURSE_CODE]:
    '/ming/how-to-crush-it-on-tiktok',
  [UNLOCKING_TIKTOK_WITH_MING_ONDEMAND_COURSE_CODE]:
    '/ming/how-to-crush-it-on-tiktok',
  [MINGWEI_CRUSH_ON_TIKTOK_COHORT_COURSE_CODE]:
    '/ming/how-to-crush-it-on-tiktok',
  [PROJECT_NIGHTFALL_CONFIDENCE_COHORT_COURSE_CODE]: '/projectnightfall',
  [NUSEIR_CRUSH_ON_FACEBOOK_COHORT_COURSE_CODE]: '/nuseir/facebook',
  [ULTIMATE_VIRAL_VIDEO_COURSE_CODE]: '/smilesquad/viral-video-formula',
  [ULTIMATE_VIRAL_VIDEO_COURSE_CODE_ACTING]: '/smilesquad/advanced',
  [ULTIMATE_VIRAL_VIDEO_COURSE_CODE_SCRIPTING]: '/smilesquad/advanced',
  [ULTIMATE_VIRAL_VIDEO_COURSE_CODE_EDITING]: '/smilesquad/advanced',
  [COMEDY_SKITS_LOW_COST_COURSE_CODE]: '/smilesquad/comedy',
  [SMILE_SQUAD_VIRAL_VIDEO]: '/smilesquad',
  [VFX_COURSE_CODE]: '/nasdaily/advanced-editing-effects',
  [HOW_TO_DRAW_COURSE_CODE]: '/_ph/anthony-francisco/how-to-draw',
  [WISE_WITH_MONEY_COURSE_CODE]: '/_ph/chinkee-tan/financial-freedom',
  [WHANG_OD_COURSE_CODE]: '/_ph/whang-od/ancient-art-of-tattooing',
  [MAV_BASKETBALL_COURSE_CODE]: '/_ph/mav',
  [CREATOR_BUSINESS_COURSE_CODE]: '/_ph/carlo-ople',
  [VISUAL_STORYTELLER_COURSE_CODE]: '/_ph/jason-magbanua',
  [RITESH_BILLION_DOLLAR_BUSINESS_COURSE_CODE]:
    '/ritesh-agarwal/turn-an-idea-into-billion-dollar-business',
  [RITESH_BILLION_DOLLAR_BUSINESS_COHORT_COURSE_CODE]: '/ritesh',
  [LEARN_FROM_JAMES_DEAKIN_COURSE_CODE]: '/_ph/james-deakin',
  [HOW_TO_PRESENT_YOURSELF_COURSE_CODE]: '/catriona',
  [LEARN_FROM_JESSICA_SOHO_COURSE_CODE]: '/_ph/jessica-soho',
  [LEARN_FROM_MICHAEL_CINCO_COURSE_CODE]: '/_ph/michael-cinco',
  [LEARN_FROM_ERWAN_HEUSSAFF_COURSE_CODE]: '/_ph/erwan-heussaff-featr',
  [LEARN_FROM_MOIRA_COURSE_CODE]: '/_ph/moira-dela-torre',
  [DAN_MACE_FINDING_YOUR_VOICE]: '/danmace',
  [SUSTENTOFILA_GOING_GREEN]: '/sustentofila',
  [DAN_MACE_BECOME_FILMMAKER]: '/danmace',
  [EDITING_FINAL_CUT_PRO_COURSE_CODE]: '/finalcutpro',
  // [UPTIN_COMMUNICATE_YOUR_BRAND_COURSE_CODE]: '/uptin',
  [SUSTENTOFILA_GOING_GREEN_SPANISH]: '/sustentofila-es',
  // [BELIK_ZOOM_HERO_COURSE_CODE]: '/belik',
  [THIS_GUY_EDITS_FILMMAKING]: '/thisguyedits',
  [JORDAN_MATTER_PHOTOGRAPHY]: '/jordanmatter',
  [GRAB_FOR_GOOD_PHASE_2_FORCE]: '/grabforgood',
  [XAVIER_MORTIMER_MAGIC]: '/xaviermortimer',
  [REAL_TALK_DARBS_PODCAST]: '/realtalkdarbs',
  [MENTOUR_PILOT_LEADERSHIP]: '/mentourpilot',
  [SMILE_SQUAD_IMPLEMENTATION_LAB]: '/smilesquad/advanced',
  [NIKKI_HENDERSON_PROJECT_MANAGEMENT]: '/nikkihenderson',
  [PATRICK_DANG_SALES]: '/patrickdang',
  [JAYNE_NORRIE_CONFIDENT_SINGING]: '/jaynenorrie',
  [NIKI_PROSHIN_CREATOR]: '/nikiproshin',
  [LO_HARRIS_SELF_DISCOVERY]: '/loharris',
  // [MARIA_MALANDRINO_FAIRYTALE]: '/mariamalandrino',
  // [ROSS_MEMORY_PRODUCTIVITY_COURSE_CODE]: '/ross',
  // [KAI_SONG_FREELANCER]: '/kiasong',
  // [NICOLE_BANISTER_ADOPT_CULTURES]: '/nicole',
  [ROSS_FLIPPIN_WORKSHOP]: '/ross',
  [MARIA_MALANDRINO_FAIRYTALE_WORKSHOP]: '/mariamalandrino',
  [UPTIN_SAIIDI_COMMUNICATE_BRAND_WORKSHOP]: '/uptin',
  [BELIK_ZOOM_WORKSHOP]: '/belik',
  [KAI_FREEDOM_WORKSHOP]: '/kaisong',
  [NICOLE_CULTURE_WORKSHOP]: '/nicole',
  [COCO_CORDERO_VOICE_WORKSHOP]: '/coco',
  [NIKO_BOLANTE_PHOTOS_WORKSHOP]: '/niko',
  [BEA_CHU_AUDIO_TIPS_WORKSHOP]: '/bea',
  [CARLOOPLE_CREATOR_BUSINESS_SCHOOL]: '/carloople',
  [VINFAST_GO_BOUNDLESS]: '/vinfast',
  [MXTAKATAK_PHASE2]: '/mxtakatak',
  [WILL_CARMACK_AFTEREFFECTS_WORKSHOP]: '/willcarmack',
  [KRISTIN_TRAVEL_WORKSHOP]: '/kristinaddis',
  [TEJAS_CREATOR_LESSONS_WORKSHOP]: '/tejashullur',
  [NORMANDO_HOW_TO_MAKE_YOUR_DREAMS_COME_TRUE_WORKSHOP]:
    '/normandothemagician',
  [TRISTAN_HOW_TO_10X_YOUR_FOLLOWERS_WORKSHOP]: '/tristan'
};

const markianComedyCourseData = {
  isWaitlist: false,
  title: 'The Viral Video Formula',
  duration: '4',
  recommendedtext: '',
  sessions: ['Scripting', 'Editing', 'Shooting'],
  proSessions: [],
  classTime: '4 hours',
  videoTime: 'Make 1 video',
  instructorCount: 2,
  studentCount: 100,
  price: '99',
  priceSubtext: 'for the whole course.',
  discountText: [
    { text: 'Get up to 20% OFF', isHighlighted: true },
    { text: '  when enrolling with 2 or more friends.' }
  ],
  currency: 'USD',
  benefits: [
    '4 hour LIVE class with Markian & Friends.',
    'Learn how to Start, Earn & Grow on Youtube.',
    'Hands on Guidance.',
    'Get your Certificate of Accomplishment.'
  ],
  courseCode: ULTIMATE_VIRAL_VIDEO_COURSE_CODE,
  courseImgData: {
    alt: 'The Viral Video Formula',
    mobileImgData: {
      src: `${STATIC_ASSET_BASE_URL}/na-website/CourseImages/jpg/markianCourseIconV2-225x240.jpg`,
      webpSrc: `${STATIC_ASSET_BASE_URL}/na-website/CourseImages/webp/markianCourseIconV2-225x240.webp`
    }
  },
  courseImg: `${STATIC_ASSET_BASE_URL}/na-website/png/trial-benefit-1.png`,
  sectionTitle: 'Join my class now!',
  checkmarkIconSrc: `${STATIC_ASSET_BASE_URL}/na-website/MarkianComedyCourse/png/checklist.png`
};

const markianComedyLowCostCourseData = {
  ...markianComedyCourseData,
  price: '49',
  discountPrice: '49',
  courseCode: COMEDY_SKITS_LOW_COST_COURSE_CODE
};

const sensitiveTopicsCourseData = {
  price: '99',
  priceSubtext: 'for the whole course.',
  discountPrice: null,
  beforeDiscountPrice: null,
  discountText: 'Get up to 20% OFF when enrolling with 2 or more friends.',
  currency: 'USD',
  benefits: [
    '4 hours of live learning with Alyne.',
    'Understand the Content Creation Process.',
    'Learn the anatomy of a successful video.',
    'Access to Alumni Community.',
    'Certificate of Completion.'
  ],
  title: 'Sensitive Topics with Dear Alyne',
  courseCode: TABOO_TOPICS_COURSE_CODE,
  courseImgData: {
    alt: 'Dear Alyne Image',
    mobileImgData: {
      src: STATIC_ASSET_BASE_URL + '/na-website/NewHome/png/homeDA.png'
    }
  },
  courseImg: STATIC_ASSET_BASE_URL + '/na-website/png/trial-benefit-1.png',
  checkmarkIconSrc: `${STATIC_ASSET_BASE_URL}/na-website/svg/pinktick.svg`,
  sectionTitle: 'Book your seat now!'
};

export const coursesData = {
  [STORYTELLING_COURSE_CODE]: {
    title: 'Art of Storytelling',
    duration: '1',
    recommendedtext: '',
    sessions: ['Scripting', 'Editing'],
    proSessions: [],
    classTime: '4 hours',
    videoTime: 'Observe Creators',
    instructorCount: 5,
    studentCount: 200,
    price: '49',
    discountPrice: '79',
    beforeDiscountPrice: '79',
    priceSubtext: 'for the whole course.',
    currency: 'USD',
    benefits: [
      'Live scripting exercises with Nas Daily team.',
      'A complete understanding of how to tell stories for the digital world.',
      'Tons of offline resources to help you grow.',
      'Online Certificate of Participation.'
    ],
    courseCode: 'NS',
    courseImgData: {
      alt: 'Art of Storytelling',
      mobileImgData: {
        src:
          STATIC_ASSET_BASE_URL +
          '/na-website/Payment/png/AoSCourseImg.png',
        webpSrc:
          STATIC_ASSET_BASE_URL +
          '/na-website/Payment/webp/AoSCourseImg.webp'
      }
    },
    courseImg: STATIC_ASSET_BASE_URL + '/na-website/png/thumbAoS.png',
    pdfLink:
      STATIC_ASSET_BASE_URL +
      '/curriculum/Art_of_Storytelling_Curriculum.pdf'
  },
  [BETTER_WRITER_COURSE_CODE]: {
    title: 'How To Be A Better Writer'
  },
  [MOBILE_CREATOR_MASTERCOURSE_CODE]: {
    title: 'Mobile Creator Mastercourse'
  },
  [PHOTOGRAPHY_COURSE_CODE]: {
    title: 'Photography Mastercourse',
    duration: '8',
    classTime: '20 hours',
    videoTime: 'Make 1 video',
    instructorCount: 2,
    studentCount: 30,
    price: '399',
    priceSubtext: 'for the whole course.',
    discountPrice: null,
    beforeDiscountPrice: null,
    discountText: [
      { text: 'Get up to 20% OFF', isHighlighted: true },
      { text: '  when enrolling with 2 or more friends.' }
    ],
    currency: 'USD',
    benefits: [
      '8 fun sessions in 4 weeks.',
      '20 Hours of Teaching.',
      'How to Master Photography.',
      'How to take the perfect product picture.',
      'How to monetize your photography skills.'
    ],
    courseCode: 'NL',
    courseImgData: {
      alt: 'Photography Course',
      mobileImgData: {
        src:
          STATIC_ASSET_BASE_URL +
          '/na-website/Photography/jpg/courseImage.jpg'
      }
    },
    courseImgDataWithoutText: {
      alt: 'Photography Course',
      mobileImgData: {
        webpSrc:
          STATIC_ASSET_BASE_URL +
          '/na-website/CourseImages/photographyCourseImageWithoutText.webp',
        src:
          STATIC_ASSET_BASE_URL +
          '/na-website/CourseImages/photographyCourseImageWithoutText.jpg',
        meta: {
          widht: 393,
          height: 419
        }
      }
    },
    courseImg:
      STATIC_ASSET_BASE_URL + '/na-website/png/trial-benefit-1.png'
  },
  [CREATOR_MASTER_COURSE_CODE]: {
    title: 'Creator Mastercourse',
    duration: '8',
    recommendedtext: '',
    sessions: ['Scripting', 'Shooting', 'Editing', 'Publishing'],
    proSessions: ['Pro Scripting', 'Pro Shooting', 'Pro Editing'],
    classTime: '20 hours',
    videoTime: 'Make 3 videos',
    instructorCount: 2,
    studentCount: 20,
    price: '499',
    priceSubtext: 'for the whole course.',
    discountPrice: null,
    beforeDiscountPrice: null,
    discountText: [
      { text: 'Get up to 20% OFF', isHighlighted: true },
      { text: '  when enrolling with 2 or more friends.' }
    ],
    currency: 'USD',
    benefits: [
      '8 live sessions with 20 hrs class time',
      'Create 3 videos with your own story.',
      'Library of copyright free songs & sound effects.',
      'Certificate of Accomplishment from Nas Academy'
    ],
    courseCode: 'NG',
    courseImgData: {
      alt: 'Go All In',
      mobileImgData: {
        src:
          STATIC_ASSET_BASE_URL +
          '/na-website/Payment/png/CMCCourseImg.png',
        webpSrc:
          STATIC_ASSET_BASE_URL +
          '/na-website/Payment/webp/CMCCourseImg.webp'
      }
    },
    courseImg:
      STATIC_ASSET_BASE_URL + '/na-website/png/thumbMasterCourse.png',
    pdfLink:
      STATIC_ASSET_BASE_URL +
      '/curriculum/Creator_Mastercourse_Curriculum.pdf'
  },
  [DEAR_ALYNE_COURSE_CODE]: {
    price: '99',
    priceSubtext: 'for the whole course.',
    discountPrice: null,
    beforeDiscountPrice: null,
    discountText:
      'Get up to 20% OFF when enrolling with 2 or more friends.',
    currency: 'USD',
    benefits: [
      '4 hours of live learning with Alyne.',
      'Understand the Content Creation Process.',
      'Learn the anatomy of a successful video.',
      'Access to Alumni Community.'
    ],
    title: 'Content Creation Crash Course',
    courseCode: DEAR_ALYNE_COURSE_CODE,
    courseImgData: {
      alt: 'Dear Alyne Image',
      mobileImgData: {
        src: STATIC_ASSET_BASE_URL + '/na-website/NewHome/png/homeDA.png'
      }
    },
    courseImg:
      STATIC_ASSET_BASE_URL + '/na-website/png/trial-benefit-1.png'
  },
  [DA_MONEY_LIVE_COURSE_CODE]: {
    title: "Let's talk money"
  },
  [PROJECT_NIGHTFALL_CONFIDENCE_COHORT_COURSE_CODE]: {
    title: 'Unstoppable Confidence',
    courseCode: PROJECT_NIGHTFALL_CONFIDENCE_COHORT_COURSE_CODE,
    courseImgData: {
      alt: 'The Unstoppable Confidence',
      mobileImgData: {
        webpSrc:
          STATIC_ASSET_BASE_URL +
          '/na-website/CourseImages/confidenceCardImg.webp',
        src:
          STATIC_ASSET_BASE_URL +
          '/na-website/CourseImages/confidenceCardImg.png',
        meta: {
          width: 656,
          height: 640
        }
      }
    }
  },
  [THE_UNSTOPPABLE_CONFIDENCE_COURSE_CODE]: {
    title: 'Unstoppable Confidence',
    courseCode: THE_UNSTOPPABLE_CONFIDENCE_COURSE_CODE,
    courseImgData: {
      alt: 'The Unstoppable Confidence',
      mobileImgData: {
        webpSrc:
          STATIC_ASSET_BASE_URL +
          '/na-website/CourseImages/confidenceCardImg.webp',
        src:
          STATIC_ASSET_BASE_URL +
          '/na-website/CourseImages/confidenceCardImg.png',
        meta: {
          width: 656,
          height: 640
        }
      }
    }
  },
  [THE_UNSTOPPABLE_CONFIDENCE_ONDEMAND_COURSE_CODE]: {
    title: 'Unstoppable Confidence (On-Demand)'
  },
  [NUSEIR_CRUSH_ON_FACEBOOK_COHORT_COURSE_CODE]: {
    title: 'How To Crush It On Facebook'
  },
  [FACEBOOK_ONDEMAND_COURSE_CODE]: {
    title: 'How To Crush It On Facebook (On-Demand)'
  },
  [MINGWEI_CRUSH_ON_TIKTOK_COHORT_COURSE_CODE]: {
    title: 'How To Crush It On Tiktok with Ming',
    duration: '4',
    recommendedtext: '',
    sessions: ['Scripting', 'Editing', 'Shooting'],
    proSessions: [],
    classTime: '10 hours',
    videoTime: 'Make 1 video',
    instructorCount: 2,
    studentCount: 30,
    price: '99',
    priceSubtext: 'for the whole course.',
    discountPrice: null,
    beforeDiscountPrice: null,
    discountText: [
      { text: 'Get up to 20% OFF', isHighlighted: true },
      { text: '  when enrolling with 2 or more friends.' }
    ],
    currency: 'USD',
    benefits: [
      '4 hour LIVE class with MING & Friends.',
      'Learn how to Start, Earn & Grow on TikTok.',
      'Hands on Guidance.',
      'Get your Certificate of Accomplishment.'
    ],
    courseCode: MINGWEI_CRUSH_ON_TIKTOK_COHORT_COURSE_CODE,
    courseImgData: {
      alt: 'Tiktok Course',
      mobileImgData: {
        src: `${STATIC_ASSET_BASE_URL}/na-website/MingTikTok/png/mtikCourseImg.png`,
        webpSrc: `${STATIC_ASSET_BASE_URL}/na-website/MingTikTok/webp/mtikCourseImg.webp`
      }
    },
    courseImg:
      STATIC_ASSET_BASE_URL + '/na-website/png/trial-benefit-1.png',
    sectionTitle: 'Join my class now!',
    checkmarkIconSrc: `${STATIC_ASSET_BASE_URL}/na-website/MingTikTok/png/checkList.png`
  },
  [UNLOCKING_TIKTOK_WITH_MING_COURSE_CODE]: {
    title: 'How To Crush It On Tiktok with Ming',
    duration: '4',
    recommendedtext: '',
    sessions: ['Scripting', 'Editing', 'Shooting'],
    proSessions: [],
    classTime: '10 hours',
    videoTime: 'Make 1 video',
    instructorCount: 2,
    studentCount: 30,
    price: '99',
    priceSubtext: 'for the whole course.',
    discountPrice: null,
    beforeDiscountPrice: null,
    discountText: [
      { text: 'Get up to 20% OFF', isHighlighted: true },
      { text: '  when enrolling with 2 or more friends.' }
    ],
    currency: 'USD',
    benefits: [
      '4 hour LIVE class with MING & Friends.',
      'Learn how to Start, Earn & Grow on TikTok.',
      'Hands on Guidance.',
      'Get your Certificate of Accomplishment.'
    ],
    courseCode: 'MTIK',
    courseImgData: {
      alt: 'Tiktok Course',
      mobileImgData: {
        src: `${STATIC_ASSET_BASE_URL}/na-website/MingTikTok/png/mtikCourseImg.png`,
        webpSrc: `${STATIC_ASSET_BASE_URL}/na-website/MingTikTok/webp/mtikCourseImg.webp`
      }
    },
    courseImg:
      STATIC_ASSET_BASE_URL + '/na-website/png/trial-benefit-1.png',
    sectionTitle: 'Join my class now!',
    checkmarkIconSrc: `${STATIC_ASSET_BASE_URL}/na-website/MingTikTok/png/checkList.png`
  },
  [COMEDY_SKITS_WITH_MARKIAN_COURSE_CODE]: markianComedyCourseData,
  [COMEDY_SKITS_LOW_COST_COURSE_CODE]: markianComedyLowCostCourseData,
  [VFX_COURSE_CODE]: {
    title: 'Advanced Editing Effects',
    courseCode: 'NG',
    courseImgData: {
      alt: 'Advenced Editing Effects Image',
      mobileImgData: {
        src: STATIC_ASSET_BASE_URL + '/na-website/NewHome/png/homeVFX.png'
      }
    }
  },
  [TABOO_TOPICS_COURSE_CODE]: sensitiveTopicsCourseData,
  [STORYTELLING_ONDEMAND_COURSE_CODE]: {
    title: 'Art of Storytelling(On-Demand)'
  },
  [UNLOCKING_TIKTOK_WITH_MING_ONDEMAND_COURSE_CODE]: {
    title: 'How To Crush It On Tiktok with Ming (On-Demand)'
  },
  [CRYPTO_CREATOR_MASTER_COURSE_ONDEMAND_COURSE_CODE]: {
    title: 'The Future of Crypto'
  },
  [ULTIMATE_VIRAL_VIDEO_COURSE_CODE_ACTING]: {
    title: 'Acting Workshop'
  },
  [ULTIMATE_VIRAL_VIDEO_COURSE_CODE_SCRIPTING]: {
    title: 'Scripting Workshop'
  },
  [ULTIMATE_VIRAL_VIDEO_COURSE_CODE_EDITING]: {
    title: 'Editing Workshop'
  },
  [RITESH_BILLION_DOLLAR_BUSINESS_COURSE_CODE]: {
    title: 'How To Turn An Idea Into A Billion Dollar Business'
  },
  [DEAR_ALYNE_LIVE_COURSE_CODE]: {
    title: 'Content Creation Crash Course'
  },
  [WISE_WITH_MONEY_COURSE_CODE]: {
    title: 'How to be Wise with Money (On-Demand)'
  },
  [HOW_TO_PRESENT_YOURSELF_COURSE_CODE]: {
    title: 'How To Be A Queen (On-Demand)'
  },
  [WHANG_OD_COURSE_CODE]: {
    title: 'Learn The Ancient Art Of Tattooing'
  },
  [HOW_TO_DRAW_COURSE_CODE]: {
    title: 'How To Draw From Your Imagination'
  },
  [SUSTENTOFILA_GOING_GREEN]: {
    title: 'Going Green With Sustentofila'
  },
  [SUSTENTOFILA_GOING_GREEN_SPANISH]: {
    title: 'Cómo ser más sustentable'
  },
  [DAN_MACE_FINDING_YOUR_VOICE]: {
    title: 'How To Become A Great F*cking Filmmaker'
  },
  [EDITING_FINAL_CUT_PRO_COURSE_CODE]: {
    title: 'Final Cut Pro For Beginner Creators'
  },
  [UPTIN_COMMUNICATE_YOUR_BRAND_COURSE_CODE]: {
    title: '7 Ways To Communicate Your Brand'
  },
  [BELIK_ZOOM_HERO_COURSE_CODE]: {
    title: 'Zero to Zoom Hero'
  },
  [ROSS_MEMORY_PRODUCTIVITY_COURSE_CODE]: {
    title: 'How To Improve Your Memory And Boost Productivity'
  },
  [KAI_SONG_FREELANCER]: {
    title: 'Freelance Your Way To Freedom'
  },
  [NICOLE_BANISTER_ADOPT_CULTURES]: {
    title: 'How To Adapt To New Cultures'
  },
  [THIS_GUY_EDITS_FILMMAKING]: {
    title: 'How To Be a YouTube Filmmaker'
  },
  [JORDAN_MATTER_PHOTOGRAPHY]: {
    title: 'Become an Amazing Photographer in 7 Days'
  },
  [GRAB_FOR_GOOD_PHASE_2_FORCE]: {
    title: 'Be A Force For Good'
  },
  [XAVIER_MORTIMER_MAGIC]: {
    title: 'How to Magic'
  },
  [SMILE_SQUAD_VIRAL_VIDEO]: {
    title: 'The Viral Video Formula'
  },
  [MENTOUR_PILOT_LEADERSHIP]: {
    title: 'Learn Leadership from Mentour Pilot'
  },
  [SMILE_SQUAD_IMPLEMENTATION_LAB]: {
    title: 'The Advanced Implementation Lab'
  },
  [NIKKI_HENDERSON_PROJECT_MANAGEMENT]: {
    title: 'To Do, Doing, Done: Creative Project Management'
  },
  [PATRICK_DANG_SALES]: {
    title: 'The Art of Sales & Negotiation for Creators'
  },
  [JAYNE_NORRIE_CONFIDENT_SINGING]: {
    title: 'How to Be Confident in Singing Without Being A Pro'
  },
  [NIKI_PROSHIN_CREATOR]: {
    title: 'Be The Creator You Want To Be'
  },
  [LO_HARRIS_SELF_DISCOVERY]: {
    title: 'Vision Boards: A Journey of Self-Discovery'
  },
  [MARIA_MALANDRINO_FAIRYTALE]: {
    title: 'Turn Your Photo Into A Fairytale Character'
  },
  [CARLOOPLE_CREATOR_BUSINESS_SCHOOL]: {
    title: "Carlo's Creator Business School"
  },
  [VINFAST_GO_BOUNDLESS]: { title: 'The Challenge: Go Boundless' },
  [MXTAKATAK_PHASE2]: {
    title: 'MX TakaTak Accelerator Creator Program'
  }
  // Do not add any more course titles here - the idea is to deprecate this object.
  // If necessary, talk to Aman.
};

export const oneDayCoursesCardData = [
  {
    title: [
      { text: 'Art of', color: 'white' },
      { text: 'Storytelling', color: 'yellow' }
    ],
    hasGradient: true,
    courseCode: STORYTELLING_COURSE_CODE,
    link: courseLinks[STORYTELLING_COURSE_CODE],
    imgData: {
      alt: 'Art of Stroytelling',
      mobileImgData: {
        webpSrc:
          STATIC_ASSET_BASE_URL +
          '/na-website/CourseImages/aosCardImg.webp',
        src:
          STATIC_ASSET_BASE_URL +
          '/na-website/CourseImages/aosCardImg.png',
        meta: {
          width: 600,
          height: 640
        }
      }
    }
  },
  {
    title: [{ text: 'Dear Alyne', color: 'yellow' }],
    hasGradient: true,
    subtitle: 'Content Creation Crash Course',
    courseCode: DEAR_ALYNE_COURSE_CODE,
    link: courseLinks[DEAR_ALYNE_CONTENT_CREATION_COHORT_COURSE_CODE],
    imgData: coursesData[DEAR_ALYNE_COURSE_CODE].courseImgData
  },
  {
    title: [
      { text: 'Unstoppable', color: 'white' },
      { text: 'Confidence', color: 'yellow' }
    ],
    hasGradient: true,
    courseCode: THE_UNSTOPPABLE_CONFIDENCE_COURSE_CODE,
    link: courseLinks[THE_UNSTOPPABLE_CONFIDENCE_COURSE_CODE],
    imgData:
      coursesData[THE_UNSTOPPABLE_CONFIDENCE_COURSE_CODE].courseImgData
  },
  {
    title: [],
    hasGradient: false,
    courseCode: UNLOCKING_TIKTOK_WITH_MING_COURSE_CODE,
    link: courseLinks[UNLOCKING_TIKTOK_WITH_MING_COURSE_CODE],
    image:
      STATIC_ASSET_BASE_URL + '/na-website/MingTikTok/png/courseCard.png',
    imgData: {
      alt: 'Unlocking Titok with Ming',
      mobileImgData: {
        webpSrc:
          STATIC_ASSET_BASE_URL +
          '/na-website/MingTikTok/webp/courseCard.webp',
        src:
          STATIC_ASSET_BASE_URL +
          '/na-website/MingTikTok/png/courseCard.png',
        meta: {
          widht: 393,
          height: 419
        }
      }
    }
  }
];

export const masterCoursesCardData = [
  {
    title: [
      { text: 'Creator', color: 'yellow' },
      { text: 'Mastercourse', color: 'white' }
    ],
    courseCode: CREATOR_MASTER_COURSE_CODE,
    link: courseLinks[CREATOR_MASTER_COURSE_CODE],
    image:
      STATIC_ASSET_BASE_URL +
      '/na-website/NewHome/png/homeCreatorMastercourse.png',
    imgData: {
      alt: 'Creator Mastercourse',
      mobileImgData: {
        webpSrc:
          STATIC_ASSET_BASE_URL +
          '/na-website/CourseImages/cmcCardImg.webp',
        src:
          STATIC_ASSET_BASE_URL +
          '/na-website/CourseImages/cmcCardImg.png',
        meta: {
          width: 600,
          height: 640
        }
      }
    }
  },
  {
    title: [
      { text: 'Photography', color: 'yellow' },
      { text: 'Mastercourse', color: 'white' }
    ],
    courseCode: PHOTOGRAPHY_COURSE_CODE,
    link: courseLinks[PHOTOGRAPHY_COURSE_CODE],
    imgData: coursesData[PHOTOGRAPHY_COURSE_CODE].courseImgDataWithoutText
  },
  {
    title: [
      { text: 'Advanced', color: 'white' },
      { text: 'Editing Effects', color: 'yellow' }
    ],
    courseCode: VFX_COURSE_CODE,
    link: courseLinks[VFX_COURSE_CODE],
    image: STATIC_ASSET_BASE_URL + '/na-website/NewHome/png/homeVFX.png',
    imgData: {
      alt: 'Advanced Editing Effects',
      mobileImgData: {
        webpSrc:
          STATIC_ASSET_BASE_URL +
          '/na-website/CourseImages/vfxCardImg.webp',
        src:
          STATIC_ASSET_BASE_URL +
          '/na-website/CourseImages/vfxCardImg.png',
        meta: {
          width: 600,
          height: 640
        }
      }
    }
  }
];

const markianEnrollmentCoverImage =
  STATIC_ASSET_BASE_URL +
  '/na-website/MarkianComedyCourse/png/markianPaymentBanner_656x340.png';

export const enrollmentCoverImage = {
  [CREATOR_MASTER_COURSE_CODE]:
    STATIC_ASSET_BASE_URL + '/na-website/png/pCMastercourse.png',
  [STORYTELLING_COURSE_CODE]:
    STATIC_ASSET_BASE_URL + '/na-website/png/pAoS.png',
  [STORYTELLING_ONDEMAND_COURSE_CODE]:
    STATIC_ASSET_BASE_URL + '/na-website/png/pAoS.png',
  [DEAR_ALYNE_COURSE_CODE]:
    STATIC_ASSET_BASE_URL + '/na-website/DearAlyne/png/pCCCwDA.png',
  [PHOTOGRAPHY_COURSE_CODE]:
    STATIC_ASSET_BASE_URL + '/na-website/Payment/png/pPhotoMC.png',
  [THE_UNSTOPPABLE_CONFIDENCE_COURSE_CODE]:
    STATIC_ASSET_BASE_URL + '/na-website/CourseImages/tuc.png',
  [THE_UNSTOPPABLE_CONFIDENCE_ONDEMAND_COURSE_CODE]:
    STATIC_ASSET_BASE_URL + '/na-website/CourseImages/tuc.png',
  [UNLOCKING_TIKTOK_WITH_MING_COURSE_CODE]:
    STATIC_ASSET_BASE_URL + '/na-website/MingTikTok/png/paymentMTIK.png',
  [UNLOCKING_TIKTOK_WITH_MING_ONDEMAND_COURSE_CODE]:
    STATIC_ASSET_BASE_URL + '/na-website/MingTikTok/png/paymentMTIK.png',
  // [CRYPTO_CREATOR_MASTER_COURSE_ONDEMAND_COURSE_CODE]:
  //   STATIC_ASSET_BASE_URL + '/na-website/MingTikTok/png/paymentMTIK.png', // TODO not needed anymore, but leaving it just for reference
  [COMEDY_SKITS_WITH_MARKIAN_COURSE_CODE]: markianEnrollmentCoverImage,
  [COMEDY_SKITS_LOW_COST_COURSE_CODE]: markianEnrollmentCoverImage,
  [TABOO_TOPICS_COURSE_CODE]:
    STATIC_ASSET_BASE_URL +
    '/na-website/CourseImages/sensitive-topics/sensitiveTopicsPaymentBanner1_656x340.jpeg'
};

export const checkmarkIconDefaultSrc =
  'https://d2oi1rqwb0pj00.cloudfront.net/na-website/svg/greentick.svg';

export const pinkCheckmarkIconSrc =
  'https://d2oi1rqwb0pj00.cloudfront.net/na-website/svg/pinktick.svg';

export const courseOfferLineItemDetails = {
  [JORDAN_MATTER_PHOTOGRAPHY]: {
    title: '',
    listData: [
      'Get a FULL REFUND if you’re not satisfied.',
      'Stand a chance to win incredible prizes.',
      'Invite to the Zoom party with Jordan Matter.',
      'Create amazing photos to show off online.',
      'Get lifetime access to all course content.'
    ]
  }
};
