import React from 'react';
const className = 'c-DiscordRoleNeutralIcon';
const DiscordRoleNeutralIcon = ({
  width,
  height,
  customClassName,
  fill
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 24"
      fill="none"
      className={`${customClassName} ${className}`}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.8957 5.34887L11.5044 0.131478C11.1883 -0.0438261 10.8063 -0.0438261 10.4902 0.131478L1.09885 5.34887C0.768066 5.53252 0.5625 5.88104 0.5625 6.25983C0.5625 6.984 0.681458 23.999 10.9973 23.999C21.3131 23.999 21.4321 6.984 21.4321 6.25983C21.4321 5.88104 21.2265 5.53252 20.8957 5.34887ZM10.9973 6.25983C12.4384 6.25983 13.606 7.42748 13.606 8.86852C13.606 10.3096 12.4384 11.4772 10.9973 11.4772C9.55624 11.4772 8.38859 10.3096 8.38859 8.86852C8.38859 7.42748 9.55624 6.25983 10.9973 6.25983ZM7.08424 16.6957C7.08424 14.2946 8.59624 12.7826 10.9973 12.7826C13.3984 12.7826 14.9104 14.2946 14.9104 16.6957H7.08424Z"
        fill={fill}
        fillOpacity="0.65"
      />
    </svg>
  );
};

DiscordRoleNeutralIcon.defaultProps = {
  width: 22,
  height: 24,
  customClassName: '',
  fill: '#1B1B18'
};

export default DiscordRoleNeutralIcon;
