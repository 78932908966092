import React from 'react';

const Collection = () => {
  return (
    <svg
      className="c-Collection"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20">
      <path
        fill="#191C1E"
        d="M2.5 17.5c-.458 0-.85-.163-1.177-.489a1.606 1.606 0 01-.49-1.177V5H2.5v10.834h14.167V17.5H2.5zm3.333-3.333c-.458 0-.85-.163-1.176-.49a1.605 1.605 0 01-.49-1.177V3.334c0-.459.163-.851.49-1.178.326-.326.718-.489 1.176-.489H10l1.667 1.667H17.5c.459 0 .851.163 1.178.49.326.326.489.718.489 1.176v7.5c0 .459-.163.851-.49 1.178-.326.326-.718.489-1.177.489H5.834zm0-1.667H17.5V5h-6.52L9.312 3.334h-3.48V12.5z"></path>
    </svg>
  );
};

export default Collection;
