import PropTypes from 'prop-types';
import React from 'react';

const className = 'c-LiveTV';

const LiveTV = ({ width, height, customClassNames, fill }) => {
  return (
    <svg
      className={`${className} ${customClassNames}`}
      width={width}
      height={height}
      viewBox="0 0 38 34"
      fill="none">
      <path
        d="M16.5 25.5834L23.1333 21.7834C24.25 21.1501 24.25 19.5334 23.1333 18.8834L16.5 15.0834C15.3833 14.4501 14 15.2668 14 16.5334V24.1168C14 25.4001 15.3833 26.2168 16.5 25.5834ZM34 7.00008H21.35L26.25 2.10008C26.5833 1.76675 26.5833 1.25008 26.25 0.916748C25.9167 0.583415 25.4 0.583415 25.0667 0.916748L19 6.98342L12.9333 0.916748C12.6 0.583415 12.0833 0.583415 11.75 0.916748C11.4167 1.25008 11.4167 1.76675 11.75 2.10008L16.65 7.00008H4C2.16666 7.00008 0.666664 8.48342 0.666664 10.3334V30.3334C0.666664 32.1667 2.16666 33.6668 4 33.6668H34C35.8333 33.6668 37.3333 32.1667 37.3333 30.3334V10.3334C37.3333 8.48342 35.8333 7.00008 34 7.00008ZM32.3333 30.3334H5.66666C4.75 30.3334 4 29.5834 4 28.6667V12.0001C4 11.0834 4.75 10.3334 5.66666 10.3334H32.3333C33.25 10.3334 34 11.0834 34 12.0001V28.6667C34 29.5834 33.25 30.3334 32.3333 30.3334Z"
        fill={fill}
      />
    </svg>
  );
};

LiveTV.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  customClassNames: PropTypes.string
};
LiveTV.defaultProps = {
  width: '38',
  height: '34',
  fill: '#222'
};

export default LiveTV;
