import React from 'react';

const LinkedinGreyIcon = ({ width, height, fill = '#AAAAAA' }) => (
  <svg
    className="c-LinkedinGreyIcon"
    width={width}
    height={height}
    viewBox="0 0 24 25"
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.83835 0.943848C0.865105 0.943848 0.0756836 1.71407 0.0756836 2.66308V23.2252C0.0756836 24.1743 0.865105 24.9438 1.83835 24.9438H22.1619C23.1355 24.9438 23.9246 24.1743 23.9246 23.2249V2.66308C23.9246 1.71407 23.1355 0.943848 22.1619 0.943848H1.83835ZM7.30506 10.1981V21.0353H3.70292V10.1981H7.30506ZM7.54239 6.84646C7.54239 7.88644 6.76052 8.71867 5.50462 8.71867H5.5043H5.48101C4.27201 8.71867 3.49046 7.88644 3.49046 6.84646C3.49046 5.78288 4.29561 4.97394 5.52854 4.97394C6.76052 4.97394 7.51878 5.78288 7.54239 6.84646ZM12.9006 21.0353H9.29877C9.29907 20.9714 9.34582 11.2117 9.29908 10.1984H12.9009V11.7325C13.3796 10.9942 14.2366 9.94404 16.1474 9.94404C18.5172 9.94404 20.294 11.493 20.294 14.8216V21.0353H16.6922V15.2383C16.6922 13.7813 16.1707 12.7876 14.8675 12.7876C13.8723 12.7876 13.2796 13.4577 13.0193 14.1052C12.9242 14.3362 12.9006 14.6604 12.9006 14.984V21.0353ZM9.29877 21.0356V21.0353C9.29876 21.0355 9.29877 21.0356 9.29877 21.0356Z"
      fill={fill}
    />
  </svg>
);

LinkedinGreyIcon.defaultProps = {
  height: '32',
  width: '32'
};

export default LinkedinGreyIcon;
