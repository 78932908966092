const className = 'c-IconV2-ChevronDown';

const ChevronDown = ({
  width,
  height,
  customClassNames,
  innerCustomClassNames,
  fill
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      className={`${className} ${customClassNames}`}>
      <path
        d="M16.1611 9.31093L11.9946 13.4355L7.82819 9.31093C7.4094 8.89636 6.73289 8.89636 6.31409 9.31093C5.8953 9.72551 5.8953 10.3952 6.31409 10.8098L11.243 15.6891C11.6617 16.1036 12.3383 16.1036 12.757 15.6891L17.6859 10.8098C18.1047 10.3952 18.1047 9.72551 17.6859 9.31093C17.2671 8.90699 16.5799 8.89636 16.1611 9.31093Z"
        fill={fill}
        className={`${innerCustomClassNames}`}
      />
    </svg>
  );
};

ChevronDown.defaultProps = {
  fill: '#191C1E',
  width: 24,
  height: 24,
  customClassNames: '',
  innerCustomClassNames: ''
};

export default ChevronDown;
