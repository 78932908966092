import React from 'react';

const className = 'c-IconV2-QandA';

const QandA = ({ customClassNames }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={`${className} ${customClassNames}`}>
      <path
        d="M12.2 1.33333H3.13333C2.51 1.33333 2.00567 1.84333 2.00567 2.46666L2 12.6667L4.26667 10.4H12.2C12.8233 10.4 13.3333 9.89 13.3333 9.26666V2.46666C13.3333 1.84333 12.8233 1.33333 12.2 1.33333ZM4.83333 5.3H10.5C10.8117 5.3 11.0667 5.555 11.0667 5.86666C11.0667 6.17833 10.8117 6.43333 10.5 6.43333H4.83333C4.52167 6.43333 4.26667 6.17833 4.26667 5.86666C4.26667 5.555 4.52167 5.3 4.83333 5.3ZM8.23333 8.13333H4.83333C4.52167 8.13333 4.26667 7.87833 4.26667 7.56666C4.26667 7.255 4.52167 7 4.83333 7H8.23333C8.545 7 8.8 7.255 8.8 7.56666C8.8 7.87833 8.545 8.13333 8.23333 8.13333ZM10.5 4.73333H4.83333C4.52167 4.73333 4.26667 4.47833 4.26667 4.16666C4.26667 3.855 4.52167 3.59999 4.83333 3.59999H10.5C10.8117 3.59999 11.0667 3.855 11.0667 4.16666C11.0667 4.47833 10.8117 4.73333 10.5 4.73333Z"
        fill="#222222"
      />
      <path
        d="M20.8667 12.4667H11.8V19.2667H20.2037L20.8667 19.9297V12.4667ZM20.8667 11.3333C21.49 11.3333 21.9943 11.8433 21.9943 12.4667L22 22.6667L19.7333 20.4H11.8C11.1767 20.4 10.6667 19.89 10.6667 19.2667V12.4667C10.6667 11.8433 11.1767 11.3333 11.8 11.3333H20.8667ZM17.4667 17.5667C17.4667 17.2537 17.213 17 16.9 17H13.5C13.187 17 12.9333 17.2537 12.9333 17.5667C12.9333 17.8796 13.187 18.1333 13.5 18.1333H16.9C17.213 18.1333 17.4667 17.8796 17.4667 17.5667ZM19.7333 15.8667C19.7333 15.5537 19.4796 15.3 19.1667 15.3H13.5C13.187 15.3 12.9333 15.5537 12.9333 15.8667C12.9333 16.1796 13.187 16.4333 13.5 16.4333H19.1667C19.4796 16.4333 19.7333 16.1796 19.7333 15.8667ZM19.7333 14.1667C19.7333 13.8537 19.4796 13.6 19.1667 13.6H13.5C13.187 13.6 12.9333 13.8537 12.9333 14.1667C12.9333 14.4796 13.187 14.7333 13.5 14.7333H19.1667C19.4796 14.7333 19.7333 14.4796 19.7333 14.1667Z"
        fill="#222222"
      />
    </svg>
  );
};

QandA.defaultProps = {
  customClassNames: ''
};

export default QandA;
