import React from 'react';

const UsersCheck = ({ customClassNames, customInnerClassNames, fill }) => {
  return (
    <svg
      className={`c-UsersCheck ${customClassNames}`}
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20">
      <path
        fill={fill}
        fillRule="evenodd"
        d="M7.915 3.333a2.5 2.5 0 100 5 2.5 2.5 0 000-5zm-4.166 2.5a4.167 4.167 0 118.333 0 4.167 4.167 0 01-8.333 0zm8.394-3.403a.833.833 0 011.085-.46 4.168 4.168 0 010 7.727.833.833 0 11-.625-1.545 2.501 2.501 0 000-4.637.833.833 0 01-.46-1.085zm-5.507 9.237h3.363a.833.833 0 010 1.666H6.665c-.788 0-1.337 0-1.767.03-.422.029-.668.083-.856.16a2.5 2.5 0 00-1.353 1.354c-.078.187-.132.433-.16.856-.03.43-.03.979-.03 1.767a.833.833 0 01-1.667 0v-.03c0-.751 0-1.357.034-1.85.034-.508.107-.956.283-1.381a4.167 4.167 0 012.255-2.255c.425-.176.873-.25 1.38-.284.494-.033 1.1-.033 1.852-.033zm11.107 1.077a.833.833 0 011.178 1.178l-3.333 3.334a.834.834 0 01-1.179 0l-1.666-1.667a.833.833 0 111.178-1.178L15 15.488l2.744-2.744z"
        clipRule="evenodd"
        className={customInnerClassNames}></path>
    </svg>
  );
};

UsersCheck.defaultProps = {
  customClassNames: '',
  fill: '#30A66D'
};

export default UsersCheck;
