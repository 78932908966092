import React from 'react';

const className = 'c-IconV2-Star';

const Star = ({ customClassNames, width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 28"
      className={`${className} ${customClassNames}`}
      fill="none">
      <path
        d="M13.9909 2.33337C7.55094 2.33337 2.33594 7.56004 2.33594 14C2.33594 20.44 7.55094 25.6667 13.9909 25.6667C20.4426 25.6667 25.6693 20.44 25.6693 14C25.6693 7.56004 20.4426 2.33337 13.9909 2.33337ZM18.9376 21L14.0026 18.025L9.0676 21L10.3743 15.3884L6.0226 11.62L11.7626 11.13L14.0026 5.83337L16.2426 11.1184L21.9826 11.6084L17.6309 15.3767L18.9376 21Z"
        fill="#222222"
      />
    </svg>
  );
};

Star.defaultProps = {
  customClassNames: '',
  width: '28',
  height: '28'
};

export default Star;
