import React from 'react';

const AddLink = ({ customClassNames }) => {
  return (
    <svg
      className={`c-AddLink ${customClassNames}`}
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32">
      <mask
        id="mask0_5097_13623"
        style={{ maskType: 'alpha' }}
        width="32"
        height="33"
        x="0"
        y="-1"
        maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0 -0.001H32V31.999H0z"></path>
      </mask>
      <g mask="url(#mask0_5097_13623)">
        <path
          fill="#1C1B1F"
          d="M14.667 22.666H9.333c-1.844 0-3.416-.65-4.716-1.95-1.3-1.3-1.95-2.872-1.95-4.716 0-1.845.65-3.417 1.95-4.718 1.3-1.3 2.872-1.949 4.716-1.949h5.334V12H9.333c-1.11 0-2.055.389-2.833 1.166-.778.778-1.167 1.723-1.167 2.834 0 1.11.39 2.055 1.167 2.833C7.278 19.611 8.222 20 9.333 20h5.334v2.666zm-4-5.333v-2.667h10.666v2.667H10.667zM29.333 16h-2.666c0-1.111-.39-2.056-1.167-2.834C24.722 12.39 23.778 12 22.667 12h-5.334V9.333h5.334c1.844 0 3.417.65 4.717 1.95 1.3 1.3 1.95 2.872 1.95 4.717zm-6.666 10.666v-4h-4V20h4v-4h2.666v4h4v2.666h-4v4h-2.666z"></path>
      </g>
    </svg>
  );
};

AddLink.defaultProps = {
  customClassNames: ''
};

export default AddLink;
