import classNames from 'classnames';

const SentimentDissatisfied = ({ fill = '#00704A', customClassNames }) => {
  return (
    <svg
      className={classNames('c-SentimentDissatisfied', customClassNames)}
      width="56"
      height="56"
      fill="none"
      viewBox="0 0 56 56">
      <path
        d="M36.166 25.667C38.099 25.667 39.666 24.1 39.666 22.167C39.666 20.234 38.099 18.667 36.166 18.667C34.233 18.667 32.666 20.234 32.666 22.167C32.666 24.1 34.233 25.667 36.166 25.667Z"
        fill={fill}
      />
      <path
        d="M19.8327 25.667C21.7657 25.667 23.3327 24.1 23.3327 22.167C23.3327 20.234 21.7657 18.667 19.8327 18.667C17.8997 18.667 16.3327 20.234 16.3327 22.167C16.3327 24.1 17.8997 25.667 19.8327 25.667Z"
        fill={fill}
      />
      <path
        d="M27.976 4.66699C15.096 4.66699 4.66602 15.1203 4.66602 28.0003C4.66602 40.8803 15.096 51.3337 27.976 51.3337C40.8794 51.3337 51.3327 40.8803 51.3327 28.0003C51.3327 15.1203 40.8794 4.66699 27.976 4.66699ZM27.9994 46.667C17.686 46.667 9.33268 38.3137 9.33268 28.0003C9.33268 17.687 17.686 9.33366 27.9994 9.33366C38.3127 9.33366 46.666 17.687 46.666 28.0003C46.666 38.3137 38.3127 46.667 27.9994 46.667Z"
        fill={fill}
      />
      <path
        d="M38.8736 35.3355C36.4936 39.0921 32.457 41.3555 28.0236 41.3555C23.5903 41.3555 19.5536 39.1155 17.1503 35.3355C16.637 34.5188 16.8936 33.4455 17.7103 32.9321C18.0136 32.7688 18.317 32.6755 18.6436 32.6755C19.227 32.6755 19.8103 32.9555 20.137 33.4921C21.8636 36.2455 24.8036 37.8788 28.0236 37.8788C31.2436 37.8788 34.1836 36.2455 35.9103 33.4921C36.4236 32.6755 37.497 32.4188 38.3136 32.9321C39.1303 33.4455 39.387 34.5188 38.8736 35.3355Z"
        fill={fill}
      />
    </svg>
  );
};

export default SentimentDissatisfied;
