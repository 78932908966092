import {
  communityAdminRolesArray,
  communityUserRoles
} from '@/utility/loginConstants';
import { createContext, useContext, useEffect, useState } from 'react';
import {
  getAdminProfile,
  getTokensService,
  userLoginService
} from '@/services/community/auth/admin/adminCommunityAuthService';
import {
  storeTrackingInformation,
  trackGAEvent
} from '@/utility/analytics';

import AdminAuth from '../../modules/AdminAuth';
import { LOGOUT_EVENT } from '@/utility/analyticsConsts';
import config from '@/utility/config';
import { signUp } from '@/services/communitiesService';
import { useRouter } from 'next/router';
import { ACCESS_TOKEN_KEY } from '@/modules/AdminAuth';
import Cookies from 'js-cookie';
import { CM_PORTAL_HOMEPAGE_ROUTE } from '@/utility/routesHelper';

export const AdminAuthContext = createContext();
const { appleRedirectLink } = config;

export const AdminAuthContextProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [loginError, setLoginError] = useState(null);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [isSocialLogin, setIsSocialLogin] = useState(false);
  const [socialLoginError, setSocialLoginError] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [user, setUser] = useState(null);
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const router = useRouter();
  const query = router.query;

  const isDemoUser = !!user?.isDemo;

  const [userConfigData, setUserConfigData] = useState({
    showLibraryTooltip: false,
    isDismissedLibraryTooltip: false
  });

  const getTokens = async (
    tokenPayload,
    isCommunityAdmin = true,
    shouldRedirect = true,
    communityMandatory = false
  ) => {
    setIsLoading(true);
    setSocialLoginError('');
    const res = await getTokensService({
      ...tokenPayload,
      isCommunityAdmin,
      communityMandatory
    });
    setIsLoading(false);

    const { data, error } = res;

    if (error) {
      setSocialLoginError(error || 'Error Logging in. Please try again.');
      return;
    }

    const { token, refresh_token } = data;
    if (!token || !refresh_token) return;
    setIsSocialLogin(true);

    await authenticateUser(
      {
        accessToken: token,
        refreshToken: refresh_token,
        userRoles: {},
        isSignUp: true
      },
      shouldRedirect
    );
  };

  const handleSocialAuth = (
    response,
    provider,
    isCommunityAdmin = true,
    shouldRedirect = true,
    communityMandatory = false
  ) => {
    let authToken = '';
    let code = '';
    let appleUserData = null;
    let payload = {};

    switch (provider) {
      case 'facebook':
        const fbToken = response?.accessToken;
        authToken = fbToken;
        payload = { authToken, provider };
        break;
      case 'google':
        authToken = response?.code;
        payload = { code: authToken, provider };
        break;
      case 'apple':
        code = response?.authorization?.code;
        authToken = response?.authorization?.id_token;
        appleUserData = response?.user;
        payload = {
          code,
          authToken,
          provider,
          appleUserData,
          appleRedirectUrl: appleRedirectLink
        };
        break;
      default:
    }

    if (authToken)
      getTokens(
        payload,
        isCommunityAdmin,
        shouldRedirect,
        communityMandatory
      );
  };

  const setAuth = (accessToken, refreshToken) => {
    AdminAuth.authenticateUser(accessToken, refreshToken);
  };

  const setAuthForMemberPortal = (accessToken, refreshToken) => {
    AdminAuth.authenticateMemberUser(accessToken, refreshToken);
  };

  useEffect(() => {
    if (AdminAuth.getAdminUserData()) {
      setUser(AdminAuth.getAdminUserData());
      return;
    }
    getUserData({
      redirect: false,
      accessToken: AdminAuth.getUserToken()
    });
  }, [isUserLoggedIn]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      storeTrackingInformation(router?.query);
    }
  }, [router?.query]);

  // Standard email, password Login
  const login = async (
    email,
    password,
    isCommunityAdmin = true,
    redirect = true,
    communityMandatory = false
  ) => {
    setIsLoading(true);
    setLoginError(null);
    const res = await userLoginService(
      email,
      password,
      isCommunityAdmin,
      communityMandatory
    );

    if (res.error) {
      setLoginError(res.error);
      setIsLoading(false);
      return { error: res.error };
    }

    const { token, refresh_token } = res;
    await authenticateUser(
      {
        accessToken: token,
        refreshToken: refresh_token
      },
      redirect
    );

    return res;
  };

  const signup = async (formData, redirect = true) => {
    setIsLoading(true);
    setLoginError(null);
    try {
      const res = await signUp(formData);
      const { data, error } = res;
      if (error) {
        setLoginError(error);
        setIsLoading(false);
        return res;
      }
      const { token, refreshToken } = data;

      await authenticateUser(
        {
          accessToken: token,
          refreshToken: refreshToken,
          userRoles: {},
          isSignUp: true
        },
        redirect
      );
      setIsLoading(false);
      return res;
    } catch (e) {
      const error = e.message;
      setLoginError(error);
      setIsLoading(false);
      return { error };
    }
  };

  const getUserData = async ({
    isMember = false,
    accessToken = AdminAuth.getUserToken()
  }) => {
    if (!isMember) {
      const res = await getAdminProfile({ accessToken });
      const { data, error } = res;
      if (error || !data) {
        return;
      }
      setUser(data);
      AdminAuth.saveUser(data);
      return data;
    }
  };

  const redirectUser = (isMember) => {
    // `redirectTo` : this query param is used if you want redirect a user to a specific route/url after login

    const { redirectTo, ...rest } = router.query;
    if (redirectTo) {
      // push all of the rest as query params
      const redirectURL = new URL(redirectTo);
      Object.keys(rest).forEach((key) => {
        redirectURL.searchParams.append(key, rest[key]);
      });

      router.push(redirectURL);
      return;
    }

    if (isMember) {
      router.push(`${config.memberPortalPath}`);
      return;
    }

    router.push(CM_PORTAL_HOMEPAGE_ROUTE);
  };

  const setUserTokens = (accessToken, refreshToken) => {
    setAuth(accessToken, refreshToken);
    setIsUserLoggedIn(true);
  };
  const setUserTokenForMemberPortal = (accessToken, refreshToken) => {
    setAuthForMemberPortal(accessToken, refreshToken);
  };

  useEffect(() => {
    if (!user) return;

    const userRolesData = user?.roles ? Object.keys(user.roles) : [];
    const isUserAdmin = userRolesData?.some((role) =>
      communityAdminRolesArray.includes(role)
    );

    setIsAdmin(isUserAdmin);
  }, [user]);

  const authenticateUser = async (
    { accessToken, refreshToken },
    redirect = true
  ) => {
    // check if user Role(object) is a community admin or a member
    const userData = await getUserData({
      redirect,
      accessToken,
      refreshToken
    });

    const userRolesData = Object.keys(userData?.roles || {});
    const isMember =
      !userRolesData.includes(communityUserRoles.OWNER) &&
      !userRolesData.includes(communityUserRoles.ADMIN) &&
      !userRolesData.includes(communityUserRoles.MANAGER);

    // Redirect user to previous route
    if (redirect) {
      redirectUser(isMember);
    }

    setUserTokens(accessToken, refreshToken);
    setUserTokenForMemberPortal(accessToken, refreshToken);
    // eslint-disable-next-line no-empty
    setIsLoading(false);
    setIsUserLoggedIn(true);
  };

  const logout = (redirect = true, routeToRedirect = null) => {
    trackGAEvent(LOGOUT_EVENT, { email: user?.email });
    AdminAuth.deauthenticateUser();
    setUser(null);
    setIsUserLoggedIn(false);
    if (redirect) {
      router.push(routeToRedirect ? routeToRedirect : '/login');
    }
  };

  // Used when user is accessing portal with access and refresh token on URL Params
  const loginCheckWithToken = async () => {
    const { token, refreshToken, ...rest } = query;

    if (token && refreshToken) {
      const redirectUrl =
        query.redirectTo ||
        `${CM_PORTAL_HOMEPAGE_ROUTE}?${new URLSearchParams(
          rest
        ).toString()}`;
      setAuth(token, refreshToken);

      // Start checking auth with token
      const { data, error } = await getAdminProfile({
        accessToken: token
      });
      if (error) {
        logout();
        return;
      }
      AdminAuth.saveUser(data);
      setUser(data);
      if (data) {
        router.replace(redirectUrl, undefined, { shallow: true });
      } else {
        router.replace('/login', undefined, { shallow: true });
      }
    }
  };

  const toggleLoginModal = () => setOpenLoginModal((prev) => !prev);

  useEffect(() => {
    if (AdminAuth.isAuthenticated()) {
      setIsUserLoggedIn(true);
    } else {
      setIsUserLoggedIn(false);
      // if on portal then logout
      const userOnPortalPage = router.pathname.includes('/portal');
      if (userOnPortalPage && Cookies.get(ACCESS_TOKEN_KEY)) {
        logout(userOnPortalPage);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (router.isReady) {
      loginCheckWithToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  useEffect(() => {
    if (localStorage.getItem('userConfigData')) {
      setUserConfigData(
        JSON.parse(localStorage.getItem('userConfigData'))
      );
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('userConfigData', JSON.stringify(userConfigData));
  }, [userConfigData]);

  const value = {
    login,
    logout,
    loginError,
    setLoginError,
    resetLoginError: () => setLoginError(''),
    signup,
    isUserLoggedIn,
    isLoading,
    user,
    isAdmin,
    isDemoUser,

    // social login
    handleSocialAuth,
    socialLoginError,

    // Login Modal
    openLoginModal,
    toggleLoginModal,
    authenticateUser,
    setUserTokens,
    getUserData,
    isSocialLogin,
    userConfigData,
    setUserConfigData
  };

  return (
    <AdminAuthContext.Provider value={value}>
      {children}
    </AdminAuthContext.Provider>
  );
};

export const useAdminAuthContext = () => useContext(AdminAuthContext);
