import React from 'react';

const Upload01 = ({ width, height, fill }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 27 28" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.557 1.05703C13.0777 0.536329 13.9219 0.536329 14.4426 1.05703L21.1093 7.7237C21.63 8.24439 21.63 9.08861 21.1093 9.60931C20.5886 10.13 19.7444 10.13 19.2237 9.60931L14.8332 5.21879V17.9998C14.8332 18.7362 14.2362 19.3332 13.4998 19.3332C12.7635 19.3332 12.1665 18.7362 12.1665 17.9998V5.21879L7.77598 9.60931C7.25528 10.13 6.41106 10.13 5.89036 9.60931C5.36966 9.08861 5.36966 8.24439 5.89036 7.7237L12.557 1.05703ZM1.49984 16.6665C2.23622 16.6665 2.83317 17.2635 2.83317 17.9998V19.5998C2.83317 20.7419 2.83421 21.5183 2.88324 22.1185C2.931 22.703 3.01757 23.0019 3.12382 23.2105C3.37948 23.7122 3.78743 24.1202 4.2892 24.3759C4.49773 24.4821 4.79667 24.5687 5.38122 24.6164C5.98134 24.6655 6.75773 24.6665 7.89984 24.6665H19.0998C20.2419 24.6665 21.0183 24.6655 21.6185 24.6164C22.203 24.5687 22.5019 24.4821 22.7105 24.3759C23.2122 24.1202 23.6202 23.7122 23.8759 23.2105C23.9821 23.0019 24.0687 22.703 24.1164 22.1185C24.1655 21.5183 24.1665 20.7419 24.1665 19.5998V17.9998C24.1665 17.2635 24.7635 16.6665 25.4998 16.6665C26.2362 16.6665 26.8332 17.2635 26.8332 17.9998V19.6549C26.8332 20.7282 26.8332 21.614 26.7742 22.3356C26.713 23.0851 26.5816 23.774 26.2519 24.4211C25.7405 25.4247 24.9247 26.2405 23.9211 26.7519C23.274 27.0816 22.5851 27.213 21.8356 27.2742C21.114 27.3332 20.2282 27.3332 19.1549 27.3332H7.84474C6.77146 27.3332 5.88566 27.3332 5.16407 27.2742C4.41461 27.213 3.72567 27.0816 3.07856 26.7519C2.07502 26.2405 1.25913 25.4247 0.747804 24.4211C0.418083 23.774 0.286664 23.0851 0.22543 22.3356C0.166473 21.614 0.166487 20.7282 0.166504 19.6549L0.166505 17.9998C0.166505 17.2635 0.763459 16.6665 1.49984 16.6665Z"
        fill={fill}
      />
    </svg>
  );
};

Upload01.defaultProps = {
  width: 27,
  height: 28,
  fill: '#1B1B18'
};
export default Upload01;
