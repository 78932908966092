import React from 'react';

const Pin = () => {
  return (
    <svg
      className="c-Pin"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16">
      <path
        fill="#75777A"
        d="M8 8c.734 0 1.334-.6 1.334-1.333 0-.734-.6-1.334-1.334-1.334-.733 0-1.333.6-1.333 1.334C6.667 7.4 7.267 8 8 8zm0-6.667c2.8 0 5.334 2.147 5.334 5.467 0 2.12-1.634 4.613-4.894 7.487a.678.678 0 01-.886 0C4.3 11.413 2.667 8.92 2.667 6.8 2.667 3.48 5.2 1.333 8 1.333z"></path>
    </svg>
  );
};

export default Pin;
