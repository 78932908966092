import React from 'react';

const className = 'c-IconV2-Globe';

const Globe = ({ customClassNames }) => {
  return (
    <svg
      className={`${className} ${customClassNames}`}
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24">
      <path
        fill="#222"
        d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18.333a8.303 8.303 0 01-5.833-2.392v-1.358c0-.396.177-.651.382-.947.211-.303.451-.648.451-1.136 0-.795-.717-.875-1.103-.918-.276-.031-.536-.06-.686-.21-.113-.113-.226-.319-.346-.536-.237-.432-.56-1.004-1.172-1.354C3.963 7.13 7.58 3.667 12 3.667c.229 0 .454.016.678.034-.046.03-.102.05-.14.088a.418.418 0 00.018.606c.15.133.179.2.185.2-.01.02-.086.15-.513.367-.704.357-1.517 1.087-1.637 1.847-.056.345.044.665.281.902a.416.416 0 00.295.122c.578 0 1.026-.214 1.458-.42.444-.212.863-.413 1.458-.413C15.72 7 17 7.366 17 7.833c0 .172-.053.224-.074.244-.204.201-.901.188-1.514.179a28.185 28.185 0 00-.495-.006c-.566 0-.741-.083-.944-.179-.237-.111-.505-.238-1.14-.238-.687 0-1.963.124-2.794.956-.68.68-.608 1.492-.561 2.03.011.123.022.24.022.348 0 .82.839 1.25 1.667 1.25 1.273 0 2.374.237 2.5.416 0 .467.138.785.25 1.04.093.214.166.383.166.627 0 .189-.03.229-.114.336-.16.207-.302.432-.302.914 0 .877.844 1.839.94 1.946a.417.417 0 00.413.124c.245-.063 2.397-.655 2.397-2.07 0-.444.138-.563.347-.745.205-.178.486-.422.486-.922 0-.334.593-1.196 1.142-1.803a.417.417 0 00-.053-.61c-.324-.25-1.218-1.068-1.518-1.86.17.088.382.232.551.401.139.14.325.207.536.198.338-.02.739-.286 1.119-.618.194.704.306 1.443.306 2.209 0 4.595-3.738 8.333-8.333 8.333z"></path>
    </svg>
  );
};

Globe.defaultProps = {
  customClassNames: ''
};

export default Globe;
