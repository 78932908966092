import React from 'react';

const MonetizationFeatureGoldIcon = ({ customClassNames }) => {
  return (
    <svg
      className={`c-MonetizationFeatureGoldIcon ${customClassNames}`}
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32">
      <g>
        <g>
          <mask id="path-1-inside-1_3077_130954" fill="#fff">
            <path d="M16 2.666c-7.36 0-13.334 5.973-13.334 13.333s5.973 13.334 13.333 13.334 13.334-5.974 13.334-13.334c0-7.36-5.974-13.333-13.334-13.333zm0 24c-5.88 0-10.667-4.787-10.667-10.667S10.119 5.333 15.999 5.333c5.88 0 10.667 4.786 10.667 10.666 0 5.88-4.787 10.667-10.667 10.667zm.413-11.813c-2.36-.6-3.12-1.254-3.12-2.227 0-1.12 1.053-1.907 2.8-1.907 1.84 0 2.533.88 2.586 2.187h2.28c-.066-1.787-1.16-3.427-3.32-3.96v-2.28h-3.106v2.253c-2.014.427-3.627 1.734-3.627 3.747 0 2.387 1.987 3.587 4.88 4.28 2.6.613 3.12 1.533 3.12 2.493 0 .707-.52 1.854-2.8 1.854-2.133 0-2.973-.96-3.093-2.187h-2.294c.134 2.267 1.814 3.547 3.814 3.96v2.267h3.12v-2.227c2.026-.387 3.626-1.547 3.64-3.693-.014-2.934-2.534-3.947-4.88-4.56z"></path>
          </mask>
          <path
            fill="#937300"
            stroke="#937300"
            strokeWidth="5"
            d="M16 2.666c-7.36 0-13.334 5.973-13.334 13.333s5.973 13.334 13.333 13.334 13.334-5.974 13.334-13.334c0-7.36-5.974-13.333-13.334-13.333zm0 24c-5.88 0-10.667-4.787-10.667-10.667S10.119 5.333 15.999 5.333c5.88 0 10.667 4.786 10.667 10.666 0 5.88-4.787 10.667-10.667 10.667zm.413-11.813c-2.36-.6-3.12-1.254-3.12-2.227 0-1.12 1.053-1.907 2.8-1.907 1.84 0 2.533.88 2.586 2.187h2.28c-.066-1.787-1.16-3.427-3.32-3.96v-2.28h-3.106v2.253c-2.014.427-3.627 1.734-3.627 3.747 0 2.387 1.987 3.587 4.88 4.28 2.6.613 3.12 1.533 3.12 2.493 0 .707-.52 1.854-2.8 1.854-2.133 0-2.973-.96-3.093-2.187h-2.294c.134 2.267 1.814 3.547 3.814 3.96v2.267h3.12v-2.227c2.026-.387 3.626-1.547 3.64-3.693-.014-2.934-2.534-3.947-4.88-4.56z"
            mask="url(#path-1-inside-1_3077_130954)"></path>
        </g>
      </g>
    </svg>
  );
};

MonetizationFeatureGoldIcon.defaultProps = {
  customClassNames: ''
};

export default MonetizationFeatureGoldIcon;
