import React from 'react';

const NPLCertificate = ({ customClassNames }) => {
  return (
    <svg
      className={`c-NPLCertificate fill-current ${customClassNames}`}
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        d="M2 4a3 3 0 013-3h14a3 3 0 013 3v14a3 3 0 01-3 3h-1.2l-1.9 1.9a1 1 0 01-1.414 0l-2.475-2.475L9.536 22.9a1 1 0 01-1.414 0l-1.9-1.9H5a3 3 0 01-3-3V4zm5.414 15.364l1.415 1.414 1.262-1.262a4.019 4.019 0 01-1.464-1.365l-1.213 1.213zM8 15.95l-2.707 2.707A1 1 0 005.07 19H5a1 1 0 01-1-1V4a1 1 0 011-1h14a1 1 0 011 1v14a1 1 0 01-1 1h-.047a1 1 0 00-.225-.343L16 15.928A4 4 0 008 15.95zm2.014.283l-.001-.006A2 2 0 1112.028 18h-.032a2 2 0 01-1.982-1.767zm3.909 3.275a4.018 4.018 0 001.458-1.37l1.226 1.226-1.414 1.414-1.27-1.27zM8 6a1 1 0 011-1h6a1 1 0 110 2H9a1 1 0 01-1-1zM6 9.5a1 1 0 011-1h10a1 1 0 110 2H7a1 1 0 01-1-1z"
        clipRule="evenodd"></path>
    </svg>
  );
};

NPLCertificate.defaultProps = {
  customClassNames: 'fill-npl-yellow-light-8'
};

export default NPLCertificate;
