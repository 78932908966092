import React from 'react';

const CalendarDays = ({
  fill,
  customClassNames,
  height = '32',
  width = '32'
}) => {
  return (
    <svg
      className={`c-CalendarDays ${customClassNames}`}
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 32 32">
      <path
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5"
        d="M25.334 5.334H6.667c-.736 0-1.333.597-1.333 1.333v18.667c0 .736.597 1.333 1.333 1.333h18.667c.736 0 1.333-.597 1.333-1.333V6.667c0-.736-.597-1.333-1.333-1.333z"></path>
      <path
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5"
        d="M21.334 21.334h.003v.003h-.003v-.003zM16 21.334h.003v.003H16v-.003zM10.666 21.334h.003v.003h-.003v-.003zM21.334 16h.003v.003h-.003V16zM16 16h.003v.003H16V16zM10.666 16h.003v.003h-.003V16zM5.334 10.666h21.333M21.334 2.666v2.667M10.666 2.666v2.667"></path>
    </svg>
  );
};

CalendarDays.defaultProps = {
  fill: '#191C1E',
  customClassNames: ''
};

export default CalendarDays;
