import PropTypes from 'prop-types';
import React from 'react';

const className = 'c-SupportAgent';

const SupportAgent = ({ width, height, customClassNames, fill }) => {
  return (
    <svg
      className={`${className} ${customClassNames}`}
      width={width}
      height={height}
      viewBox="0 0 34 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M32 15.3667C32 6.21667 24.9 0 17 0C9.18334 0 2 6.08333 2 15.4667C1 16.0333 0.333336 17.1 0.333336 18.3333V21.6667C0.333336 23.5 1.83334 25 3.66667 25C4.58334 25 5.33334 24.25 5.33334 23.3333V15.3167C5.33334 8.93333 10.25 3.35 16.6333 3.16667C23.2333 2.96667 28.6667 8.26667 28.6667 14.8333V26.6667H17C16.0833 26.6667 15.3333 27.4167 15.3333 28.3333C15.3333 29.25 16.0833 30 17 30H28.6667C30.5 30 32 28.5 32 26.6667V24.6333C32.9833 24.1167 33.6667 23.1 33.6667 21.9V18.0667C33.6667 16.9 32.9833 15.8833 32 15.3667Z"
        fill={fill}
      />
      <path
        d="M12 18.3333C12.9205 18.3333 13.6667 17.5871 13.6667 16.6667C13.6667 15.7462 12.9205 15 12 15C11.0795 15 10.3333 15.7462 10.3333 16.6667C10.3333 17.5871 11.0795 18.3333 12 18.3333Z"
        fill={fill}
      />
      <path
        d="M22 18.3333C22.9205 18.3333 23.6667 17.5871 23.6667 16.6667C23.6667 15.7462 22.9205 15 22 15C21.0795 15 20.3333 15.7462 20.3333 16.6667C20.3333 17.5871 21.0795 18.3333 22 18.3333Z"
        fill={fill}
      />
      <path
        d="M27 13.3833C26.2 8.63333 22.0667 5 17.0833 5C12.0333 5 6.6 9.18333 7.03334 15.75C11.15 14.0667 14.25 10.4 15.1333 5.93333C17.3167 10.3167 21.8 13.3333 27 13.3833Z"
        fill={fill}
      />
    </svg>
  );
};

SupportAgent.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  customClassNames: PropTypes.string
};
SupportAgent.defaultProps = {
  width: '34',
  height: '30',
  fill: '#222'
};

export default SupportAgent;
