import React from 'react';

const IconSLeft = ({ customClassNames }) => {
  return (
    <svg
      className={`c-IconSLeft ${customClassNames}`}
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16">
      <path
        fill="#000"
        d="M2.337 6.95a3.323 3.323 0 002.713 3.452l-.527-.526a.664.664 0 11.94-.94l1.727 1.726c.26.26.26.68 0 .94l-1.72 1.734a.664.664 0 11-.94-.94l.587-.587v-.04a4.663 4.663 0 01-4.1-4.98c.173-2.467 2.346-4.32 4.82-4.32h1.16c.366 0 .666.3.666.667 0 .366-.3.666-.666.666H5.81c-1.8 0-3.38 1.36-3.473 3.147zM10.33 7.136h3.334c.733 0 1.333-.6 1.333-1.334v-2c0-.733-.6-1.333-1.333-1.333H10.33c-.733 0-1.333.6-1.333 1.333v2c0 .734.6 1.334 1.333 1.334zm3.334-1.334H10.33v-2h3.334v2zM13.664 13.136H10.33c-.733 0-1.333-.6-1.333-1.333v-2c0-.734.6-1.334 1.333-1.334h3.334c.733 0 1.333.6 1.333 1.333v2c0 .734-.6 1.334-1.333 1.334z"></path>
    </svg>
  );
};

IconSLeft.defaultProps = {
  customClassNames: ''
};

export default IconSLeft;
