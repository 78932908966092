import React from 'react';

const Stars = ({ customClassNames }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={customClassNames}>
      <path
        d="M4.5 8.66666L5.02297 9.71261C5.19996 10.0666 5.28846 10.2436 5.40669 10.397C5.51159 10.5331 5.6336 10.6551 5.7697 10.76C5.92308 10.8782 6.10007 10.9667 6.45406 11.1437L7.5 11.6667L6.45406 12.1896C6.10007 12.3666 5.92308 12.4551 5.7697 12.5733C5.6336 12.6783 5.51159 12.8003 5.40669 12.9364C5.28846 13.0897 5.19996 13.2667 5.02297 13.6207L4.5 14.6667L3.97703 13.6207C3.80003 13.2667 3.71154 13.0897 3.59331 12.9364C3.48841 12.8003 3.3664 12.6783 3.2303 12.5733C3.07692 12.4551 2.89993 12.3666 2.54594 12.1896L1.5 11.6667L2.54594 11.1437C2.89993 10.9667 3.07692 10.8782 3.2303 10.76C3.3664 10.6551 3.48841 10.5331 3.59331 10.397C3.71154 10.2436 3.80003 10.0666 3.97703 9.71261L4.5 8.66666Z"
        className="stroke-content"
        strokeOpacity="0.65"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.1667 1.33333L10.9524 3.37627C11.1404 3.86509 11.2344 4.1095 11.3806 4.31509C11.5102 4.4973 11.6694 4.65649 11.8516 4.78605C12.0572 4.93223 12.3016 5.02624 12.7904 5.21425L14.8333 5.99999L12.7904 6.78574C12.3016 6.97375 12.0572 7.06775 11.8516 7.21394C11.6694 7.3435 11.5102 7.50269 11.3806 7.6849C11.2344 7.89049 11.1404 8.1349 10.9524 8.62372L10.1667 10.6667L9.38092 8.62372C9.19291 8.1349 9.09891 7.89049 8.95272 7.6849C8.82316 7.50269 8.66397 7.3435 8.48176 7.21394C8.27618 7.06775 8.03177 6.97375 7.54295 6.78574L5.5 5.99999L7.54294 5.21425C8.03176 5.02624 8.27617 4.93224 8.48176 4.78605C8.66397 4.65649 8.82316 4.4973 8.95272 4.31509C9.09891 4.1095 9.19291 3.86509 9.38092 3.37627L10.1667 1.33333Z"
        className="stroke-content"
        strokeOpacity="0.65"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Stars;
