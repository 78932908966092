import React from 'react';

const Announcement = () => {
  return (
    <svg width="19" height="18" fill="none" viewBox="0 0 19 18">
      <path
        fill="#007AA3"
        fillRule="evenodd"
        d="M13.18 4.033c-.406.753-.68 1.845-.68 3.092s.274 2.339.68 3.092c.302.561.622.848.896.966l.354.065c.313-.028.743-.292 1.14-1.031.406-.753.68-1.845.68-3.092s-.274-2.339-.68-3.092c-.397-.74-.827-1.003-1.14-1.03l-.354.064c-.274.118-.594.405-.897.966zm-1.406-.548c-.492.991-.774 2.27-.774 3.64s.282 2.649.774 3.64L4.717 9.482c-.743-.135-.964-.183-1.128-.263a1.5 1.5 0 01-.782-.937c-.05-.176-.057-.402-.057-1.157s.007-.98.057-1.157a1.5 1.5 0 01.782-.937c.164-.08.385-.128 1.128-.263l7.057-1.283zm1.884-1.867l-9.21 1.675-.1.018c-.597.108-1.044.189-1.42.374a3 3 0 00-1.564 1.873c-.115.404-.114.858-.114 1.464a172.317 172.317 0 010 .206c0 .606 0 1.06.114 1.464a3 3 0 001.563 1.873c.179.088.373.152.59.207l.282 3.94c.013.176.025.35.047.496.025.162.069.351.177.539a1.5 1.5 0 00.648.604c.195.094.387.124.55.137.148.012.322.012.499.012h1.384c.204 0 .4 0 .565-.014a1.52 1.52 0 00.608-.166 1.5 1.5 0 00.66-.712c.097-.22.117-.437.118-.618.001-.166-.014-.362-.03-.565l-.205-2.673 4.838.88c.225.076.464.118.717.118 1.133 0 1.988-.841 2.517-1.822.544-1.012.858-2.358.858-3.803 0-1.445-.314-2.791-.858-3.803-.529-.98-1.384-1.822-2.517-1.822-.253 0-.492.042-.717.118zm-6.364 9.857l-2.252-.41.252 3.517a5.913 5.913 0 00.037.41l.008.001c.082.006.195.007.403.007H7.08c.238 0 .37 0 .465-.009h.01v-.012c.002-.095-.008-.226-.026-.463l-.234-3.041z"
        clipRule="evenodd"></path>
    </svg>
  );
};

export default Announcement;
