const NA_WAITLIST_SESSION_TAG = 'na-waitlistEnrollment';

class Waitlist {
  static getWaitlistEnrollmentData() {
    try {
      const waitListEnrolledData = JSON.parse(
        sessionStorage.getItem(NA_WAITLIST_SESSION_TAG)
      );

      return waitListEnrolledData;
    } catch {
      return {};
    }
  }

  static setWaitlistEnrollmentData(data) {
    try {
      const waitListEnrolledData = JSON.stringify(data);

      sessionStorage.setItem(
        NA_WAITLIST_SESSION_TAG,
        waitListEnrolledData
      );
    } catch {
      return {};
    }
  }

  static clearWaitlistEnrollmentData() {
    sessionStorage.removeItem(NA_WAITLIST_SESSION_TAG);
  }
}

export default Waitlist;
