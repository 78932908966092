import React from 'react';

const CalendarPlus01 = ({ customClassNames, width, height, fill }) => {
  return (
    <svg
      className={`c-CalendarPlus01 ${customClassNames}`}
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 20 20">
      <path
        fill={fill}
        fillRule="evenodd"
        d="M6.668.833c.46 0 .833.373.833.834V2.5h5v-.833a.833.833 0 111.667 0V2.5c.396.003.743.011 1.043.036.469.038.9.12 1.304.326.627.32 1.137.83 1.456 1.457.206.405.288.835.327 1.304a13.901 13.901 0 01.036 1.086V14.368c0 .67 0 1.224-.036 1.675-.038.469-.12.9-.327 1.304a3.334 3.334 0 01-1.456 1.456c-.405.206-.835.289-1.304.327-.45.037-1.005.037-1.675.037h-7.07c-.67 0-1.224 0-1.675-.037-.468-.038-.899-.12-1.303-.327a3.333 3.333 0 01-1.457-1.456c-.206-.405-.288-.835-.326-1.304-.037-.45-.037-1.004-.037-1.675V7.299l.001-.59a.845.845 0 010-.097c.004-.373.012-.702.036-.988.038-.469.12-.9.326-1.304.32-.627.83-1.137 1.457-1.457.404-.206.835-.288 1.303-.326.301-.025.647-.033 1.044-.036v-.834c0-.46.373-.834.833-.834zm-.833 3.335c-.372.003-.663.01-.908.03-.365.03-.552.084-.682.15-.314.16-.569.415-.729.729-.066.13-.12.317-.15.682a5.748 5.748 0 00-.006.074h13.282a4.985 4.985 0 00-.005-.074c-.03-.365-.084-.552-.15-.682a1.667 1.667 0 00-.729-.729c-.13-.066-.317-.12-.683-.15-.244-.02-.536-.027-.907-.03a.833.833 0 01-1.667-.001h-5a.833.833 0 01-1.666.001zM16.668 7.5H3.335v6.833c0 .714 0 1.2.03 1.575.03.365.085.552.151.682.16.314.415.569.729.728.13.067.317.12.682.15.375.031.86.032 1.574.032h7c.714 0 1.2 0 1.574-.031.366-.03.553-.084.683-.15.314-.16.569-.415.728-.729.067-.13.12-.317.15-.682.031-.376.032-.86.032-1.575V7.5zm-6.667 1.667c.46 0 .834.373.834.833v1.667H12.5a.833.833 0 010 1.666h-1.666V15a.833.833 0 11-1.667 0v-1.667H7.501a.833.833 0 010-1.666h1.667V10c0-.46.373-.833.833-.833z"
        clipRule="evenodd"></path>
    </svg>
  );
};

CalendarPlus01.defaultProps = {
  fill: '#D742A0',
  width: '20',
  height: '20',
  customClassNames: ''
};

export default CalendarPlus01;
