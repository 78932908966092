import React from 'react';

const ZoomSessionLive = ({ customClassNames, fill, width, height }) => {
  const className = 'c-ZoomSessionLive';

  return (
    <svg
      className={`${className} ${customClassNames}`}
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 24 24">
      <path
        fill={fill}
        fillRule="evenodd"
        d="M20.5 7c.563 0 1.082-.186 1.5-.5l-.01 9.5c0 1.1-.89 2-1.99 2h3c.55 0 1 .45 1 1s-.45 1-1 1H1c-.55 0-1-.45-1-1s.45-1 1-1h3c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2h14.05a2.511 2.511 0 00.45 2h-6.17v4.333H14v-.166c0-.665 1.332-1 2-1 .668 0 2 .335 2 1v.166h2V7l-.001-.05c.162.033.33.05.501.05zm-.5 4.333h-7.67V16H14v-.5c0-.665 1.332-1 2-1 .668 0 2 .335 2 1v.5h1c.55 0 1-.45 1-1v-3.667zM11.33 16v-4.667H4V15c0 .55.45 1 1 1h1v-.5c0-.665 1.332-1 2-1 .668 0 2 .335 2 1v.5h1.33zM4 10.333h2v-.166c0-.665 1.332-1 2-1 .668 0 2 .335 2 1v.166h1.33V6H5c-.55 0-1 .45-1 1v3.333zm13-2.666a1 1 0 11-2 0 1 1 0 012 0zM8 14a1 1 0 100-2 1 1 0 000 2zm8 0a1 1 0 100-2 1 1 0 000 2zM8 8.667a1 1 0 100-2 1 1 0 000 2z"
        clipRule="evenodd"></path>
      <path
        fill="#DB3952"
        fillRule="evenodd"
        d="M22.5 4.5a2 2 0 11-4 0 2 2 0 014 0z"
        clipRule="evenodd"></path>
    </svg>
  );
};

ZoomSessionLive.defaultProps = {
  customClassNames: '',
  fill: '#222',
  width: '24',
  height: '24'
};

export default ZoomSessionLive;
