import React from 'react';

function FacebookGreyIcon({ customClassNames, height, width }) {
  return (
    <svg
      width={width}
      height={height}
      className={`${customClassNames}`}
      viewBox={'0 0 34 34'}
      fill="none">
      <path
        d="M33.6666 16.9997C33.6666 7.79995 26.211 0.333008 16.9999 0.333008C7.80019 0.333008 0.333252 7.79995 0.333252 16.9997C0.333252 25.3216 6.4322 32.2186 14.4007 33.4611V21.8218H10.16V16.9997H14.3893V13.3289C14.3893 9.15654 16.8745 6.84236 20.6821 6.84236C22.5061 6.84236 24.4099 7.17295 24.4099 7.17295V11.2769H22.3123C20.2375 11.2769 19.5991 12.5651 19.5991 13.8761V16.9997H24.2275L23.4865 21.8218H19.5991V33.4725C27.579 32.2186 33.6666 25.3216 33.6666 16.9997Z"
        fill="black"
        fillOpacity="0.25"
      />
    </svg>
  );
}

FacebookGreyIcon.defaultProps = {
  customClassNames: '',
  height: 24,
  width: 24
};

export default FacebookGreyIcon;
