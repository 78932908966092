import axios from 'axios';
import config from '@/utility/config';
import { getAxiosError } from '@/services/helpers';
import adminProtectedAxiosRequest from '@/services/helpers/adminProtectedAxiosRequest';
const { communityApiBasePath, loginApiRoute } = config;

export const userLoginService = async (
  email,
  password,
  // eslint-disable-next-line no-unused-vars
  isCommunityAdmin = true,
  communityMandatory = false
) => {
  try {
    const path = loginApiRoute + '/api/v1/log-in';
    const res = await axios.post(
      path,
      {
        email,
        password,
        communityMandatory
        // ...(isCommunityAdmin ? { isCommunityAdmin: true } : {})
      },
      { withCredentials: true }
    );
    const data = res?.data;
    if (data.error) {
      throw new Error(data.info || data.error);
    }

    if (data?.status === 'error') {
      throw new Error(data.message || 'Something went wrong');
    }

    return data;
  } catch (e) {
    return {
      error:
        e?.response?.data?.message || 'an error occured, please try again'
    };
  }
};

/**
 * Payload should send either authToken or refreshToken
 * @param {*} payload | Object
 * @param {*} payload.authToken | String | For Social Logins
 * @param {*} payload.refreshToken | String
 * @returns Promise
 */
export const getTokensService = async (payload) => {
  try {
    const path = `${loginApiRoute}/api/v1/get-token`;
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify(payload)
    };
    const res = await fetch(path, options);
    const data = await res.json();

    if (data.error) {
      throw new Error(data.info || data.error);
    }

    if (data?.status === 'error') {
      throw new Error(data.message || 'Something went wrong');
    }

    return { data, error: '' };
  } catch (e) {
    return { error: e.message };
  }
};

export const getAdminProfile = async ({ accessToken }) => {
  try {
    //we don't need to make an unauthorized request unless `accessToken` is provided
    if (!accessToken) {
      return {
        error: 'No access token provided'
      };
    }

    return await adminProtectedAxiosRequest.get(
      `${communityApiBasePath}/api/v1/user-profile`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
  } catch (e) {
    return getAxiosError(e);
  }
};
