import React from 'react';

const FileUploadIcon = ({ width, height, fillColor }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill={fillColor}>
      <path
        d="M14.9997 12.5002V15.0002H4.99967V12.5002H3.33301V15.0002C3.33301 15.9168 4.08301 16.6668 4.99967 16.6668H14.9997C15.9163 16.6668 16.6663 15.9168 16.6663 15.0002V12.5002H14.9997ZM5.83301 7.50016L7.00801 8.67516L9.16634 6.52516V13.3335H10.833V6.52516L12.9913 8.67516L14.1663 7.50016L9.99967 3.3335L5.83301 7.50016Z"
        fill="#75777A"
      />
    </svg>
  );
};

FileUploadIcon.defaultProps = {
  width: 20,
  height: 20,
  fillColor: '#75777A'
};
export default FileUploadIcon;
