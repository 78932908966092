import Cookies from 'js-cookie';
import { getPrimaryDomain } from '@/utility/stringHelper';

//DEPRECATED - DO NOT USE
const ACCESS_TOKEN_KEY = 'accessTokenNA';
const REFRESH_TOKEN_KEY = 'refreshTokenNA';
const USER_LOGGED_IN_KEY_SESSION = 'userLoggedIn';

class Auth {
  /**
   * Save the accessToken in cookies
   *
   * @param {string} accessToken
   * @param {string} refreshToken
   */
  static authenticateUser(accessToken, refreshToken) {
    Cookies.set(ACCESS_TOKEN_KEY, accessToken, {
      path: '/',
      domain: getPrimaryDomain()
    });
    Cookies.set(REFRESH_TOKEN_KEY, refreshToken, {
      path: '/',
      domain: getPrimaryDomain()
    });
  }

  /**
   * De-authenticate a user.
   * Remove a token from Local Storage.
   *
   */
  static deauthenticateUser() {
    sessionStorage?.removeItem(USER_LOGGED_IN_KEY_SESSION);
    Cookies.remove(ACCESS_TOKEN_KEY, {
      path: '/',
      domain: getPrimaryDomain()
    });
    Cookies.remove(REFRESH_TOKEN_KEY, {
      path: '/',
      domain: getPrimaryDomain()
    });
  }

  /**
   * return user token
   *
   * @returns {string}
   */
  static getUserToken() {
    return Cookies.get(ACCESS_TOKEN_KEY);
  }

  /**
   * return user token
   *
   * @returns {string}
   */
  static getRefreshToken() {
    return Cookies.get(REFRESH_TOKEN_KEY);
  }

  /**
   * return user token
   *
   * @returns {string}
   */
  static getUserTokenFromCookies() {
    return Cookies.get(ACCESS_TOKEN_KEY);
  }

  /**
   * return user token
   *
   * @returns {string}
   */
  static getRefreshTokenFromCookies() {
    return Cookies.get(REFRESH_TOKEN_KEY);
  }

  static isAuthenticated() {
    return !!this.getUserToken();
  }

  //use this for tracking/analytics purposes only
  static setUserLoggedInForSession() {
    sessionStorage?.setItem(USER_LOGGED_IN_KEY_SESSION, 'logged_in');
  }

  //use this for tracking/analytics purposes only
  static getUserLoggedInForSession() {
    const isUserLoggedIn = sessionStorage?.getItem(
      USER_LOGGED_IN_KEY_SESSION
    );

    return !!isUserLoggedIn;
  }
}

export default Auth;
