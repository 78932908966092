import React from 'react';

const NPLHamburgerMD = () => {
  return (
    <svg
      className="c-NPLHamburgerMD"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24">
      <path
        stroke="#14181F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M5 17h14M5 12h14M5 7h14"></path>
    </svg>
  );
};

export default NPLHamburgerMD;
