import React from 'react';

const className = 'c-IconV2-MinusCircleFill';

const MinusCircleFill = ({ customClassNames, ...rest }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={`${className} ${customClassNames}`}
      {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM15.25 10.9545C15.6642 10.9545 16 10.6187 16 10.2045C16 9.79032 15.6642 9.45453 15.25 9.45453L4.75 9.45453C4.33579 9.45453 4 9.79032 4 10.2045C4 10.6187 4.33579 10.9545 4.75 10.9545L15.25 10.9545Z"
        fill="#191C1E"
      />
    </svg>
  );
};

export default MinusCircleFill;
