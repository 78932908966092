import React from 'react';
const className = 'c-Download01Icon';

function Download01Icon({ width, height, fill, customClassNames }) {
  return (
    <svg
      className={`${className} ${customClassNames}`}
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 24 24">
      <path
        fill={fill}
        fillRule="evenodd"
        d="M12 2a1 1 0 011 1v9.586l3.293-3.293a1 1 0 111.414 1.414l-5 5a1 1 0 01-1.414 0l-5-5a1 1 0 011.414-1.414L11 12.586V3a1 1 0 011-1zM3 14a1 1 0 011 1v1.2c0 .857 0 1.439.038 1.889.035.438.1.663.18.819a2 2 0 00.874.874c.156.08.38.145.819.18C6.361 20 6.943 20 7.8 20h8.4c.857 0 1.439 0 1.889-.038.438-.035.663-.1.819-.18a2 2 0 00.874-.874c.08-.156.145-.38.18-.819.037-.45.038-1.032.038-1.889V15a1 1 0 112 0v1.241c0 .805 0 1.47-.044 2.01-.046.563-.145 1.08-.392 1.565a4 4 0 01-1.748 1.748c-.485.247-1.002.346-1.564.392-.541.044-1.206.044-2.01.044H7.758c-.805 0-1.47 0-2.01-.044-.563-.046-1.08-.145-1.565-.392a4 4 0 01-1.748-1.748c-.247-.485-.346-1.002-.392-1.564C2 17.71 2 17.046 2 16.242V15a1 1 0 011-1z"
        clipRule="evenodd"></path>
    </svg>
  );
}

Download01Icon.defaultProps = {
  width: 24,
  height: 24,
  fill: '#EBB800',
  customClassNames: ''
};

export default Download01Icon;
