const config = {
  // gaId: process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID,
  apiPaymentBasePath: process.env.NEXT_PUBLIC_BACKEND_PAYMENT_URL,
  learningPortalDashboard:
    process.env.NEXT_PUBLIC_LEARNING_PORTAL_DASHBOARD ||
    'https://learn.nasacademy.com',
  apiNewPaymentBasePath: process.env.NEXT_PUBLIC_NEW_BACKEND_PAYMENT_URL,
  apiStaticDataPath: process.env.NEXT_PUBLIC_BACKEND_STATIC_DATA_SEVER,
  apiStaticDataAuthToken:
    process.env.NEXT_PUBLIC_BACKEND_STATIC_DATA_AUTH_TOKEN,
  learnPortalBackend: process.env.NEXT_PUBLIC_BACKEND_PROTECTED_ROUTE,
  communityApiBasePath: process.env.NEXT_PUBLIC_COMMUNITY_PORTAL_BACKEND,
  copsBackendPath: process.env.NEXT_PUBLIC_COPS_BACKEND,
  stripeApiKey: process.env.NEXT_PUBLIC_STRIPE_KEY,
  backendPaymentAuthKey: process.env.NEXT_PUBLIC_BACKEND_PAYMENT_AUTH,
  memberHideThreshold: process.env.NEXT_PUBLIC_MEMBER_HIDE_THRESHOLD,
  appleClientId: process.env.NEXT_PUBLIC_APPLE_CLIENT_ID,
  appleRedirectLink: process.env.NEXT_PUBLIC_APPLE_REDIRECT_LINK,
  gtmId: process.env.NEXT_PUBLIC_GTM,
  imagesBasePath: process.env.NEXT_PUBLIC_CLOUDFRONT_URL,
  // videosBasePath: process.env.NEXT_PUBLIC_VIDEO_CLOUDFRONT_URL,
  // rapydToolkitSrc: process.env.NEXT_PUBLIC_RAPYD_TOOLKIT_SRC,
  paymentGOExpId: process.env.NEXT_PUBLIC_PAYMENT_GO_EXPERIMENT_ID,
  memberPortalPath: process.env.NEXT_PUBLIC_LEARNER_PORTAL_PATH,
  appleAppStoreLink: process.env.NEXT_PUBLIC_APPLE_APP_STORE,
  googlePlayStoreLink: process.env.NEXT_PUBLIC_GOOGLE_PLAY_STORE,
  communityPortalPath:
    process.env.NEXT_PUBLIC_COMMUNITY_PORTAL_DASHBOARD ||
    'https://learn.nasacademy.com/communities',
  googleClientId: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID,
  fbAppId: process.env.NEXT_PUBLIC_FB_APP_ID,
  siteBaseUrl: process.env.NEXT_PUBLIC_SITE_BASE_URL,
  referralBaseUrl: process.env.NEXT_PUBLIC_REFERRAL_BASE_URL,
  urlShortenerApi: process.env.NEXT_PUBLIC_URL_SHORTENER_API,

  algoliaAppId: process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
  algoliaApiKey: process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY,
  algoliaSearchIndex: process.env.NEXT_PUBLIC_SEARCH_INDEX_NAME,

  configCatApiKey: process.env.NEXT_PUBLIC_CONFIG_CAT_API_KEY,
  // homePageFlagName: process.env.NEXT_PUBLIC_HOMEPAGE_COURSE_FLAG_NAME,
  contentfulAccessToken: process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN,
  contentfulSpaceId: process.env.NEXT_PUBLIC_CONTENTFUL_SPACE,
  paypalClientId: process.env.NEXT_PUBLIC_PAYPAL_CLIENT_ID,
  // isDevEnvironment: process.env.NODE_ENV === 'development',
  // isProdEnvironment: process.env.VERCEL_ENV === 'production',
  isDevEnvironment: process.env.NEXT_PUBLIC_ENV === 'development',
  isProdEnvironment: process.env.NEXT_PUBLIC_ENV === 'production',
  recapchaSiteToken: process.env.NEXT_PUBLIC_RECAPCHA_SITE_TOKEN,
  contentfulRevalidateSec:
    parseInt(process.env.NEXT_PUBLIC_CONTENTFUL_REVALIDATE_SEC, 10) || 100,
  domainRegion: process.env.NEXT_PUBLIC_DOMAIN_REGION,
  optimizeId: process.env.NEXT_PUBLIC_OPTIMIZE_ID,
  connectDiscordLink: process.env.NEXT_PUBLIC_CONNECT_DISCORD_CHAT_URL,
  connectTelegramLink: process.env.NEXT_PUBLIC_CONNECT_TELEGRAM_CHAT_URL,
  telegramApiId: process.env.NEXT_PUBLIC_TELEGRAM_API_ID,
  telegramApiHash: process.env.NEXT_PUBLIC_TELEGRAM_API_HASH,
  telegramBotUserName: process.env.NEXT_PUBLIC_TELEGRAM_BOT_USER_NAME,
  telegramBotUserId: process.env.NEXT_PUBLIC_TELEGRAM_BOT_USER_ID,
  telegramBackEndApiKey: process.env.NEXT_PUBLIC_TELEGRAM_API_KEY,
  discordClientId: process.env.NEXT_PUBLIC_DISCORD_CLIENT_ID,
  discordBotPermissionsNumber:
    process.env.NEXT_PUBLIC_DISCORD_BOT_PERMISSIONS_NUMBER,
  socialLoginsOnSignup: process.env.NEXT_PUBLIC_SOCIAL_LOGINS_ON_SIGNUP,
  socialLoginsOnLogin: process.env.NEXT_PUBLIC_SOCIAL_LOGINS_ON_LOGIN,
  whatsappBackEndApiKey: process.env.NEXT_PUBLIC_WHATSAPP_API_KEY,
  whatsappBackendPath: process.env.NEXT_PUBLIC_WHATSAPP_BACKEND,
  intercomAppId: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
  intercomApiBase: process.env.NEXT_PUBLIC_INTERCOM_API_BASE,
  loginApiRoute: process.env.NEXT_PUBLIC_LOGIN_ROUTE,
  showCsvImportOption: process.env.NEXT_PUBLIC_ALLOW_CSV_IMPORT === '1',
  whatsappDefaultTemplateId:
    process.env.NEXT_PUBLIC_WHATSAPP_DEFAULT_TEMPLATE_ID,
  legendsCommunityId: process.env.NEXT_PUBLIC_LEGENDS_COMMUNITY_ID,
  xenditPOCPaymentPath: process.env.NEXT_PUBLIC_TEST_XENDIT_URL,
  disableCryptoPaymentOption:
    process.env.NEXT_PUBLIC_DISABLE_CRYPTO_PAYMENT_OPTION === '1',
  whatsappGodModeBePath:
    process.env.NEXT_PUBLIC_WHATSAPP_GOD_BE || 'http://localhost:3001',
  whatsappBotBePath:
    process.env.NEXT_PUBLIC_WHATSAPP_BOT_BE || 'http://localhost:3001',
  payoutThreshold: process.env.NEXT_PUBLIC_PAYOUT_THRESHOLD,
  whatsappBotNumber: process.env.NEXT_PUBLIC_WHATSAPP_BOT_NUMBER,
  fallbackWhatsappBotNumber:
    process.env.NEXT_PUBLIC_FALLBACK_WHATSAPP_BOT_NUMBER
};

export default config;
