import config from '@/utility/config';

const cdnBasePath = config.imagesBasePath;
const portalStoragePath = `${cdnBasePath}/nasIO/portal`;

export const announcementsWaitingStateImg = {
  alt: 'Announcements Image',
  mobileImgData: {
    src: `${portalStoragePath}/png/waiting-state.png`,
    meta: {
      width: 350,
      height: 350
    }
  }
};

export const NEW_MESSGAE_PROGRESS_STEPS = [
  'Recipient',
  'Compose',
  'Preview'
];

export const MOCK_NEW_MESSAGE_BUCKETS = [
  {
    id: 'members',
    title: 'Members',
    description: 'Send a message to your active community',
    numberOfRecipients: 185
  },
  {
    id: 'interested',
    title: 'Interested',
    description: 'Reach out to users that dropped off from signup',
    numberOfRecipients: 50
  },
  {
    id: 'unsubscribed',
    title: 'Unsubscribed',
    description: 'Speak to the people that unsubscribed',
    numberOfRecipients: 18
  }
];

export const SELECT_MEMBERS_BUCKET_CARD_DATA = {
  _id: 'select-members',
  bucket: 'Select Member(s)',
  description: 'Speak to a particular member, choose from list'
};

export const DEFAULT_PAGE_FILTER = {
  pageNo: 1,
  pageSize: 100
};

export const trainerProfilePlaceholder = {
  firstName: 'John',
  lastName: 'Doe',
  profileImageProps: {
    alt: 'profile image',
    desktopImgProps: {
      src: 'https://d2yjtdaqamc55g.cloudfront.net/randomProfileImage5.jpg',
      width: 40,
      height: 40
    },
    mobileImgProps: {
      src: 'https://d2yjtdaqamc55g.cloudfront.net/randomProfileImage5.jpg',
      width: 40,
      height: 40
    }
  }
};

export const helpCenterCardProps = {
  title: 'Help center',
  description: 'Discover the best community practices',
  iconName: 'npl-light-bulb',
  iconColorClass: 'stroke-npl-yellow-light-11',
  iconBackgroundClass: 'bg-npl-yellow-light-5',
  link: 'https://help.nas.io'
};

export const legendsCommunityCardProps = {
  title: 'Learn from Community Builders',
  description: 'Join our community of experts',
  iconName: 'nas-io',
  iconColorClass: '',
  link: `${config.learningPortalDashboard}?activeCommunityId=${config.legendsCommunityId}`,
  iconBackgroundClass: 'bg-npl-base-black'
};

//TODO: Update the values following API documentation
export const timeRangeOptions = [
  { label: 'Last 24 hours', value: '1' },
  { label: 'Last 7 days', value: '7', default: true },
  { label: 'Last 30 days', value: '30' },
  { label: 'Last 12 months', value: '365' },
  { label: 'All', value: 'all' }
];

export const whatsappMoreActionImageProps = {
  eventCreation: {
    alt: 'Event creation banner',
    desktopImgProps: {
      src: 'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/new_home/png/whatsapp-event-creation-banner.png',
      width: 280,
      height: 186,
      layout: 'fixed'
    },
    mobileImgProps: {
      src: 'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/new_home/png/whatsapp-event-creation-banner.png',
      width: 280,
      height: 186,
      layout: 'fixed'
    }
  },
  contentCreation: {
    alt: 'Content creation banner',
    desktopImgProps: {
      src: 'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/new_home/png/whatsapp-content-creation-banner.png',
      width: 280,
      height: 186,
      layout: 'fixed'
    },
    mobileImgProps: {
      src: 'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/new_home/png/whatsapp-content-creation-banner.png',
      width: 280,
      height: 186,
      layout: 'fixed'
    }
  },
  walkthroughVideo: {
    alt: 'Walkthrough video',
    desktopImgProps: {
      src: 'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/new_home/png/whatsapp-walkthrough-video-banner.png',
      width: 280,
      height: 186,
      layout: 'fixed'
    },
    mobileImgProps: {
      src: 'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/new_home/png/whatsapp-walkthrough-video-banner.png',
      width: 280,
      height: 186,
      layout: 'fixed'
    }
  }
};

export const WHATSAPP_WALKTHROUGH_VIDEO_URL = `${portalStoragePath}/video/whatsapp-walkthrough.mp4`;
export const WHATSAPP_ADD_BOT_TO_GROUP_TUTORIAL_LINK = `${portalStoragePath}/video/whatsapp-add-bot-tutorial.mp4`;

export const HOME_PAGE_VARIANT = {
  FEED: 'feed'
};

export const DISCOUNT_ANNOUNCEMENT_HOME_PAGE_MODAL_PROPS = {
  title: 'Discounts have moved to the Money Tab!',
  imageProps: {
    alt: 'home-page',
    mobileImgProps: {
      src: 'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/portal/discount/money_tab.png',
      width: 500,
      height: 250
    }
  }
};

export const AI_SUMMARY_STATES = {
  LOADING: 'LOADING',
  NO_MESSAGE: 'NO_MESSAGE',
  INSUFFICIENT_MSG: 'INSUFFICIENT_MSG',
  PAST_SUMMARY_EXISTS: 'PAST_SUMMARY_EXISTS',
  AVAILABLE: 'AVAILABLE'
};

export const AI_SUMMARY_CONTENT_TYPES = {
  PARAGRAPH: 'paragraph',
  TOPIC: 'topic'
};
