import {
  clearTrackUserId,
  initializeTrackUserId,
  storeTrackingInformation,
  trackIdentityImpact,
  trackTikTokIdentity
} from '@/utility/analytics';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import {
  getTokensService,
  getUserEnrollmentData,
  getUserProfile,
  userLoginService
} from '@/services/authService';

import Auth from '../../modules/Auth';
import config from '@/utility/config';
import { signUp } from '@/services/communitiesService';
import { useRouter } from 'next/router';
import { useUserSessionContext } from '../UserSessionContext';
import AdminAuth from '@/modules/AdminAuth';

export const AuthContext = createContext();
const { appleRedirectLink } = config;

export const AuthContextProvider = ({ children }) => {
  const [accessToken, setAccessToken] = useState('');
  const [refreshToken, setRefreshToken] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [loginError, setLoginError] = useState('');
  const [socialLoginError, setSocialLoginError] = useState('');
  const [user, setUser] = useState(null);
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [userEnrollmentData, setUserEnrollmentData] = useState(null);
  const [isUserEnrollmentDataLoading, setIsUserEnrollmentDataLoading] =
    useState(false);

  const { clearWaitinglistSessionData } = useUserSessionContext();

  const router = useRouter();
  // Standard email, password Login
  const login = async (email, password, callback) => {
    setIsLoading(true);
    setLoginError('');
    const res = await userLoginService(email, password);

    if (res.error) {
      setLoginError(res.error);
      setIsLoading(false);
      callback && callback(res);
      return;
    }

    const { token, refresh_token } = res;

    setRefreshToken(refresh_token);
    AdminAuth.authenticateMemberUser(token, refresh_token);
    AdminAuth.authenticateUser(token, refresh_token);
    setAccessToken(token);
    clearWaitinglistSessionData();

    setIsLoading(false);

    callback && callback(res);
  };

  const signup = async (formData) => {
    setIsLoading(true);
    const res = await signUp(formData);
    const { token, refreshToken } = res;
    setRefreshToken(refreshToken);
    setAccessToken(token);
    clearWaitinglistSessionData();
    setIsLoading(false);
  };

  const handleSocialAuth = (response, provider) => {
    let authToken = '';
    let code = '';
    let appleUserData = null;
    let payload = {};

    switch (provider) {
      case 'facebook':
        const fbToken = response?.accessToken;
        authToken = fbToken;
        payload = { authToken, provider };
        break;
      case 'google':
        authToken = response?.code;
        payload = { code: authToken, provider };
        break;
      case 'apple':
        code = response?.authorization?.code;
        authToken = response?.authorization?.id_token;
        appleUserData = response?.user;
        payload = {
          code,
          authToken,
          provider,
          appleUserData,
          appleRedirectUrl: appleRedirectLink
        };
        break;
      default:
    }

    if (authToken) getTokens(payload);
  };

  /**
   * Use token received from socal logins (authToken) or refresh token and pass to backend to get access token and refresh token
   * Payload should send either authToken or refreshToken
   * @param {*} tokenPayload | Object
   * @param {*} tokenPayload.authToken | String | For Social Logins
   * @param {*} tokenPayload.refreshToken | String
   */
  const getTokens = async (tokenPayload) => {
    setIsLoading(true);
    setSocialLoginError('');
    const { data, error } = await getTokensService(tokenPayload);
    setIsLoading(false);

    if (error) {
      setSocialLoginError(error || 'Error Logging in. Please try again.');
      return;
    }

    const { token, refresh_token } = data;
    if (!token || !refresh_token) return;

    setUser(null);
    setRefreshToken(refresh_token);
    setAccessToken(token);
  };

  const loginWithToken = (accessToken, refreshToken) => {
    Auth.authenticateUser(accessToken, refreshToken);
    getUserData();

    setRefreshToken(refreshToken);
    setAccessToken(accessToken);
  };

  const logout = () => {
    setAccessToken(null);
    setRefreshToken(null);
    setUser(null);
    setUserEnrollmentData({});
    clearWaitinglistSessionData();
    clearTrackUserId();

    Auth.deauthenticateUser();
  };

  const getUserData = async () => {
    const { data, error } = await getUserProfile();
    if (error || !data) return;
    setUser(data);
    initializeTrackUserId(data?.user_id);

    //keeping track of session login so that this event does not get called more than once
    if (!Auth.getUserLoggedInForSession()) {
      trackTikTokIdentity({
        email: data?.email,
        phone: data?.learner?.phoneNumber
      });
      Auth.setUserLoggedInForSession();
    }
  };

  const toggleLoginModal = () => setOpenLoginModal((open) => !open);

  // Getting all user course enrollment and waitlist data when the user logs in or the user object updates
  const getUserEnrollments = useCallback(async () => {
    if (user) {
      setIsUserEnrollmentDataLoading(true);
      const { data, error } = await getUserEnrollmentData();

      if (error) {
        setIsUserEnrollmentDataLoading(false);
        return;
      }

      setUserEnrollmentData(data);
      setIsUserEnrollmentDataLoading(false);
    }
  }, [user]);

  useEffect(() => {
    getUserEnrollments();
  }, [getUserEnrollments]);

  // If Access Token & Refresh Token are updated, set it in localStorage AND get user data using the access token. If access token and refresh token are revoked, reset user to null
  useEffect(() => {
    if (accessToken && refreshToken) {
      if (!user) {
        Auth.authenticateUser(accessToken, refreshToken);
        getUserData();
      }
    }
  }, [accessToken, refreshToken, user]);

  useEffect(() => {
    if (user) setOpenLoginModal(false);
  }, [user]);

  useEffect(() => {
    //on development env we cannot persist token in cookies due to cross-origin policy so we have to use localStorage
    if (config.isDevEnvironment) {
      // const initialAccessToken = Auth.getUserToken() || '';
      // const initialRefreshToken = Auth.getRefreshToken() || ''
      const initialAccessToken = Auth.getUserToken() || '';
      const initialRefreshToken = Auth.getRefreshToken() || '';
      setAccessToken(initialAccessToken);
      setRefreshToken(initialRefreshToken);
    } else {
      //check cookie for the access token and refresh token
      const initialAccessToken = Auth.getUserTokenFromCookies() || '';
      const initialRefreshToken = Auth.getRefreshTokenFromCookies() || '';

      setAccessToken(initialAccessToken);
      setRefreshToken(initialRefreshToken);
    }
  }, [user]);

  useEffect(() => {
    const intervalIdIreCheck = setInterval(() => {
      if (window.ire) {
        trackIdentityImpact(user);
        clearInterval(intervalIdIreCheck);
      }
    }, 2000);

    return () => clearInterval(intervalIdIreCheck);
  }, [user]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      storeTrackingInformation(router?.query);
    }
  }, [router?.query]);

  const value = {
    login,
    loginWithToken,
    handleSocialAuth,
    logout,
    signup,
    loginError,
    setLoginError,
    socialLoginError,
    resetLoginError: () => setLoginError(''),

    accessToken,
    refreshToken,
    isLoggedIn: !!user,
    user,
    getUserData,
    isLoading: isLoading || isUserEnrollmentDataLoading,

    toggleLoginModal,
    openLoginModal,

    userEnrollmentData
  };

  return (
    <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
