import React from 'react';

const FaceHappy = () => {
  return (
    <svg width="40" height="40" fill="none" viewBox="0 0 40 40">
      <g>
        <path
          fill="#30A66D"
          fillRule="evenodd"
          d="M20 5C11.716 5 5 11.716 5 20c0 8.284 6.716 15 15 15 8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15zM1.667 20C1.667 9.875 9.875 1.667 20 1.667c10.125 0 18.333 8.208 18.333 18.333 0 10.125-8.208 18.333-18.333 18.333C9.875 38.333 1.667 30.125 1.667 20zM12.5 15a2.5 2.5 0 115 0 2.5 2.5 0 01-5 0zm10 0a2.5 2.5 0 115 0 2.5 2.5 0 01-5 0zm-11.667 8.333c0-.92.746-1.666 1.667-1.666h15c.92 0 1.667.746 1.667 1.666 0 2.041-1.193 3.931-2.797 5.247A10.093 10.093 0 0120 30.833c-2.488 0-4.733-.91-6.37-2.253-1.604-1.316-2.797-3.206-2.797-5.247zM14.792 25c.24.337.556.678.952 1.003 1.072.88 2.577 1.497 4.256 1.497 1.679 0 3.184-.617 4.256-1.497A5.28 5.28 0 0025.208 25H14.792z"
          clipRule="evenodd"></path>
      </g>
    </svg>
  );
};

export default FaceHappy;
