import React from 'react';

const CurrencyDollarCircle = ({ customClassNames }) => {
  return (
    <svg
      className={`c-CurrencyDollarCircle ${customClassNames}`}
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20">
      <g clipPath="url(#CurrencyDollarCircle_clip0_617_73093)">
        <path
          fill="#EBB800"
          fillRule="evenodd"
          d="M9.999 2.5a7.5 7.5 0 100 15 7.5 7.5 0 000-15zM.832 10a9.167 9.167 0 1118.333 0A9.167 9.167 0 01.832 10zm9.167-6.25c.46 0 .833.373.833.833V5h.139a2.778 2.778 0 012.778 2.778.833.833 0 11-1.667 0c0-.614-.497-1.111-1.111-1.111H9.165a1.25 1.25 0 000 2.5h1.667a2.917 2.917 0 110 5.833v.417a.833.833 0 01-1.667 0V15h-.139a2.778 2.778 0 01-2.777-2.778.833.833 0 111.666 0c0 .614.498 1.111 1.111 1.111h1.806a1.25 1.25 0 000-2.5H9.165a2.917 2.917 0 010-5.833v-.417c0-.46.373-.833.834-.833z"
          clipRule="evenodd"></path>
      </g>
      <defs>
        <clipPath id="CurrencyDollarCircle_clip0_617_73093">
          <path fill="#fff" d="M0 0H20V20H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

CurrencyDollarCircle.defaultProps = {
  customClassNames: ''
};

export default CurrencyDollarCircle;
