import React from 'react';

const PlayOverlayVideo = ({ customClassNames }) => {
  return (
    <svg
      width="41"
      height="41"
      viewBox="0 0 41 41"
      fill="none"
      className={`${customClassNames}`}>
      <rect
        x="3.83398"
        y="3.44189"
        width="33.3333"
        height="33.3333"
        rx="16.6667"
        fill="#FBC91B"
      />
      <rect
        x="3.83398"
        y="3.44189"
        width="33.3333"
        height="33.3333"
        rx="16.6667"
        fill="url(#paint0_linear_1101_262757)"
      />
      <path
        d="M16.3887 14.3299C16.3887 13.8024 16.9722 13.4839 17.4158 13.7691L26.4052 19.548C26.8134 19.8104 26.8134 20.4071 26.4052 20.6695L17.4158 26.4484C16.9722 26.7336 16.3887 26.4151 16.3887 25.8876V14.3299Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1101_262757"
          x1="39.8076"
          y1="36.7752"
          x2="1.00819"
          y2="29.1092"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FBC91B" />
          <stop offset="1" stopColor="#FF834E" />
        </linearGradient>
      </defs>
    </svg>
  );
};

PlayOverlayVideo.defaultprops = {
  customClassNames: ''
};

export default PlayOverlayVideo;
