import React from 'react';

const className = 'c-IconV2-GiftCard';

const GiftCard = ({ customClassNames }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={`${className} ${customClassNames}`}>
      <path
        d="M20 6H17.82C17.93 5.69 18 5.35 18 5C18 3.34 16.66 2 15 2C13.95 2 13.04 2.54 12.5 3.35L12 4.02L11.5 3.34C10.96 2.54 10.05 2 9 2C7.34 2 6 3.34 6 5C6 5.35 6.07 5.69 6.18 6H4C2.89 6 2.01 6.89 2.01 8L2 19C2 20.11 2.89 21 4 21H20C21.11 21 22 20.11 22 19V8C22 6.89 21.11 6 20 6ZM15 4C15.55 4 16 4.45 16 5C16 5.55 15.55 6 15 6C14.45 6 14 5.55 14 5C14 4.45 14.45 4 15 4ZM9 4C9.55 4 10 4.45 10 5C10 5.55 9.55 6 9 6C8.45 6 8 5.55 8 5C8 4.45 8.45 4 9 4ZM20 19H4V17H20V19ZM20 14H4V9C4 8.45 4.45 8 5 8H9.08L7.6 10.02C7.27 10.47 7.37 11.1 7.82 11.42C8.26 11.74 8.89 11.64 9.21 11.2L12 7.4L14.79 11.2C15.11 11.64 15.74 11.74 16.18 11.42C16.63 11.1 16.73 10.47 16.4 10.02L14.92 8H19C19.55 8 20 8.45 20 9V14Z"
        fill="#222222"
      />
    </svg>
  );
};

GiftCard.defaultProps = {
  customClassNames: ''
};

export default GiftCard;
