import React from 'react';

const Link = ({ customClassNames, fill }) => {
  return (
    <svg
      className={`c-Link ${customClassNames}`}
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24">
      <path
        fill={fill}
        stroke={fill}
        d="M7 8.4A3.603 3.603 0 003.4 12c0 1.986 1.614 3.6 3.6 3.6h3.5v.9H7A4.502 4.502 0 012.5 12c0-2.484 2.016-4.5 4.5-4.5h3.5v.9H7zm8.5 3.1v1h-7v-1h7zm1.5 5h-3.5v-.9H17c1.986 0 3.6-1.614 3.6-3.6S18.986 8.4 17 8.4h-3.5v-.9H17c2.484 0 4.5 2.016 4.5 4.5s-2.016 4.5-4.5 4.5z"></path>
    </svg>
  );
};

Link.defaultProps = {
  fill: '#75777A',
  customClassNames: ''
};

export default Link;
