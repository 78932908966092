import React from 'react';

const CalendarNew = () => {
  return (
    <svg width="19" height="18" fill="none" viewBox="0 0 19 18">
      <path
        fill="#BD4B00"
        fillRule="evenodd"
        d="M6.5.75a.75.75 0 01.75.75v.75h4.5V1.5a.75.75 0 011.5 0v.751c.357.003.668.01.939.032.421.035.809.109 1.173.294a3 3 0 011.311 1.311c.186.364.26.752.294 1.173.033.406.033.904.033 1.508v6.362c0 .604 0 1.102-.033 1.508-.035.421-.108.809-.294 1.173a3 3 0 01-1.311 1.311c-.364.186-.752.26-1.173.294-.406.033-.904.033-1.508.033H6.319c-.604 0-1.102 0-1.508-.033-.421-.035-.809-.108-1.173-.294a3 3 0 01-1.311-1.311c-.185-.364-.26-.752-.294-1.173C2 14.033 2 13.535 2 12.93V6.569c0-.604 0-1.102.033-1.508.035-.421.109-.809.294-1.173a3 3 0 011.311-1.311c.364-.185.752-.26 1.173-.294.27-.022.582-.03.939-.032V1.5A.75.75 0 016.5.75zm-.75 3.001c-.334.003-.597.01-.817.027-.329.027-.497.076-.614.135a1.5 1.5 0 00-.656.656c-.06.117-.108.285-.135.614-.027.338-.028.775-.028 1.417v.15h12V6.6c0-.642 0-1.08-.028-1.417-.027-.329-.076-.497-.136-.614a1.5 1.5 0 00-.655-.656c-.117-.06-.285-.108-.614-.135-.22-.018-.483-.024-.817-.027V4.5a.75.75 0 01-1.5 0v-.75h-4.5v.75a.75.75 0 01-1.5 0v-.749zM15.5 8.25h-12v4.65c0 .642 0 1.08.028 1.417.027.329.076.497.135.614a1.5 1.5 0 00.656.655c.117.06.285.109.614.136.338.027.775.028 1.417.028h6.3c.642 0 1.08 0 1.417-.028.329-.027.497-.076.614-.136a1.5 1.5 0 00.655-.655c.06-.117.109-.285.136-.614.027-.338.028-.775.028-1.417V8.25z"
        clipRule="evenodd"></path>
    </svg>
  );
};

export default CalendarNew;
