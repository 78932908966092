import React from 'react';

const PlayButton = ({ customClassNames }) => {
  return (
    <svg
      className={`c-PlayButton ${customClassNames}`}
      width="120"
      height="120"
      fill="none"
      viewBox="0 0 120 120">
      <rect
        width="120"
        height="119.008"
        y="0.331"
        fill="#000"
        fillOpacity="0.5"
        rx="59.504"></rect>
      <path
        fill="#fff"
        d="M45.201 39.206c0-1.883 2.1-3.02 3.698-2.002l32.362 20.632a2.37 2.37 0 010 4.004L48.899 82.472c-1.597 1.018-3.698-.119-3.698-2.002V39.206z"></path>
    </svg>
  );
};

PlayButton.defaultProps = {
  customClassNames: ''
};

export default PlayButton;
