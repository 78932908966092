import React from 'react';

const FacebookColoured = () => {
  return (
    <svg width="20" height="20" fill="none" viewBox="0 0 20 20">
      <path
        fill="url(#paint0_linear_434_53288)"
        d="M20 10c0 5.523-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0s10 4.477 10 10z"></path>
      <path
        fill="#fff"
        d="M13.62 13.006l.433-2.844H11.35V8.317c0-.778.376-1.537 1.587-1.537h1.229V4.359s-1.115-.192-2.18-.192c-2.227 0-3.68 1.362-3.68 3.827v2.168H5.833v2.844h2.474v6.875a9.703 9.703 0 003.044 0v-6.875h2.27z"></path>
      <defs>
        <linearGradient
          id="paint0_linear_434_53288"
          x1="10"
          x2="10"
          y1="0"
          y2="19.941"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#18ACFE"></stop>
          <stop offset="1" stopColor="#0163E0"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};

export default FacebookColoured;
