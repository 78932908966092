import classNames from 'classnames';

const SmartWebsite = ({ fill = '#937300', customClassNames }) => {
  return (
    <svg
      className={classNames('c-SmartWebsite', customClassNames)}
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24">
      <path
        fill={fill}
        d="M4 18c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 012 16V5c0-.55.196-1.021.588-1.413A1.925 1.925 0 014 3h16c.55 0 1.021.196 1.413.587.391.392.587.863.587 1.413v11c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0120 18H4zm0-2h16V5H4v11zm-2 5a.965.965 0 01-.712-.288A.965.965 0 011 20c0-.283.096-.52.288-.712A.965.965 0 012 19h20c.283 0 .52.096.712.288A.965.965 0 0123 20c0 .283-.096.52-.288.712A.965.965 0 0122 21H2z"></path>
      <path
        fill={fill}
        d="M10.313 12.514l.675 1.5c.083.2.225.3.425.3.2 0 .341-.1.425-.3l.675-1.5 1.5-.675c.2-.084.3-.226.3-.425 0-.2-.1-.342-.3-.425l-1.5-.676-.675-1.5c-.1-.216-.242-.325-.425-.325-.184 0-.325.109-.425.325l-.675 1.5-1.5.675c-.217.1-.325.242-.325.426 0 .183.108.325.325.425l1.5.675zM13.287 8.538l.325.7c.084.184.184.275.3.275.117 0 .217-.091.3-.275l.325-.7.7-.325c.184-.083.275-.183.275-.3 0-.116-.091-.216-.274-.3l-.7-.325-.325-.7c-.084-.183-.184-.275-.3-.275-.117 0-.217.092-.3.275l-.325.7-.7.325c-.184.084-.275.184-.275.3 0 .117.091.217.274.3l.7.325z"></path>
    </svg>
  );
};

export default SmartWebsite;
