import PropTypes from 'prop-types';
import React from 'react';

const className = 'c-CameraIcon';

const CameraIcon = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 28 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.9993 19.3333C15.666 19.3333 17.0829 18.7502 18.25 17.584C19.4162 16.4169 19.9993 15 19.9993 13.3333C19.9993 11.6667 19.4162 10.2498 18.25 9.08267C17.0829 7.91644 15.666 7.33333 13.9993 7.33333C12.3327 7.33333 10.9158 7.91644 9.74868 9.08267C8.58246 10.2498 7.99935 11.6667 7.99935 13.3333C7.99935 15 8.58246 16.4169 9.74868 17.584C10.9158 18.7502 12.3327 19.3333 13.9993 19.3333ZM13.9993 16.6667C13.066 16.6667 12.2771 16.3444 11.6327 15.7C10.9882 15.0556 10.666 14.2667 10.666 13.3333C10.666 12.4 10.9882 11.6111 11.6327 10.9667C12.2771 10.3222 13.066 10 13.9993 10C14.9327 10 15.7216 10.3222 16.366 10.9667C17.0105 11.6111 17.3327 12.4 17.3327 13.3333C17.3327 14.2667 17.0105 15.0556 16.366 15.7C15.7216 16.3444 14.9327 16.6667 13.9993 16.6667ZM3.33268 24C2.59935 24 1.97179 23.7391 1.45002 23.2173C0.927349 22.6947 0.666016 22.0667 0.666016 21.3333V5.33333C0.666016 4.6 0.927349 3.97244 1.45002 3.45067C1.97179 2.928 2.59935 2.66667 3.33268 2.66667H7.53268L9.99935 0H17.9993L20.466 2.66667H24.666C25.3993 2.66667 26.0273 2.928 26.55 3.45067C27.0718 3.97244 27.3327 4.6 27.3327 5.33333V21.3333C27.3327 22.0667 27.0718 22.6947 26.55 23.2173C26.0273 23.7391 25.3993 24 24.666 24H3.33268ZM24.666 21.3333V5.33333H19.266L16.8327 2.66667H11.166L8.73268 5.33333H3.33268V21.3333H24.666Z"
        fill="#AAABAE"
      />
    </svg>
  );
};

CameraIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string
};
CameraIcon.defaultProps = {
  width: '28',
  height: '24'
};

export default CameraIcon;
