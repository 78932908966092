import classNames from 'classnames';

const IconNE = ({ fill = '#191C1E', customClassNames }) => {
  return (
    <svg
      className={classNames('c-IconNE', customClassNames)}
      width="24"
      height="25"
      fill="none"
      viewBox="0 0 24 25">
      <path
        fill={fill}
        d="M7.553 1.649c0 .92.74 1.644 1.645 1.644h9.194L.482 21.203a1.638 1.638 0 000 2.32 1.638 1.638 0 002.318 0l17.91-17.91v9.193c0 .905.74 1.645 1.645 1.645A1.65 1.65 0 0024 14.806V1.649A1.65 1.65 0 0022.355.004H9.198a1.65 1.65 0 00-1.645 1.645z"></path>
    </svg>
  );
};

export default IconNE;
