import classNames from 'classnames';

const InfoQuestion = ({ fill = '#5C5E61', customClassNames }) => {
  return (
    <svg
      className={classNames('c-InfoQuestion', customClassNames)}
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18">
      <path
        fill={fill}
        fillRule="evenodd"
        d="M9 15.375a6.375 6.375 0 100-12.75 6.375 6.375 0 000 12.75zM9 16.5a7.5 7.5 0 100-15 7.5 7.5 0 000 15z"
        clipRule="evenodd"></path>
      <path
        fill={fill}
        d="M8.145 10.839c0-.537.068-.964.202-1.283.134-.318.399-.667.793-1.046.4-.383.652-.655.757-.816.163-.246.245-.513.245-.799 0-.379-.096-.667-.287-.865-.188-.201-.464-.302-.83-.302-.35 0-.633.099-.85.296-.21.194-.317.458-.317.793H6.375c.008-.714.252-1.279.733-1.694.484-.415 1.123-.623 1.916-.623.818 0 1.455.206 1.911.617.46.411.69.986.69 1.724 0 .657-.31 1.304-.928 1.941l-.75.732c-.27.303-.408.744-.416 1.325H8.145zm-.103 1.88c0-.237.075-.429.225-.574.151-.149.355-.224.61-.224.261 0 .467.077.617.23.151.15.226.339.226.569a.763.763 0 01-.22.556c-.146.15-.353.224-.622.224s-.476-.075-.623-.224a.774.774 0 01-.213-.556z"></path>
    </svg>
  );
};

export default InfoQuestion;
