import React from 'react';

const className = 'c-IconV2-AccountBalanceWallet';

const AccountBalanceWallet = ({ customClassNames }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={`${className} ${customClassNames}`}>
      <path
        d="M21 18V19C21 20.1 20.1 21 19 21H5C3.89 21 3 20.1 3 19V5C3 3.9 3.89 3 5 3H19C20.1 3 21 3.9 21 5V6H12C10.89 6 10 6.9 10 8V16C10 17.1 10.89 18 12 18H21ZM12 16H22V8H12V16ZM16 13.5C15.17 13.5 14.5 12.83 14.5 12C14.5 11.17 15.17 10.5 16 10.5C16.83 10.5 17.5 11.17 17.5 12C17.5 12.83 16.83 13.5 16 13.5Z"
        fill="#222222"
      />
    </svg>
  );
};

AccountBalanceWallet.defaultProps = {
  customClassNames: ''
};

export default AccountBalanceWallet;
