import React from 'react';

const FileUpload = ({ width, height, customClassNames, fill }) => {
  return (
    <svg
      className={`c-FileUpload ${customClassNames}`}
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 40 40">
      <path
        fill={fill}
        d="M30 25v5H10v-5H6.667v5c0 1.833 1.5 3.333 3.333 3.333h20c1.833 0 3.333-1.5 3.333-3.333v-5H30zM11.667 15l2.35 2.35 4.316-4.3v13.617h3.334V13.05l4.316 4.3 2.35-2.35L20 6.667 11.667 15z"></path>
    </svg>
  );
};

FileUpload.defaultProps = {
  customClassNames: '',
  fill: '#E5484D',
  width: '40',
  height: '40'
};

export default FileUpload;
