import React from 'react';

const ContentLibraryGoldIcon = ({ customClassNames }) => {
  return (
    <svg
      className={`c-ContentLibraryGoldIcon ${customClassNames}`}
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32">
      <g>
        <path
          fill="#937300"
          d="M16 26.666l-1.04.693a1.25 1.25 0 002.08 0L16 26.666zm1.48-2.219l1.04.694-1.04-.694zm-2.96 0l-1.04.694 1.04-.694zM26.668 5.416H20v2.5h6.667v-2.5zm-11.917 5.25v16h2.5v-16h-2.5zm14.5 10.667V7.999h-2.5v13.334h2.5zm-12.21 6.026l1.48-2.218-2.08-1.387-1.48 2.219 2.08 1.386zm3.767-3.443h5.86v-2.5h-5.86v2.5zm-2.288 1.225a2.75 2.75 0 012.288-1.225v-2.5a5.25 5.25 0 00-4.368 2.338l2.08 1.387zm8.231-3.808a.083.083 0 01-.083.083v2.5a2.583 2.583 0 002.583-2.583h-2.5zM20 5.416a5.25 5.25 0 00-5.25 5.25h2.5A2.75 2.75 0 0120 7.916v-2.5zm6.667 2.5c.046 0 .083.037.083.083h2.5a2.583 2.583 0 00-2.583-2.583v2.5zm-21.334 0H12v-2.5H5.333v2.5zM5.25 21.333V7.999h-2.5v13.334h2.5zm11.79 4.64l-1.48-2.22-2.08 1.388 1.48 2.218 2.08-1.386zm-5.847-4.557h-5.86v2.5h5.86v-2.5zm4.368 2.338a5.25 5.25 0 00-4.368-2.338v2.5c.92 0 1.778.46 2.288 1.225l2.08-1.387zM2.75 21.333a2.583 2.583 0 002.583 2.583v-2.5a.083.083 0 01-.083-.083h-2.5zM12 7.916a2.75 2.75 0 012.75 2.75h2.5c0-2.9-2.35-5.25-5.25-5.25v2.5zm-6.667-2.5A2.583 2.583 0 002.75 7.999h2.5c0-.046.037-.083.083-.083v-2.5z"></path>
      </g>
    </svg>
  );
};

ContentLibraryGoldIcon.defaultProps = {
  customClassNames: ''
};

export default ContentLibraryGoldIcon;
