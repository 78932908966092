// NOTE :
// The order of css imports in this file is intentional and must not be changed.
// Kindly make sure you or any auto formattors do not overwrite it.
// When you update this file, use the "Save without formatting" option.

import 'react-datepicker/dist/react-datepicker.css';
import 'shaka-player/dist/controls.css';
import '../styles/tailwind/globals.css';
import './app.scss';

import React from 'react';
import { AdminAuthContextProvider } from '@/contexts/AdminAuthContext';
import { AuthContextProvider } from '@/contexts/AuthContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Head from 'next/head';
import { ToastContainer } from '@/components/common/ToastContainer';
import { UserSessionContextProvider } from '@/contexts/UserSessionContext';
import { WindowWidthContextProvider } from '@/contexts/WindowWidthContext/WindowWidthContext';
import { initiateGTM } from '@/utility/analytics';
import { useEffect } from 'react';
import ConfettiContainer from '../components/common/ConfettiContainer';
import RouteTracker from '@/components/common/RouteTracker';

const GOOGLE_CLIENT_ID = process?.env?.NEXT_PUBLIC_GOOGLE_CLIENT_ID;

// suppress useLayoutEffect warnings when running outside a browser
if (typeof window === 'undefined') React.useLayoutEffect = React.useEffect;

function MyApp({ Component, pageProps }) {
  //if the page page has custom layout that needs to be shared across multiple specific pages
  const getLayout = Component.getLayout ?? ((page) => page);

  useEffect(() => {
    initiateGTM();
  }, []);

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
        />
        <meta
          name="google-site-verification"
          content="NQ1IZVU4g7iIaXwENMnrVBlPca7nJH3r6Dxv2ChX658"
        />
        <meta
          name="facebook-domain-verification"
          content="750yp8lttgw11xhcvmxzgl2z6e666b"
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `
(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/p7trjx3s';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
`
          }}
          async
          defer
        />
      </Head>
      <RouteTracker />
      <WindowWidthContextProvider>
        <UserSessionContextProvider>
          <AuthContextProvider>
            <AdminAuthContextProvider>
              <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
                <div className="c-MyApp">
                  {getLayout(<Component {...pageProps} />)}
                  <ToastContainer />
                  <ConfettiContainer />
                </div>
              </GoogleOAuthProvider>
            </AdminAuthContextProvider>
          </AuthContextProvider>
        </UserSessionContextProvider>
      </WindowWidthContextProvider>
    </>
  );
}

export default MyApp;
