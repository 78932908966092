import { COUNTRY_DATA_SESSION } from '@/utility/constants';
import { getDataFromRes } from './helpers';
import config from '../utility/config';
import authorizedRequest from './helpers/authorizedRequest';
import { convertObjToQueryString } from './helpers/queryString';
const { apiPaymentBasePath } = config;

/**
 * @param {string} params.bookingId - required
 * @param {string} params.courseOfferCode
 * @param {string } params.bundleId
 * @param {string } params.timezoneId - required
 * One of coutseOfferCode and bundleId is required.
 */
export const verifyPaymentService = async (params) => {
  try {
    const queryParamsStr = convertObjToQueryString(params);
    const path = `${apiPaymentBasePath}/api/v1/verify-payment?${queryParamsStr}`;
    const res = await fetch(path, { method: 'GET' });
    const data = await getDataFromRes(res);

    return {
      data,
      error: false
    };
  } catch (e) {
    return { error: e.message };
  }
};

export const getLocalPriceInfo = async (
  isBackConversion,
  localCurrency = null
) => {
  try {
    // Default local pricing is based on user IP address
    const url = localCurrency
      ? `${apiPaymentBasePath}/api/v1/get-local-pricing?isBackConversion=${isBackConversion}&selectedCurrency=${localCurrency}`
      : `${apiPaymentBasePath}/api/v1/get-local-pricing?isBackConversion=${isBackConversion}`;
    const res = await fetch(url, { method: 'GET' });
    const data = await res.json();

    if (data.error) {
      const errMsg = data?.error || 'Something went wrong';
      throw new Error(errMsg);
    }

    return { data, error: false };
  } catch (e) {
    return { error: e.message };
  }
};

export const getUserCountryData = async () => {
  try {
    const url = `${apiPaymentBasePath}/api/v1/get-country-data`;
    const res = await fetch(url, { method: 'GET' });
    const data = await res.json();

    if (data.error) {
      const errMsg = data?.error || 'Something went wrong';
      throw new Error(errMsg);
    }

    sessionStorage.setItem(COUNTRY_DATA_SESSION, JSON.stringify(data));

    return { data, error: false };
  } catch (e) {
    return { error: e.message };
  }
};

export const paypalCreateOrder = async ({ discount, credit }) => {
  let body = {
    currency: 'USD',
    paymentType: 'full',
    paymentMode: 'paypal'
  };
  discount && (body.discount = discount);
  credit && (body.credit = credit);
  return authorizedRequest(
    `${apiPaymentBasePath}/api/v1/sign-up-payment/paypal`,
    {
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify(body)
    }
  );
};

// Xendit

export const xenditSignUpPayment = async ({ payload }) => {
  let body = {
    ...payload
  };
  return authorizedRequest(
    `${apiPaymentBasePath}/api/v1/sign-up-payment/xendit`,
    {
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify(body)
    }
  );
};
