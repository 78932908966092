import React from 'react';

const AnalyticsStroke = ({ fill, customClassNames }) => {
  return (
    <svg
      className={`c-AnalyticsStroke ${customClassNames}`}
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32">
      <path
        fill={fill}
        d="M12 14.666h1.25c0-.69-.56-1.25-1.25-1.25v1.25zm0 12v1.25c.69 0 1.25-.56 1.25-1.25H12zm-8 0H2.75c0 .69.56 1.25 1.25 1.25v-1.25zm1.333-10.75H12v-2.5H5.333v2.5zm5.417-1.25v12h2.5v-12h-2.5zM12 25.416H4v2.5h8v-2.5zm-6.75 1.25V15.999h-2.5v10.667h2.5zm.083-13.25a2.583 2.583 0 00-2.583 2.583h2.5c0-.046.037-.083.083-.083v-2.5zM20 10.666v-1.25c-.69 0-1.25.56-1.25 1.25H20zm8 16v1.25c.69 0 1.25-.56 1.25-1.25H28zm-8 0h-1.25c0 .69.56 1.25 1.25 1.25v-1.25zm0-14.75h6.667v-2.5H20v2.5zm6.75.083v14.667h2.5V11.999h-2.5zM28 25.416h-8v2.5h8v-2.5zm-6.75 1.25v-16h-2.5v16h2.5zm5.417-14.75c.046 0 .083.037.083.083h2.5a2.583 2.583 0 00-2.583-2.583v2.5z"></path>
      <path
        fill={fill}
        d="M20 26.667v1.25c.69 0 1.25-.56 1.25-1.25H20zm-8 0h-1.25c0 .69.56 1.25 1.25 1.25v-1.25zm1.333-20.083h5.334v-2.5h-5.334v2.5zm5.417.083v20h2.5v-20h-2.5zM20 25.417h-8v2.5h8v-2.5zm-6.75 1.25v-20h-2.5v20h2.5zm5.417-20.083c.046 0 .083.037.083.083h2.5a2.583 2.583 0 00-2.583-2.583v2.5zm-5.334-2.5a2.583 2.583 0 00-2.583 2.583h2.5c0-.046.037-.083.083-.083v-2.5z"></path>
    </svg>
  );
};

AnalyticsStroke.defaultProps = {
  fill: '#191C1E',
  customClassNames: ''
};

export default AnalyticsStroke;
