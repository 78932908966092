import React from 'react';

const DeleteOutline = ({ fill, customClassNames }) => {
  return (
    <svg
      className={`c-DeleteOutline ${customClassNames}`}
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24">
      <mask id="path-1-inside-1_4855_14009" fill="#fff">
        <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2H8c-1.1 0-2 .9-2 2v10zM9 9h6c.55 0 1 .45 1 1v8c0 .55-.45 1-1 1H9c-.55 0-1-.45-1-1v-8c0-.55.45-1 1-1zm6.5-5l-.71-.71c-.18-.18-.44-.29-.7-.29H9.91c-.26 0-.52.11-.7.29L8.5 4H6c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1h-2.5z"></path>
      </mask>
      <path
        fill={fill}
        d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2H8c-1.1 0-2 .9-2 2v10zM9 9h6c.55 0 1 .45 1 1v8c0 .55-.45 1-1 1H9c-.55 0-1-.45-1-1v-8c0-.55.45-1 1-1zm6.5-5l-.71-.71c-.18-.18-.44-.29-.7-.29H9.91c-.26 0-.52.11-.7.29L8.5 4H6c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1h-2.5z"></path>
      <path
        fill={fill}
        d="M15.5 4l-.707.707.293.293h.414V4zm-7 0v1h.414l.293-.293L8.5 4zM5 19c0 1.652 1.348 3 3 3v-2c-.548 0-1-.452-1-1H5zm3 3h8v-2H8v2zm8 0c1.652 0 3-1.348 3-3h-2c0 .548-.452 1-1 1v2zm3-3V9h-2v10h2zm0-10c0-1.652-1.348-3-3-3v2c.548 0 1 .452 1 1h2zm-3-3H8v2h8V6zM8 6C6.348 6 5 7.348 5 9h2c0-.548.452-1 1-1V6zM5 9v10h2V9H5zm4 1h6V8H9v2zm6 0h2c0-1.102-.898-2-2-2v2zm0 0v8h2v-8h-2zm0 8v2c1.102 0 2-.898 2-2h-2zm0 0H9v2h6v-2zm-6 0H7c0 1.102.898 2 2 2v-2zm0 0v-8H7v8h2zm0-8V8c-1.102 0-2 .898-2 2h2zm7.207-6.707l-.71-.71-1.414 1.414.71.71 1.414-1.414zm-.71-.71A2.009 2.009 0 0014.09 2v2a.06.06 0 01-.006 0h-.002.001a.024.024 0 01.005.002l-.005-.005 1.414-1.414zM14.09 2H9.91v2h4.18V2zM9.91 2c-.528 0-1.042.217-1.407.583l1.414 1.414a.06.06 0 01-.004.004l-.001.001a.029.029 0 01.006-.003h-.002L9.91 4V2zm-1.407.583l-.71.71 1.414 1.414.71-.71-1.414-1.414zM8.5 3H6v2h2.5V3zM6 3c-1.102 0-2 .898-2 2h2V3zM4 5c0 1.102.898 2 2 2V5H4zm2 2h12V5H6v2zm12 0c1.102 0 2-.898 2-2h-2v2zm2-2c0-1.102-.898-2-2-2v2h2zm-2-2h-2.5v2H18V3z"
        mask="url(#path-1-inside-1_4855_14009)"></path>
    </svg>
  );
};

DeleteOutline.defaultProps = {
  fill: '#B02D29'
};

export default DeleteOutline;
