import React from 'react';

const ChatIntegrationGoldFeatureIcon = ({ customClassNames }) => {
  return (
    <svg
      className={`c-ChatIntegrationGoldFeatureIcon ${customClassNames}`}
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32">
      <g clipPath="url(#clip0_3077_130944)">
        <g>
          <mask id="path-1-inside-1_3077_130944" fill="#fff">
            <path d="M28 9.333c-2.213 0-4 1.786-4 4 0 .093 0 .186.013.28l-2.706.906a5.958 5.958 0 00-4.294-3.093v-2.88A4.024 4.024 0 0020 4.666c0-2.213-1.787-4-4-4s-4 1.787-4 4a4.02 4.02 0 003 3.88v2.88a5.958 5.958 0 00-4.293 3.093l-2.72-.906c.013-.094.013-.187.013-.28 0-2.214-1.787-4-4-4s-4 1.786-4 4c0 2.213 1.787 4 4 4 1.413 0 2.64-.734 3.36-1.827l2.707.907a6.018 6.018 0 001.453 4.92l-1.88 2.36a3.782 3.782 0 00-1.64-.36c-2.213 0-4 1.786-4 4 0 2.213 1.787 4 4 4s4-1.787 4-4c0-.907-.293-1.734-.8-2.4l1.88-2.36a5.999 5.999 0 005.827 0l1.88 2.36a4.012 4.012 0 00-.787 2.4c0 2.213 1.787 4 4 4s4-1.787 4-4c0-2.214-1.787-4-4-4-.587 0-1.133.12-1.64.346l-1.88-2.36a5.986 5.986 0 001.453-4.92l2.707-.906A3.998 3.998 0 0028 17.319c2.213 0 4-1.786 4-4a3.983 3.983 0 00-4-3.986zM4 14.666c-.733 0-1.333-.6-1.333-1.333 0-.734.6-1.334 1.333-1.334s1.333.6 1.333 1.334c0 .733-.6 1.333-1.333 1.333zm4 14c-.733 0-1.333-.6-1.333-1.333 0-.734.6-1.334 1.333-1.334s1.333.6 1.333 1.334c0 .733-.6 1.333-1.333 1.333zm6.667-24c0-.733.6-1.333 1.333-1.333s1.333.6 1.333 1.333-.6 1.333-1.333 1.333-1.333-.6-1.333-1.333zm1.333 16a3.334 3.334 0 010-6.667 3.335 3.335 0 010 6.667zm8 5.333c.733 0 1.333.6 1.333 1.334 0 .733-.6 1.333-1.333 1.333s-1.333-.6-1.333-1.333c0-.734.6-1.334 1.333-1.334zm4-11.333c-.733 0-1.333-.6-1.333-1.333 0-.734.6-1.334 1.333-1.334s1.333.6 1.333 1.334c0 .733-.6 1.333-1.333 1.333z"></path>
          </mask>
          <path
            fill="#937300"
            stroke="#937300"
            strokeWidth="5"
            d="M28 9.333c-2.213 0-4 1.786-4 4 0 .093 0 .186.013.28l-2.706.906a5.958 5.958 0 00-4.294-3.093v-2.88A4.024 4.024 0 0020 4.666c0-2.213-1.787-4-4-4s-4 1.787-4 4a4.02 4.02 0 003 3.88v2.88a5.958 5.958 0 00-4.293 3.093l-2.72-.906c.013-.094.013-.187.013-.28 0-2.214-1.787-4-4-4s-4 1.786-4 4c0 2.213 1.787 4 4 4 1.413 0 2.64-.734 3.36-1.827l2.707.907a6.018 6.018 0 001.453 4.92l-1.88 2.36a3.782 3.782 0 00-1.64-.36c-2.213 0-4 1.786-4 4 0 2.213 1.787 4 4 4s4-1.787 4-4c0-.907-.293-1.734-.8-2.4l1.88-2.36a5.999 5.999 0 005.827 0l1.88 2.36a4.012 4.012 0 00-.787 2.4c0 2.213 1.787 4 4 4s4-1.787 4-4c0-2.214-1.787-4-4-4-.587 0-1.133.12-1.64.346l-1.88-2.36a5.986 5.986 0 001.453-4.92l2.707-.906A3.998 3.998 0 0028 17.319c2.213 0 4-1.786 4-4a3.983 3.983 0 00-4-3.986zM4 14.666c-.733 0-1.333-.6-1.333-1.333 0-.734.6-1.334 1.333-1.334s1.333.6 1.333 1.334c0 .733-.6 1.333-1.333 1.333zm4 14c-.733 0-1.333-.6-1.333-1.333 0-.734.6-1.334 1.333-1.334s1.333.6 1.333 1.334c0 .733-.6 1.333-1.333 1.333zm6.667-24c0-.733.6-1.333 1.333-1.333s1.333.6 1.333 1.333-.6 1.333-1.333 1.333-1.333-.6-1.333-1.333zm1.333 16a3.334 3.334 0 010-6.667 3.335 3.335 0 010 6.667zm8 5.333c.733 0 1.333.6 1.333 1.334 0 .733-.6 1.333-1.333 1.333s-1.333-.6-1.333-1.333c0-.734.6-1.334 1.333-1.334zm4-11.333c-.733 0-1.333-.6-1.333-1.333 0-.734.6-1.334 1.333-1.334s1.333.6 1.333 1.334c0 .733-.6 1.333-1.333 1.333z"
            mask="url(#path-1-inside-1_3077_130944)"></path>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_3077_130944">
          <path fill="#fff" d="M0 0H32V32H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

ChatIntegrationGoldFeatureIcon.defaultProps = {
  customClassNames: ''
};

export default ChatIntegrationGoldFeatureIcon;
