import React from 'react';
const className = 'c-ErrorAlertWarningIcon';
const ErrorAlertWarningIcon = ({
  width,
  height,
  customClassName,
  fill
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      className={`${className} ${customClassName}`}>
      <path
        d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 11C9.45 11 9 10.55 9 10V6C9 5.45 9.45 5 10 5C10.55 5 11 5.45 11 6V10C11 10.55 10.55 11 10 11ZM11 15H9V13H11V15Z"
        fill={fill}
      />
    </svg>
  );
};

ErrorAlertWarningIcon.defaultProps = {
  width: 20,
  height: 20,
  customClassName: '',
  fill: '#594400'
};

export default ErrorAlertWarningIcon;
