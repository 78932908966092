import {
  HOME_DELETE_ANNOUNCEMENT_EVENT,
  HOME_EDIT_ANNOUNCEMENT_EVENT,
  HOME_PIN_ANNOUNCEMENT_EVENT,
  HOME_UNPIN_ANNOUNCEMENT_EVENT
} from '@/utility/analyticsConsts';
import {
  getCommunityDataForAnalytics,
  trackGAEvent,
  trackGAEventWithCommunityAndUser
} from '@/utility/analytics';
import { AI_SUMMARY_CONTENT_TYPES } from './constants';

export const SHOW_SESSIONIZED_PAYOUT_NUDGE =
  'SHOW_SESSIONIZED_PAYOUT_NUDGE';

export const getCommunityMemberProfileImgProps = (url) => {
  return {
    alt: 'community member profile image',
    desktopImgProps: {
      src: url,
      height: 32,
      width: 32
    },
    mobileImgProps: {
      src: url,
      height: 32,
      width: 32
    }
  };
};

export const emptyCommunityChatImage = {
  alt: 'connect chat app',
  desktopImgProps: {
    src: 'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/portal/png/empty-connect-chat.png',
    layout: 'fixed',
    height: 40,
    width: 120
  },
  mobileImgProps: {
    src: 'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/portal/png/empty-connect-chat.png',
    layout: 'fixed',
    height: 40,
    width: 120
  }
};

export const getSessionizedPayoutNudgeFromSessionStorage = () => {
  try {
    if (
      sessionStorage.getItem(SHOW_SESSIONIZED_PAYOUT_NUDGE) === 'dismissed'
    )
      return false;

    return true;
  } catch {
    return false;
  }
};

const _getDataForAnalytics = (postId, user, community) => {
  const communityDataForAnalytics =
    getCommunityDataForAnalytics(community);

  const params = {
    postId,
    email: user?.email,
    ...communityDataForAnalytics
  };

  return params;
};

export const trackEditAnnouncement = (postId, user, community) => {
  const params = _getDataForAnalytics(postId, user, community);
  trackGAEventWithCommunityAndUser(HOME_EDIT_ANNOUNCEMENT_EVENT, params);
};

export const trackPinAnnouncement = (postId, user, community) => {
  const params = _getDataForAnalytics(postId, user, community);
  trackGAEventWithCommunityAndUser(HOME_PIN_ANNOUNCEMENT_EVENT, params);
};

export const trackUnpinAnnouncement = (postId, user, community) => {
  const params = _getDataForAnalytics(postId, user, community);
  trackGAEvent(HOME_UNPIN_ANNOUNCEMENT_EVENT, params);
};

export const trackDeleteAnnouncement = (postId, user, community) => {
  const params = _getDataForAnalytics(postId, user, community);
  trackGAEvent(HOME_DELETE_ANNOUNCEMENT_EVENT, params);
};

export const getFormattedShareLink = (base, link) => {
  if (!link) return null;
  // append / if not present
  if (link[0] !== '/') return `${base}/${link}`;
  return `${base}${link}`;
};

export const generateTextByType = (item) => {
  switch (item.type) {
    case AI_SUMMARY_CONTENT_TYPES.TOPIC: {
      const flatenedSubpoints = item?.subpoints
        ?.map((subpoint) => `- ${subpoint}`)
        .join('\n');
      return `*${item?.text}*\n${flatenedSubpoints}${
        flatenedSubpoints && '\n'
      }`;
    }
  }

  return item?.text + '\n';
};

export const flattenSummaryText = (title = '', list = []) => {
  const flattenedList = list
    .map((item) => generateTextByType(item))
    .join('\n');

  return `*${title}*\n\n${flattenedList}`;
};

function getMRParagraph(text, isBold) {
  return {
    children: [
      {
        detail: 0,
        format: isBold ? 1 : 0,
        mode: 'normal',
        style: '',
        text,
        type: 'text',
        version: 1
      }
    ],
    direction: 'ltr',
    format: '',
    indent: 0,
    type: 'paragraph',
    version: 1
  };
}

function getMRNewLine() {
  return {
    children: [],
    direction: null,
    format: '',
    indent: 0,
    type: 'paragraph',
    version: 1
  };
}

function getMRList(list) {
  return {
    children: list.map((text, index) => {
      return {
        children: [
          {
            detail: 0,
            format: 0,
            mode: 'normal',
            style: '',
            text,
            type: 'text',
            version: 1
          }
        ],
        direction: 'ltr',
        format: '',
        indent: 0,
        type: 'listitem',
        version: 1,
        value: index + 1
      };
    }),
    direction: 'ltr',
    format: '',
    indent: 0,
    type: 'list',
    version: 1,
    listType: 'bullet',
    start: 1,
    tag: 'ul'
  };
}

const generateMagicReachSummaryItem = (summaryItem) => {
  if (summaryItem.type === AI_SUMMARY_CONTENT_TYPES.TOPIC) {
    return [
      getMRParagraph(summaryItem.text, true),
      getMRList(summaryItem.subpoints)
    ];
  }

  return [getMRParagraph(summaryItem.text)];
};

const generate = (summaryItem) => {
  if (summaryItem.type === AI_SUMMARY_CONTENT_TYPES.TOPIC) {
    return [
      getMRParagraph(summaryItem.text, true),
      getMRList(summaryItem.subpoints)
    ];
  }

  return [getMRParagraph(summaryItem.text)];
};

export const getMagicReachFormattedSummary = (list) => {
  return {
    root: {
      children: list.reduce((acc, summaryItem) => {
        return [
          ...acc,
          ...generateMagicReachSummaryItem(summaryItem),
          getMRNewLine() //line-break for each summary section
        ];
      }, []),
      direction: 'ltr',
      format: '',
      indent: 0,
      type: 'root',
      version: 1
    }
  };
};

export const formatDateParam = (dateParam) => {
  try {
    if (!dateParam) {
      return new Date();
    }

    const date = new Date(dateParam);
    return date;
  } catch {
    return new Date();
  }
};
