export const getCommunityIdFromSessionStorage = () => {
  try {
    // Read JSON string from session storage
    const activeCommunityString =
      sessionStorage.getItem('activeCommunity');
    if (!activeCommunityString) return '';

    // Convert JSON string to JSON object
    const activeCommuntiy = JSON.parse(activeCommunityString);

    return activeCommuntiy._id;
  } catch (e) {
    return '';
  }
};

export const getLoggedInUserEmailFromStorage = () => {
  try {
    // Read JSON string from local storage
    const adminUserDataString = localStorage.getItem('adminUserData');
    if (!adminUserDataString) return '';

    // Convert JSON string to JSON object
    const adminUserData = JSON.parse(adminUserDataString);

    return adminUserData.email;
  } catch (e) {
    return '';
  }
};
