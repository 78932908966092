import PropTypes from 'prop-types';
import React from 'react';

const className = 'c-LinkedInOutlineIcon';
const LinkedInOutlineIcon = ({ width, height, fillColor }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 17 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.41844 2C2.93182 2 2.53711 2.38511 2.53711 2.85962V13.1407C2.53711 13.6152 2.93182 14 3.41844 14H13.5802C14.067 14 14.4616 13.6152 14.4616 13.1405V2.85962C14.4616 2.38511 14.067 2 13.5802 2H3.41844ZM6.1518 6.6271V12.0457H4.35073V6.6271H6.1518ZM6.27046 4.95131C6.27046 5.47129 5.87953 5.88741 5.25158 5.88741H5.25142H5.23977C4.63527 5.88741 4.2445 5.47129 4.2445 4.95131C4.2445 4.41952 4.64707 4.01505 5.26354 4.01505C5.87953 4.01505 6.25866 4.41952 6.27046 4.95131ZM8.94956 12.0457H7.14865C7.1488 12.0138 7.17218 7.13394 7.14881 6.62726H8.94972V7.39435C9.18909 7.02518 9.61756 6.5001 10.5729 6.5001C11.7579 6.5001 12.6463 7.27457 12.6463 8.93887V12.0457H10.8454V9.14725C10.8454 8.41873 10.5846 7.92188 9.93304 7.92188C9.4354 7.92188 9.13905 8.25694 9.00889 8.58067C8.96137 8.69619 8.94956 8.85829 8.94956 9.02008V12.0457ZM7.14865 12.0459V12.0457C7.14865 12.0458 7.14865 12.0459 7.14865 12.0459Z"
        fill={fillColor}
      />
    </svg>
  );
};

LinkedInOutlineIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fillColor: PropTypes.string
};

LinkedInOutlineIcon.defaultProps = {
  width: '15',
  height: '14',
  fillColor: '#FBFCFF'
};

export default LinkedInOutlineIcon;
