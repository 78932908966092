import { useEffect, useState } from 'react';

export function useDocument() {
  const [document, setDocument] = useState(null);

  useEffect(() => {
    setDocument(window.document);
  }, []);

  return document;
}
