import PriceTagIcon from '@/components/common/Icons/PriceTagIcon';
import Close from '@/components/common/IconsV2/Close';
import FacebookIcon from '@/components/common/IconsV2/FacebookIcon';
import InfoCircleOutlineIcon from '@/components/common/IconsV2/InfoCircleOutlineIcon';
import LinkedInIcon from '@/components/common/IconsV2/LinkedInIcon';
import Location from '@/components/common/IconsV2/Location';
import SentimentSatisfied from '@/components/common/IconsV2/SentimentSatisfied';
import ShareIcon from '@/components/common/IconsV2/ShareIcon';
import TwitterIcon from '@/components/common/IconsV2/TwitterIcon';
import WhatsappIcon from '@/components/common/IconsV2/WhatsappIcon';
import { TelegramIcon } from 'react-share';
import ChatIntegrationGoldFeatureIcon from '../Icons/ChatIntegrationGoldFeatureIcon';
import ContentLibraryGoldIcon from '../Icons/ContentLibraryGoldIcon';
import DiscordIcon from '../Icons/DiscordIcon';
import ErrorAlertWarningIcon from '../Icons/ErrorAlertWarningIcon';
import FileUploadIcon from '../Icons/FileUploadIcon/FileUploadIcon';
import LiveEventsFeatureGoldIcon from '../Icons/LiveEventsFeatureGoldIcon';
import MagicReachFeatureIcon from '../Icons/MagicReachFeatureIcon';
import MonetizationFeatureGoldIcon from '../Icons/MonetizationFeatureGoldIcon';
import NPLEditIcon from '../Icons/NPLEditIcon';
import ScreenSearchDesktopIcon from '../Icons/ScreenSearchDesktop/ScreenSearchDesktopIcon';
import ShieldIcon from '../Icons/ShieldIcon';
import VisitIcon from '../Icons/VisitIcon/VisitIcon';
import Web3FeatureIcon from '../Icons/Web3FeatureIcon';
import AccountBalanceWallet from './AccountBalanceWallet';
import AddLink from './AddLink';
import AlertCircle from './AlertCircle';
import AnalyticsStroke from './AnalyticsStroke';
import Announcement from './Announcement';
import ArrowForward from './ArrowForward';
import BarChart10Icon from './BarChart10Icon';
import BookOpen from './BookOpen';
import Cached from './Cached';
import CalendarDateIcon from './CalendarDateIcon';
import CalendarDays from './CalendarDays';
import CalendarEvent from './CalendarEvent/CalendarEvent';
import CalendarIcon from './CalendarIcon';
import CalendarNew from './CalendarNew';
import CalendarPlus01 from './CalendarPlus01';
import CameraIcon from './CameraIcon';
import Certificate01 from './Certificate01';
import Check from './Check';
import CheckCircle from './CheckCircle';
import CheckSquareIcon from './CheckSquareIcon';
import ChevronCircleDownIcon from './ChevronCircleDown';
import ChevronDown from './ChevronDown';
import Clock from './Clock/Clock';
import Collection from './Collection';
import CollectionsBookmark from './CollectionsBookmark';
import CopyContentIcon from './CopyContentIcon';
import CopyLink from './CopyLinkIcon';
import CouponIcon from './CouponIcon';
import Course from './Course';
import CoursesIcon from './CoursesIcon';
import CubeOutlineIcon from './CubeOutlineIcon';
import CurrencyDollarCircle from './CurrencyDollarCircle';
import CursorBox from './CursorBox';
import Dash from './Dash';
import DeleteOutline from './DeleteOutline';
import DiscordRoleNeutralIcon from './DiscordRoleNeutralIcon/DiscordRoleNeutralIcon';
import Download01Icon from './Download01Icon';
import DragIndicatorReorder from './DragIndicatorReorder';
import EditDocument from './EditDocument';
import EditPencil from './EditPencil';
import Exercise from './Exercise/Exercise';
import FaceFrown from './FaceFrown';
import FaceHappy from './FaceHappy';
import FacebookColoured from './FacebookColoured';
import FacebookOutlineIcon from './FacebookOutline';
import FeaturePlay from './FeaturePlay';
import File04 from './File04';
import FileUpload from './FileUpload';
import FormsAddOn from './FormsAddOn';
import GiftCard from './GiftCard';
import Globe from './Globe/Globe';
import HashIcon from './HashIcon';
import HourGlassIcon from './HourGlassIcon';
import Hub from './Hub';
import IconLLeft from './IconLLeft';
import IconLRight from './IconLRight';
import IconNE from './IconNE';
import IconSLeft from './IconSLeft';
import InfoCircleIcon from './InfoCircleIcon';
import InfoQuestion from './InfoQuestion';
import LeadIcon from './LeadIcon/LeadIcon';
import LibraryAdd from './LibraryAdd';
import LibraryStroke from './LibraryStroke';
import Link from './Link';
import LinkedInOutlineIcon from './LinkedInOutlineIcon';
import LinkedinColoured from './LinkedinColoured';
import LiveSession from './LiveSession';
import LiveTV from './LiveTV';
import LockIcon from './LockIcon';
import MagicReach from './MagicReach';
import Mail01 from './Mail01';
import MemberAddIcon from './MemberAddIcon';
import MessageAlertCircle from './MessageAlertCircle/MessageAlertCircle';
import MessageChatCircle from './MessageChatCircle';
import MinusCircleFill from './MinusCircleFill';
import MonetizationOn from './MonetizationOn';
import MoreVerticle from './MoreVerticle';
import NPLAnnouncement from './NPLAnnouncement';
import NPLBookOpen from './NPLBookOpen';
import NPLCalender from './NPLCalender';
import NPLCertificate from './NPLCertificate';
import NPLDollar from './NPLDollar';
import NPLHamburgerMD from './NPLHamburgerMD';
import NPLHeartHand from './NPLHeartHand';
import NPLHelpCircle from './NPLHelpCircle';
import NPLInfo from './NPLInfo';
import NPLLightbulb from './NPLLightbulb';
import NPLLoudSpeaker from './NPLLoudSpeaker';
import NPLMail from './NPLMail';
import NPLShop from './NPLShop';
import NPLSponsor from './NPLSponsor';
import NPLUserShield from './NPLUserShield';
import NPLUsers from './NPLUsers';
import NPLUsersPlus from './NPLUsersPlus';
import NasIODark from './NasIODark';
import NasIoLogo from './NasIoLogo';
import People from './People';
import Pin from './Pin';
import Place from './Place/Place';
import Play from './Play';
import PlayButton from './PlayButton';
import PlayOverlayVideo from './PlayOverlayVideo';
import PlusIcon from './PlusIcon';
import QandA from './QandA';
import Readings from './Readings';
import Rectangle1284Icon from './Rectangle1284Icon';
import Resource from './Resource';
import RocketOutlineIcon from './RocketOutlineIcon';
import Send01 from './Send01';
import SentimentDissatisfied from './SentimentDissatisfied';
import ShoppingBag01 from './ShoppingBag01';
import SlackOutlineIcon from './SlackOutlineIcon';
import SmartWebsite from './SmartWebsite';
import Star from './Star';
import Stars from './Stars';
import SupportAgent from './SupportAgent';
import TextIcon from './TextIcon';
import TiktokColoured from './TiktokColoured';
import TokenNftWeb3 from './TokenNftWeb3';
import Tool01 from './Tool01';
import UnlockIcon from './UnlockIcon';
import Upload01 from './Upload01';
import UploadCloudO1Icon from './UploadCloudO1Icon';
import User01 from './User01/User01';
import UserEditIcon from './UserEditIcon/UserEditIcon';
import UserSquareIcon from './UserSquareIcon';
import UsersCheck from './UsersCheck';
import UsersMoney from './UsersMoney';
import VideoLibrary from './VideoLibrary/VideoLibrary';
import Warning from './Warning';
import Website from './Website';
import WebsiteStroke from './WebsiteStroke';
import WhatsappColored from './WhatsappColored';
import ZoomSessionLive from './ZoomSessionLive/ZoomSessionLive';
import CopyContentV2 from '@/components/common/IconsV2/CopyContentV2/CopyContent';
import PlayFilled from '@/icons/collection/PlayFilled';

const IconByName = (props) => {
  const { name, ...rest } = props;
  switch (name) {
    case 'people':
      return <People {...rest} />;
    case 'place':
      return <Place {...rest} />;
    case 'video-library':
      return <VideoLibrary {...rest} />;
    case 'zoom-session-live':
      return <ZoomSessionLive {...rest} />;
    case 'exercise':
      return <Exercise {...rest} />;
    case 'calendar-event':
      return <CalendarEvent {...rest} />;
    case 'star':
      return <Star {...rest} />;
    case 'website':
      return <Website {...rest} />;
    case 'acount-balance-wallet':
      return <AccountBalanceWallet {...rest} />;
    case 'readings':
      return <Readings {...rest} />;
    case 'q-and-a':
      return <QandA {...rest} />;
    case 'card-giftcard':
      return <GiftCard {...rest} />;
    case 'minus-circle-fill':
      return <MinusCircleFill {...rest} />;
    case 'chevron-down':
      return <ChevronDown {...rest} />;
    case 'clock':
      return <Clock {...rest} />;
    case 'globe':
      return <Globe {...rest} />;
    case 'live-session':
      return <LiveSession {...rest} />;
    case 'collections-bookmark':
      return <CollectionsBookmark {...rest} />;
    case 'edit-pencil':
      return <EditPencil {...rest} />;
    case 'delete-outline':
      return <DeleteOutline {...rest} />;
    case 'more-verticle':
      return <MoreVerticle {...rest} />;
    case 'file-upload':
      return <FileUpload {...rest} />;
    case 'file-upload-icon':
      return <FileUploadIcon {...rest} />;
    case 'file-04':
      return <File04 {...rest} />;
    case 'edit-document':
      return <EditDocument {...rest} />;
    case 'library-add':
      return <LibraryAdd {...rest} />;
    case 'add-link':
      return <AddLink {...rest} />;
    case 'forms-add-on':
      return <FormsAddOn {...rest} />;
    case 'drag-indicator-reorder':
      return <DragIndicatorReorder {...rest} />;
    case 'warning':
      return <Warning {...rest} />;
    case 'cached':
      return <Cached {...rest} />;
    case 'check-circle':
      return <CheckCircle {...rest} />;
    case 'link':
      return <Link {...rest} />;
    case 'camera':
      return <CameraIcon {...rest} />;
    case 'lock-open':
      return <UnlockIcon {...rest} />;
    case 'lock-closed':
      return <LockIcon {...rest} />;
    case 'live-tv':
      return <LiveTV {...rest} />;
    case 'support-agent':
      return <SupportAgent {...rest} />;
    case 'arrow-forward':
      return <ArrowForward {...rest} />;
    case 'icon-l-right':
      return <IconLRight {...rest} />;
    case 'play-video-overlay':
      return <PlayOverlayVideo {...rest} />;
    case 'smart-website':
      return <SmartWebsite {...rest} />;
    case 'icon-l-left':
      return <IconLLeft {...rest} />;
    case 'feature-play':
      return <FeaturePlay {...rest} />;
    case 'sentiment-dissatisfied':
      return <SentimentDissatisfied {...rest} />;
    case 'icon-ne':
      return <IconNE {...rest} />;
    case 'calendar-days':
      return <CalendarDays {...rest} />;
    case 'library-stroke':
      return <LibraryStroke {...rest} />;
    case 'magic-reach':
      return <MagicReach {...rest} />;
    case 'hub':
      return <Hub {...rest} />;
    case 'website-stroke':
      return <WebsiteStroke {...rest} />;
    case 'monetization-on':
      return <MonetizationOn {...rest} />;
    case 'analytic-stroke':
      return <AnalyticsStroke {...rest} />;
    case 'token-nft-web3':
      return <TokenNftWeb3 {...rest} />;
    case 'icon-s-left':
      return <IconSLeft {...rest} />;
    case 'external-link':
      return <VisitIcon {...rest} />;
    case 'copy-link':
      return <CopyLink {...rest} />;
    case 'plus':
      return <PlusIcon {...rest} />;
    case 'info-question':
      return <InfoQuestion {...rest} />;
    case 'info-circle':
      return <InfoCircleIcon {...rest} />;
    case 'info-circle-outline':
      return <InfoCircleOutlineIcon {...rest} />;
    case 'book-open':
      return <BookOpen {...rest} />;
    case 'screen_search_desktop':
      return <ScreenSearchDesktopIcon {...rest} />;
    case 'copy-content':
      return <CopyContentIcon {...rest} />;
    case 'copy-page':
      return <CopyContentV2 {...rest} />;
    case 'dash':
      return <Dash {...rest} />;
    case 'web3-gold':
      return <Web3FeatureIcon {...rest} />;
    case 'chat-integration-gold':
      return <ChatIntegrationGoldFeatureIcon {...rest} />;
    case 'magic-reach-gold':
      return <MagicReachFeatureIcon {...rest} />;
    case 'events-feature-gold':
      return <LiveEventsFeatureGoldIcon {...rest} />;
    case 'content-library-feature-gold':
      return <ContentLibraryGoldIcon {...rest} />;
    case 'monetization-feature-gold':
      return <MonetizationFeatureGoldIcon {...rest} />;
    case 'sentiment-satisfied':
      return <SentimentSatisfied {...rest} />;
    case 'error-alert-warning':
      return <ErrorAlertWarningIcon {...rest} />;
    case 'alert-circle':
      return <AlertCircle {...rest} />;
    case 'hour-glass':
      return <HourGlassIcon {...rest} />;
    case 'whatspp-colored':
      return <WhatsappColored {...rest} />;
    case 'check':
      return <Check {...rest} />;
    case 'close':
      return <Close {...rest} />;
    case 'location':
      return <Location {...rest} />;
    case 'whatsapp':
      return <WhatsappIcon {...rest} />;
    case 'share':
      return <ShareIcon {...rest} />;
    case 'linkedin':
      return <LinkedInIcon {...rest} />;
    case 'twitter':
      return <TwitterIcon {...rest} />;
    case 'facebook':
      return <FacebookIcon {...rest} />;
    case 'pin':
      return <Pin {...rest} />;
    case 'calendar-icon':
      return <CalendarIcon {...rest} />;
    case 'course':
      return <Course {...rest} />;
    case 'collection':
      return <Collection {...rest} />;
    case 'resource':
      return <Resource {...rest} />;
    case 'facebook-outline':
      return <FacebookOutlineIcon {...rest} />;
    case 'slack-outline':
      return <SlackOutlineIcon {...rest} />;
    case 'linkedin-outline':
      return <LinkedInOutlineIcon {...rest} />;
    case 'coupon':
      return <CouponIcon {...rest} />;
    case 'play-button':
      return <PlayButton {...rest} />;
    case 'play-filled':
      return <PlayFilled {...rest} />;
    case 'rectangle-1284':
      return <Rectangle1284Icon {...rest} />;
    case 'member-add':
      return <MemberAddIcon {...rest} />;
    case 'rocket-outline':
      return <RocketOutlineIcon {...rest} />;
    case 'user-square':
      return <UserSquareIcon {...rest} />;
    case 'user-edit':
      return <UserEditIcon {...rest} />;
    case 'tool-01':
      return <Tool01 {...rest} />;
    case 'shield-tick':
      return <ShieldIcon {...rest} />;
    case 'discord-role-neutral':
      return <DiscordRoleNeutralIcon {...rest} />;
    case 'type':
      return <TextIcon {...rest} />;
    case 'hash':
      return <HashIcon {...rest} />;
    case 'chevron-circle-down':
      return <ChevronCircleDownIcon {...rest} />;
    case 'check-square':
      return <CheckSquareIcon {...rest} />;
    case 'stars':
      return <Stars {...rest} />;
    case 'npl-edit':
      return <NPLEditIcon {...rest} />;
    case 'npl-users-plus':
      return <NPLUsersPlus {...rest} />;
    case 'npl-calender':
      return <NPLCalender {...rest} />;
    case 'npl-dollar':
      return <NPLDollar {...rest} />;
    case 'npl-book-open':
      return <NPLBookOpen {...rest} />;
    case 'npl-announcement':
      return <NPLAnnouncement {...rest} />;
    case 'npl-light-bulb':
      return <NPLLightbulb {...rest} />;
    case 'nas-io':
      return <NasIoLogo {...rest} />;
    case 'npl-user-shield':
      return <NPLUserShield {...rest} />;
    case 'npl-info':
      return <NPLInfo {...rest} />;
    case 'npl-users':
      return <NPLUsers {...rest} />;
    case 'npl-certificate':
      return <NPLCertificate {...rest} />;
    case 'npl-shop':
      return <NPLShop {...rest} />;
    case 'npl-sponsor':
      return <NPLSponsor {...rest} />;
    case 'npl-loud-speaker':
      return <NPLLoudSpeaker {...rest} />;
    case 'price-tag':
      return <PriceTagIcon {...rest} />;
    case 'heart-hand':
      return <NPLHeartHand {...rest} />;
    case 'users-01':
      return <User01 {...rest} />;
    case 'message-alert-circle':
      return <MessageAlertCircle {...rest} />;
    case 'hamburger-MD':
      return <NPLHamburgerMD {...rest} />;
    case 'help-circle':
      return <NPLHelpCircle {...rest} />;
    case 'lead-icon':
      return <LeadIcon {...rest} />;
    case 'discord':
      return <DiscordIcon {...rest} />;
    case 'telegram':
      return <TelegramIcon {...rest} />;
    case 'npl-mail':
      return <NPLMail {...rest} />;
    case 'discord-coloured':
      return <DiscordIcon {...rest} />;
    case 'message-chat-circle':
      return <MessageChatCircle {...rest} />;
    case 'calendar':
      return <CalendarNew {...rest} />;
    case 'users-money':
      return <UsersMoney {...rest} />;
    case 'certificate-01':
      return <Certificate01 {...rest} />;
    case 'shopping-bag-01':
      return <ShoppingBag01 {...rest} />;
    case 'mail-01':
      return <Mail01 {...rest} />;
    case 'announcement':
      return <Announcement {...rest} />;
    case 'cursor-box':
      return <CursorBox {...rest} />;
    case 'play':
      return <Play {...rest} />;
    case 'face-happy':
      return <FaceHappy {...rest} />;
    case 'face-frown':
      return <FaceFrown {...rest} />;
    case 'facebook-coloured':
      return <FacebookColoured {...rest} />;
    case 'tiktok-coloured':
      return <TiktokColoured {...rest} />;
    case 'linkedin-coloured':
      return <LinkedinColoured {...rest} />;
    case 'nas-io-dark':
      return <NasIODark {...rest} />;
    case 'send-01':
      return <Send01 {...rest} />;
    case 'courses':
      return <CoursesIcon {...rest} />;
    case 'calendar-date':
      return <CalendarDateIcon {...rest} />;
    case 'bar-chart-10':
      return <BarChart10Icon {...rest} />;
    case 'cube-outline':
      return <CubeOutlineIcon {...rest} />;
    case 'currency-dollar-circle':
      return <CurrencyDollarCircle {...rest} />;
    case 'users-check':
      return <UsersCheck {...rest} />;
    case 'calendar-plus-01':
      return <CalendarPlus01 {...rest} />;
    case 'upload-01':
      return <Upload01 {...rest} />;
    case 'upload-cloud-01':
      return <UploadCloudO1Icon {...rest} />;
    case 'download-01':
      return <Download01Icon {...rest} />;
    default:
    // return null;
  }

  return <></>;
};

export default IconByName;
