import React from 'react';

const FaceFrown = () => {
  return (
    <svg width="40" height="40" fill="none" viewBox="0 0 40 40">
      <g>
        <path
          fill="#E5484D"
          fillRule="evenodd"
          d="M20 5C11.716 5 5 11.716 5 20c0 8.284 6.716 15 15 15 8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15zM1.667 20C1.667 9.875 9.875 1.667 20 1.667c10.125 0 18.333 8.208 18.333 18.333 0 10.125-8.208 18.333-18.333 18.333C9.875 38.333 1.667 30.125 1.667 20zM12.5 15a2.5 2.5 0 115 0 2.5 2.5 0 01-5 0zm10 0a2.5 2.5 0 115 0 2.5 2.5 0 01-5 0zm-8.376 8.652c1.33-.967 3.333-1.985 5.876-1.985s4.546 1.018 5.876 1.985a11.57 11.57 0 011.538 1.34 9.387 9.387 0 01.53.603l.036.045.012.016.004.006.002.003s.002.002-1.331 1.002l1.333-1a1.667 1.667 0 01-2.661 2.007l-.008-.01a6.112 6.112 0 00-.323-.365 8.243 8.243 0 00-1.092-.951C22.954 25.648 21.623 25 20 25s-2.954.648-3.916 1.348a8.247 8.247 0 00-1.092.951 6.112 6.112 0 00-.323.365l-.007.009.002-.003.001-.001m0 0l-.003.004A1.668 1.668 0 0112 25.667l1.333 1-1.333-1 .004-.005.004-.006.012-.016.036-.045a9.429 9.429 0 01.53-.602c.353-.37.87-.855 1.538-1.34"
          clipRule="evenodd"></path>
      </g>
    </svg>
  );
};

export default FaceFrown;
