import { ACCESS_TOKEN_KEY } from '@/modules/AdminAuth';
import Auth from '@/modules/Auth';
import adminProtectedAxiosRequest from './helpers/adminProtectedAxiosRequest';
import authorizedRequest from './helpers/authorizedRequest';
import axios from 'axios';
import config from '@/utility/config';
import { getAxiosError, getAxiosErrorMsg } from './helpers';
import unAuthorizedRequest from './helpers/unAuthorizedRequest';
import Cookies from 'js-cookie';

const {
  apiStaticDataPath,
  apiPaymentBasePath,
  learnPortalBackend,
  communityApiBasePath,
  loginApiRoute
} = config;

export const enrollToCommunityWaitlistService = async (body) => {
  try {
    const uri = `${apiStaticDataPath}/api/v1/communities`;
    const res = await axios(uri, { method: 'POST', data: body });
    const data = await res.data;

    if (data.error) {
      const errMsg =
        data?.info ||
        data?.message ||
        data?.error ||
        'Something went wrong';
      throw new Error(errMsg);
    }

    return { data, error: false };
  } catch (e) {
    return { error: e.message };
  }
};

export const resendEmail = async (body) => {
  try {
    const apiPath = `${communityApiBasePath}/api/v1/communities/community-manager/resend-email`;
    const res = await adminProtectedAxiosRequest(apiPath, {
      method: 'POST',
      withCredentials: 'include',
      data: body
    });
    const data = await res.data;
    if (data.error) {
      const errMsg =
        data?.info ||
        data?.message ||
        data?.error ||
        'Something went wrong';
      throw new Error(errMsg);
    }
    return { data, error: false };
  } catch (err) {
    return { error: err.message };
  }
};

export const createCommunity = async (payload) => {
  try {
    const apiPath = `${apiPaymentBasePath}/api/v1/community-create`;
    const res = await axios(apiPath, {
      method: 'POST',
      data: payload
    });
    const data = await res.data;
    if (data.error) {
      const errMsg =
        data?.info ||
        data?.message ||
        data?.error ||
        'Something went wrong';
      throw new Error(errMsg);
    }
    return { data, error: false };
  } catch (err) {
    return { error: err.message };
  }
};

export const updateCommunityOnboardTrack = async (
  payload,
  communityId
) => {
  try {
    const apiPath = `${apiPaymentBasePath}/api/v1/community/${communityId}/update-tracking/`;
    const res = await axios(apiPath, {
      method: 'POST',
      data: payload
    });
    const data = await res.data;
    if (data.error) {
      const errMsg =
        data?.info ||
        data?.message ||
        data?.error ||
        'Something went wrong';
      throw new Error(errMsg);
    }
    return { data, error: false };
  } catch (err) {
    return { error: err.message };
  }
};

export const updateCommunity = async (communityId, communityData) => {
  try {
    const apiPath = `${communityApiBasePath}/api/v1/communities/${communityId}`;
    const res = await adminProtectedAxiosRequest(apiPath, {
      method: 'PUT',
      data: communityData,
      validateStatus: (status) => status <= 500
    });
    const data = await res.data;
    if (data.error || data.status === 'error') {
      const errMsg =
        data?.info ||
        data?.message ||
        data?.error ||
        'Something went wrong';
      throw new Error(errMsg);
    }
    return { data, error: false };
  } catch (err) {
    return { error: err.message };
  }
};

export const addAsCommunityManager = async (formData) => {
  try {
    const apiPath = `${communityApiBasePath}/api/v1/communities/community-manager`;
    const res = await adminProtectedAxiosRequest(apiPath, {
      method: 'POST',
      withCredentials: 'include',
      data: formData
    });
    const data = await res.data;

    if (res.error) {
      const errMsg =
        res?.info || res?.message || res?.error || 'Something went wrong';
      throw new Error(errMsg);
    }
    return { data, error: false };
  } catch (err) {
    return { error: getAxiosErrorMsg(err) };
  }
};

export const signUp = async (formData) => {
  try {
    const apiPath = `${loginApiRoute}/api/v1/sign-up`;
    const res = await axios(apiPath, {
      method: 'POST',
      withCredentials: 'include',
      data: formData
    })
      .then((res) => res)
      .catch((err) => {
        throw err;
      });
    const data = await res.data;
    if (data.error || data.status === 'error') {
      const errMsg =
        data?.info ||
        data?.message ||
        data?.error ||
        'Something went wrong';
      throw new Error(errMsg);
    }
    return { data, error: false };
  } catch (err) {
    return { error: err.message };
  }
};

export const signUpCheck = async (email) => {
  try {
    const apiPath = `${loginApiRoute}/api/v1/community-manager/signup-check`;
    const res = await axios(apiPath, {
      method: 'GET',
      params: {
        email
      }
    });
    const data = await res.data;
    if (data.error) {
      const errMsg =
        data?.info ||
        data?.message ||
        data?.error ||
        'Something went wrong';
      throw new Error(errMsg);
    }
    return { data, error: false };
  } catch (err) {
    return { error: err.message };
  }
};

export const getCommunityDataService = async (
  communitySlug,
  communityCode
) => {
  if (communitySlug && communityCode) {
    console.error('Please have only one option');
    return;
  }
  try {
    const apiPath = `${apiPaymentBasePath}/api/v1/community-data?${
      communitySlug ? `communitySlug=${communitySlug}` : ''
    }${communityCode ? `communityCode=${communityCode}` : ''}`;

    const res = await fetch(apiPath, {
      method: 'GET'
    });

    // catch non existing pages
    if (res?.status !== 200) {
      return {
        data: null,
        error: { message: res?.statusText, status: res?.status }
      };
    }

    const data = await res.json();

    if (data.error) {
      const errMsg =
        data?.info ||
        data?.message ||
        data?.error ||
        'Something went wrong';
      throw new Error(errMsg);
    }

    return { data, error: false };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const adminGetEventById = async ({ communityId, eventId }) => {
  try {
    const apiPath = `${communityApiBasePath}/api/v1/communities/${communityId}/event-admin/${eventId}`;
    const { data, error } = await adminProtectedAxiosRequest.get(apiPath);
    if (error) {
      throw new Error(error);
    }

    return { data };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getEventBySlug = async (slug) => {
  try {
    const apiPath = `${communityApiBasePath}/api/v1/event/${slug}`;
    const res = await fetch(apiPath, { method: 'GET' });
    // catch non existing pages
    if (res?.status !== 200) {
      return {
        data: null,
        error: { message: res?.statusText, status: res?.status }
      };
    }
    const data = await res.json();

    if (data.error) {
      const errMsg =
        data?.info ||
        data?.message ||
        data?.error ||
        'Something went wrong';
      throw new Error(errMsg);
    }

    return { data, error: false };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getAuthenticatedEventBySlug = async (slug) => {
  try {
    const apiPath = `${communityApiBasePath}/api/v1/event/private${slug}`;
    const authToken = Auth.getUserToken();
    let res = await fetch(apiPath, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });
    if (res.status === 401 && localStorage.getItem('adminAccessToken')) {
      res = await fetch(apiPath, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${Cookies.get(ACCESS_TOKEN_KEY)}`
        }
      });
    }
    // catch non existing pages
    if (res?.status !== 200) {
      return {
        data: null,
        error: { message: res?.statusText, status: res?.status }
      };
    }
    const data = await res.json();

    if (data.error) {
      const errMsg =
        data?.info ||
        data?.message ||
        data?.error ||
        'Something went wrong';
      throw new Error(errMsg);
    }

    return { data, error: false };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getCommunityDataByCode = async (communityCode) => {
  try {
    const apiPath = `${apiPaymentBasePath}/api/v1/community-data?communityCode=${communityCode}`;
    const res = await fetch(apiPath, { method: 'GET' });
    // catch non existing pages
    if (res?.status !== 200) {
      return {
        data: null,
        error: { message: res?.statusText, status: res?.status }
      };
    }

    const data = await res.json();

    if (data.error) {
      const errMsg =
        data?.info ||
        data?.message ||
        data?.error ||
        'Something went wrong';
      throw new Error(errMsg);
    }

    return { data, error: false };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const getCommunityPaths = async () => {
  try {
    const apiPath = `${apiPaymentBasePath}/api/v1/community-links`;
    const res = await fetch(apiPath, { method: 'GET' });
    const data = await res.json();
    if (data.error) {
      const errMsg =
        data?.info ||
        data?.message ||
        data?.error ||
        'Something went wrong';
      throw new Error(errMsg);
    }

    return { data, error: false };
  } catch (e) {
    return { data: null, error: e.message };
  }
};

export const getCommunityPricingService = async (productId) => {
  try {
    const apiPath = `${apiPaymentBasePath}/api/v1/get-community-pricing?product_id=${productId}`;
    const res = await fetch(apiPath, { method: 'GET' });
    const data = await res.json();
    if (data.error) {
      const errMsg =
        data?.info ||
        data?.message ||
        data?.error ||
        'Something went wrong';
      throw new Error(errMsg);
    }

    return { data, error: false };
  } catch (e) {
    return { error: e.message };
  }
};

export const getCommunityPricingServiceV2 = async (communityId) => {
  try {
    const apiPath = `${learnPortalBackend}/api/v1/communities/${communityId}/subscription-display-prices`;
    const res = await fetch(apiPath, { method: 'GET' });
    const data = await res.json();
    if (data.error) {
      const errMsg =
        data?.info ||
        data?.message ||
        data?.error ||
        'Something went wrong';
      throw new Error(errMsg);
    }

    return { data, error: false };
  } catch (e) {
    return { error: e.message };
  }
};

export const getCommunityPricingByDiscountCodeService = async (params) => {
  try {
    const path = `${apiPaymentBasePath}/api/v1/community-discount-details/`;

    const res = await axios.get(path, { params });
    return { data: res.data, error: false };
  } catch (e) {
    return {
      error: e?.response?.data?.info ?? 'Something went wrong!'
    };
  }
};

export const submitCommunityEnrollmenForm = async (payload) => {
  try {
    const authToken = sessionStorage.getItem('accessToken') || '';
    const path = `${apiPaymentBasePath}/api/v1/community-enrollment-application`;
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`
      },
      data: payload
    };

    const res = await axios(path, options);
    const data = await res.data;

    return data;
  } catch (e) {
    return {
      error: {
        message: getAxiosError(e).error ?? 'Something went wrong!',
        fieldError: e?.response?.data?.info?.field_errors ?? {}
      }
    };
  }
};

export const submitFreeCommunityEnrollment = async (
  payload = {},
  token
) => {
  try {
    const path = `${apiPaymentBasePath}/api/v1/community-enrolment-free`;
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    };

    if (token) {
      options.headers['Authorization'] = 'Bearer ' + token;
    }

    const { data, error } = await authorizedRequest(path, options);

    if (error) {
      throw new Error(error);
    }

    return data;
  } catch (e) {
    return { error: e.message };
  }
};

export const submitTokenGatedCommunityEnrollment = async () => {
  try {
    // TODO
    console.log(
      'mock service: submitting token gated community enrollment'
    );
  } catch (e) {
    return { error: e.message };
  }
};

export const getApplicableCollectionsCommunityDataService = async (
  communityCode
) => {
  try {
    const url = `${apiPaymentBasePath}/api/v1/community-web3-discount_methods?communityCode=`;
    const apiPath = `${url}${communityCode}`;
    const res = await fetch(apiPath, { method: 'GET' });
    const data = await res.json();

    if (data.error) {
      const errMsg =
        data?.info ||
        data?.message ||
        data?.error ||
        'Something went wrong';
      throw new Error(errMsg);
    }

    return { data, error: false };
  } catch (e) {
    return { error: e.message };
  }
};

export const applyCommunitySignUpDiscount = async (payload) => {
  try {
    const path = `${apiPaymentBasePath}/api/v1/community-sign-up-apply-discount/`;
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    };

    const { data, error } = await authorizedRequest(path, options);

    if (error) {
      throw new Error(error);
    }

    return { data, error };
  } catch (e) {
    return { error: e.message };
  }
};

export const startCommunityApplication = async (params) => {
  try {
    const apiPath = `${apiPaymentBasePath}/api/v1/start-community-application`;
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(params)
    };
    const { data, error } = await unAuthorizedRequest(apiPath, options);

    if (error) {
      throw new Error(error);
    }

    // if (data?.message !== 'Successfully signed up!') {
    //   const error = new Error(data.message || 'Something went wrong');
    //   error.errorCode = data.errorCode;

    //   throw error;
    // }

    return { data, error: false };
  } catch (error) {
    return { error: true, data: error };
  }
};

export const contactUsApplication = async (params) => {
  try {
    const apiPath = `${apiStaticDataPath}/api/v1/contact-us`;

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(params)
    };
    const { data, error } = await unAuthorizedRequest(apiPath, options);

    if (error) {
      throw new Error(error);
    }

    return { data, error: false };
  } catch (error) {
    return { error: true, data: error };
  }
};

export const getCommunitiesList = async (payload = {}) => {
  const { activeCommunityId } = payload;
  try {
    // const accessToken = Auth.getUserToken();
    const path = `${communityApiBasePath}/api/v1/communities`;
    const options = {
      method: 'GET',
      ...(activeCommunityId
        ? {
            params: {
              activeCommunityId
            }
          }
        : {})
    };

    const res = await adminProtectedAxiosRequest.get(path, options);

    const data = res.data;

    return { data, error: false };
  } catch (e) {
    return { data: {}, error: getAxiosError(e).error };
  }
};

export const getLimitAndStats = async (communityId) => {
  try {
    const path = `${communityApiBasePath}/api/v1/communities/${communityId}/magic-reach/limit-and-stats`;
    const options = {
      method: 'GET'
    };

    const res = await adminProtectedAxiosRequest.get(path, options);

    const data = res.data;

    return { data, error: false };
  } catch (e) {
    return getAxiosError(e);
  }
};

export const getResourceBySlug = async (slug) => {
  try {
    const apiPath = `${communityApiBasePath}/api/v1/resource/${slug}`;
    const res = await fetch(apiPath, { method: 'GET' });
    // catch non existing pages
    if (res?.status !== 200) {
      return {
        data: null,
        error: { message: res?.statusText, status: res?.status }
      };
    }
    const data = await res.json();

    if (data.error) {
      const errMsg =
        data?.info ||
        data?.message ||
        data?.error ||
        'Something went wrong';
      throw new Error(errMsg);
    }

    return { data, error: false };
  } catch (e) {
    return { data: null, error: e };
  }
};

export const inviteMembersByCommunityId = async (communityId, payload) => {
  try {
    const apiPath = `${communityApiBasePath}/api/v1/communities/${communityId}/invite-members`;
    const { data, error } = await adminProtectedAxiosRequest.post(
      apiPath,
      payload
    );
    if (error) {
      throw new Error(error);
    }

    return { data, error: false };
  } catch (error) {
    return { error: true, data: getAxiosError(error) };
  }
};

/**
 * Payload = {
    data: [
      {
        email, (mandatory)
        phoneNumber,
        firstName,
        lastName
        },
        ...
    ]
  }
 */
export const inviteMembersInBulk = async (communityId, payload) => {
  try {
    const apiPath = `${communityApiBasePath}/api/v1/communities/${communityId}/bulk-invite-members`;
    const { data, error } = await adminProtectedAxiosRequest.post(
      apiPath,
      payload
    );
    if (error) {
      throw new Error(error);
    }

    return { data, error: false };
  } catch (error) {
    return { error: true, data: getAxiosError(error) };
  }
};

export const getCommunityAdmins = async (communityId) => {
  try {
    const apiPath = `${communityApiBasePath}/api/v1/communities/admin/${communityId}`;
    const { data, error } = await adminProtectedAxiosRequest.get(apiPath);
    if (error) {
      throw new Error(error);
    }

    return { data, error: false };
  } catch (error) {
    return { error: true, data: getAxiosError(error) };
  }
};

export const getUserStatus = async (communityId, emails) => {
  try {
    const emailString = Array.isArray(emails)
      ? emails.map((e) => encodeURIComponent(e)).join(',')
      : emails;
    const apiPath = `${communityApiBasePath}/api/v1/communities/admin-status/${communityId}?emails=${emailString}`;
    const { data, error } = await adminProtectedAxiosRequest.get(apiPath);
    if (error) {
      throw new Error(error);
    }

    return { data, error: false };
  } catch (error) {
    return { error: true, data: getAxiosError(error) };
  }
};

export const addCommunityManagers = async (communityId, payload) => {
  try {
    const apiPath = `${communityApiBasePath}/api/v1/communities/admin/${communityId}`;
    const { data, error } = await adminProtectedAxiosRequest.post(
      apiPath,
      payload
    );
    if (error) {
      throw new Error(error);
    }

    return { data, error: false };
  } catch (error) {
    return { error: true, data: getAxiosError(error) };
  }
};

export const removeCommunityManagers = async (communityId, email) => {
  try {
    const apiPath = `${communityApiBasePath}/api/v1/communities/admin/${communityId}?email=${email}`;
    const { data, error } = await adminProtectedAxiosRequest.delete(
      apiPath
    );
    if (error) {
      throw new Error(error);
    }

    return { data, error: false };
  } catch (error) {
    return { error: true, data: getAxiosError(error) };
  }
};

export const removeWhatsappOnlyMember = async (params) => {
  try {
    const { communityId, whatsappId } = params;
    const apiPath = `${communityApiBasePath}/api/v1/communities/${communityId}/whatsapp/${whatsappId}`;
    const res = await adminProtectedAxiosRequest.delete(apiPath);
    console.log('>> removeWhatsappOnlyMember res ', res);
    const { data, error } = res;
    if (error) {
      throw new Error(error);
    }
    return { data, error: false };
  } catch (error) {
    return { error: true, data: getAxiosError(error) };
  }
};

export const removeCommunityMember = async ({
  communityId,
  // email,
  removalReason,
  learnerId
}) => {
  try {
    const apiPath = `${communityApiBasePath}/api/v1/communities/${communityId}/community-manager/remove-member`;
    const { data, error } = await adminProtectedAxiosRequest.post(
      apiPath,
      {
        communityId,
        // removeMemberEmail: email,
        removalReason,
        learnerId
      }
    );
    if (error) {
      throw new Error(error);
    }

    if (data.error) {
      return {
        data: null,
        error: data.error
      };
    }

    return { data, error: false };
  } catch (error) {
    // return { error: true, data: getAxiosError(error) };
    return { error: getAxiosError(error) };
  }
};

export const doesCommunityMemberHavePaidEvent = async (
  communityId,
  learnerId
) => {
  try {
    const apiPath = `${communityApiBasePath}/api/v1/communities/${communityId}/${learnerId}/paid-events`;
    const { data, error } = await adminProtectedAxiosRequest.get(apiPath);
    if (error) {
      throw new Error(error);
    }
    return { data, error: false };
  } catch (error) {
    return { error: true, data: getAxiosError(error) };
  }
};

export const getAllDiscordChannels = async (communityId) => {
  try {
    const apiPath = `${communityApiBasePath}/api/v1/communities/${communityId}/getAllChannelsFromDiscord/`;
    const { data, error } = await adminProtectedAxiosRequest.get(apiPath);
    if (error) {
      throw new Error(error);
    }
    return { ...data, error: false };
  } catch (error) {
    return { error: true, data: getAxiosError(error) };
  }
};

export const setDiscordAnnouncementChannel = async (
  communityId,
  payload
) => {
  try {
    const apiPath = `${communityApiBasePath}/api/v1/communities/${communityId}/setDiscordAnnouncementChannel`;
    const { data, error } = await adminProtectedAxiosRequest.post(
      apiPath,
      payload
    );
    if (error) {
      throw new Error(error);
    }
    return { ...data, error: false };
  } catch (error) {
    return { error: true, data: getAxiosError(error) };
  }
};

export const sendDiscordMessage = async (communityId, payload) => {
  try {
    const apiPath = `${communityApiBasePath}/api/v1/communities/${communityId}/send-discord-message`;
    const { data, error } = await adminProtectedAxiosRequest.post(
      apiPath,
      payload
    );
    if (error) {
      throw new Error(error);
    }
    return { data, error: false };
  } catch (error) {
    return { error: true, data: getAxiosError(error) };
  }
};

export const sendWhatsappMessage = async (communityId, payload) => {
  try {
    const apiPath = `${communityApiBasePath}/api/v1/communities/${communityId}/communicate/send-whatsapp-template-msg`;
    const { data, error } = await adminProtectedAxiosRequest.post(
      apiPath,
      payload
    );
    if (error) {
      throw new Error(error);
    }
    return { data, error: false };
  } catch (error) {
    return { error: true, data: getAxiosError(error) };
  }
};

export const checkMember = async (communityId) => {
  try {
    const apiPath = `${communityApiBasePath}/api/v1/communities/${communityId}/isMember`;
    const { data, error } = await adminProtectedAxiosRequest.get(apiPath);
    if (error) {
      throw new Error(error);
    }
    return { data, error: false };
  } catch (error) {
    return { error: true, data: error };
  }
};
