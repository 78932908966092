import config from '../../utility/config';
import {
  COMEDY_SKITS_LOW_COST_COURSE_CODE,
  COMEDY_SKITS_WITH_MARKIAN_COURSE_CODE,
  coursesData,
  CREATOR_BUSINESS_COURSE_CODE,
  DEAR_ALYNE_COURSE_CODE,
  EDITING_FINAL_CUT_PRO_COURSE_CODE,
  LEARN_FROM_ERWAN_HEUSSAFF_COURSE_CODE,
  LEARN_FROM_JAMES_DEAKIN_COURSE_CODE,
  LEARN_FROM_JESSICA_SOHO_COURSE_CODE,
  LEARN_FROM_MICHAEL_CINCO_COURSE_CODE,
  LEARN_FROM_MOIRA_COURSE_CODE,
  MAV_BASKETBALL_COURSE_CODE,
  TABOO_TOPICS_COURSE_CODE,
  VISUAL_STORYTELLER_COURSE_CODE,
  WISE_WITH_MONEY_COURSE_CODE
} from '../coursesData';

const staticAssetBaseUrl = config.imagesBasePath;

// TODO move this to static api
const courseList = [
  {
    courseCode: CREATOR_BUSINESS_COURSE_CODE,
    isWaitlist: true
  },
  {
    courseCode: LEARN_FROM_ERWAN_HEUSSAFF_COURSE_CODE,
    isWaitlist: true
  },
  {
    courseCode: LEARN_FROM_JAMES_DEAKIN_COURSE_CODE,
    isWaitlist: true
  },
  {
    courseCode: VISUAL_STORYTELLER_COURSE_CODE,
    isWaitlist: true
  },
  {
    courseCode: LEARN_FROM_JESSICA_SOHO_COURSE_CODE,
    isWaitlist: true
  },
  {
    courseCode: MAV_BASKETBALL_COURSE_CODE,
    isWaitlist: true
  },
  {
    courseCode: LEARN_FROM_MICHAEL_CINCO_COURSE_CODE,
    isWaitlist: true
  },
  {
    courseCode: LEARN_FROM_MOIRA_COURSE_CODE,
    isWaitlist: true
  },
  {
    courseCode: WISE_WITH_MONEY_COURSE_CODE,
    isWaitlist: true
  },
  {
    courseCode: EDITING_FINAL_CUT_PRO_COURSE_CODE,
    isWaitlist: false
  },
  {
    title: 'Art of Storytelling',
    duration: '1',
    recommendedtext: '',
    sessions: ['Scripting', 'Editing'],
    proSessions: [],
    classTime: '4 hours',
    videoTime: 'Observe Creators',
    instructorCount: 5,
    studentCount: 200,
    price: '79',
    discountPrice: null,
    beforeDiscountPrice: null,
    priceSubtext: 'for the whole course.',
    currency: 'USD',
    benefits: [
      'Live scripting exercises with Nas Daily team.',
      'A complete understanding of how to tell stories for the digital world.',
      'Tons of offline resources to help you grow.',
      'Certificate of Completion.'
    ],
    courseCode: 'NS',
    courseImgData: {
      alt: 'Art of Storytelling',
      mobileImgData: {
        src: `${staticAssetBaseUrl}/na-website/Payment/png/AoSCourseImg.png`,
        webpSrc: `${staticAssetBaseUrl}/na-website/Payment/webp/AoSCourseImg.webp`
      }
    },
    courseImg: `${staticAssetBaseUrl}/na-website/png/thumbAoS.png`,
    pdfLink: `${staticAssetBaseUrl}/curriculum/Art_of_Storytelling_Curriculum.pdf`
  },
  {
    title: 'Photography Course',
    duration: '20',
    classTime: '20 hours',
    instructorCount: 2,
    studentCount: 30,
    price: '399',
    priceSubtext: 'for the whole course.',
    discountPrice: null,
    beforeDiscountPrice: null,
    discountText: [
      { text: 'Get up to 20% OFF', isHighlighted: true },
      { text: '  when enrolling with 2 or more friends.' }
    ],
    currency: 'USD',
    benefits: [
      '8 fun sessions in 4 weeks.',
      '20 Hours of Teaching.',
      'How to Master Photography.',
      'How to take the perfect product picture.',
      'How to monetize your photography skills.',
      'Certificate of Achievement.'
    ],
    courseCode: 'NP',
    courseImgData: {
      alt: 'Photography Course',
      mobileImgData: {
        src: `${staticAssetBaseUrl}/na-website/Photography/jpg/courseImage.jpg`
      }
    },
    courseImg: `${staticAssetBaseUrl}/na-website/png/trial-benefit-1.png`
  },
  {
    title: 'Go All In',
    duration: '8',
    recommendedtext: '',
    sessions: ['Scripting', 'Shooting', 'Editing', 'Publishing'],
    proSessions: ['Pro Scripting', 'Pro Shooting', 'Pro Editing'],
    classTime: '20 hours',
    videoTime: 'Make 3 videos',
    instructorCount: 2,
    studentCount: 20,
    price: '499',
    priceSubtext: 'for the whole course.',
    discountPrice: null,
    beforeDiscountPrice: null,
    discountText: [
      { text: 'Get up to 20% OFF', isHighlighted: true },
      { text: '  when enrolling with 2 or more friends.' }
    ],
    currency: 'USD',
    benefits: [
      '8 live sessions with 20 hrs class time',
      'Create 3 videos with your own story.',
      'Library of copyright free songs & sound effects.',
      'Certificate of Achievement.'
    ],
    courseCode: 'NG',
    courseImgData: {
      alt: 'Go All In',
      mobileImgData: {
        webpSrc: `${staticAssetBaseUrl}/na-website/CourseImages/curriculumImage/cmcCourseImg-w-text.webp`,
        src: `${staticAssetBaseUrl}/na-website/Payment/png/CMCCourseImg.png`,
        meta: {
          width: 450,
          height: 480
        }
      }
    },
    courseImg: `${staticAssetBaseUrl}/na-website/png/thumbMasterCourse.png`,
    pdfLink: `${staticAssetBaseUrl}/curriculum/Creator_Mastercourse_Curriculum.pdf`
  },
  {
    title: 'The Unstoppable Condifence',
    duration: '1',
    recommendedtext: '',
    sessions: ['Scripting', 'Editing'],
    proSessions: [],
    classTime: '4 hours',
    videoTime: 'Observe Creators',
    instructorCount: 5,
    studentCount: 200,
    price: '149',
    discountPrice: null,
    beforeDiscountPrice: null,
    priceSubtext: 'for the whole course.',
    currency: 'USD',
    benefits: [
      '4 hour Live class with Project Nightfall.',
      'Discover your on camera identity.',
      'Understand the Psychology of Viewers.',
      'Get your Certificate of Accomplishment.'
    ],
    courseCode: 'TUC',
    courseImgData: {
      alt: 'The Unstopbbale Confidence',
      mobileImgData: {
        src: `${staticAssetBaseUrl}/na-website/1-Day-Course/png/tuc_thumbnail.png`,
        webpSrc: `${staticAssetBaseUrl}/na-website/1-Day-Course/tuc_thumbnail.webp`
      }
    },
    courseImg: `${staticAssetBaseUrl}/na-website/png/thumbAoS.png`,
    pdfLink: `${staticAssetBaseUrl}/curriculum/Art_of_Storytelling_Curriculum.pdf`
  },
  {
    title: 'Unlock Tiktok with Ming',
    duration: '4',
    recommendedtext: '',
    sessions: ['Scripting', 'Editing', 'Shooting'],
    proSessions: [],
    classTime: '10 hours',
    videoTime: 'Make 1 video',
    instructorCount: 2,
    studentCount: 30,
    price: '49',
    priceSubtext: 'for the whole course.',
    discountPrice: '49',
    beforeDiscountPrice: '99',
    discountText: [
      { text: 'Get up to 20% OFF', isHighlighted: true },
      { text: '  when enrolling with 2 or more friends.' }
    ],
    currency: 'USD',
    benefits: [
      '4 hour LIVE class with MING & Friends.',
      'Learn how to Start, Earn & Grow on TikTok.',
      'Hands on Guidance.',
      'Get your Certificate of Accomplishment.'
    ],
    courseCode: 'MTIK',
    courseImgData: {
      alt: 'Tiktok Course',
      mobileImgData: {
        src: `${staticAssetBaseUrl}/na-website/MingTikTok/png/mtikCourseImg.png`,
        webpSrc: `${staticAssetBaseUrl}/na-website/MingTikTok/webp/mtikCourseImg.webp`
      }
    },
    courseImg: `${staticAssetBaseUrl}/na-website/png/trial-benefit-1.png`,
    sectionTitle: 'Join my class now!',
    checkmarkIconSrc: `${staticAssetBaseUrl}/na-website/MingTikTok/png/checkList.png`
  },
  coursesData[COMEDY_SKITS_WITH_MARKIAN_COURSE_CODE],
  coursesData[COMEDY_SKITS_LOW_COST_COURSE_CODE],
  coursesData[TABOO_TOPICS_COURSE_CODE],
  {
    title: 'Dear Alyne',
    price: '99',
    priceSubtext: 'for the whole course.',
    discountPrice: null,
    beforeDiscountPrice: null,
    discountText:
      'Get up to 20% OFF when enrolling with 2 or more friends.',
    currency: 'USD',
    benefits: [
      '4 hours of live learning with Alyne.',
      'Understand the Content Creation Process.',
      'Learn the anatomy of a successful video.',
      'Access to Alumni Community.'
    ],
    courseCode: DEAR_ALYNE_COURSE_CODE,
    courseImgData: {
      alt: 'Dear Alyne Image',
      mobileImgData: {
        src: `${staticAssetBaseUrl}/na-website/NewHome/png/homeDA.png`
      }
    },
    courseImg: `${staticAssetBaseUrl}/na-website/png/trial-benefit-1.png`
  }
];

export default courseList;
