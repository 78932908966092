import React from 'react';

const Hub = ({ fill, customClassNames }) => {
  return (
    <svg
      className={`c-Hub ${customClassNames}`}
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32">
      <path
        fill="#000"
        stroke={fill}
        strokeWidth="2.5"
        d="M25.69 14.814l-.311-.48-.128-.893v-.013l-.001-.095a2.745 2.745 0 012.75-2.75c1.528 0 2.75 1.219 2.75 2.736A2.745 2.745 0 0128 16.07a2.748 2.748 0 01-2.31-1.255zm-5.488.29l.376.71.12.777a4.736 4.736 0 01-1.15 3.895l-.533.597-.715.397c-1.427.792-3.18.8-4.61.002l-.72-.403-.518-.579a4.768 4.768 0 01-1.15-3.896l.118-.76.392-.74a4.708 4.708 0 013.39-2.444l.805-.133.804.133a4.708 4.708 0 013.39 2.444zm1.59 10.572l.412-.558.666-.298a2.723 2.723 0 011.13-.237 2.745 2.745 0 012.75 2.75 2.745 2.745 0 01-2.75 2.75 2.745 2.745 0 01-2.75-2.75c0-.631.203-1.199.541-1.657zm-12.687-.854l.69.327.41.54c.343.452.545 1.013.545 1.644A2.745 2.745 0 018 30.083a2.745 2.745 0 01-2.75-2.75A2.745 2.745 0 018 24.583c.41 0 .773.082 1.105.24zM6.748 13.441l-.131.92-.301.457A2.766 2.766 0 014 16.084a2.745 2.745 0 01-2.75-2.75A2.745 2.745 0 014 10.583a2.745 2.745 0 012.748 2.858zm9.944-6.103l-.683.181-.69-.182a2.77 2.77 0 01-2.069-2.671A2.745 2.745 0 0116 1.916a2.745 2.745 0 012.75 2.75c0 1.28-.88 2.359-2.058 2.672zM1.417 13.333A2.587 2.587 0 004 15.916a2.587 2.587 0 002.583-2.583A2.587 2.587 0 004 10.749a2.587 2.587 0 00-2.583 2.584zm4 14A2.587 2.587 0 008 29.916a2.587 2.587 0 002.583-2.583A2.587 2.587 0 008 24.749a2.587 2.587 0 00-2.583 2.584zM16 2.083a2.587 2.587 0 00-2.583 2.583A2.587 2.587 0 0016 7.249a2.587 2.587 0 002.583-2.583A2.587 2.587 0 0016 2.083zm-4.583 15.25a4.584 4.584 0 009.166 0 4.585 4.585 0 00-9.166 0zm15.166 10A2.587 2.587 0 0024 24.749a2.587 2.587 0 00-2.583 2.584A2.587 2.587 0 0024 29.916a2.587 2.587 0 002.583-2.583zm-1.166-14A2.587 2.587 0 0028 15.916a2.587 2.587 0 002.583-2.583A2.587 2.587 0 0028 10.749a2.587 2.587 0 00-2.583 2.584z"></path>
    </svg>
  );
};

Hub.defaultProps = {
  fill: '#191C1E',
  customClassNames: ''
};

export default Hub;
