import React from 'react';

const TiktokGreyIcon = ({ customClassNames, width, height }) => {
  const className = 'c-TiktokGreyIcon';

  return (
    <svg
      width={width}
      height={height}
      viewBox={'0 0 30 34'}
      fill="none"
      className={`${customClassNames}`}>
      <path
        d="M15.758 0.361134C17.641 0.333008 19.5133 0.349675 21.3833 0.333008C21.4966 2.45916 22.2887 4.6249 23.9009 6.12811C25.5098 7.66882 27.7857 8.37406 30 8.61262V14.2056C27.9249 14.14 25.84 13.7233 23.957 12.8608C23.1369 12.5024 22.3729 12.0409 21.6251 11.569C21.6153 15.6276 21.6423 19.6809 21.5981 23.7228C21.4859 25.6646 20.8222 27.597 19.6525 29.1971C17.7705 31.8607 14.504 33.5973 11.1491 33.6515C9.09125 33.765 7.03555 33.2233 5.28201 32.2253C2.37598 30.5711 0.331071 27.5428 0.0332375 24.2926C-0.00427776 23.6043 -0.0100387 22.9147 0.0159718 22.2258C0.274957 19.583 1.62924 17.0547 3.73133 15.3349C6.114 13.3316 9.45167 12.3774 12.5768 12.942C12.6059 14.9994 12.5206 17.0547 12.5206 19.1121C11.093 18.6663 9.42469 18.7913 8.17725 19.6278C7.26459 20.2082 6.57488 21.0621 6.21435 22.0581C5.91652 22.7623 6.00177 23.5447 6.01904 24.2926C6.36111 26.5719 8.63155 28.4876 11.0552 28.2803C12.662 28.2637 14.2019 27.3636 15.0393 26.0458C15.3101 25.5844 15.6134 25.1125 15.6296 24.5697C15.7709 22.0852 15.7148 19.6111 15.7321 17.1266C15.7439 11.5274 15.7148 5.94372 15.759 0.362176L15.758 0.361134Z"
        fill="black"
        fillOpacity="0.25"
      />
    </svg>
  );
};

export default TiktokGreyIcon;
