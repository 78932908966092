import React from 'react';

const EditDocument = ({ customClassNames, fill }) => {
  return (
    <svg
      className={`c-EditDocument ${customClassNames}`}
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16">
      <mask
        id="mask0_4477_709925"
        style={{ maskType: 'alpha' }}
        width="16"
        height="17"
        x="0"
        y="-1"
        maskUnits="userSpaceOnUse">
        <path fill="#D9D9D9" d="M0 0H16V16H0z"></path>
      </mask>
      <g mask="url(#mask0_4477_709925)">
        <path
          fill={fill}
          d="M4.4 14.4c-.333 0-.617-.117-.85-.35a1.155 1.155 0 01-.35-.85V2.8c0-.334.117-.617.35-.85.233-.234.517-.35.85-.35h4.7a1.15 1.15 0 01.85.35l2.5 2.5c.122.122.211.255.267.4.055.144.083.294.083.45V8h-1.2V5.6H9.4a.578.578 0 01-.425-.176A.578.578 0 018.8 5V2.8H4.4v10.4H8v1.2H4.4zm7.883-4.784l.6.584L10 13.033v.567h.566l2.884-2.85.55.55-2.917 2.916a.667.667 0 01-.191.134.578.578 0 01-.242.05H9.5a.285.285 0 01-.208-.093.285.285 0 01-.092-.207v-1.15c0-.09.016-.17.05-.242a.668.668 0 01.133-.192l2.9-2.9zM14 11.3l-1.717-1.684.983-.983a.8.8 0 01.568-.242c.221-.005.41.07.566.225l.566.583a.771.771 0 01.234.567.772.772 0 01-.234.567L14 11.3z"></path>
      </g>
    </svg>
  );
};

EditDocument.defaultProps = {
  customClassNames: '',
  fill: '#191C1E'
};

export default EditDocument;
