import PropTypes from 'prop-types';
import React from 'react';

const className = 'c-LockIcon';
const LockIcon = ({ width, height, fill, customClassNames }) => {
  return (
    <svg
      className={`${className} ${customClassNames}`}
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none">
      <path
        d="M18.5 8H17.5V6C17.5 3.24 15.26 1 12.5 1C9.74 1 7.5 3.24 7.5 6V8H6.5C5.4 8 4.5 8.9 4.5 10V20C4.5 21.1 5.4 22 6.5 22H18.5C19.6 22 20.5 21.1 20.5 20V10C20.5 8.9 19.6 8 18.5 8ZM12.5 17C11.4 17 10.5 16.1 10.5 15C10.5 13.9 11.4 13 12.5 13C13.6 13 14.5 13.9 14.5 15C14.5 16.1 13.6 17 12.5 17ZM15.6 8H9.4V6C9.4 4.29 10.79 2.9 12.5 2.9C14.21 2.9 15.6 4.29 15.6 6V8Z"
        fill={fill}
      />
    </svg>
  );
};

LockIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  customClassNames: PropTypes.string
};

LockIcon.defaultProps = {
  width: '25',
  height: '24',
  fill: '#191C1E',
  customClassNames: ''
};

export default LockIcon;
