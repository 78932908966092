import React from 'react';

const className = 'c-MemberAddIcon';
const MemberAddIcon = ({ width, height, fill, customClassNames }) => {
  return (
    <svg
      className={`${className} ${customClassNames}`}
      width={width}
      height={height}
      viewBox="0 0 16 14"
      fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.66705 1.66667C5.37838 1.66667 4.33371 2.71133 4.33371 4C4.33371 5.28866 5.37838 6.33333 6.66705 6.33333C7.95571 6.33333 9.00038 5.28866 9.00038 4C9.00038 2.71133 7.95571 1.66667 6.66705 1.66667ZM3.00038 4C3.00038 1.97495 4.642 0.333332 6.66705 0.333332C8.69209 0.333332 10.3337 1.97495 10.3337 4C10.3337 6.02504 8.69209 7.66667 6.66705 7.66667C4.642 7.66667 3.00038 6.02504 3.00038 4ZM12.667 8.33333C13.0352 8.33333 13.3337 8.63181 13.3337 9V10.3333H14.667C15.0352 10.3333 15.3337 10.6318 15.3337 11C15.3337 11.3682 15.0352 11.6667 14.667 11.6667H13.3337V13C13.3337 13.3682 13.0352 13.6667 12.667 13.6667C12.2989 13.6667 12.0004 13.3682 12.0004 13V11.6667H10.667C10.2989 11.6667 10.0004 11.3682 10.0004 11C10.0004 10.6318 10.2989 10.3333 10.667 10.3333H12.0004V9C12.0004 8.63181 12.2989 8.33333 12.667 8.33333ZM4.88456 8.66664C4.9226 8.66665 4.9612 8.66667 5.00038 8.66667H8.00038C8.36857 8.66667 8.66705 8.96514 8.66705 9.33333C8.66705 9.70152 8.36857 10 8.00038 10H5.00038C4.0215 10 3.67985 10.0072 3.41981 10.0861C2.78061 10.28 2.2804 10.7802 2.0865 11.4194C2.00762 11.6795 2.00038 12.0211 2.00038 13C2.00038 13.3682 1.7019 13.6667 1.33371 13.6667C0.965525 13.6667 0.667048 13.3682 0.667048 13C0.667048 12.9608 0.667033 12.9222 0.667019 12.8842C0.666717 12.066 0.66651 11.5073 0.810581 11.0324C1.13375 9.96704 1.96743 9.13337 3.03276 8.8102C3.5077 8.66613 4.06638 8.66633 4.88456 8.66664Z"
        fill={fill}
      />
    </svg>
  );
};

MemberAddIcon.defaultProps = {
  width: '16',
  height: '14',
  fill: '#F5F5F5',
  customClassNames: ''
};

export default MemberAddIcon;
