import classNames from 'classnames';

const IconLLeft = ({ fill, customClassNames }) => {
  return (
    <svg
      className={classNames('c-IconLLeft', customClassNames)}
      width="21"
      height="20"
      fill="none"
      viewBox="0 0 21 20">
      <path
        fill={fill}
        d="M6.273 5.683v8.633a.832.832 0 001.283.7L14.34 10.7a.834.834 0 000-1.409L7.556 4.983a.832.832 0 00-1.283.7z"></path>
    </svg>
  );
};

export default IconLLeft;
