import React from 'react';

const CheckCircle = ({ customClassNames, fill, width, height }) => {
  return (
    <svg
      className={`c-CheckCircle ${customClassNames}`}
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 24 24">
      <path
        fill={fill}
        fillRule="evenodd"
        d="M12 3a9 9 0 100 18 9 9 0 000-18zM1 12C1 5.925 5.925 1 12 1s11 4.925 11 11-4.925 11-11 11S1 18.075 1 12zm16.207-3.707a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-3-3a1 1 0 111.414-1.414l2.293 2.293 5.293-5.293a1 1 0 011.414 0z"
        clipRule="evenodd"></path>
    </svg>
  );
};

CheckCircle.defaultProps = {
  customClassNames: '',
  fill: '#EBB800',
  width: 24,
  height: 24
};

export default CheckCircle;
