import React from 'react';

const TokenNftWeb3 = ({ fill, customClassNames }) => {
  return (
    <svg
      className={`c-TokenNftWeb3 ${customClassNames}`}
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32">
      <path
        fill={fill}
        d="M16 29c-.2 0-.389-.022-.566-.066a1.871 1.871 0 01-.534-.234l-9.766-5.533a2.219 2.219 0 01-.834-.8c-.2-.334-.3-.711-.3-1.133v-10.7c0-.4.106-.772.317-1.117.211-.344.483-.617.817-.817l9.8-5.366c.155-.09.322-.156.5-.2.177-.045.366-.067.566-.067.2 0 .389.022.566.067.178.044.345.11.5.2l9.8 5.366c.334.2.606.473.817.817.211.345.317.717.317 1.117v10.7c0 .422-.1.8-.3 1.133-.2.333-.478.6-.834.8L17.1 28.7c-.178.111-.356.19-.534.234A2.334 2.334 0 0116 29zm-3.834-16.4a6.261 6.261 0 011.75-1.233A4.801 4.801 0 0116 10.9c.733 0 1.428.156 2.083.467a6.261 6.261 0 011.75 1.233l4.733-2.733L16 5.234 7.434 9.867l4.732 2.733zM14.9 26.167V21a5.446 5.446 0 01-2.866-1.85c-.756-.922-1.134-1.972-1.134-3.15 0-.222.011-.455.034-.7.022-.244.077-.488.166-.733L6.234 11.7v9.534l8.666 4.933zM16 18.9c.8 0 1.483-.283 2.05-.85.566-.566.85-1.25.85-2.05 0-.8-.284-1.483-.85-2.05A2.792 2.792 0 0016 13.1c-.8 0-1.483.284-2.05.85A2.792 2.792 0 0013.1 16c0 .8.284 1.484.85 2.05.567.567 1.25.85 2.05.85zm1.1 7.267l8.666-4.933V11.7L20.9 14.567c.09.267.145.517.166.75.023.233.034.461.034.683 0 1.178-.378 2.228-1.134 3.15A5.446 5.446 0 0117.1 21v5.167z"></path>
    </svg>
  );
};

TokenNftWeb3.defaultProps = {
  fill: '#191C1E',
  customClassNames: ''
};

export default TokenNftWeb3;
