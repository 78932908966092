import React from 'react';

const NPLUsers = ({ customClassNames }) => {
  return (
    <svg
      className={`c-NPLUsers fill-current ${customClassNames}`}
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        d="M9.5 4a3 3 0 100 6 3 3 0 000-6zm-5 3a5 5 0 1110 0 5 5 0 01-10 0zm10.073-4.084a1 1 0 011.302-.552 5.001 5.001 0 010 9.272 1 1 0 01-.75-1.854 3.001 3.001 0 000-5.564 1 1 0 01-.552-1.302zM7.964 14h3.072c.901 0 1.629 0 2.22.04.61.042 1.148.13 1.657.34a5 5 0 012.706 2.707c.212.51.3 1.048.34 1.656.041.592.041 1.32.041 2.222V21a1 1 0 11-2 0c0-.946 0-1.605-.036-2.12-.034-.508-.099-.803-.192-1.028a3 3 0 00-1.624-1.624c-.225-.093-.52-.158-1.027-.192C12.605 16 11.946 16 11 16H8c-.946 0-1.605 0-2.12.036-.507.034-.803.099-1.028.192a3 3 0 00-1.624 1.624c-.093.225-.158.52-.192 1.027C3 19.395 3 20.054 3 21a1 1 0 11-2 0v-.035c0-.902 0-1.63.04-2.222.042-.608.13-1.147.34-1.656a5 5 0 012.707-2.706c.51-.212 1.048-.3 1.656-.34C6.335 14 7.063 14 7.964 14zm10.068.877a1 1 0 011.217-.72A5.002 5.002 0 0123 19v2a1 1 0 11-2 0v-2a3.002 3.002 0 00-2.25-2.905 1 1 0 01-.718-1.218z"
        clipRule="evenodd"></path>
    </svg>
  );
};

NPLUsers.defaultProps = {
  customClassNames: 'fill-npl-yellow-light-8'
};

export default NPLUsers;
