import React from 'react';

const className = 'c-IconV2-Check';

const Check = ({ width, height, customClassNames, fill }) => {
  return (
    <svg
      className={`${className} ${customClassNames}`}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none">
      <path
        fill={fill}
        d="M9.126 16.782L5.31 12.967a1.058 1.058 0 111.498-1.498l3.071 3.06 7.31-7.31a1.058 1.058 0 111.5 1.497l-8.066 8.066a1.058 1.058 0 01-1.498 0z"
      />
    </svg>
  );
};

Check.defaultProps = {
  customClassNames: '',
  fill: '#FBC91B',
  width: 24,
  height: 24
};

export default Check;
