const unAuthorizedRequest = async (path, options) => {
  try {
    const res = await fetch(path, options);

    const data = await res.json();

    // Check Res Status for Error.
    if (res.status >= 500) {
      const msg = data?.message || 'Internal Server Error';
      throw new Error(msg);
    }

    if (res.status >= 400) {
      const msg = data?.info || data?.message || 'Not Found.';
      throw new Error(msg);
    }

    return { error: false, data };
  } catch (e) {
    return { error: e, data: {} };
  }
};

export default unAuthorizedRequest;
