import React from 'react';

const CollectionsBookmark = ({ customClassNames, fill = '#75777A' }) => {
  return (
    <svg
      className={`c-CollectionsBookmark ${customClassNames}`}
      width="16"
      height="17"
      fill="none"
      viewBox="0 0 16 17">
      <path
        fill={fill}
        d="M11.333 14h-8a.669.669 0 01-.666-.668v-8c0-.366-.3-.666-.667-.666-.367 0-.667.3-.667.666V14c0 .733.6 1.333 1.334 1.333h8.666c.367 0 .667-.3.667-.666 0-.367-.3-.667-.667-.667zm2-12h-8C4.6 2 4 2.6 4 3.331v8c0 .734.6 1.334 1.333 1.334h8c.734 0 1.334-.6 1.334-1.334v-8c0-.733-.6-1.333-1.334-1.333zm0 6.666l-1.666-1-1.667 1V3.332h3.333v5.334z"></path>
    </svg>
  );
};

export default CollectionsBookmark;
