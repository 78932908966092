import React from 'react';

const NPLUserShield = ({ customClassNames }) => {
  return (
    <svg
      width="22"
      height="24"
      viewBox="0 0 22 24"
      fill="none"
      className={`c-NPLUserShield fill-current ${customClassNames}`}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.8996 5.34887L11.5083 0.131478C11.1922 -0.0438261 10.8102 -0.0438261 10.4941 0.131478L1.10275 5.34887C0.771972 5.53252 0.566406 5.88104 0.566406 6.25983C0.566406 6.984 0.685364 23.999 11.0012 23.999C21.317 23.999 21.436 6.984 21.436 6.25983C21.436 5.88104 21.2304 5.53252 20.8996 5.34887ZM11.0012 6.25983C12.4423 6.25983 13.6099 7.42748 13.6099 8.86852C13.6099 10.3096 12.4423 11.4772 11.0012 11.4772C9.56015 11.4772 8.3925 10.3096 8.3925 8.86852C8.3925 7.42748 9.56015 6.25983 11.0012 6.25983ZM7.08815 16.6957C7.08815 14.2946 8.60015 12.7826 11.0012 12.7826C13.4023 12.7826 14.9143 14.2946 14.9143 16.6957H7.08815Z"
        className="fill-current"
      />
    </svg>
  );
};

export default NPLUserShield;
