import React from 'react';

const LeadIcon = ({ width, height, fill, fillOpacity }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 12 12" fill="none">
      <path
        d="M10.5273 5.33665H3.08066L6.334 2.08332C6.594 1.82332 6.594 1.39665 6.334 1.13665C6.074 0.87665 5.654 0.87665 5.394 1.13665L1.00066 5.52998C0.740664 5.78998 0.740664 6.20998 1.00066 6.46998L5.394 10.8633C5.654 11.1233 6.074 11.1233 6.334 10.8633C6.594 10.6033 6.594 10.1833 6.334 9.92332L3.08066 6.66998H10.5273C10.894 6.66998 11.194 6.36998 11.194 6.00332C11.194 5.63665 10.894 5.33665 10.5273 5.33665Z"
        fill={fill}
        fillOpacity={fillOpacity}
      />
    </svg>
  );
};

LeadIcon.defaultProps = {
  width: 12,
  height: 12,
  fill: '#1B1B18',
  fillOpacity: 0.65
};

export default LeadIcon;
