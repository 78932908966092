import { Player } from '@lottiefiles/react-lottie-player';
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useDocument } from './ToastContainer/useDocument';

export function showConfetti() {
  if (!document) return;
  const showConfettiEvent = new CustomEvent('show-confetti');
  document.dispatchEvent(showConfettiEvent);
}

const ConfettiContainer = () => {
  const [show, setShow] = useState(false);
  const _document = useDocument();
  const portalEl = _document?.getElementById('confetti-container');

  useEffect(() => {
    function showConfetti() {
      setShow(true);

      setTimeout(() => {
        setShow(false);
      }, 2000);
    }

    document.addEventListener('show-confetti', showConfetti);

    return () => {
      document.removeEventListener('show-confetti', showConfetti);
    };
  }, []);

  if (!portalEl) return null;

  return createPortal(
    show ? (
      <div className="fixed top-0 left-1/2 -translate-x-1/2">
        <Player autoplay src="/animation/confetti.json" />
      </div>
    ) : null,
    portalEl
  );
};

export default ConfettiContainer;
