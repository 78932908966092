import React from 'react';

const className = 'c-IconV2-Website';

const Website = ({ customClassNames, width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      className={`${className} ${customClassNames}`}>
      <path
        d="M12.3217 20.9836C13.6982 20.788 14.9049 19.3514 15.6642 17.2297C14.5665 16.9839 13.4464 16.852 12.3217 16.8362V20.9836Z"
        fill="#222222"
      />
      <path
        d="M14.5204 20.646C14.6018 20.6225 14.6829 20.5988 14.7633 20.5727C14.8311 20.5508 14.8981 20.5273 14.965 20.5039C15.0441 20.4765 15.123 20.4484 15.2015 20.4186C15.2684 20.3931 15.3347 20.3661 15.4012 20.3391C15.4778 20.3069 15.5543 20.2749 15.63 20.2427C15.6962 20.2136 15.7618 20.1831 15.8275 20.1525C15.9018 20.1175 15.9758 20.0818 16.0491 20.0447C16.1135 20.0119 16.1778 19.9785 16.2422 19.9441C16.3143 19.9054 16.3859 19.866 16.457 19.8255C16.5215 19.789 16.5835 19.7524 16.6462 19.7147C16.7159 19.6718 16.7853 19.629 16.8543 19.5861C16.916 19.5468 16.9776 19.5069 17.0384 19.4658C17.1062 19.42 17.1732 19.3725 17.2398 19.3249C17.2996 19.282 17.3591 19.2392 17.4176 19.1962C17.482 19.1466 17.548 19.0952 17.6125 19.0437C17.6698 18.998 17.727 18.9526 17.7834 18.9056C17.8477 18.8522 17.9092 18.7969 17.9716 18.7416C18.0263 18.6931 18.0812 18.645 18.1346 18.5952C18.1961 18.5379 18.2559 18.4785 18.3157 18.4196C18.3676 18.3684 18.42 18.318 18.4708 18.2655C18.4802 18.2558 18.4892 18.2453 18.4989 18.2352C17.7972 17.8728 17.0582 17.5884 16.2946 17.387C15.8975 18.6534 15.1908 19.801 14.2386 20.7255C14.2647 20.7188 14.2908 20.7133 14.3169 20.7065C14.3857 20.6872 14.4532 20.666 14.5204 20.646Z"
        fill="#222222"
      />
      <path
        d="M20.9998 12.3233H17.1426C17.128 13.8293 16.9095 15.3264 16.4931 16.7739C17.3422 17.0007 18.1617 17.3268 18.9345 17.7454C20.2034 16.2161 20.9297 14.3093 20.9998 12.3233Z"
        fill="#222222"
      />
      <path
        d="M12.3217 11.6798H16.4995C16.4832 10.227 16.27 8.78314 15.8662 7.3875C14.7029 7.65281 13.5147 7.79449 12.3217 7.8102V11.6798Z"
        fill="#222222"
      />
      <path
        d="M12.3217 3.01953V7.16684C13.4464 7.15098 14.5665 7.01919 15.6642 6.77336C14.9049 4.65172 13.6982 3.21509 12.3217 3.01953Z"
        fill="#222222"
      />
      <path
        d="M12.3217 16.1929C13.5147 16.2087 14.7029 16.3504 15.8662 16.6157C16.27 15.2201 16.4832 13.7761 16.4995 12.3233H12.3217V16.1929Z"
        fill="#222222"
      />
      <path
        d="M18.9345 6.25777C18.1617 6.67639 17.3422 7.00248 16.4931 7.2293C16.9095 8.67676 17.128 10.1739 17.1426 11.6799H20.9998C20.9295 9.694 20.2032 7.78724 18.9345 6.25777Z"
        fill="#222222"
      />
      <path
        d="M18.4996 5.77077C18.4902 5.76119 18.4812 5.75082 18.4719 5.74092C18.421 5.68846 18.3683 5.63788 18.3164 5.58683C18.2567 5.52793 18.1972 5.46839 18.1356 5.41122C18.0824 5.36158 18.0272 5.31462 17.9726 5.26514C17.9102 5.20984 17.8479 5.15408 17.7838 5.10068C17.7282 5.05371 17.6712 5.00879 17.6146 4.9637C17.5502 4.91156 17.4842 4.85972 17.4179 4.80993C17.3598 4.7661 17.3009 4.72369 17.2417 4.68128C17.1745 4.6329 17.1069 4.58468 17.0384 4.53865C16.9783 4.49813 16.9172 4.45854 16.8561 4.41975C16.7865 4.37498 16.7171 4.33115 16.6462 4.28874C16.5842 4.25152 16.5215 4.21476 16.4587 4.1791C16.3867 4.13795 16.3146 4.09805 16.2419 4.0591C16.1775 4.0269 16.1133 3.99187 16.0489 3.95967C15.9749 3.92228 15.9003 3.88631 15.8238 3.85097C15.7594 3.82034 15.694 3.79049 15.6277 3.76144C15.5516 3.72766 15.4746 3.69719 15.3978 3.66499C15.3315 3.63797 15.2655 3.61127 15.1986 3.58582C15.1205 3.55598 15.0414 3.52802 14.9622 3.50069C14.8953 3.47713 14.8284 3.45372 14.7605 3.43173C14.6801 3.40566 14.5997 3.38194 14.517 3.35806C14.4501 3.33843 14.3829 3.31848 14.3152 3.30057C14.2893 3.2935 14.2629 3.288 14.2368 3.28125C15.189 4.20581 15.8956 5.35326 16.2928 6.61977C17.0571 6.4184 17.7973 6.13377 18.4996 5.77077Z"
        fill="#222222"
      />
      <path
        d="M3 11.6799H6.85719C6.8718 10.1739 7.09029 8.67676 7.50655 7.2293C6.65754 7.00263 5.83807 6.67654 5.06525 6.25777C3.79638 7.78708 3.07006 9.694 3 11.6799Z"
        fill="#222222"
      />
      <path
        d="M11.6783 20.9836V16.8362C10.5537 16.8521 9.43353 16.9839 8.33588 17.2297C9.09503 19.3514 10.3019 20.788 11.6783 20.9836Z"
        fill="#222222"
      />
      <path
        d="M11.6778 12.3233H7.50003C7.51637 13.7761 7.72936 15.2201 8.13337 16.6157C9.29668 16.3502 10.4847 16.2086 11.6778 16.1929V12.3233Z"
        fill="#222222"
      />
      <path
        d="M11.6783 3.01953C10.3019 3.21509 9.09503 4.65172 8.33588 6.77336C9.43353 7.01935 10.5537 7.15113 11.6783 7.16684V3.01953Z"
        fill="#222222"
      />
      <path
        d="M11.6778 7.8102C10.4848 7.79449 9.29668 7.65281 8.13337 7.3875C7.72936 8.78314 7.51637 10.227 7.50003 11.6798H11.6778V7.8102Z"
        fill="#222222"
      />
      <path
        d="M9.7598 3.28125C9.73372 3.288 9.70765 3.2935 9.68157 3.30026C9.6134 3.31848 9.54617 3.33874 9.47831 3.35837C9.39758 3.38194 9.31747 3.40566 9.23641 3.43142C9.16809 3.45356 9.10086 3.47713 9.03363 3.50053C8.95493 3.52833 8.87608 3.55598 8.79817 3.58551C8.73094 3.61127 8.66465 3.63797 8.59805 3.6653C8.52156 3.6975 8.4449 3.72971 8.36935 3.76175C8.30306 3.79081 8.23756 3.82128 8.1719 3.85191C8.0976 3.88694 8.02362 3.9226 7.95027 3.95967C7.88586 3.9925 7.82146 4.02611 7.75722 4.06035C7.68512 4.09899 7.61365 4.13858 7.54265 4.1791C7.47825 4.21507 7.41495 4.25199 7.3529 4.28969C7.283 4.33178 7.21389 4.37529 7.14509 4.41833C7.0832 4.45792 7.02178 4.49781 6.96099 4.53897C6.89314 4.58468 6.82622 4.63227 6.75962 4.67987C6.69977 4.72275 6.64024 4.76547 6.58165 4.80867C6.51584 4.85815 6.453 4.90967 6.38672 4.96103C6.32954 5.00674 6.27189 5.05214 6.21598 5.09942C6.15157 5.15251 6.09047 5.20749 6.02842 5.26247C5.97345 5.31147 5.918 5.35907 5.86428 5.4098C5.80318 5.46682 5.74364 5.52604 5.68395 5.58479C5.63212 5.636 5.57934 5.68657 5.52845 5.7392C5.51918 5.74893 5.51023 5.75962 5.50049 5.76951C6.202 6.13205 6.9412 6.41651 7.70475 6.61789C8.10185 5.35216 8.80806 4.20534 9.7598 3.28125Z"
        fill="#222222"
      />
      <path
        d="M5.68292 18.419C5.74277 18.4779 5.8023 18.5375 5.86372 18.5948C5.91713 18.6443 5.9721 18.6912 6.02692 18.7407C6.08928 18.7962 6.15164 18.8518 6.21573 18.9052C6.27133 18.9518 6.32804 18.9969 6.3849 19.0422C6.4493 19.0943 6.51355 19.1461 6.58172 19.1962C6.63968 19.2398 6.69858 19.2819 6.75749 19.3251C6.82472 19.3733 6.89258 19.4215 6.96106 19.4678C7.02122 19.5084 7.08232 19.5479 7.14343 19.5869C7.21301 19.6315 7.28244 19.6753 7.35328 19.7177C7.41533 19.7551 7.478 19.7917 7.54083 19.8275C7.61277 19.8687 7.68487 19.9086 7.7576 19.9474C7.82184 19.9796 7.88625 20.0146 7.95065 20.0468C8.02463 20.0842 8.09924 20.1202 8.17574 20.1557C8.24014 20.186 8.30548 20.2158 8.37177 20.2451C8.44795 20.2788 8.52476 20.3093 8.60173 20.3415C8.66802 20.3685 8.73399 20.3952 8.80091 20.4207C8.87897 20.4507 8.95814 20.4786 9.03731 20.506C9.10422 20.5294 9.17114 20.5529 9.23899 20.5748C9.31942 20.6008 9.39984 20.6247 9.48246 20.6484C9.54938 20.6681 9.61661 20.688 9.68415 20.7061C9.71023 20.7131 9.73662 20.7186 9.76269 20.7254C8.81049 19.8007 8.10395 18.6532 7.70671 17.3867C6.94315 17.5882 6.20395 17.8727 5.50244 18.2354C5.51187 18.245 5.52082 18.2554 5.53024 18.2653C5.57847 18.3174 5.63046 18.368 5.68292 18.419Z"
        fill="#222222"
      />
      <path
        d="M5.06525 17.7454C5.83807 17.3268 6.65754 17.0007 7.50655 16.7739C7.09029 15.3264 6.8718 13.8293 6.85719 12.3233H3C3.07021 14.3092 3.79638 16.2159 5.06525 17.7454Z"
        fill="#222222"
      />
    </svg>
  );
};

Website.defaultProps = {
  customClassNames: '',
  width: '24',
  height: '24'
};

export default Website;
