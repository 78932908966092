import React from 'react';

const NPLLoudSpeaker = ({ customClassNames }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      className={`c-NPLLoudSpeaker ${customClassNames}`}>
      <path
        d="M5.67342 4.88353L5.61998 4.13543L5.67342 4.88353ZM5.13982 9.07852L5.08639 9.82661L5.13982 9.07852ZM3.23441 8.2093L2.70957 8.74507L3.23441 8.2093ZM10.5833 2.92419V11.076H12.0833V2.92419H10.5833ZM3.75924 6.32663L3.78003 6.30627L2.73036 5.23474L2.70957 5.2551L3.75924 6.32663ZM3.78003 7.69389L3.75924 7.67353L2.70957 8.74507L2.73036 8.76543L3.78003 7.69389ZM5.19326 5.66974L5.72685 5.63162L5.61998 4.13543L5.08639 4.17355L5.19326 5.66974ZM5.72685 8.36854L5.19326 8.33043L5.08639 9.82661L5.61998 9.86473L5.72685 8.36854ZM11.5831 10.5808C9.88788 9.28923 7.85262 8.52038 5.72685 8.36854L5.61998 9.86473C7.45456 9.99577 9.21102 10.6593 10.674 11.774L11.5831 10.5808ZM5.72685 5.63162C7.85262 5.47978 9.88788 4.71093 11.5831 3.41934L10.674 2.2262C9.21102 3.34086 7.45456 4.00439 5.61998 4.13543L5.72685 5.63162ZM2.73036 8.76543C3.3651 9.38722 4.20009 9.76331 5.08639 9.82661L5.19326 8.33043C4.66162 8.29245 4.16077 8.06686 3.78003 7.69389L2.73036 8.76543ZM3.78003 6.30627C4.16077 5.9333 4.66163 5.70771 5.19326 5.66974L5.08639 4.17355C4.20009 4.23685 3.3651 4.61295 2.73036 5.23474L3.78003 6.30627ZM2.70957 5.2551C1.73176 6.21295 1.73177 7.78721 2.70957 8.74507L3.75924 7.67353C3.38187 7.30386 3.38187 6.6963 3.75924 6.32663L2.70957 5.2551ZM10.5833 11.076C10.5833 10.5608 11.1733 10.2686 11.5831 10.5808L10.674 11.774C11.2516 12.2141 12.0833 11.8021 12.0833 11.076H10.5833ZM12.0833 2.92419C12.0833 2.19802 11.2516 1.78611 10.674 2.2262L11.5831 3.41934C11.1733 3.73154 10.5833 3.43933 10.5833 2.92419H12.0833Z"
        fill="currentFill"
      />
      <path
        d="M14.0007 4H14.002V4.00133L13.334 4.66667L14.0007 4Z"
        stroke="#1b1b18"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3353 10H13.334V10.0013L14.002 10.6667L13.3353 10Z"
        stroke="#1b1b18"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.38959 8.46941C4.2806 8.06979 3.8683 7.83419 3.46868 7.94318C3.06906 8.05216 2.83346 8.46447 2.94244 8.86409L4.38959 8.46941ZM7.39362 9.15151C7.29316 8.74967 6.88596 8.50535 6.48411 8.60581C6.08227 8.70627 5.83795 9.11347 5.93841 9.51532L7.39362 9.15151ZM7.12333 11.1627L6.39572 11.3446L7.12333 11.1627ZM5.2807 12.6408L4.94529 13.3116L5.2807 12.6408ZM2.94244 8.86409L3.7778 11.9271L5.22494 11.5324L4.38959 8.46941L2.94244 8.86409ZM4.89029 13.2841L4.94529 13.3116L5.61611 11.9699L5.56111 11.9424L4.89029 13.2841ZM7.85093 10.9808L7.39362 9.15151L5.93841 9.51532L6.39572 11.3446L7.85093 10.9808ZM7.09213 13.118C7.75198 12.6231 8.05098 11.781 7.85093 10.9808L6.39572 11.3446C6.44939 11.5593 6.36917 11.7852 6.19213 11.918L7.09213 13.118ZM4.94529 13.3116C5.63981 13.6588 6.47093 13.5839 7.09213 13.118L6.19213 11.918C6.02545 12.043 5.80246 12.0631 5.61611 11.9699L4.94529 13.3116ZM3.7778 11.9271C3.93871 12.5171 4.34331 13.0106 4.89029 13.2841L5.56111 11.9424C5.39583 11.8598 5.27357 11.7107 5.22494 11.5324L3.7778 11.9271Z"
        fill="currentFill"
      />
      <path
        d="M13.334 6.58325C12.9198 6.58325 12.584 6.91904 12.584 7.33325C12.584 7.74747 12.9198 8.08325 13.334 8.08325V6.58325ZM14.0007 8.08325C14.4149 8.08325 14.7507 7.74747 14.7507 7.33325C14.7507 6.91904 14.4149 6.58325 14.0007 6.58325V8.08325ZM13.334 8.08325H14.0007V6.58325H13.334V8.08325Z"
        fill="currentFill"
      />
    </svg>
  );
};

export default NPLLoudSpeaker;
