import React from 'react';

const ScreenSearchDesktopIcon = ({ fill, width, height, strokeColor }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 25"
      fill="none"
      stroke={strokeColor}>
      <path
        d="M4.04098 18.0351C3.49098 18.0335 3.02089 17.8364 2.6307 17.444C2.23986 17.0508 2.04524 16.5792 2.04685 16.0292L2.07909 5.02929C2.08071 4.47929 2.27809 4.00887 2.67123 3.61802C3.06371 3.22784 3.53495 3.03355 4.08495 3.03516L20.0849 3.08206C20.6349 3.08368 21.1053 3.28072 21.4962 3.6732C21.8863 4.06635 22.0806 4.53792 22.079 5.08792L22.0468 16.0879C22.0452 16.6379 21.8481 17.1083 21.4556 17.4991C21.0625 17.8893 20.5909 18.0836 20.0409 18.082L4.04098 18.0351ZM4.04684 16.0351L20.0468 16.082L20.079 5.08205L4.07909 5.03515L4.04684 16.0351ZM2.03219 21.0292C1.74886 21.0284 1.51181 20.9317 1.32104 20.7391C1.1296 20.5472 1.0343 20.3096 1.03513 20.0263C1.03596 19.743 1.13265 19.5059 1.32521 19.3151C1.51711 19.1237 1.75472 19.0284 2.03805 19.0292L22.038 19.0879C22.3213 19.0887 22.5584 19.1854 22.7491 19.3779C22.9406 19.5698 23.0359 19.8075 23.035 20.0908C23.0342 20.3741 22.9375 20.6112 22.7449 20.8019C22.5531 20.9934 22.3154 21.0887 22.0321 21.0878L2.03219 21.0292Z"
        stroke={strokeColor}
      />
      <path
        d="M10.3693 12.5671L11.0399 14.0691C11.1227 14.2694 11.264 14.3698 11.464 14.3704C11.664 14.3709 11.806 14.2714 11.8899 14.0716L12.5693 12.5736L14.0713 11.903C14.2715 11.8202 14.3719 11.6789 14.3725 11.4789C14.3731 11.2789 14.2735 11.1369 14.0738 11.053L12.5758 10.3736L11.9052 8.87163C11.8058 8.65467 11.6644 8.54592 11.4811 8.54538C11.2978 8.54484 11.1558 8.65276 11.0552 8.86913L10.3758 10.3671L8.87379 11.0377C8.65684 11.1371 8.54809 11.2785 8.54755 11.4618C8.54701 11.6451 8.65493 11.7871 8.8713 11.8877L10.3693 12.5671Z"
        stroke={strokeColor}
      />
      <path
        d="M13.356 8.60088L13.6789 9.30183C13.7617 9.4854 13.8614 9.57736 13.9781 9.57771C14.0948 9.57805 14.195 9.48667 14.2789 9.30359L14.606 8.60454L15.3069 8.2816C15.4905 8.1988 15.5824 8.09907 15.5828 7.9824C15.5831 7.86574 15.4917 7.76547 15.3087 7.6816L14.6096 7.35455L14.2867 6.6536C14.2039 6.47002 14.1041 6.37806 13.9875 6.37772C13.8708 6.37738 13.7705 6.46875 13.6867 6.65184L13.3596 7.35088L12.6587 7.67383C12.4751 7.75663 12.3831 7.85636 12.3828 7.97302C12.3825 8.08969 12.4738 8.18996 12.6569 8.27383L13.356 8.60088Z"
        stroke={strokeColor}
      />
    </svg>
  );
};

ScreenSearchDesktopIcon.defaultProps = {
  fill: '#AAABAE',
  width: 25,
  height: 25,
  strokeColor: '#AAABAE'
};
export default ScreenSearchDesktopIcon;
