import React from 'react';

const Link = ({ customClassNames, fill }) => {
  return (
    <svg
      className={`c-Location ${customClassNames}`}
      width="24"
      height="24"
      viewBox="0 0 16 16">
      <path
        fill={fill}
        d="M7.9935 1.33344C4.3135 1.33344 1.3335 4.3201 1.3335 8.0001C1.3335 11.6801 4.3135 14.6668 7.9935 14.6668C11.6802 14.6668 14.6668 11.6801 14.6668 8.0001C14.6668 4.3201 11.6802 1.33344 7.9935 1.33344ZM12.6135 5.33344H10.6468C10.4335 4.5001 10.1268 3.7001 9.72683 2.9601C10.9535 3.3801 11.9735 4.23344 12.6135 5.33344ZM8.00016 2.69344C8.5535 3.49344 8.98683 4.3801 9.2735 5.33344H6.72683C7.0135 4.3801 7.44683 3.49344 8.00016 2.69344ZM2.84016 9.33344C2.7335 8.90677 2.66683 8.4601 2.66683 8.0001C2.66683 7.5401 2.7335 7.09344 2.84016 6.66677H5.0935C5.04016 7.10677 5.00016 7.54677 5.00016 8.0001C5.00016 8.45344 5.04016 8.89344 5.0935 9.33344H2.84016ZM3.38683 10.6668H5.3535C5.56683 11.5001 5.8735 12.3001 6.2735 13.0401C5.04683 12.6201 4.02683 11.7734 3.38683 10.6668ZM5.3535 5.33344H3.38683C4.02683 4.22677 5.04683 3.3801 6.2735 2.9601C5.8735 3.7001 5.56683 4.5001 5.3535 5.33344ZM8.00016 13.3068C7.44683 12.5068 7.0135 11.6201 6.72683 10.6668H9.2735C8.98683 11.6201 8.5535 12.5068 8.00016 13.3068ZM9.56016 9.33344H6.44016C6.38016 8.89344 6.3335 8.45344 6.3335 8.0001C6.3335 7.54677 6.38016 7.1001 6.44016 6.66677H9.56016C9.62016 7.1001 9.66683 7.54677 9.66683 8.0001C9.66683 8.45344 9.62016 8.89344 9.56016 9.33344ZM9.72683 13.0401C10.1268 12.3001 10.4335 11.5001 10.6468 10.6668H12.6135C11.9735 11.7668 10.9535 12.6201 9.72683 13.0401ZM10.9068 9.33344C10.9602 8.89344 11.0002 8.45344 11.0002 8.0001C11.0002 7.54677 10.9602 7.10677 10.9068 6.66677H13.1602C13.2668 7.09344 13.3335 7.5401 13.3335 8.0001C13.3335 8.4601 13.2668 8.90677 13.1602 9.33344H10.9068Z"
      />
    </svg>
  );
};

Link.defaultProps = {
  customClassNames: '',
  fill: '#A3A3A3'
};

export default Link;
