/**
 * Sample queryString = '&coupon=ABC&email=abc@gmail.com
 * Sample Response : {
 *  coupon: 'ABC',
 *  email: 'abc@gmail.com'
 * }
 */
export const convertQueryStringToJson = (queryString) => {
  if (!queryString) return {};
  const pairs = queryString.slice(1).split('&');

  const responseJson = pairs.reduce((acc, pair) => {
    const keyValPair = pair.split('=');
    const key = keyValPair[0];
    const val = decodeURIComponent(keyValPair[1] || '');
    acc[key] = val;

    return acc;
  }, {});

  return responseJson;
};

export const parseFullName = (fullName) => {
  const nameTokens = fullName.trim().split(' ');
  let firstName = '';
  let lastName = '';
  const tokensLength = nameTokens.length;
  if (tokensLength > 0) {
    lastName = nameTokens[tokensLength - 1];
    const firstNameTokens = nameTokens.slice(0, tokensLength - 1);
    firstName = firstNameTokens.join(' ');
  }

  return {
    firstName,
    lastName
  };
};

export const getEllipsisTextFormatted = (str) => {
  try {
    if (str.length <= 12) return str;
    return `${str.substr(0, 6)}...${str.slice(-3)}`;
  } catch (e) {
    return str;
  }
};

export const convertLocaleStringToNumber = (str) => str.replace(/,/g, '');

export const commaSeperate = (num) => {
  if (isNaN(Number(num))) {
    return num;
  }

  const numberFormat = Intl.NumberFormat();
  return numberFormat.format(Number(num));
};

//Remove -, (, ) and space
export const sanitisePhoneNumber = (number) => {
  return number
    .replace('-', '')
    ?.replace('(', '')
    ?.replace(')', '')
    .replace(' ', '');
};

export const hashEmail = (email) => {
  if (!email) {
    return '';
  }

  const hashedEmail = email.replace(
    /(\w{2})[\w.-/+]+(\w{2})@([\w.]+\w)/,
    '$1**$2@$3'
  );
  return hashedEmail;
};

export const sanitizeHtml = (htmlString) => {
  let result = htmlString;

  //remove script elements from HTML string
  const regex = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi;
  result = result.replace(regex, '');

  //`className` is not applicable in templates, so we reserve `class` to prevent mistakes
  result = result.replaceAll('className', 'class');

  return result;
};

export const getPrimaryDomain = () => {
  //server-side rendering does not have window object
  if (typeof window === 'undefined') {
    return '';
  }

  const parts = window.location.hostname.split('.');
  let primaryDomain = parts.slice(-2).join('.');

  //the preview links are dynamic, so we cannot apply any specific domain to cookies
  if (primaryDomain === 'vercel.app') {
    primaryDomain = '';
  }

  return primaryDomain;
};

export const removeSpecialChars = (str) => {
  try {
    // only keep english chars, number, hyphen -, underscore _ and dot .
    return str.replace(/[^a-zA-Z0-9-_. ]/g, '');
  } catch (e) {
    return str;
  }
};

export const addTimestampToFileName = (fileName) => {
  try {
    const fileNameWithoutExtension = fileName
      .split('.')
      .slice(0, -1)
      .join('.');
    const fileExtension = fileName.split('.').pop();
    const fileNameWithTimestamp = removeSpecialChars(
      `${fileNameWithoutExtension}-${new Date().getTime()}.${fileExtension}`
    );

    return fileNameWithTimestamp;
  } catch (e) {
    return fileName;
  }
};
