import React from 'react';

const Course = () => {
  return (
    <svg
      className="c-Course"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20">
      <path
        fill="#191C1E"
        d="M6.667 3.334a.807.807 0 01-.595-.24.806.806 0 01-.239-.594c0-.236.08-.434.24-.594.16-.16.357-.239.593-.239h6.667c.236 0 .434.08.594.24.16.16.24.357.24.593s-.08.434-.24.594a.804.804 0 01-.594.24H6.666zm-2.5 2.5a.808.808 0 01-.595-.24A.808.808 0 013.333 5c0-.236.08-.434.24-.594.16-.16.357-.239.594-.239h11.666c.236 0 .434.08.594.24.16.16.24.357.24.593s-.08.434-.24.594a.806.806 0 01-.594.24H4.167zm-.834 12.5c-.458 0-.85-.163-1.177-.49a1.606 1.606 0 01-.49-1.177V8.334c0-.459.164-.851.49-1.178.327-.326.719-.489 1.177-.489h13.334c.458 0 .85.163 1.177.49.326.326.49.718.49 1.177v8.333c0 .458-.164.85-.49 1.177-.327.327-.72.49-1.178.49H3.333zm0-1.667h13.334V8.334H3.332v8.333zm6.292-1.688l2.666-1.791a.784.784 0 00.375-.688.784.784 0 00-.375-.687L9.625 10.02c-.278-.194-.563-.208-.854-.042a.811.811 0 00-.438.75v3.542c0 .333.146.583.438.75.291.167.576.153.854-.042z"></path>
    </svg>
  );
};

export default Course;
