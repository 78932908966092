import React from 'react';

const TiktokFooterIcon = ({ customClassNames }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={`${customClassNames}`}>
      <g id="footer-icon-Tiktok">
        <path
          id="Vector"
          d="M12.4548 2.01688C13.5846 2 14.708 2.01 15.83 2C15.898 3.27569 16.3732 4.57514 17.3405 5.47706C18.3059 6.40148 19.6714 6.82463 21 6.96776V10.3236C19.7549 10.2842 18.504 10.0342 17.3742 9.51665C16.8821 9.30164 16.4237 9.02475 15.975 8.74161C15.9692 11.1767 15.9854 13.6087 15.9589 16.0339C15.8915 17.1989 15.4933 18.3584 14.7915 19.3184C13.6623 20.9166 11.7024 21.9586 9.68946 21.9911C8.45475 22.0592 7.22133 21.7342 6.1692 21.1354C4.42559 20.1428 3.19864 18.3259 3.01994 16.3758C2.99743 15.9628 2.99398 15.549 3.00958 15.1357C3.16497 13.55 3.97754 12.033 5.2388 11.0011C6.6684 9.79917 8.671 9.22664 10.5461 9.5654C10.5635 10.7998 10.5124 12.033 10.5124 13.2675C9.65579 13 8.65482 13.075 7.90635 13.5769C7.35875 13.9251 6.94493 14.4375 6.72861 15.0351C6.54991 15.4576 6.60106 15.927 6.61142 16.3758C6.81667 17.7433 8.17893 18.8928 9.63313 18.7684C10.5972 18.7584 11.5211 18.2184 12.0236 17.4277C12.1861 17.1508 12.368 16.8677 12.3777 16.542C12.4625 15.0513 12.4289 13.5669 12.4392 12.0762C12.4464 8.71661 12.4289 5.36643 12.4554 2.0175L12.4548 2.01688Z"
          fill="#FBFCFF"
        />
      </g>
    </svg>
  );
};

TiktokFooterIcon.defaultProps = {
  customClassNames: ''
};

export default TiktokFooterIcon;
