export const HOMEPAGE_CTA_CLICK_EVENT = 'click_cta_homepage';
export const PRODUCT_DEMO_CTA_CLICK_EVENT = 'click_cta_product_demo';
export const NAVBAR_ITEM_CLICK_EVENT = 'click_navbar_link';
export const FOOTER_ITEM_CLICK_EVENT = 'click_footer_link';
export const HOMEPAGE_WATCH_VIDEO_EVENT = 'watch_home_video_click';
export const HOMEPAGE_WATCH_VIDEO_COMPLETED_EVENT = 'watched_home_video';
export const HOMEPAGE_WATCH_PRODUCT_DEMO_VIDEO_EVENT =
  'watch_home_product_demo_video_click';
export const HOMEPAGE_WATCH_PRODUCT_DEMO_VIDEO_COMPLETED_EVENT =
  'watched_home_product_demo_video';
export const HOMEPAGE_LEARN_MORE_EVENT = 'click_homepage_learn_more';

export const LOGOUT_EVENT = 'logout_success';
export const HOME_CREATE_ANNOUNCEMENT_EVENT = 'home_create_announcement';
export const HOME_CREATE_ANNOUNCEMENT_SUCCESS_EVENT =
  'home_create_announcement_success';
export const HOME_EDIT_ANNOUNCEMENT_EVENT = 'home_edit_announcement';
export const HOME_DELETE_ANNOUNCEMENT_EVENT = 'home_delete_annoucement';
export const HOME_PIN_ANNOUNCEMENT_EVENT = 'home_pin_announcement';
export const HOME_UNPIN_ANNOUNCEMENT_EVENT = 'home_unpin_announcement';
export const HOME_CLICKED_COPY_INVITE_LINK_EVENT =
  'clicked_copy_link_home';

export const EVENTS_ADD_EVENT = 'events_add_event';
export const EVENTS_ADD_SUCCESS_EVENT = 'events_add_event_success';
export const EVENTS_EDIT_EVENT = 'events_edit_event';
export const EVENTS_EDIT_SUCCESS_EVENT = 'events_edit_event_success';
export const EVENTS_DELETE_EVENT = 'events_delete_event';
export const EVENTS_DUPLICATE_EVENT = 'events_duplicate_event';
export const EVENTS_SHARE_EVENT = 'events_share';

export const LIB_ADD_COURSE_SUCCESS_EVENT = 'library_add_course_success';
export const LIB_ADD_COURSE_EVENT = 'library_add_course';
export const LIB_EDIT_COURSE_SUCCESS_EVENT = 'library_edit_course_details';
export const LIB_EDIT_COLLECTION_SUCCESS_EVENT =
  'library_edit_collection_details';
export const LIB_COURSE_PUBLISH_EVENT = 'library_course_publish';
export const LIB_MANAGE_COURSE_EVENT = 'library_manage_course_content';
export const LIB_DELETE_COURSE_EVENT = 'library_delete_course';
export const LIB_DELETE_COLLECTION_EVENT = 'library_delete_collection';
export const LIB_COURSE_ADD_VIDEO_EVENT = 'library_add_video';
export const LIB_COURSE_ADD_FILE_EVENT = 'library_add_file';
export const LIB_COURSE_DELETE_VIDEO_EVENT = 'library_delete_video';
export const LIB_COURSE_DELETE_FILE_EVENT = 'library_delete_file';
export const LIB_COURSE_SHARE_EVENT = 'library_course_share';
export const LIB_COLLECTION_SHARE_EVENT = 'library_collection_share';

export const CMP_LOGIN_EVENT = 'cmp_login_on_login_page';
export const MEMBER_LOGIN_EVENT = 'member_login_on_login_page';

export const CLICKED_MEMBER_VIEW_EVENT = 'clicked_member_view';
export const CLICKED_VIEW_PROFILE_EVENT = 'clicked_profile';

export const CLICKED_GETTING_STARTED_SIDENAV_EVENT =
  'clicked_getting_started';
export const CLICKED_HOME_SIDENAV_EVENT = 'clicked_home';
export const CLICKED_MEMBERS_SIDENAV_EVENT = 'clicked_members';
export const CLICKED_EVENTS_SIDENAV_EVENT = 'clicked_events';
export const CLICKED_ANALYTICS_SIDENAV_EVENT = 'clicked_analytics';
export const CLICKED_LIBRARY_SIDENAV_EVENT = 'clicked_library';
export const CLICKED_PUBLIC_PAGE_SIDENAV_EVENT = 'clicked_publicpage';
export const CLICKED_SETTINGS_SIDENAV_EVENT = 'clicked_settings';
export const CLICKED_MAGIC_REACH_SIDENAV_EVENT = 'clicked_magicreach';
export const CLICKED_CHAT_SIDENAV_EVENT = 'clicked_chat';
export const CLICKED_MONEY_PAGE_SIDENAV_EVENT = 'clicked_money_tab';
export const CLICKED_FEED_PAGE_SIDENAV_EVENT = 'clicked_feed_tab';

// price settings change
export const PRICE_SETTINGS_CHANGE = 'clicked_save_changes_pricing';
export const PRICE_SETTINGS_CONFIRM_CHANGES =
  'clicked_confirm_saved_changes_pricing';
export const CLICKED_EXPORT_MEMBERS_EVENT = 'clicked_export_member';

export const PUBLIC_PAGE_CHANGE_COMMUNITY_IMAGE_EVENT =
  'publicpage_change_community_image';
export const PUBLIC_PAGE_CHANGE_COMMUNITY_COVER_EVENT =
  'publicpage_change_community_cover';
export const PUBLIC_PAGE_CHANGE_COMMUNITY_TITLE_EVENT =
  'publicpage_change_community_title';
export const PUBLIC_PAGE_CHANGE_COMMUNITY_DESC_EVENT =
  'publicpage_change_community_description';
export const PUBLIC_PAGE_CHANGE_COMMUNITY_HOST_EVENT =
  'publicpage_change_community_host';
export const PUBLIC_PAGE_CHANGE_COMMUNITY_URL_EVENT =
  'publicpage_change_community_url';
export const PUBLIC_PAGE_VISIT_CLICK_EVENT = 'publicpage_visit_page';
export const PUBLIC_PAGE_COPY_LINK_EVENT = 'public_page_copy_link';

export const INVITE_MEMBER_BY_EMAIL_EVENT = 'invite_members_by_email';

export const MONEY_PAGE_CLICKED_CREATE_PAID_EVENT =
  'money_clicked_create_event';
export const MONEY_PAGE_CLICKED_OPEN_EDIT_MEMBERSHIP_MODAL =
  'money_clicked_paid_membership';
export const MONEY_PAGE_EDIT_COMMUNITY_PRICE_EVENT =
  'money_clicked_paid_membership_success';
export const MONEY_PAGE_CLICKED_PAYOUT_FORM = 'money_clicked_payout_form';
export const MONEY_PAGE_CLICKED_OPEN_EDIT_REVENUE_TARGET_MODAL =
  'money_clicked_edit_revenue_target';
export const MONEY_PAGE_EDIT_REVENUE_TARGET_SUCCESS_EVENT =
  'money_clicked_edit_revenue_target_success';
export const CHANGE_MEMBER_ACCESS_TYPE = 'settings_change_access_type';

export const TRACK_PRODUCT_DEMO_START_COMMUNITY_NAV =
  'product_demo_start_community_nav';

export const TRACK_PRODUCT_DEMO_START_COMMUNITY_TIMEOUT_MODAL =
  'product_demo_start_community_timeout_modal';
export const TRACK_PRODUCT_DEMO_START_COMMUNITY_ACTION_BLOCKED_MODAL =
  'product_demo_start_community_action_blocked_modal';
export const TRACK_PRODUCT_DEMO_PAGE_START_DEMO_CLICK =
  'product_demo_page_start_demo_buttn_click';

export const CONTACT_US_FORM_SUBMISSION = 'contact_us_form_submit';
export const SET_COMMUNITY_ACCESS_TYPE_EVENT = 'set_community_access_type';

export const CLICK_GET_EARLY_ACCESS_WHATSAPP = 'click_whatsapp_get_beta';
export const SUBMIT_GET_EARLY_ACCESS_WHATSAPP_SUCCESS =
  'submit_whatsapp_get_beta_success';

//---- Whatsapp Member Sign up analytics ------
export const INITIATE_CHECKOUT = 'initiate_checkout';
export const SIGNUP_SUCCESS = 'signup_success';
export const CHECKOUT_FILL_WHATSAPP_NUMBER =
  'checkout_fill_whatsapp_number';
//---- Whatsapp Member Sign up analytics ------

/*Referral events*/
export const VISIT_REFERRAL_LINK = 'visit_referral_link';

/*Event*/
export const COMPOSE_MESSAGE_TO_EVENT_ATTENDEES =
  'compose_message_to_event_attendees';

export const TRACK_ROUTE_CHANGE = 'route_change';
